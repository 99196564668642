import { useContext, useEffect, useState } from "react";
import { UsersContext } from "../contexts/UsersContext";

/**
 * Hook for all users functionality
 * @returns All usable variables and functions from this hook
 */
const useUsers = () => {
    const { users, usersMap, loading, admins } = useContext(UsersContext);

    const getUserByID = (userId) => {
        if (!usersMap || usersMap.size <= 0) {
            console.error("Error trying to get user by ID: No users!");
            return;
        }

        if (usersMap.has(userId)) {
            return usersMap.get(userId);
        }
        return admins.find(a => a.id === userId);
    }

    return { users, loading, getUserByID }; // Access all context values directly
};

export default useUsers;