import React, { useEffect, useState } from 'react';
import { useInworld } from '../../../hooks/useInworld';

const Knowledge = () => {
    const { character, knowledge, setKnowledgeField, addKnowledgeField, removeKnowledgeField} = useInworld();

    // Render the UI based on loading, error, and knowledgeArray states
    if (!character) return <div>Loading...</div>;

    return (
        <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-6 flex justify-center">
            <div className="max-w-4xl w-full bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
                <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-4">Knowledge & Cognition</h3>
                <div className="space-y-4">
                    {knowledge.map((entry, index) => (
                        <div key={index} className="flex items-center space-x-4">
                            <input
                                type="text"
                                value={entry.text}
                                onChange={(e) => setKnowledgeField(index, e.target.value)}
                                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                                placeholder={`Knowledge entry ${index + 1}`}
                            />
                            <button
                                onClick={() => removeKnowledgeField(index)}
                                className="text-red-500 hover:text-red-700 focus:outline-none"
                            >
                                &times;
                            </button>
                        </div>
                    ))}
                </div>
                <button
                    onClick={addKnowledgeField}
                    className="mt-4 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300"
                >
                    Add Knowledge Entry
                </button>
            </div>
        </div>
    );
};

export default Knowledge;
