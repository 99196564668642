import React, { useState } from 'react'
import DataTable from '../components/shared/Datatable';
import defaultImage from '../assets/images/22.png';
import useUsers from '../hooks/useUsers';
import { DateTimeFormat } from 'intl';
import { HiPlus, HiUsers } from 'react-icons/hi';
import useSchool from '../hooks/useSchool';
import { render } from '@testing-library/react';
import Searchbar from '../components/shared/Searchbar';
import { usePopUp } from '../hooks/usePopUp';
import InviteUser from '../components/authentication/IniviteUser';
import UserInfo from '../components/users/UserInfo';
import { TabsUnderlined } from '../components/shared/TabNavigation';
import useRegistrations from '../hooks/useRegistrations';
import { NotificationTypes } from '../utils/notifications';
import { useNotification } from '../hooks/useNotification';
import { auth } from '../firebase/firebase';
import UserRow from '../components/shared/UserRow';

const Tabs = {
    USERS: "Users",
    REGISTRATIONS: "Registrations"
}

const tabs = [Tabs.USERS, Tabs.REGISTRATIONS];

const Users = () => {
    const { showPopUp } = usePopUp();
    const [tab, setTab] = useState(0);

    const handleAddUserClick = () => {
        showPopUp(<InviteUser />);
    };

    const handleAddMultipleUserClick = () => {
        // showPopUp(<MultiInviteUser onClose={() => showPopUp(null)} currentUser={user} />);
    };

    return (
        <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen w-full h-full">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 h-full flex flex-col">
                <div className="flex justify-between items-center space-x-2 p-8">
                    <div className="flex items-center">
                        <HiUsers className="text-4xl text-gray-700 dark:text-gray-300" />
                        <h1 className="text-xl px-2 font-semibold text-gray-900 dark:text-white">
                            {tabs[tab]}
                        </h1>
                    </div>
                    <div className="flex space-x-2 justify-end">
                        <button
                            onClick={handleAddUserClick}
                            className="flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium"
                        >
                            <HiPlus className="h-5 w-5 mr-2" />
                            Invite User
                        </button>
                        {/* <button
                            onClick={handleAddMultipleUserClick}
                            className="flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium"
                        >
                            <HiPlus className="h-5 w-5 mr-2" />
                            Invite Multiple
                        </button> */}
                    </div>
                </div>

                <TabsUnderlined tabs={tabs} activeTab={tab} setActiveTab={setTab} />

                {tabs[tab] === Tabs.USERS ? <UsersTable /> : < RegistrationsTable />}
            </div>
        </div>
    )
}

const UsersTable = () => {
    const { showPopUp } = usePopUp();
    const { users, loading } = useUsers();
    //Filtered users by searchbar, this is allowed in the page since this is only required as gateway between the search bar and the datatable
    const [filteredUsers, setFilteredUsers] = useState(users);

    const { showNotification } = useNotification();

    const handleShowUserClick = (userId) => {
        showPopUp(<UserInfo userId={userId} onClose={() => showPopUp(null)} />);
    }

    const columns = [
        {
            key: "name",
            label: "NAME",
            render: (_, user) => {
                return (
                    <UserRow user={user} />);
            }
        },
        {
            key: "role",
            label: "ROLE",
            render: (_, user) => {
                return (
                    (
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${user.role?.trim() === 'Teacher'
                            ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                            : user.role?.trim() === 'Student'
                                ? 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300'
                                : user.role?.trim() === 'Admin'
                                    ? 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300'
                                    : 'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-300'
                            }`}>
                            {user.role?.trim() || 'N/A'}
                        </span>
                    )
                );
            }
        },
        {
            key: "createdAt",
            label: "DATE JOINED",
            render: (date, user) => {
                const formattedDate = date.toDate().toLocaleString().split(",")[0];
                return (<div>{formattedDate}</div>)
            }
        }
    ];

    return (
        <div>
            {/* Searchbar */}
            <Searchbar
                data={users} // Pass the original users array
                searchLabel={"name"}
                filterKeys={["firstName", "lastName"]} // Define the key to filter by
                onFilter={setFilteredUsers} // Update the filtered data
            />

            {/* Data table */}
            <div className="flex-grow">
                <DataTable
                    rows={filteredUsers}
                    columns={columns}
                    isLoading={loading}
                    onViewClick={
                        // (e) => { console.log(e) }
                        handleShowUserClick
                    } />
            </div>
        </div>
    );
}


const RegistrationsTable = () => {
    const { registrations, loading, deleteRegistration } = useRegistrations();
    //Filtered users by searchbar, this is allowed in the page since this is only required as gateway between the search bar and the datatable
    const [filteredRegistrations, setFilteredRegistrations] = useState(registrations);


    const columns = [
        {
            key: "name",
            label: "NAME",
            render: (_, user) => {
                return (
                    <div className="text-sm font-medium text-gray-900 dark:text-white">
                        {`${user.firstName} ${user.lastName}`}
                    </div>);
            }
        },
        {
            key: "email",
            label: "EMAIL",
        },
        {
            key: "role",
            label: "ROLE",
            render: (_, user) => {
                return (
                    (
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${user.role?.trim() === 'Teacher'
                            ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                            : user.role?.trim() === 'Student'
                                ? 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300'
                                : user.role?.trim() === 'Admin'
                                    ? 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300'
                                    : 'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-300'
                            }`}>
                            {user.role?.trim() || 'N/A'}
                        </span>
                    )
                );
            }
        },
        {
            key: "createdAt",
            label: "DATE INVITED",
            render: (date, user) => {
                const formattedDate = date.toDate().toLocaleString().split(",")[0];
                return (<div>{formattedDate}</div>)
            }
        }
    ];


    return (
        <div>
            {/* Searchbar */}
            <Searchbar
                data={registrations} // Pass the original users array
                searchLabel={"name"}
                filterKeys={["firstName", "lastName"]} // Define the key to filter by
                onFilter={setFilteredRegistrations} // Update the filtered data
            />

            {/* Data table */}
            <div className="flex-grow">
                <DataTable
                    rows={filteredRegistrations}
                    columns={columns}
                    isLoading={loading}
                    onDeleteClick={(registration) => {
                        deleteRegistration(registration.id);
                    }}
                />
            </div>
        </div>
    );
}

export default Users;