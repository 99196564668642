import React, { useState, useEffect } from 'react';

const Accordion = ({ title, children, onRemove, isRemovable }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="border-b border-gray-300 mb-4">
            <div className="flex justify-between items-center">
                <button
                    className="w-full flex justify-between items-center p-4 text-left focus:outline-none"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <span className="font-semibold">{title}</span>
                    <span>{isOpen ? '-' : '+'}</span>
                </button>
                {isRemovable && (
                    <button
                        className="text-red-500 hover:text-red-700 px-2"
                        onClick={(e) => {
                            e.stopPropagation();
                            onRemove();
                        }}
                    >
                        &#10005;
                    </button>
                )}
            </div>
            {isOpen && <div className="p-4 bg-gray-50">{children}</div>}
        </div>
    );
};

export default Accordion;