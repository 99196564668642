import { Timestamp } from "firebase/firestore";
import useAssignments from "../../hooks/useAssignments";
import useCourses from "../../hooks/useCourses";
import { usePopUp } from "../../hooks/usePopUp";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { convertTimePickerValueToTimestamp, convertToTimePickerValue, fromFirebaseTimestamp, toFirebaseTimestamp } from "../../utils/utils";
import useAuth from "../../hooks/useAuth";
import { HiX } from "react-icons/hi";

const CreateAssignment = ({ courseId, scenarioId = null }) => {
    const { role } = useAuth();
    const { showPopUp } = usePopUp();
    const { createAssignment } = useAssignments();
    const { courses, getCourseById } = useCourses();

    const [name, setName] = useState("");
    const [dueDate, setDueDate] = useState(Timestamp.fromDate(new Date()));
    const [selectedCourseId, setselectedCourseId] = useState("");

    const navigate = useNavigate();

    const onClose = () => {
        showPopUp(null);
    }

    const handleSubmit = async () => {
        onClose();
        const newId = await createAssignment(name, dueDate, courseId ? courseId : selectedCourseId, scenarioId);
        if (newId) {
            navigate(`/${role}/assignments/${newId}?edit`,);
        }
    }

    return (
        <div className={`fixed inset-0 z-50 flex items-center justify-center`}>
            <div className="relative w-full max-w-2xl bg-white rounded-lg shadow dark:bg-gray-800 overflow-hidden max-h-screen p-4">
                <div className="overflow-auto max-h-[80vh]">
                    <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
                        <h3 className="text-lg font-medium text-gray-900 dark:text-gray-300 mb-2">Create Assignment</h3>
                        <button
                            onClick={onClose}
                            type="button"
                            className="text-red-600 bg-transparent hover:bg-red-100 hover:text-red-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center justify-center dark:hover:bg-red-800 dark:hover:text-red-400"
                            style={{ marginTop: '8px', marginRight: '8px' }}  // Extra marge toegevoegd
                        >
                            <span className="sr-only">Close modal</span>
                            <HiX />
                        </button>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Assignment name</label>
                            <input
                                type="text"
                                id="assignmentName"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                                placeholder="Enter assignment name"
                                required
                            />
                        </div>

                        {/* Due Date Input */}
                        <div className="mb-6">
                            <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Due date</label>
                            <input
                                type="date"
                                id="formattedDueDate"
                                value={fromFirebaseTimestamp(dueDate)}
                                onChange={(e) => {
                                    e.preventDefault();
                                    setDueDate(toFirebaseTimestamp(e.target.value));
                                }}
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                                required
                            />
                        </div>

                        {/* Due Time Input */}
                        <div className="mb-6">
                            <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Due time</label>
                            <input
                                type="time"
                                id="formattedDueTime"
                                value={convertToTimePickerValue(dueDate)}
                                onChange={(e) => {
                                    e.preventDefault();
                                    setDueDate(convertTimePickerValueToTimestamp(dueDate, e.target.value));
                                }}
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                                required
                            />
                        </div>

                        {/* Course Selection */}
                        {!courseId ? (
                            <fieldset className="mb-4">
                                <legend className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Course</legend>

                                <div className="max-h-64 overflow-y-auto mb-4">
                                    {courses.length > 0 ? (
                                        courses.map((course) => (
                                            <div key={course.id} className="course-item">
                                                <input
                                                    type="radio"
                                                    required
                                                    id={`course-${course.id}`}
                                                    name="course"
                                                    value={course.id}
                                                    checked={selectedCourseId === course.id}
                                                    onChange={() => setselectedCourseId(course.id)}
                                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                />
                                                <label htmlFor={`course-${course.id}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                    {course.name}
                                                </label>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No courses.</p>
                                    )}
                                </div>
                            </fieldset>
                        ) : (
                            <div className="mb-4">
                                <p className="text-sm font-medium text-gray-900 dark:text-gray-300">Course:</p>
                                <div className="p-4 bg-gray-100 dark:bg-gray-700 rounded-lg">
                                    {getCourseById(courseId)?.name}
                                </div>
                            </div>
                        )}

                        <button type="submit" className="w-full justify-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300">
                            Create Assignment
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateAssignment;