import { HiBookOpen } from "react-icons/hi2";
import Searchbar from "../shared/Searchbar";
import DataTable from "../shared/Datatable";
import { useState } from "react";
import useAssignments from "../../hooks/useAssignments";
import { useNavigate } from "react-router-dom";
import useCourses from "../../hooks/useCourses";
import useGrades from "../../hooks/useGrades";
import { TabsUnderlined } from "../shared/TabNavigation";
import useAuth from "../../hooks/useAuth";
import { HiOutlineCalendar, HiPlus } from "react-icons/hi";
import GenericButton from "../shared/GenericButton";
import { usePopUp } from "../../hooks/usePopUp";
import CreateAssignment from "../assignments/CreateAssignment";

const CourseAssignments = () => {
    const { isStudent, isAdmin, user, role } = useAuth();
    const { course } = useCourses();
    const { loading, getAssignmentsForCourse } = useAssignments();
    const { getGradesForAssignment, getGradeForAssignment } = useGrades();
    const [tab, setTab] = useState(0);
    const navigate = useNavigate();

    const { showPopUp } = usePopUp();

    const activeAssignments = getAssignmentsForCourse(course?.id, true, false);
    const assignmentsHistory = getAssignmentsForCourse(course?.id, false, true).reverse();
    const [filteredAssignments, setFilteredAssignments] = useState(activeAssignments);

    const handleCreate = () => {
        showPopUp(<CreateAssignment courseId={course.id} />);
    }

    if (!course) {
        return <div>Loading...</div>
    }

    const canEdit = (course.admin === user.id || isAdmin);

    const columns = [
        {
            key: "name",
            label: "ASSIGNMENT NAME",
            render: (name, assignment) => {
                return (
                    <div className="text-sm font-medium text-gray-900 dark:text-white">
                        {name}
                    </div>);
            }
        },
        {
            key: "submissions",
            label: "SUBMISSIONS",
            render: (_, assignment) => {
                const submissions = getGradesForAssignment(assignment.id).length;
                const attendees = course.attendees.length;
                return (<div>{`${submissions} out of ${attendees}`}</div>)
            }
        },
        {
            key: "dueDate",
            label: "DUE",
            render: (date, assignment) => {
                const formattedDate = date?.toDate().toISOString().split('T')[0];
                return (<div>{formattedDate}</div>)
            }
        },
        {
            key: "published",
            label: "STATUS",
            render: (published, assignment) => {
                return (
                    <td className="px-6 py-4">
                        <span
                            className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${activeAssignments.includes(assignment) ? published
                                ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                                : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300' : published ? `bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300` : `bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300`
                                }`}
                        >
                            {activeAssignments.includes(assignment) ? published
                                ? 'Active'
                                : 'Hidden' : published ? "Visible" : "Hidden"}
                        </span>
                    </td>);
            }
        }
    ];

    const studentColumns = [
        {
            key: "name",
            label: "ASSIGNMENT NAME",
            render: (name, assignment) => {
                return (
                    <div className="text-sm font-medium text-gray-900 dark:text-white">
                        {name}
                    </div>);
            }
        },
        {
            key: "dueDate",
            label: "DUE",
            render: (date, assignment) => {
                const formattedDate = date?.toDate().toISOString().split('T')[0];
                return (<div>{formattedDate}</div>)
            }
        },
        {
            key: "published",
            label: "STATUS",
            render: (_, assignment) => {
                const grade = getGradeForAssignment(assignment.id);
                return (
                    <td className="px-6 py-4">
                        <span
                            className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${grade ? grade.submissionDate
                                ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                                : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300' : `bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300`
                                }`}
                        >
                            {grade ? grade.submissionDate
                                ? 'Complete'
                                : 'Submitted' : "Unsubmitted"}
                        </span>
                    </td>
                );
            }
        }
    ];

    return (
        <div className="p-4 bg-white border dark:bg-gray-800 min-h-screen w-full h-full">
            <div className="flex justify-between items-center">
                <div className='flex flex-wrap p-6'>
                    <HiOutlineCalendar className="text-3xl text-gray-700 dark:text-gray-300" />
                    <h1 className="pl-5 text-2xl font-semibold text-gray-900 dark:text-white">Assignments</h1>
                </div>
                {canEdit && <GenericButton icon={<HiPlus />} label={"Create Assignment"} handleClick={handleCreate} />}
            </div>

            <TabsUnderlined activeTab={tab} setActiveTab={setTab} tabs={["Active", "History"]} />

            {/* Searchbar */}
            <Searchbar
                data={tab === 0 ? activeAssignments : assignmentsHistory} // Pass the original assignments array
                searchLabel={"assignment name"}
                onFilter={setFilteredAssignments} // Update the filtered data
                filterKeys={["name"]}
            />

            {/* Data table */}
            <div className="flex-grow">
                <DataTable
                    rows={filteredAssignments}
                    columns={isStudent ? studentColumns : columns}
                    isLoading={loading}
                    onViewClick={(assignmentId) => {
                        isStudent ? navigate(`/${role}/assignments`) : navigate(`/${role}/assignments/${assignmentId}`);
                    }}
                    onEditClick={canEdit ? ((assignmentId) => {
                        navigate(`/${role}/assignments/${assignmentId}?edit`);
                    }) : null} />
            </div>
            {/* </div> */}
        </div>
    )
}

export default CourseAssignments;