import React, { useEffect } from "react";

const ProfilePicture = ({ size = 12, userToShow }) => {
    const defaultProfileURL = "https://ui-avatars.com/api/?name=John+Doe&color=7F9CF5&background=EBF4FF";
    // const user = useUser();

    return (
        <img
            src={(userToShow ? userToShow : 'N/A')?.photo_url ?? defaultProfileURL}
            className={`h-${size} w-${size} rounded-full object-cover`}
            alt="user profile"
        />
    );
}

export default ProfilePicture;