import React, { useState } from 'react'
import DataTable from '../shared/Datatable';
import useUsers from '../../hooks/useUsers';
import { DateTimeFormat } from 'intl';
import { HiAcademicCap, HiOutlineAcademicCap, HiUsers } from 'react-icons/hi';
import useSchool from '../../hooks/useSchool';
import { render } from '@testing-library/react';
import Searchbar from '../shared/Searchbar';
import useGrades from '../../hooks/useGrades';
import { usePopUp } from '../../hooks/usePopUp';
import GradingDetails from '../grades/GradingDetails';
import UserRow from '../shared/UserRow';
import useCourses from '../../hooks/useCourses';



const CourseGrades = () => {
    const { getGradesForCourse, loading, resultBadgeStyle, resultText, handleGradeClick } = useGrades();
    const { course } = useCourses();
    const [filteredGrades, setFilteredGrades] = useState([]);
    const { getUserByID } = useUsers();
    const { showPopUp } = usePopUp();

    if(!course){
        return <div>Loading...</div>
    }
    
    const courseGrades = getGradesForCourse(course.id);

    const columns = [
        {
            key: "name",
            label: "NAME",
            render: (_, grade) => {
                //Get user by grade.userId
                const user = getUserByID(grade.userId);
                return (
                    <UserRow user={user} />);
            }
        },
        {
            key: "assignmentName",
            label: "ASSIGNMENT TITLE",
        },
        {
            key: "submissionDate",
            label: "DATE SUBMITTED",
            render: (date, grade) => {
                if (!date) { return <div>N/A</div> }
                // return date;
                return <div>{date.toDate().toLocaleString()}</div>
            }
        },
        {
            key: "result",
            label: "RESULT",
            render: (_, grade) => {
                return (
                    <td className="px-6 py-4">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${resultBadgeStyle(grade.result)}`}>
                            {resultText(grade.result)}
                        </span>
                    </td>);
            }
        },
        {
            key: "status",
            label: "STATUS",
            render: (_, grade) => {
                // const formattedDate = date.toDate().toISOString().split('T')[0];
                return (<td className="px-6 py-4">
                    <span
                        className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${grade.submissionDate
                            ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                            : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300'
                            }`}
                    >
                        {grade.submissionDate
                            ? 'Completed'
                            : 'Incomplete'}
                    </span>
                </td>);
            }
        },
    ];

    // console.log("Render");

    return (
        <div>
            <div className="border rounded-lg p-4">
                <div className="flex items-center space-x-2 p-8">
                    <HiAcademicCap className="text-4xl text-gray-700 dark:text-gray-300" />
                    <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                        Grades
                    </h1>
                </div>

                {/* Searchbar */}
                <Searchbar
                    data={courseGrades} // Pass the original users array
                    searchLabel={"name"}
                    onFilter={setFilteredGrades} // Update the filtered data
                    customFilter={(grade, value) => {
                        const user = getUserByID(grade.userId);
                        if (user) {
                            const name = `${user.firstName} ${user.lastName}`.toLowerCase();
                            return name.includes(value);
                        }
                        return "deleted user".includes(value);
                    }}
                />

                {/* Data table */}
                <div className="flex-grow">
                    <DataTable
                        rows={filteredGrades}
                        columns={columns}
                        isLoading={loading}
                        onViewClick={(e) => {
                            handleGradeClick(e);
                        }} />
                </div>
            </div>
        </div>
    )
}

export default CourseGrades;