import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Dropdown, Checkbox } from "flowbite-react";

// Register the Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function Analytics() {
    // Mock Data
    const mockGrades =
    {
        "student1": [
            {
                "courseName": "Math 101",
                "accuracy": 82,
                "safety": 94,
                "communication": 64,
                "answers": 64
            },
            {
                "courseName": "Science 202",
                "accuracy": 95,
                "safety": 84,
                "communication": 61,
                "answers": 78
            },
            {
                "courseName": "History 303",
                "accuracy": 92,
                "safety": 74,
                "communication": 88,
                "answers": 88
            },
            {
                "courseName": "English 404",
                "accuracy": 77,
                "safety": 90,
                "communication": 83,
                "answers": 100
            },
            {
                "courseName": "Biology 505",
                "accuracy": 85,
                "safety": 90,
                "communication": 79,
                "answers": 74
            },
            {
                "courseName": "Chemistry 606",
                "accuracy": 77,
                "safety": 95,
                "communication": 63,
                "answers": 98
            },
            {
                "courseName": "Physics 707",
                "accuracy": 79,
                "safety": 97,
                "communication": 66,
                "answers": 100
            }
        ],
        "student2": [
            {
                "courseName": "Math 101",
                "accuracy": 71,
                "safety": 71,
                "communication": 65,
                "answers": 82
            },
            {
                "courseName": "Science 202",
                "accuracy": 88,
                "safety": 65,
                "communication": 63,
                "answers": 90
            },
            {
                "courseName": "History 303",
                "accuracy": 98,
                "safety": 60,
                "communication": 73,
                "answers": 74
            },
            {
                "courseName": "English 404",
                "accuracy": 90,
                "safety": 87,
                "communication": 63,
                "answers": 100
            },
            {
                "courseName": "Biology 505",
                "accuracy": 86,
                "safety": 72,
                "communication": 81,
                "answers": 73
            },
            {
                "courseName": "Chemistry 606",
                "accuracy": 93,
                "safety": 62,
                "communication": 84,
                "answers": 79
            },
            {
                "courseName": "Physics 707",
                "accuracy": 92,
                "safety": 62,
                "communication": 93,
                "answers": 87
            }
        ],
        "student3": [
            {
                "courseName": "Math 101",
                "accuracy": 73,
                "safety": 67,
                "communication": 92,
                "answers": 75
            },
            {
                "courseName": "Science 202",
                "accuracy": 72,
                "safety": 77,
                "communication": 98,
                "answers": 67
            },
            {
                "courseName": "History 303",
                "accuracy": 63,
                "safety": 92,
                "communication": 71,
                "answers": 76
            },
            {
                "courseName": "English 404",
                "accuracy": 62,
                "safety": 90,
                "communication": 73,
                "answers": 91
            },
            {
                "courseName": "Biology 505",
                "accuracy": 77,
                "safety": 61,
                "communication": 77,
                "answers": 83
            },
            {
                "courseName": "Chemistry 606",
                "accuracy": 99,
                "safety": 78,
                "communication": 64,
                "answers": 82
            },
            {
                "courseName": "Physics 707",
                "accuracy": 92,
                "safety": 82,
                "communication": 100,
                "answers": 94
            }
        ]
    }


    // Extract students dynamically from mockGrades
    const students = Object.keys(mockGrades);

    // Initialize state with all students and courses selected
    const [selectedStudents, setSelectedStudents] = useState(students);
    const [selectedCourses, setSelectedCourses] = useState([]);

    const [courseData, setCourseData] = useState([]);

    // Update course data based on selected students and courses
    useEffect(() => {
        // Filter grades based on selected students
        const filteredGrades = selectedStudents.length
            ? selectedStudents.reduce((acc, student) => acc.concat(mockGrades[student] || []), [])
            : Object.values(mockGrades).flat();

        // Derive available courses from filtered grades
        const availableCourses = [
            ...new Set(filteredGrades.map((grade) => grade.courseName)),
        ];

        // Set all courses as selected by default if none are explicitly selected
        if (selectedCourses.length === 0) {
            setSelectedCourses(availableCourses);
        }

        // Aggregate data for the available courses
        const aggregatedData = availableCourses.map((course) => {
            let totalAccuracy = 0;
            let totalSafety = 0;
            let totalCommunication = 0;
            let totalAnswers = 0;
            let countAccuracy = 0;
            let countSafety = 0;
            let countCommunication = 0;
            let countAnswers = 0;

            filteredGrades.forEach((grade) => {
                if (grade.courseName === course) {
                    totalAccuracy += grade.accuracy ?? 0;
                    totalSafety += grade.safety ?? 0;
                    totalCommunication += grade.communication ?? 0;
                    totalAnswers += grade.answers ?? 0;
                    countAccuracy++;
                    countSafety++;
                    countCommunication++;
                    countAnswers++;
                }
            });

            return {
                courseName: course,
                accuracy: countAccuracy > 0 ? totalAccuracy / countAccuracy : 0,
                safety: countSafety > 0 ? totalSafety / countSafety : 0,
                communication:
                    countCommunication > 0 ? totalCommunication / countCommunication : 0,
                answers: countAnswers > 0 ? totalAnswers / countAnswers : 0,
            };
        });

        setCourseData(aggregatedData);
    }, [selectedStudents]);

    // Filtered data
    const filteredData = courseData.filter((course) =>
        selectedCourses.length === 0 || selectedCourses.includes(course.courseName)
    );

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                title: { display: true, text: "Courses" },
                grid: { display: false },
            },
            y: {
                title: { display: true, text: "Percentage" },
                beginAtZero: true,
                max: 100,
                ticks: { stepSize: 10 },
                grid: { borderDash: [5, 5] },
            },
        },
        plugins: {
            legend: { position: "top" },
            tooltip: {
                callbacks: {
                    label: (context) =>
                        `${context.dataset.label}: ${context.parsed.y.toFixed(1)}%`,
                },
            },
        },
    };

    const renderChart = (metric, label, color) => {
        const chartData = {
            labels: filteredData.map((course) => course.courseName),
            datasets: [
                {
                    label,
                    data: filteredData.map((course) => course[metric]),
                    backgroundColor: color,
                    borderRadius: 10,
                },
            ],
        };

        return (
            <div className="bg-white dark:bg-gray-800 p-4 rounded-lg border">
                <h3 className="text-lg font-semibold mb-4">{label}</h3>
                <div style={{ height: "300px" }}>
                    <Bar data={chartData} options={chartOptions} />
                </div>
            </div>
        );
    };

    return (
        <div className="bg-white dark:bg-gray-800 p-6">
            {/* Student Filter Dropdown */}
            <Dropdown inline label="Filter by Students">
                {students.map((student) => (
                    <Dropdown.Item key={student}>
                        <Checkbox
                            id={student}
                            checked={selectedStudents.includes(student)}
                            onChange={() => {
                                setSelectedStudents((prevSelected) =>
                                    prevSelected.includes(student)
                                        ? prevSelected.filter((s) => s !== student)
                                        : [...prevSelected, student]
                                );
                            }}
                        />
                        {student}
                    </Dropdown.Item>
                ))}
            </Dropdown>

            {/* Course Filter Dropdown */}
            <Dropdown inline label="Filter by Courses">
                {courseData.map((course) => (
                    <Dropdown.Item key={course.courseName}>
                        <Checkbox
                            id={course.courseName}
                            checked={selectedCourses.includes(course.courseName)}
                            onChange={() => {
                                setSelectedCourses((prevSelected) =>
                                    prevSelected.includes(course.courseName)
                                        ? prevSelected.filter((c) => c !== course.courseName)
                                        : [...prevSelected, course.courseName]
                                );
                            }}
                        />
                        {course.courseName}
                    </Dropdown.Item>
                ))}
            </Dropdown>

            {/* Charts for each metric in two rows of two */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
                {renderChart("accuracy", "Accuracy", "#00bcd4")}
                {renderChart("safety", "Safety", "#29b6f6")}
                {renderChart("communication", "Communication", "#1e88e5")}
                {renderChart("answers", "Answers", "#4db6ac")}
            </div>
        </div>
    );
}