import { HiBeaker } from "react-icons/hi";
import useScenarios from "../../hooks/useScenarios";
import { useEffect, useState } from "react";
import useCourses from "../../hooks/useCourses";
import SaveButton from "../shared/SaveButton";
import { SkeletonLoaderCard } from "../shared/SkeletonLoader";
import Thumbnail from "../shared/Thumbnail";
import useAuth from "../../hooks/useAuth";


const OpenLab = () => {
    const { loading, course, updateOpenLab } = useCourses();
    const { scenarios } = useScenarios();
    const [selectedScenarios, setSelectedScenarios] = useState([]);
    const [isDirty, setIsDirty] = useState(false);
    const [saving, setSaving] = useState(false);
    const { isAdmin, user } = useAuth();

    useEffect(() => {
        if (course) {
            setSelectedScenarios(course.openLab ?? []);
        }
    }, [course])

    const handleSelect = (scenarioId) => {
        if (!canEdit) return;
        setSelectedScenarios((prev) =>
            prev.includes(scenarioId) ? prev.filter(s => s !== scenarioId) : [...prev, scenarioId]
        );
        setIsDirty(true);
    }

    const handleSave = async () => {
        setSaving(true);
        await updateOpenLab(course.id, selectedScenarios);
        setIsDirty(false);
        setSaving(false);
    }

    if(!course){
        return <div>Loading...</div>
    }

    const canEdit = (course.admin === user.id || isAdmin);

    return (
        <div className="p-4 bg-white border dark:bg-gray-800 min-h-screen w-full h-full">
            <div className="flex justify-between items-center space-x-2 p-8">
                <div className='w-full flex gap-2 items-center justify-between'>
                    <div className="flex items-center">
                        <HiBeaker className="text-4xl text-gray-700 dark:text-gray-300" />
                        <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Open Lab
                        </h1>
                    </div>
                    {canEdit && <SaveButton hasUnsavedChanges={isDirty} saveChanges={handleSave} saving={saving} />}
                </div>
            </div>
            <div className="p-6">
                <h2 className="text-2xl font-semibold mb-4">Courseta Scenarios</h2>
                <p className="text-gray-600 text-lg mb-6">Available Open Lab Scenarios</p>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
                    {loading ? <SkeletonLoaderCard /> : scenarios.map((scenario) => {
                        return (
                            <div
                                key={scenario.id}
                                className={`p-2 rounded-lg cursor-pointer border-4 transition duration-300 ease-in-out ${selectedScenarios.includes(scenario.id) ? 'border-green-500' : 'border-gray-300'} hover:shadow-lg`}
                                onClick={() => handleSelect(scenario.id)}
                            >
                                <Thumbnail thumbnailURL={`scenarios/${scenario.id}/${scenario.thumbnail}`} altName={scenario.name} />
                                <p className={`mt-2 font-medium text-center ${selectedScenarios.includes(scenario.id) ? 'text-green-500' : 'text-gray-700'}`}>
                                    {scenario.name}
                                </p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>);
}

export default OpenLab;