import React from 'react';
import { HiOutlineDocument, HiChevronRight, HiArrowRight, HiDocument, HiAcademicCap } from "react-icons/hi";
import { HiDocumentPlus } from 'react-icons/hi2';
import useCourses from '../../hooks/useCourses';
import { sortByDate } from '../../utils/analytics';
import useAssignments from '../../hooks/useAssignments';
import useGrades from '../../hooks/useGrades';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const UpcomingAssignments = ({ course, onViewAll }) => {
    const { isStudent, role } = useAuth();
    const { getAssignmentsForCourse, getActiveAssignments } = useAssignments();
    const { getGradesForAssignment } = useGrades();
    const navigate = useNavigate();

    // if (!course) {
    //     return <div>Loading...</div>
    // }

    const assignments = course ? sortByDate(getAssignmentsForCourse(course.id, true), "dueDate").slice(0, 3) : sortByDate(getActiveAssignments(), "dueDate").slice(0, 3);

    const handleRowClick = (assignment) => {
        navigate(`/${role}/assignments/${assignment.id}`);
    };

    return (
        <div className="bg-gray-10 p-6 rounded-lg border">
            <div className="flex justify-between items-center mb-4">
                <h3 className="flex items-center text-lg font-bold text-gray-900">
                    Upcoming Assignments
                </h3>
                <button
                    onClick={() => { onViewAll() }}
                    className="flex items-center text-gray-500 text-sm font-medium">
                    View All
                    <HiArrowRight className="w-5 h-5 ml-1" />
                </button>
            </div>
            <ul>
                {assignments.map((assignment, index) => {
                    const submissions = getGradesForAssignment(assignment.id);
                    return (
                        <li
                            key={index}
                            className="flex justify-between items-center py-4 border-b last:border-0 cursor-pointer hover:bg-gray-100"
                            onClick={() => handleRowClick(assignment)}
                        >
                            <div className="flex items-center">
                                <HiAcademicCap className="w-5 h-5 text-gray-500 mr-2" />
                                <div>
                                    <p className="font-medium text-gray-900">{assignment.name}</p>
                                    <p className="text-xs text-gray-400">{assignment.dueDate.toDate().toLocaleString()}</p>
                                </div>
                            </div>
                            {!isStudent ? <div className="flex items-center">
                                <p className="text-sm text-gray-500">Submissions: {submissions.length}</p>
                                <HiChevronRight className="w-5 h-5 ml-4 text-gray-400" />
                            </div> : <span className={`bg-${submissions.length > 0 ? submissions[0].submissionDate ? "green" : "yellow" : "red"}-100 text-${submissions.length > 0 ? submissions[0].submissionDate ? "green" : "yellow" : "red"}-600 text-xs font-medium px-3 py-1 rounded-lg`}>
                                {submissions.length > 0 ? submissions[0].submissionDate ? "Submitted" : "Incomplete" : "Unsubmitted"}
                            </span>}
                        </li>
                    )
                })}
            </ul>
        </div>
    );
};

export default UpcomingAssignments;
