import { MetaHumans } from '../../utils/ScriptingEnumLibrary';

export function MetaHumanImages(metaHumanId) {
    switch (metaHumanId) {
        case MetaHumans.Ada:
            return {
                faceImage: require('../../assets/Patient Library- Face Avatars/3.png'),
                avatarImage1: require('../../assets/Patient Library Avatars/5.png'),
                avatarImage2: require('../../assets/Patient Library Avatars/6.png'),
                description: "Middle-Aged Adult - AA Female"
            };
        case MetaHumans.Amelia:
            return {
                faceImage: require('../../assets/Patient Library- Face Avatars/8.png'),
                avatarImage1: require('../../assets/Patient Library Avatars/15.png'),
                avatarImage2: require('../../assets/Patient Library Avatars/16.png'),
                description: "School-Aged Child - Caucasian Female"
            };
        case MetaHumans.Bes:
            return {
                faceImage: require('../../assets/Patient Library- Face Avatars/9.png'),
                avatarImage1: require('../../assets/Patient Library Avatars/17.png'),
                avatarImage2: require('../../assets/Patient Library Avatars/18.png'),
                description: "Adolescent - Indian Male"
            };
        case MetaHumans.Dhruv:
            return {
                faceImage: require('../../assets/Patient Library- Face Avatars/1.png'),
                avatarImage1: require('../../assets/Patient Library Avatars/1.png'),
                avatarImage2: require('../../assets/Patient Library Avatars/2.png'),
                description: "Older Adult - Indian Male"
            };
        case MetaHumans.Emory:
            return {
                faceImage: require('../../assets/Patient Library- Face Avatars/7.png'),
                avatarImage1: require('../../assets/Patient Library Avatars/13.png'),
                avatarImage2: require('../../assets/Patient Library Avatars/14.png'),
                description: "School-Age Child - AA Male"
            };
        case MetaHumans.Glenda:
            return {
                faceImage: require('../../assets/Patient Library- Face Avatars/19.png'),
                avatarImage1: require('../../assets/Patient Library Avatars/37.png'),
                avatarImage2: require('../../assets/Patient Library Avatars/38.png'),
                description: "Older Adult - Caucasian Female"
            };
        case MetaHumans.Irene:
            return {
                faceImage: require('../../assets/Patient Library- Face Avatars/12.png'),
                avatarImage1: require('../../assets/Patient Library Avatars/23.png'),
                avatarImage2: require('../../assets/Patient Library Avatars/24.png'),
                description: "Middle-Aged Adult - Caucasian Female"
            };
        case MetaHumans.Keiji:
            return {
                faceImage: require('../../assets/Patient Library- Face Avatars/5.png'),
                avatarImage1: require('../../assets/Patient Library Avatars/9.png'),
                avatarImage2: require('../../assets/Patient Library Avatars/10.png'),
                description: "Older Adult - Hispanic Male"
            };
        case MetaHumans.Kendra:
            return {
                faceImage: require('../../assets/Patient Library- Face Avatars/11.png'),
                avatarImage1: require('../../assets/Patient Library Avatars/21.png'),
                avatarImage2: require('../../assets/Patient Library Avatars/22.png'),
                description: "Middle-Aged Adult - Indian Female"
            };
        case MetaHumans.Lucian:
            return {
                faceImage: require('../../assets/Patient Library- Face Avatars/16.png'),
                avatarImage1: require('../../assets/Patient Library Avatars/31.png'),
                avatarImage2: require('../../assets/Patient Library Avatars/32.png'),
                description: "Older Adult - AA Male"
            };
        case MetaHumans.Maria:
            return {
                faceImage: require('../../assets/Patient Library- Face Avatars/10.png'),
                avatarImage1: require('../../assets/Patient Library Avatars/19.png'),
                avatarImage2: require('../../assets/Patient Library Avatars/20.png'),
                description: "Middle-Aged Adult - Hispanic Female"
            };
        case MetaHumans.Orla:
            return {
                faceImage: require('../../assets/Patient Library- Face Avatars/20.png'),
                avatarImage1: require('../../assets/Patient Library Avatars/39.png'),
                avatarImage2: require('../../assets/Patient Library Avatars/40.png'),
                description: "Middle-Aged Adult - Caucasian Female"
            };
        case MetaHumans.Oskar:
            return {
                faceImage: require('../../assets/Patient Library- Face Avatars/2.png'),
                avatarImage1: require('../../assets/Patient Library Avatars/3.png'),
                avatarImage2: require('../../assets/Patient Library Avatars/4.png'),
                description: "Middle-Aged Adult - Caucasian Male"
            };
        case MetaHumans.Pia:
            return {
                faceImage: require('../../assets/Patient Library- Face Avatars/4.png'),
                avatarImage1: require('../../assets/Patient Library Avatars/7.png'),
                avatarImage2: require('../../assets/Patient Library Avatars/8.png'),
                description: "Middle-Aged Adult - Asian Female"
            };
        case MetaHumans.Rowan:
            return {
                faceImage: require('../../assets/Patient Library- Face Avatars/17.png'),
                avatarImage1: require('../../assets/Patient Library Avatars/33.png'),
                avatarImage2: require('../../assets/Patient Library Avatars/34.png'),
                description: "Middle-Aged Adult - Asian Male"
            };
        case MetaHumans.Seneca:
            return {
                faceImage: require('../../assets/Patient Library- Face Avatars/18.png'),
                avatarImage1: require('../../assets/Patient Library Avatars/35.png'),
                avatarImage2: require('../../assets/Patient Library Avatars/36.png'),
                description: "Middle-Aged Adult - Arab Male"
            };
        case MetaHumans.SookJa:
            return {
                faceImage: require('../../assets/Patient Library- Face Avatars/13.png'),
                avatarImage1: require('../../assets/Patient Library Avatars/25.png'),
                avatarImage2: require('../../assets/Patient Library Avatars/26.png'),
                description: "Older Adult - Asian Female"
            };
        case MetaHumans.Trey:
            return {
                faceImage: require('../../assets/Patient Library- Face Avatars/trey.png'),
                avatarImage1: require('../../assets/Patient Library Avatars/27.png'),
                avatarImage2: require('../../assets/Patient Library Avatars/28.png'),
                description: "Middle-Aged Adult - AA Male"
            };
        case MetaHumans.Valrie:
            return {
                faceImage: require('../../assets/Patient Library- Face Avatars/15.png'),
                avatarImage1: require('../../assets/Patient Library Avatars/29.png'),
                avatarImage2: require('../../assets/Patient Library Avatars/30.png'),
                description: "Middle-Aged Adult - Arab Female"
            };
        case MetaHumans.Wallace:
            return {
                faceImage: require('../../assets/Patient Library- Face Avatars/6.png'),
                avatarImage1: require('../../assets/Patient Library Avatars/11.png'),
                avatarImage2: require('../../assets/Patient Library Avatars/12.png'),
                description: "Older Adult - Caucasian Male"
            };
        default:
            return {
                faceImage: 'https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png',
                avatarImage1: 'https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png',
                avatarImage2: 'https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png'
            };
    }
}
