import React, { useState, useEffect } from 'react';
import { MedicationTypes, MedicationRoute, MedicationUnit, StatusType, prettyPrintEnum, MedicationRouteInclIVBag, TitrationUnit } from '../../../utils/ScriptingEnumLibrary';
import useScenarioCustomization from '../../../hooks/useScenarioCustomization';
import GenericButton from '../../shared/GenericButton';
import { HiPlus } from 'react-icons/hi';

const printRoute = (route) => {
    switch (route) {
        case MedicationRouteInclIVBag.Oral:
            return "Oral";
        case MedicationRouteInclIVBag.Intramuscular:
            return "Intramuscular";
        case MedicationRouteInclIVBag.Subcutaneous:
            return "Subcutaneous";
        case MedicationRouteInclIVBag.IvPush:
            return "IV Push";
        case MedicationRouteInclIVBag.IvBag:
            return "Intravenous";
    }
}

const printUnit = (unit) => {
    switch (unit) {
        case MedicationUnit.mg:
            return "mg";
        case MedicationUnit.mgMl:
            return "mg/mL";
        case MedicationUnit.units:
            return "units";
        case MedicationUnit.dose:
            return "dose";
        case MedicationUnit.ml:
            return "mL";
        case MedicationUnit.mcgMl:
            return "mcg/mL";
        case MedicationUnit.gMl:
            return "g/mL";
        case MedicationUnit.meqMl:
            return "mEq/mL";
        case MedicationUnit.unitsMl:
            return "units/mL";
        case MedicationUnit.grams:
            return "grams";
        default:
            return "Unknown unit";
    }
};

const printTitrationUnit = (unit) => {
    switch (unit) {
        case TitrationUnit.mlH:
            return "mL/hr";
        case TitrationUnit.mcgMin:
            return "mcg/min";
        case TitrationUnit.mgHr:
            return "mg/hr";
        case TitrationUnit.mcgKgHr:
            return "mcg/kg/hr";
        case TitrationUnit.mcgKgMin:
            return "mcg/kg/min";
        case TitrationUnit.unitsMin:
            return "units/min";
        case TitrationUnit.unitsHr:
            return "units/hr";
        case TitrationUnit.mcgHr:
            return "mcg/hr";
        default:
            return "Unknown unit";
    }
};


const MedicationCard = ({ medication, index }) => {
    const { patient, setPatientField } = useScenarioCustomization();

    const handleRemove = (e) => {
        e.preventDefault();
        const updatedMAR = patient.mar.filter((m, i) => i !== index);
        setPatientField('mar', updatedMAR)
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedMAR = [...patient.mar];
        updatedMAR[index][name] = value;
        setPatientField('mar', updatedMAR)
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        const updatedMAR = [...patient.mar];
        updatedMAR[index][name] = checked;
        setPatientField('mar', updatedMAR)
    };

    return (
        <div className="border p-4 rounded-lg shadow-sm mb-4">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold">Medication {index + 1}</h3>
                <button onClick={handleRemove} className="text-red-500 hover:text-red-700">
                    &#10005;
                </button>
            </div>
            {/* Medication Selection */}
            <label className="block text-sm font-medium text-gray-700">Medication</label>
            <select
                name="medication"
                value={medication.medication ?? -1}
                onChange={(e) => handleInputChange({ target: { name: 'medication', value: Number(e.target.value) } })}
                className="w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 mb-4"
            >
                <option value={-1} disabled>
                    Select Medication
                </option>
                {Object.entries(MedicationTypes).map(([key, value]) => (
                    <option key={value} value={value}>
                        {prettyPrintEnum(key)}
                    </option>
                ))}
            </select>
            <label className="block text-sm font-medium text-gray-700">Route</label>
            <select
                name="route"
                value={medication.route ?? -1}
                onChange={(e) => handleInputChange({ target: { name: 'route', value: Number(e.target.value) } })}
                className="w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 mb-4"
            >
                <option value={-1} disabled>
                    Select Route
                </option>
                {Object.entries(MedicationRouteInclIVBag).map(([key, value]) => (
                    <option key={value} value={value}>
                        {printRoute(value)}
                    </option>
                ))}
            </select>

            <label className="block text-sm font-medium text-gray-700">Dose</label>
            <div className="flex items-center space-x-4 mb-4">
                {/* Toggle for Range Input */}
                <label className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        checked={medication.isRange}
                        onChange={(e) =>
                            handleInputChange({
                                target: {
                                    name: 'isRange',
                                    value: !medication.isRange,
                                },
                            })
                        }
                        className="text-blue-500 border-gray-300 rounded focus:ring-blue-500"
                    />
                    <span>Enter as range</span>
                </label>
            </div>
            <div className='flex items-center space-x-4 w-full mb-4 justify-between'>
                {medication.isRange ? (
                    // Range Input Fields
                    <div className="flex items-center space-x-2 w-full">
                        <input
                            type="number"
                            step="any"
                            name="doseMin"
                            value={medication.doseMin ?? ''}
                            onChange={(e) =>
                                handleInputChange({
                                    target: {
                                        name: 'doseMin',
                                        value: !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : e.target.value,
                                    },
                                })
                            }
                            placeholder="Enter Min Dose"
                            className="w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2"
                        />
                        <input
                            type="number"
                            step="any"
                            name="doseMax"
                            value={medication.doseMax ?? ''}
                            onChange={(e) =>
                                handleInputChange({
                                    target: {
                                        name: 'doseMax',
                                        value: !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : e.target.value,
                                    },
                                })
                            }
                            placeholder="Enter Max Dose"
                            className="w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2"
                        />
                    </div>) :
                    (<div className="flex space-x-4 items-center w-full">
                        <input
                            type="number"
                            step="any"
                            name="dose"
                            value={medication.dose ?? ''}
                            onChange={(e) => {
                                const value = e.target.value;
                                handleInputChange({
                                    target: {
                                        name: 'dose',
                                        value: !isNaN(parseFloat(value)) && isFinite(value) ? parseFloat(value) : value,
                                    },
                                });
                            }}
                            placeholder="Enter Dose"
                            className="w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2"
                        />
                    </div>)
                }
                <select
                    name="unit"
                    value={medication.unit ?? -1}
                    onChange={(e) => handleInputChange({ target: { name: 'unit', value: Number(e.target.value) } })}
                    className="w-1/3 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2"
                >
                    <option value={-1} disabled>
                        Metric
                    </option>
                    {Object.entries(MedicationUnit).map(([key, value]) => (
                        <option key={value} value={value}>
                            {printUnit(value)}
                        </option>
                    ))}
                </select>
            </div>

            {
                medication.route === MedicationRouteInclIVBag.IvBag && <div>
                    <label className="block text-sm font-medium text-gray-700">Rate</label>
                    {/* Dose Input */}
                    <div className="flex space-x-4 items-center mb-4">
                        <input
                            type="number"
                            step="any"
                            name="rate"
                            value={medication.rate ?? ''}
                            onChange={(e) => {
                                const value = e.target.value;
                                handleInputChange({
                                    target: {
                                        name: 'rate',
                                        value: !isNaN(parseFloat(value)) && isFinite(value) ? parseFloat(value) : value,
                                    },
                                });
                            }
                            }
                            placeholder="Enter Rate"
                            className="w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2"
                        />
                        <select
                            name="rateUnit"
                            value={medication.rateUnit ?? -1}
                            onChange={(e) => handleInputChange({ target: { name: 'rateUnit', value: Number(e.target.value) } })}
                            className="w-1/3 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2"
                        >
                            <option value={-1} disabled>
                                Metric
                            </option>
                            {Object.entries(TitrationUnit).map(([key, value]) => (
                                <option key={value} value={value}>
                                    {printTitrationUnit(value)}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            }

            <label className="block text-sm font-medium text-gray-700">Frequency</label>
            {/* Frequency Input */}
            <input
                type="text"
                name="frequency"
                value={medication.frequency ?? ''}
                onChange={handleInputChange}
                placeholder="Enter Frequency"
                className="w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 mb-4"
            />

            <label className="block text-sm font-medium text-gray-700">Status</label>
            <div className='flex items-center space-x-4'>
                {/* Status Selection using StatusType Enum */}
                <select
                    name="status"
                    value={medication.status ?? -1}
                    onChange={(e) => handleInputChange({ target: { name: 'status', value: Number(e.target.value) } })}
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 mb-4"
                >
                    <option value={-1} disabled>
                        Select Status
                    </option>
                    {Object.entries(StatusType).map(([key, value]) => (
                        <option key={value} value={value}>
                            {prettyPrintEnum(key)}
                        </option>
                    ))}
                </select>
                {medication.status === 1 && <input
                    type="text"
                    name="lastGiven"
                    value={medication.lastGiven ?? ''}
                    onChange={handleInputChange}
                    placeholder="Last given"
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 mb-4"
                />}
            </div>

            {/* Due Checkbox */}
            <div className="flex space-x-4 mb-2 items-center">
                <label className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        name="due"
                        checked={!!medication.due}
                        onChange={handleCheckboxChange}
                        className="text-blue-500 border-gray-300 rounded focus:ring-blue-500"
                    />
                    <span>Due</span>
                </label>
            </div>
            <label className="block text-sm font-medium text-gray-700">Admin Instruction</label>
            <textarea
                name="description"
                value={medication.description ?? ''}
                onChange={handleInputChange}
                placeholder="Enter Description"
                className="w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 mb-4"
                rows="4"
            />
        </div >
    );
};

const MARTab = () => {
    const { patient, setPatientField } = useScenarioCustomization();

    const handleAddMedication = () => {
        const updatedMAR = [...patient.mar];
        updatedMAR.push({
        });
        setPatientField('mar', updatedMAR);
    };

    return (
        <div className="p-6 bg-white rounded-md border">
            <h2 className="text-2xl font-semibold mb-4">Medication Administration Record (MAR)</h2>
            <p className="mb-4 text-gray-600">Enter in the information you want inside the patient EMR</p>

            {patient.mar.map((medication, index) => (
                <MedicationCard
                    key={index}
                    medication={medication}
                    index={index}
                />
            ))}
            <GenericButton handleClick={handleAddMedication} icon={<HiPlus />} label={'Add New Medication'} />
        </div>
    );
};

export default MARTab;
