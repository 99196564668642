import { useContext, useEffect, useState } from "react";
import { ScenarioCustomizationContext } from "../contexts/ScenarioCustomizationContext";
import useSchool from "./useSchool";
import { useSearchParams } from "react-router-dom";
import useAuth from "./useAuth";
import Roles from "../utils/roles";

/**
 * Hook for all scenarios functionality
 * @returns All usable variables and functions from this hook
 */
const useScenarioCustomization = () => {
    const context = useContext(ScenarioCustomizationContext);
    const { school } = useSchool();
    const [searchParams] = useSearchParams();
    const { role } = useAuth();

    if (!context) {
        throw new Error("Use scenario customization has to be used within a ScenarioCustomizationContext!");
    }

    const getNotePath = () => {
        const fromCourseta = searchParams.get("courseta") != null && role === Roles.ADMIN;
        return (fromCourseta ? `scenarios/${context.scenario.id}/patients/${0}/notes` : `institutions/${school.id}/scenarios/${context.scenario.id}/patients/${0}/notes`);
    }


    return { ...context, getNotePath }; // Access all context values directly
};

export default useScenarioCustomization;