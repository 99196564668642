import React from 'react';
import { HiChartBar, HiClipboard, HiDocumentText } from "react-icons/hi";
import { usePopUp } from '../../hooks/usePopUp';
import ScenarioFilter from './ScenarioFilter';
import CourseAnalytics from './CourseAnalytics';
import UpcomingAssignments from './UpcomingAssignments';
import RecentGrades from './RecentGrades';
import useCourses from '../../hooks/useCourses';
import useUsers from '../../hooks/useUsers';
import useAssignments from '../../hooks/useAssignments';
import useGrades from '../../hooks/useGrades';

const Overview = ({ setTab }) => {
    const { course } = useCourses();
    const { getUserByID } = useUsers();
    const { getGradesForCourse } = useGrades();
    const { getAssignmentsForCourse } = useAssignments();

    if (!course) {
        return <div>Loading...</div>
    }

    // Mock data
    // const mockAdminInfo = {
    //     firstName: 'John',
    //     lastName: 'Doe',
    // };

    const admin = getUserByID(course.admin);
    const courseAssignments = getAssignmentsForCourse(course.id);
    const courseGrades = getGradesForCourse(course.id);

    return (
        <div className="p-4 bg-white border dark:bg-gray-800 min-h-screen w-full h-full">
            <div className="flex items-center space-x-2 p-8">
                <HiChartBar className="text-4xl text-gray-700 dark:text-gray-300" />
                <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                    Overview
                </h1>
            </div>
            <div className="p-6 mb-4 border grid grid-cols-2 md:grid-cols-6 divide-x divide-gray-200 text-center">
                <div>
                    <h4 className="text-sm font-medium text-gray-500">Teacher</h4>
                    <p className="text-lg font-semibold text-gray-900">
                        {admin ? `${admin.firstName} ${admin.lastName}` : 'No Admin'}
                    </p>
                </div>
                <div>
                    <h4 className="text-sm font-medium text-gray-500">Students</h4>
                    <p className="text-lg font-semibold text-gray-900">{course.attendees.length}</p>
                </div>
                <div>
                    <h4 className="text-sm font-medium text-gray-500">Date Created</h4>
                    <p className="text-lg font-semibold text-gray-900">{course.createdAt.toDate().toLocaleString().split(",")[0]}</p>
                </div>
                <div>
                    <h4 className="text-sm font-medium text-gray-500">Assignments</h4>
                    <p className="text-lg font-semibold text-gray-900">{courseAssignments.length.toString()}</p>
                </div>
                <div>
                    <h4 className="text-sm font-medium text-gray-500">Grades</h4>
                    <p className="text-lg font-semibold text-gray-900">{courseGrades.length}</p>
                </div>
                <div>
                    <h4 className="text-sm font-medium text-gray-500">Status</h4>
                    <p className="text-lg font-semibold text-gray-900">
                        {course.published ? 'Published' : 'Unpublished'}
                    </p>
                </div>
            </div>

            {/* Course Analytics */}
            <CourseAnalytics onViewAll={() => { setTab(3) }} />
            <br></br>
            {/* Upcoming Assignments and Grades */}
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-12">
                <UpcomingAssignments course={course} onViewAll={() => { setTab(3) }} />
                <RecentGrades course={course} onViewAll={() => { setTab(4) }} />
            </div>
        </div>
    );
};

export default Overview;
