import React from 'react';
import { prettyPrintEnum } from '../../../utils/ScriptingEnumLibrary';
import useScenarioCustomization from '../../../hooks/useScenarioCustomization';

export default function PresentationField({ fieldName, label = "Undefined", isTextField = false, placeholder = "Enter value", enumType = null }) {
    const { patient, setPatientField } = useScenarioCustomization();

    const updatePresentationField = (fieldName, fieldValue) => {
        const updatedPresentation = { ...patient.presentation };
        updatedPresentation[fieldName] = fieldValue;
        setPatientField('presentation', updatedPresentation);
    };

    const getPresentationField = (fieldName) => {
        return patient.presentation[fieldName];
    }

    return (
        <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{label}</label>
            {enumType ?
                <select
                    value={getPresentationField(fieldName)}
                    onChange={(e) => updatePresentationField(fieldName, Number(e.target.value))}
                    className="w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:text-gray-200"
                >
                    {Object.entries(enumType).map(([key, value]) => (
                        <option key={value} value={value}>
                            {prettyPrintEnum(key)}
                        </option>
                    ))}
                </select>
                :
                <input
                    type="text"
                    pattern={isTextField ? "" : "[0-9]*"}
                    value={getPresentationField(fieldName)}
                    onChange={(e) => updatePresentationField(fieldName, isTextField ? e.target.value : Number(e.target.value))}
                    className="w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:text-gray-200"
                    placeholder={placeholder}
                />}
        </div>
    );
}