const Roles = {
    STUDENT : "student",
    TEACHER : "teacher",
    SCHOOLADMIN : "school_admin",
    ADMIN: "admin",
}

/**
 * Finds the role enum that's connected to the string
 * @param {RoleString} string The string of the role
 * @returns The role as 'Roles' enum value
 */
export const stringToRole = (string) => {
    // Check if the provided string matches any value in the Roles enum
    const role = Object.values(Roles).find((r) => r === string);

    if (!role) {
        // throw new Error(`Invalid role string: "${roleString}"`);
        return null;
    }

    return role; // Return the matched role
}

export default Roles;