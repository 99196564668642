import { useState } from "react";
import useSignup from "../../hooks/useSignup";
import logo from '../../assets/images/Courseta_Logo.png';
import { useNavigate } from "react-router-dom";

const Signup = () => {
    const { registration, error, loading, handleSubmit } = useSignup();

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const navigate = useNavigate();

    return (
        <section className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
            <div className="flex w-full h-screen max-w-none mx-auto bg-white dark:bg-gray-800">
                <div className="w-1/2 h-full flex items-center justify-center p-8">
                    <div className="w-full max-w-md space-y-4 md:space-y-6">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Finish Registration
                        </h1>
                        {registration && <p className="text-sm text-gray-700 dark:text-gray-400">
                            Welcome, {registration.firstName} {registration.lastName}! Complete the
                            form below to set your password and activate your account.
                        </p>}
                        {error && (
                            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                <strong className="font-bold">Error: </strong>
                                <span className="block sm:inline">{error}</span>
                            </div>
                        )}
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(password, confirmPassword)
                        }
                        } className="space-y-4">
                            <div>
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                                >
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                    placeholder="Your email will be filled in automically"
                                    value={registration?.email}
                                    disabled={true}
                                    required
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="password"
                                    className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                                >
                                    Password
                                </label>
                                <input
                                    type="password"
                                    id="password"
                                    className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                    placeholder="Enter your password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="confirmPassword"
                                    className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                                >
                                    Confirm Password
                                </label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                    placeholder="Confirm your password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                disabled={loading}
                                className="w-full px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none disabled:bg-gray-400 disabled:cursor-not-allowed"
                            >
                                {loading ? error ? "Unable to complete registration" : "Completing Registration..." : "Complete Registration"}
                            </button>
                        </form>
                        <div className="text-sm font-light text-gray-500 dark:text-gray-400">
                            Already have an account?
                            <a href="/forgotpassword" onClick={(e) => { e.preventDefault(); navigate("/login"); }} className="font-medium pl-1 text-blue-600 hover:underline dark:text-blue-500">Click here!</a>
                        </div>
                    </div>
                </div>
                <div className="w-1/2 h-full flex items-center justify-center bg-blue-600 rounded-tl-3xl rounded-bl-3xl">
                    <img className="w-80 h-90" src={logo} alt="Courseta Logo" />
                </div>
            </div>
        </section>
    );
}

export default Signup;