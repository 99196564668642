import React, { useState, useEffect } from "react";
import { HiArrowRight, HiOutlineAcademicCap, HiOutlineNewspaper, HiPlus, HiX } from "react-icons/hi";
import { TabsUnderlined } from "../../components/shared/TabNavigation";
import useCourses from "../../hooks/useCourses";
import CourseDetails from "../../pages/CourseDetails";
import { usePopUp } from "../../hooks/usePopUp";
import { useNavigate } from "react-router-dom";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { useNotification } from "../../hooks/useNotification";
import { Dropdown, DropdownItem } from 'flowbite-react';
import { NotificationTypes } from "../../utils/notifications";
import useUsers from "../../hooks/useUsers";
import { SkeletonLoaderCard } from "../../components/shared/SkeletonLoader";
import GenericButton from "../../components/shared/GenericButton";
import { getFileUrl, uploadFile } from "../../firebase/storage";
import useAuth from "../../hooks/useAuth";
import Thumbnail from "../shared/Thumbnail";
import useSchool from "../../hooks/useSchool";
import Roles from "../../utils/roles";
import EditPopup from "../shared/EditPopup";
import defaultImage from '../../assets/images/Course image placeholder (if no thumbnail) .png'

const CourseCard = ({ courseId }) => {
    const navigate = useNavigate();
    const { getCourseById, updateCourse } = useCourses();
    const { getUserByID } = useUsers();
    const { school } = useSchool();
    const { showPopUp } = usePopUp();
    const { isStudent, user, isAdmin, role } = useAuth();

    const course = getCourseById(courseId);
    const admin = getUserByID(course.admin);

    const canEdit = isAdmin || user.id === admin;

    const handleView = (e) => {
        e.preventDefault();
        navigate(`/${role}/courses/${course.id}`);
    }

    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg w-full">
            {/* Square image */}
            <Thumbnail altName={course.name} thumbnailURL={(course.thumbnail && course.thumbnail != "") ? `institutions/${school.id}/courses/${course.id}/${course.thumbnail}` : null} defaultImg={defaultImage} />
            <div className="p-4">
                <h3 className="text-lg font-bold text-gray-900 mb-2 truncate">
                    {course.name}
                </h3>
                <div className="flex justify-between items-center text-sm">
                    <p className="text-sm text-gray-500 mb-4 truncate">
                        {admin ? `${admin.firstName} ${admin.lastName}` : "Deleted user"}
                    </p>
                    {/* <p>{`${submissions} out of ${attendees}`}</p> */}
                </div>
                {/* <div className="flex justify-between items-center text-sm">
                    <span className="text-gray-600">📅 {assignment.dueDate.toDate().toLocaleString()}</span>
                    {overdue && (
                        <span className="text-red-500 font-bold">Overdue</span>
                    )}
                </div> */}
                {!isStudent ? <div className="mt-4 flex items-center space-x-2">
                    <button
                        className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                        onClick={handleView}
                    >
                        View
                    </button>
                    {canEdit && <button
                        className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300"
                        onClick={(e) => {
                            e.preventDefault();
                            showPopUp(<EditPopup label="Course" baseUrl={`institutions/${school.id}/courses/${course.id}`} updateData={updateCourse} data={course} />);
                        }}
                    >
                        Edit
                    </button>}
                </div> : <div>
                    <button
                        onClick={handleView}
                        type="button"
                        className={`w-full flex items-center justify-center border rounded-lg shadow-sm font-medium mt-4 px-4 py-2 bg-blue-500 text-white hover:bg-blue-600`}
                    >
                        View
                        <HiArrowRight className="ml-2 text-lg" />
                    </button>
                </div>}
            </div>
        </div>
    );
}

export default CourseCard;