import { chunkArray } from "../utils/utils";
import { firestore } from "./firebase"
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';

/**
 * Fetches a single document as react object
 * @param {String} id The document ID in firebase
 * @param {String} collectionName The name of the collection the document is stored in
 * @returns A react object with all the data stored in the document
 */
export const getDocument = async (id, collectionName) => {
    if (!id || id === "") {
        console.error("Fetch data object called with empty id: " + collectionName);
        return null;
    }

    const docRef = doc(firestore, collectionName, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return {
            id: id,
            ...docSnap.data()
        };
    }
    else {
        //console.error("Doc was empty!");
        return null;
    }
}

/**
 * Fetches multiple documents as react objects from a collection
 * @param {String} collectionName The name of the collection the documents are stored in
 * @param {[String]} ids Optional filter of IDs, **(Warning: This is a slow operation for more then 10 IDs! Consider using queries to get your desired documents instead)**
 * @returns An array with react objects holding the documents data
 */
export const getDocuments = async (collectionName, ids = null) => {
    try {
        const collectionRef = collection(firestore, collectionName);

        if (ids) {
            // Split IDs into chunks of 10 for the "in" operator
            const chunks = chunkArray(ids, 10);
            const promises = chunks.map((chunk) =>
                getDocs(query(collectionRef, where("__name__", "in", chunk)))
            );

            const results = await Promise.all(promises);
            return results.flatMap((querySnapshot) =>
                querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }))
            );
        } else {
            // Fetch all documents
            const querySnapshot = await getDocs(query(collectionRef));
            return querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
        }
    } catch (error) {
        console.error("Error fetching documents:", error);
        throw error;
    }
}

/**
 * Retrieves the firebase IDs of *all* documents stored in the given collection
 * @param {String} collectionName The name of the collection the documents are stored in
 * @returns An array of strings representing the firebase IDs
 */
export const getCollectionIDs = async (collectionName) => {
    try {
        const querySnapshot = await getDocs(collection(firestore, collectionName));
        const documentIds = querySnapshot.docs.map(doc => doc.id);
        return documentIds;
    } catch (error) {
        console.error('Error getting document IDs: ', error);
        return [];
    }
}

/**
 * Adds a document in the given collection
 * @param {*} document The document data
 * @param {*} collectionName The collection you wish to store the document in
 * @param {*} customID A (optional) custom ID to set in the collection
 * @returns The firebase ID of the new document
 */
export const addDocument = async (document, collectionName, customID = null) => {
    //Add the document to firebase
    const d = { ...document };
    delete d.id;
    if (!customID) {
        const doc = await addDoc(collection(firestore, collectionName), d);
        return doc.id;
    }
    else {
        const docRef = doc(collection(firestore, collectionName), customID);
        await setDoc(docRef, d);
        return customID;
    }
}

/**
 * Updates a document's data in firebase. (Warning: This might override all data in the document!)
 * @param {String} documentId The ID of the document you wish to update
 * @param {String} collectionName The name of the collection the document in stored in
 * @param {*} data The new data of the document
 */
export const updateDocument = async (documentId, collectionName, data) => {
    //Update the document in firebase
    const d = { ...data };
    delete d.id;
    const docRef = doc(firestore, collectionName, documentId);
    await updateDoc(docRef, d);
}

/**
 * This deletes a document from a collection
 * @param {String} documentId The ID of the document you wish to delete
 * @param {String} collectionName The name of the collection the document in stored in
 */
export const deleteDocument = async (documentId, collectionName) => {
    const docRef = doc(firestore, collectionName, documentId);
    await deleteDoc(docRef);
}

/**
 * Checks if the document exists
 * @param {*} documentId The ID of the document you wish to check
 * @param {String} collectionName The name of the collection the document might be stored in
 * @returns A boolean whether or not the document exists in the given collection
 */
export const existsDocument = async (documentId, collectionName) => {
    const docRef = doc(firestore, collectionName, documentId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        //console.log('Document exists!', docSnap.data());
        return true;
    } else {
        //console.log('No such document!');
        return false;
    }
}

/**
 * Subscribes to a Firestore document for real-time updates.
 *
 * @param {string} path - The Firestore document path (e.g., "collectionName/documentID").
 * @param {Function} callback - The function to call with the document data when a change occurs.
 * @returns {Function} - Unsubscribe function to stop listening to changes.
 */
export const subscribeDocument = (path, callback) => {
    try {
        const docRef = doc(firestore, path);

        const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                callback({ id: docSnapshot.id, ...docSnapshot.data() });
            } else {
                console.warn(`Document at path "${path}" does not exist.`);
                callback(null);
            }
        });

        return unsubscribe;
    } catch (error) {
        console.error("Error subscribing to Firestore document:", error);
        throw error;
    }
};

/**
 * Sets (creates or overwrites) a document in the given collection.
 * If the document with the specified ID exists, it will be overwritten.
 * If the document does not exist, it will be created.
 * @param {String} documentId The ID of the document to set
 * @param {String} collectionName The name of the collection to store the document in
 * @param {*} data The data to set in the document
 * @returns {String} The document ID of the set document
 */
export const setDocument = async (documentId, collectionName, data) => {
    try {
        const docRef = doc(firestore, collectionName, documentId);
        const d = { ...data };
        delete d.id; // Ensure we don't overwrite the 'id' field accidentally
        await setDoc(docRef, d);
        return documentId;
    } catch (error) {
        console.error("Error setting document:", error);
        throw error;
    }
};


/**
 * Subscribes to a Firestore collection for real-time updates with optional queries.
 *
 * @param {string} path - The Firestore collection path (e.g., "collectionName").
 * @param {Function} callback - The function to call with the collection data when a change occurs.
 * @param {Object} [options] - Optional query parameters:
 *  - where: An array of conditions (e.g., [["field", "==", "value"], ["field2", ">", 10]]).
 *  - orderBy: Field to order by (e.g., "fieldName") or an array of fields with directions (e.g., [["field1", "asc"], ["field2", "desc"]]).
 *  - limit: Number of documents to retrieve.
 * @returns {Function} - Unsubscribe function to stop listening to changes.
 */
export const subscribeCollection = (path, callback, options = {}) => {
    try {
        let collectionRef = collection(firestore, path);

        // Apply where conditions
        if (options.where) {
            options.where.forEach(([field, operator, value]) => {
                collectionRef = query(collectionRef, where(field, operator, value));
            });
        }

        // Apply ordering
        if (options.orderBy) {
            if (Array.isArray(options.orderBy)) {
                options.orderBy.forEach(([field, direction]) => {
                    collectionRef = query(collectionRef, orderBy(field, direction || "asc"));
                });
            } else {
                collectionRef = query(collectionRef, orderBy(options.orderBy));
            }
        }

        // Apply limit
        if (options.limit) {
            collectionRef = query(collectionRef, limit(options.limit));
        }

        const unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
            const documents = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            callback(documents);
        });

        return unsubscribe;
    } catch (error) {
        console.error("Error subscribing to Firestore collection:", error);
        throw error;
    }
};