import React from "react";
import { FaVrCardboard } from 'react-icons/fa';
import logo from '../../assets/images/Courseta_Logo.png';
import useHeader from "../../hooks/navigation/useHeader";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom/dist";
import useSchool from "../../hooks/useSchool";
import { HiAcademicCap } from "react-icons/hi";
import { Dropdown, Avatar } from "flowbite-react";
import { NotificationTypes } from "../../utils/notifications";
import { useNotification } from "../../hooks/useNotification";
import Roles from "../../utils/roles";
import { capitalize } from "lodash";
import { usePopUp } from "../../hooks/usePopUp";
import SignInCodePopup from "../authentication/SignInCode";

const defaultProfileURL = "https://ui-avatars.com/api/?name=John+Doe&color=7F9CF5&background=EBF4FF";

const Header = () => {
    const { user, logout, role } = useAuth();
    const { school } = useSchool();
    const navigate = useNavigate();
    const { handleInstitutionClick } = useHeader();

    const { showNotification } = useNotification();
    const { showPopUp } = usePopUp();

    const generateSignInCode = (e) => {
        e.preventDefault();
        showPopUp(< SignInCodePopup />);
    }

    return (
        <nav className="bg-white border-b border-gray-200 px-4 py-2.5 dark:bg-gray-800 dark:border-gray-700 fixed left-0 right-0 top-0 z-10">
            <div className="flex justify-between items-center ">
                <div className="flex items-center space-x-10">
                    <img src={logo} className="mr-13 pl-10 h-16" alt="Courseta Logo" />

                    {school &&
                        (role === Roles.ADMIN ? (
                            <div className="p-4">
                                <Dropdown inline label={
                                    <div className="flex items-center space-x-2">
                                        <HiAcademicCap className="text-3xl text-blue-600 dark:text-blue-400" />
                                        <span className="text-base font-semibold text-gray-900 dark:text-white">
                                            {school.name}
                                        </span>
                                    </div>}>
                                    <Dropdown.Item
                                        onClick={handleInstitutionClick}
                                        className="cursor-pointer"
                                    >
                                        Switch institution
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            navigate(`/admin/school-info`)
                                        }}
                                        className="cursor-pointer"
                                    >
                                        Show institution details
                                    </Dropdown.Item>
                                </Dropdown></div>
                        ) : (
                            <div className="flex items-center space-x-2">
                                <HiAcademicCap className="text-3xl text-blue-600 dark:text-blue-400" />
                                <span className="text-base font-semibold text-gray-900 dark:text-white">
                                    {school.name}
                                </span>
                            </div>
                        ))}
                </div>

                <div className="flex items-center space-x-4">
                    <button
                        type="button"
                        onClick={generateSignInCode}
                        className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 hover:scale-105 shadow-md transition-transform duration-300 ease-in-out"
                        style={{ height: '40px', marginRight: '10px' }}
                    >
                        <FaVrCardboard className="mr-2" />
                        Sign in to VR
                    </button>
                    <Dropdown
                        inline
                        label={
                            <div className="flex items-center space-x-3">
                                <Avatar
                                    img={user?.photoUrl ?? defaultProfileURL}
                                    rounded={true}
                                    alt="user profile"
                                />
                                <div className="flex flex-col text-left">
                                    <span className="text-base font-semibold text-gray-900 dark:text-white">
                                        {user?.firstName} {user?.lastName}
                                    </span>
                                    <span className="text-sm text-gray-500 dark:text-gray-400">
                                        {capitalize(role ?? "")}
                                    </span>
                                </div>
                            </div>
                        }
                    >
                        <Dropdown.Item
                            onClick={() => {
                                if (school)
                                    navigate(`/${role}/profile`)
                                else
                                    showNotification(NotificationTypes.DANGER, "No associated school.");
                            }}
                            className="cursor-pointer"
                        >
                            My profile
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={logout}
                            className="cursor-pointer"
                        >
                            Sign out
                        </Dropdown.Item>
                    </Dropdown>
                </div>
            </div>
        </nav>
    );
};

export default Header;
