import React from 'react';
import { HiArrowRight } from 'react-icons/hi';
import useCourses from '../../hooks/useCourses';
import useGrades from '../../hooks/useGrades';
import useAssignments from '../../hooks/useAssignments';
import { calculateArrayFieldAverage } from '../../utils/analytics';
import useAuth from '../../hooks/useAuth';

const CourseAnalytics = ({ onViewAll }) => {
    const { isStudent } = useAuth();
    const { course } = useCourses();
    const { getGradesForCourse, getGradesForAssignment } = useGrades();
    const { getAssignmentsForCourse } = useAssignments();

    if (!course) {
        return <div>Loading...</div>
    }

    const courseAssignments = getAssignmentsForCourse(course.id);
    const courseGrades = getGradesForCourse(course.id);

    return (
        <div className="bg-gray-40 p-6 rounded-lg border">
            <h3 className="text-xl font-bold text-gray-900 mb-6">{isStudent ? "Your Performance" : "Course Analytics"}</h3>

            {/* Overall Analytics */}
            <div className="grid grid-cols-5 gap-6 mb-6">
                <div>
                    <h4 className="text-sm font-medium text-gray-500">Total Assignments</h4>
                    <p className="text-2xl font-bold text-gray-900">{courseAssignments.length}</p>
                </div>
                <div>
                    <h4 className="text-sm font-medium text-gray-500">Overall Safety</h4>
                    <p className="text-2xl font-bold text-green-600">{calculateArrayFieldAverage(courseGrades, "safetyResult")}%</p>
                </div>
                <div>
                    <h4 className="text-sm font-medium text-gray-500">Overall Accuracy</h4>
                    <p className="text-2xl font-bold text-blue-600">{calculateArrayFieldAverage(courseGrades, "accuracyResult")}%</p>
                </div>
                <div>
                    <h4 className="text-sm font-medium text-gray-500">Overall Communication</h4>
                    <p className="text-2xl font-bold text-green-600">{calculateArrayFieldAverage(courseGrades, "communicationResult")}%</p>
                </div>
                <div>
                    <h4 className="text-sm font-medium text-gray-500">Overall Average</h4>
                    <p className="text-2xl font-bold text-blue-600">{calculateArrayFieldAverage(courseGrades, "result")}%</p>
                </div>
            </div>

            {/* Individual Analytics */}
            <div className='bg-gray-100 p-6 rounded-lg border'>
                <div className="flex justify-between items-center mb-4 ">
                    <h4 className="text-lg font-medium text-gray-900">Assignment Performances</h4>
                    <button
                        onClick={() => { onViewAll() }}
                        className="flex items-center text-gray-500 text-sm font-medium">
                        View All
                        <HiArrowRight className="w-5 h-5 ml-1" />
                    </button>
                </div>
                <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3">Assignment Title</th>
                            <th scope="col" className="px-6 py-3">Safety</th>
                            <th scope="col" className="px-6 py-3">Accuracy</th>
                            <th scope="col" className="px-6 py-3">Communication</th>
                            <th scope="col" className="px-6 py-3">Average</th>
                        </tr>
                    </thead>
                    <tbody>
                        {courseAssignments.slice(0, 4).map((assignment, index) => {
                            const assignmentGrades = getGradesForAssignment(assignment.id);
                            const assignmentAverage = calculateArrayFieldAverage(assignmentGrades, "result");
                            return (<tr key={index} className="bg-white border-b">
                                <td className="px-6 py-4 font-medium text-gray-900">{assignment.name}</td>
                                <td className="px-6 py-4">{calculateArrayFieldAverage(assignmentGrades, "safetyResult")}%</td>
                                <td className="px-6 py-4">{calculateArrayFieldAverage(assignmentGrades, "accuracyResult")}%</td>
                                <td className="px-6 py-4">{calculateArrayFieldAverage(assignmentGrades, "communicationResult")}%</td>
                                <td className="px-6 py-4">
                                    <span
                                        className={`px-3 py-1 rounded-full text-sm font-bold ${assignmentAverage >= 75
                                            ? "bg-green-100 text-green-600"
                                            : assignmentAverage >= 50
                                                ? "bg-blue-100 text-blue-600"
                                                : "bg-red-100 text-red-600"
                                            }`}
                                    >
                                        {assignmentAverage}%
                                    </span>
                                </td>

                            </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CourseAnalytics;
