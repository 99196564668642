import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { Timestamp } from 'firebase/firestore';

const styles = StyleSheet.create({
    page: {
        paddingTop: 40,
        paddingBottom: 20,
        paddingHorizontal: 20,
        fontSize: 12,
        fontFamily: 'Helvetica',
    },
    sectionTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#2B6CB0',
        marginBottom: 10,
    },
    performanceOverview: {
        backgroundColor: '#EBF8FF', // Light blue background
        borderRadius: 8,
        padding: 10,
        marginBottom: 20,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,
    },
    headerTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#2B6CB0',
    },
    logo: {
        width: 90,
        height: 60,
    },
    divider: {
        height: 4,
        backgroundColor: '#2B6CB0',
        marginTop: 5,
        marginBottom: 10,
    },
    infoGrid: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginBottom: 15,
    },
    infoItem: {
        width: '48%',
        marginBottom: 10,
    },
    infoTitle: {
        fontSize: 12,
        color: '#4A5568',
        marginBottom: 2,
    },
    infoValue: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#1A202C',
    },
    scoresTable: {
        width: '100%',
        marginTop: 10,
        borderRadius: 8,
    },
    tableHeader: {
        backgroundColor: '#EDF2F7',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 5,
    },
    tableHeaderText: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#4A5568',
    },
    tableHeaderMetric: {
        width: '70%', // Fix width for metric column
        textAlign: 'left',
    },
    tableHeaderScore: {
        width: '30%', // Fix width for score column
        textAlign: 'right',
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 5,
        borderBottomWidth: 1,
        borderBottomColor: '#E2E8F0',
    },
    tableCellMetric: {
        fontSize: 12,
        width: '70%', // Align metric to left
        textAlign: 'left',
        paddingRight: 10,
    },
    tableCellScore: {
        fontSize: 12,
        width: '30%', // Align score to right
        textAlign: 'right',
    },
    scoreSummary: {
        backgroundColor: '#EBF8FF',
        padding: 10,
        borderRadius: 8,
        marginTop: 15,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    totalScoreText: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#2B6CB0',
    },
    congratulatoryText: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    bubblePlayer: {
        backgroundColor: '#D3E0FF',
        alignSelf: 'flex-start',
        padding: 8,
        borderRadius: 12,
        marginBottom: 10,
        width: '70%',
        color: '#1a202c',
    },
    bubblePatient: {
        backgroundColor: '#E2E8F0',
        alignSelf: 'flex-end',
        padding: 8,
        borderRadius: 12,
        marginBottom: 10,
        width: '70%',
        color: '#1a202c',
    },
    transcriptTitle: {
        fontSize: 12,
        fontWeight: 'bold',
        marginBottom: 5,
        color: '#2B6CB0',
    },
    transcriptTime: {
        fontSize: 10,
        textAlign: 'right',
        marginTop: 4,
        color: '#718096',
    },
    messageText: {
        fontSize: 11,
        color: '#1a202c',
    },
    gradeCard: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 10,
        borderRadius: 8,
        marginBottom: 10,
        width: '100%',  // Ensure cards do not overflow
        backgroundColor: '#fff',
        borderWidth: 1,
        borderColor: '#E2E8F0',
    },
    gradeCardContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        height: 40,
        width: 40,
        marginRight: 15,
    },
    gradeCardTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#2D3748',
    },
    gradeCardScore: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#2D3748',
    },
    footer: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center',
        fontSize: 12,
        color: '#A0AEC0',
    },
    // New styles for table structure in Answers Accuracy section
    tableContainer: {
        display: 'table',
        width: '100%',
        borderCollapse: 'collapse',
    },
    tableHeader: {
        flexDirection: 'row',
        backgroundColor: '#EDF2F7',
        padding: 5,
        borderBottomWidth: 1,
        borderBottomColor: '#E2E8F0',
    },
    tableHeaderText: {
        width: '33%',
        fontSize: 12,
        fontWeight: 'bold',
        color: '#4A5568',
        textAlign: 'left',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#E2E8F0',
        paddingVertical: 5,
    },
    tableCell: {
        width: '33%',
        fontSize: 12,
        color: '#1A202C',
        textAlign: 'left',
        padding: 5,
    },
    correctAnswer: {
        color: '#48BB78',  // Green for correct answers
    },
    incorrectAnswer: {
        color: '#F56565',  // Red for incorrect answers
    },
    // New styles for Clinical Judgement Debriefing section
    debriefingCard: {
        marginBottom: 20,
        padding: 10,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#E2E8F0',
        backgroundColor: '#F7FAFC',
    },
    questionTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#2B6CB0',
        marginBottom: 8,
    },
    answersContainer: {
        marginBottom: 12,
    },
    answerRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 8,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#E2E8F0',
        marginBottom: 6,
    },
    defaultAnswer: {
        backgroundColor: '#fff',
    },
    userAnswer: {
        backgroundColor: '#EBF8FF',
        borderColor: '#3182CE',
    },
    dimmedAnswer: {
        opacity: 0.6,
    },
    answerText: {
        fontSize: 12,
        color: '#1A202C',
    },
    correctIcon: {
        fontSize: 12,
        color: '#48BB78',  // Green for correct answers
    },
    incorrectIcon: {
        fontSize: 12,
        color: '#F56565',  // Red for incorrect answers
    },
    resultContainer: {
        marginTop: 10,
        padding: 8,
        borderRadius: 5,
        backgroundColor: '#EDF2F7',
        borderWidth: 1,
        borderColor: '#E2E8F0',
    },
    resultText: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 6,
    },
    correctResult: {
        color: '#48BB78',  // Green for correct
    },
    incorrectResult: {
        color: '#F56565',  // Red for incorrect
    },
    rationaleTitle: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#2B6CB0',
        marginBottom: 4,
    },
    rationaleText: {
        fontSize: 12,
        color: '#1A202C',
    },
    reflectionCard: {
        marginBottom: 20,
        padding: 10,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#E2E8F0',
        backgroundColor: '#F7FAFC',
    },
    questionTitle: {
        fontSize: 16, // Groter lettertype voor de vraag
        fontWeight: 'bold',
        color: '#2B6CB0',
        marginBottom: 8,
    },
    reflectionFooter: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 10,
        marginBottom: 10,
    },
    userName: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#1A202C',
    },
    reflectionDate: {
        fontSize: 12,
        color: '#718096',
    },
    reflectionAnswer: {
        fontSize: 12,
        color: '#4A5568',
        marginTop: 10,
    },
});



// Placeholder for chat icon
const chatIcon = '../../../assets/images/22.png';
const warningIcon = '/path-to-your-images/chat-icon.png';
const crossIcon = '../../../assets/images/14.png';
const checkIcon = '../../../assets/images/13.png';

const Header = ({ logo, assessment, userData }) => (
    <View style={styles.header}>
        <Text style={styles.headerText}>
            Student: {`${userData?.firstName || 'Unknown'} ${userData?.lastName || 'Unknown'}`}
        </Text>
        <Text style={styles.headerText}>
            Assignment: {`${assessment?.assignmentName || 'Unknown Assignment'}`}
        </Text>
        <Text style={styles.headerText}>
            Course: {assessment?.course || 'Unknown Course'}
        </Text>
        <Text style={styles.headerText}>
            Submission Date: {new Date().toLocaleString()}
        </Text>
        <Image src={logo || 'fallback-logo-url.png'} style={styles.logo} />
    </View>
);

const TranscriptItem = ({ speaker, message, time, isPlayer }) => {
    const bubbleStyle = isPlayer ? styles.bubblePlayer : styles.bubblePatient;
    const alignmentStyle = isPlayer ? { flexDirection: 'row' } : { flexDirection: 'row-reverse' };

    return (
        <View style={[{ display: 'flex', alignItems: 'center', marginBottom: 10 }, alignmentStyle]}>
            <Image src={chatIcon} style={{ width: 20, height: 20, marginRight: 8 }} />
            <View style={bubbleStyle}>
                <Text style={styles.transcriptTitle}>{isPlayer ? 'Player' : 'Patient'}</Text>
                <Text style={styles.messageText}>{message}</Text>
                <Text style={styles.transcriptTime}>{time}</Text>
            </View>
        </View>
    );
};

const getColorAndIcon = (achievedScore, maxScore) => {
    if (achievedScore === maxScore) {
        return { icon: checkIcon, color: '#48BB78', backgroundColor: '#F0FFF4' }; // Green for success
    } else if (achievedScore > 0 && achievedScore < maxScore) {
        return { icon: warningIcon, color: '#ECC94B', backgroundColor: '#FFFAF0' }; // Yellow for partial success
    } else {
        return { icon: crossIcon, color: '#F56565', backgroundColor: '#FFF5F5' }; // Red for failure
    }
};


const PDFDocument = ({ userData, grade, logo }) => {
    return (
        <Document>
            {/* Performance Overview */}
            <Page size="A4" style={styles.page}>
                <View style={styles.performanceOverview}>
                    <View style={styles.header}>
                        <Text style={styles.headerTitle}>PERFORMANCE OVERVIEW</Text>
                        <Image src={logo || 'fallback-logo-url.png'} style={styles.logo} />
                    </View>
                    <View style={styles.divider}></View>

                    <View style={styles.infoGrid}>
                        <View style={styles.infoItem}>
                            <Text style={styles.infoTitle}>Student:</Text>
                            <Text style={styles.infoValue}>{userData?.firstName} {userData?.lastName}</Text>
                        </View>
                        <View style={styles.infoItem}>
                            <Text style={styles.infoTitle}>Assignment:</Text>
                            <Text style={styles.infoValue}>{grade?.assignmentName}</Text>
                        </View>
                        <View style={styles.infoItem}>
                            <Text style={styles.infoTitle}>Course:</Text>
                            <Text style={styles.infoValue}>{grade?.courseName}</Text>
                        </View>
                        <View style={styles.infoItem}>
                            <Text style={styles.infoTitle}>Submission Date:</Text>
                            <Text style={styles.infoValue}>{grade?.createdAt ? grade?.createdAt.toDate().toLocaleString() : 'N/A'}</Text>
                        </View>
                        <View style={styles.infoItem}>
                            <Text style={styles.infoTitle}>Status:</Text>
                            <Text style={styles.infoValue}>{grade?.submissionDate ? 'Completed' : 'Incomplete'}</Text>
                        </View>

                        <View style={styles.infoItem}>
                            <Text style={styles.infoTitle}>Completion Date:</Text>
                            <Text style={styles.infoValue}>{grade?.submissionDate ? grade?.submissionDate.toDate().toLocaleString() : 'N/A'}</Text>
                        </View>
                    </View>

                    <View style={styles.scoresTable}>
                        <View style={styles.tableHeader}>
                            <Text style={[styles.tableHeaderText, styles.tableHeaderMetric]}>Metric</Text>
                            <Text style={[styles.tableHeaderText, styles.tableHeaderScore]}>Score</Text>
                        </View>
                        <View key={'answers'} style={styles.tableRow}>
                            <Text style={styles.tableCellMetric}>Answers:</Text>
                            <Text style={styles.tableCellScore}>{grade?.answersResult}%</Text>
                        </View>
                        <View key={'accuracy'} style={styles.tableRow}>
                            <Text style={styles.tableCellMetric}>Accuracy:</Text>
                            <Text style={styles.tableCellScore}>{grade?.accuracyResult}%</Text>
                        </View>
                        <View key={'communication'} style={styles.tableRow}>
                            <Text style={styles.tableCellMetric}>Communication:</Text>
                            <Text style={styles.tableCellScore}>{grade?.communicationResult}%</Text>
                        </View>
                        <View key={'safety'} style={styles.tableRow}>
                            <Text style={styles.tableCellMetric}>Safety:</Text>
                            <Text style={styles.tableCellScore}>{grade?.safetyResult}%</Text>
                        </View>
                        {/*
                        Object.entries(categoryPercentages || {}).map(([metric, score], index) => (
                            <View key={index} style={styles.tableRow}>
                                <Text style={styles.tableCellMetric}>{metric}:</Text>
                                <Text style={styles.tableCellScore}>{score}%</Text>
                            </View>
                        ))*/}
                    </View>

                    <View style={styles.scoreSummary}>
                        <View>
                            <Text style={styles.totalScoreText}>Total Score:</Text>
                            <Text style={styles.totalScoreText}>{grade?.result}%</Text>
                        </View>
                        <Text
                            style={[
                                styles.congratulatoryText,
                                {
                                    color: grade?.result >= 70 ? '#48BB78' : grade?.result >= 50 ? '#ECC94B' : '#F56565',
                                },
                            ]}
                        >
                            {grade?.result >= 70 ? 'Awesome Job!' :
                                grade?.result >= 50 ? 'Good Job!' :
                                    grade?.result >= 30 ? 'Almost there!' :
                                        'You can do better!'}
                        </Text>
                    </View>
                </View>

                <Text style={styles.footer} render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} fixed />
            </Page>

            {/* Accuracy Performance */}
            <Page size="A4" style={styles.page}>
                <Text style={styles.sectionTitle}>ACCURACY PERFORMANCE</Text>
                <View style={styles.divider}></View>
                {Object.entries(grade?.accuracy || {}).map(([key, value], index) => {
                    const [title, description] = key.split('/').map((text) => text.trim());
                    const [achievedScore, maxScore] = value.split('/').map(Number);
                    const { icon, color, backgroundColor } = getColorAndIcon(achievedScore, maxScore);
                    return (
                        <View key={index} style={[styles.gradeCard, { backgroundColor }]}>
                            <View style={styles.gradeCardContent}>
                                <Image src={icon} style={[styles.icon, { tintColor: color }]} />
                                <Text style={styles.gradeCardTitle}>{title}</Text>
                            </View>
                            <Text style={[styles.gradeCardScore, { color }]}>{`${achievedScore} / ${maxScore} Points Earned`}</Text>
                        </View>
                    );
                })}
                <Text style={styles.footer} render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} fixed />
            </Page>

            {/* Communication Performance */}
            <Page size="A4" style={styles.page}>
                <Text style={styles.sectionTitle}>COMMUNICATION PERFORMANCE</Text>
                <View style={styles.divider}></View>
                {Object.entries(grade?.communication || {}).map(([key, value], index) => {
                    const [title, description] = key.split('/').map((text) => text.trim());
                    const [achievedScore, maxScore] = value.split('/').map(Number);
                    const { icon, color, backgroundColor } = getColorAndIcon(achievedScore, maxScore);
                    return (
                        <View key={index} style={[styles.gradeCard, { backgroundColor }]}>
                            <View style={styles.gradeCardContent}>
                                <Image src={icon} style={[styles.icon, { tintColor: color }]} />
                                <Text style={styles.gradeCardTitle}>{title}</Text>
                            </View>
                            <Text style={[styles.gradeCardScore, { color }]}>{`${achievedScore} / ${maxScore} Points Earned`}</Text>
                        </View>
                    );
                })}
                <Text style={styles.footer} render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} fixed />
            </Page>

            {/* Safety Performance */}
            <Page size="A4" style={styles.page}>
                <Text style={styles.sectionTitle}>SAFETY PERFORMANCE</Text>
                <View style={styles.divider}></View>
                {Object.entries(grade?.safety || {}).map(([key, value], index) => {
                    const [title, description] = key.split('/').map((text) => text.trim());
                    const [achievedScore, maxScore] = value.split('/').map(Number);
                    const { icon, color, backgroundColor } = getColorAndIcon(achievedScore, maxScore);
                    return (
                        <View key={index} style={[styles.gradeCard, { backgroundColor }]}>
                            <View style={styles.gradeCardContent}>
                                <Image src={icon} style={[styles.icon, { tintColor: color }]} />
                                <Text style={styles.gradeCardTitle}>{title}</Text>
                            </View>
                            <Text style={[styles.gradeCardScore, { color }]}>{`${achievedScore} / ${maxScore} Points Earned`}</Text>
                        </View>
                    );
                })}
                <Text style={styles.footer} render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} fixed />
            </Page>

            {/* Answers Accuracy */}
            <Page size="A4" style={styles.page}>
                <Text style={styles.sectionTitle}>ANSWERS ACCURACY</Text>
                <View style={styles.divider}></View>

                <View style={styles.tableContainer}>
                    <View style={styles.tableHeader}>
                        <Text style={styles.tableHeaderText}>Question</Text>
                        <Text style={styles.tableHeaderText}>Selected Answer(s)</Text>
                        <Text style={styles.tableHeaderText}>Points Earned</Text>
                    </View>

                    {grade?.scenario?.documentation?.map((question, index) => {
                        const answerIndicesString = grade?.answers?.[String(index)] || '';
                        const answerIndices = answerIndicesString.split(';').map(Number).filter(index => !isNaN(index));
                        const hasAnswered = answerIndices.length > 0;
                        const selectedChoices = hasAnswered
                            ? answerIndices.map(answerIndex => question.options[answerIndex]).filter(Boolean).join(' AND ')
                            : 'No answer given';
                        const correctAnswersCount = answerIndices.reduce((count, answerIndex) => {
                            return count + (question.correctAnswers.includes(answerIndex) ? 1 : 0);
                        }, 0);
                        const isCorrect = correctAnswersCount === answerIndices.length;

                        return (
                            <View key={index} style={styles.tableRow}>
                                <Text style={styles.tableCell}>{question.questionTitle}</Text>
                                <Text style={[styles.tableCell, isCorrect ? styles.correctAnswer : styles.incorrectAnswer]}>
                                    {selectedChoices}
                                </Text>
                                <Text style={[styles.tableCell, isCorrect ? styles.correctAnswer : styles.incorrectAnswer]}>
                                    {`${correctAnswersCount}/${answerIndices.length} Points Earned`}
                                </Text>
                            </View>
                        );
                    })}
                </View>

                <Text style={styles.footer} render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} fixed />
            </Page>

            {/* Debriefing Section */}
            <Page size="A4" style={styles.page}>
                <Text style={styles.sectionTitle}>DEBRIEFING</Text>
                <View style={styles.divider}></View>

                <View>
                    {grade?.scenario?.debriefing?.map((question, index) => {
                        const answer = grade?.debriefing?.[index] !== "unanswered"
                            ? grade?.debriefing?.[index]
                            : "You did not answer this question.";
                        return (
                            <View key={index} style={styles.reflectionCard}>
                                {/* Question */}
                                <Text style={styles.questionTitle}>{question || 'No question provided'}</Text>

                                {/* User details (without profile picture) */}
                                <View style={styles.reflectionFooter}>
                                    <Text style={styles.userName}>
                                        {userData?.firstName} {userData?.lastName}
                                    </Text>
                                    <Text style={styles.reflectionDate}>{grade?.creationDate || 'Date not available'}</Text>
                                </View>

                                {/* User answer */}
                                <Text style={styles.reflectionAnswer}>
                                    {answer && answer.trim() !== '' ? answer : 'You did not answer this question.'}
                                </Text>
                            </View>
                        );
                    })}
                </View>

                <Text style={styles.footer} render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} fixed />
            </Page>



            {/* Clinical Judgement Debriefing */}
            <Page size="A4" style={styles.page}>
                <Text style={styles.sectionTitle}>CLINICAL JUDGEMENT DEBRIEFING</Text>
                <View style={styles.divider}></View>

                <View>
                    {grade?.scenario?.debriefing_2?.map((question, index) => {
                        // Pak het gebruikersantwoord van grade.debriefing_2 met dezelfde index
                        const userAnswer = grade?.debriefing2?.[index] ?? "unanswered";
                        const isCorrect = userAnswer === question.correctAnswer;

                        return (
                            <View key={index} style={styles.debriefingCard}>
                                <Text style={styles.questionTitle}>{`Question: ${question.question}`}</Text>

                                {/* Answers */}
                                <View style={styles.answersContainer}>
                                    {question.answers?.map((choice, choiceIndex) => (
                                        <View
                                            key={choiceIndex}
                                            style={[
                                                styles.answerRow,
                                                userAnswer === choiceIndex ? styles.userAnswer : styles.defaultAnswer,
                                                userAnswer !== choiceIndex && styles.dimmedAnswer
                                            ]}
                                        >
                                            <Text style={styles.answerText}>{choice}</Text>
                                            {userAnswer !== null && (
                                                <Text style={isCorrect && question.correctAnswer === choiceIndex ? styles.correctIcon : userAnswer === choiceIndex ? styles.incorrectIcon : null}>
                                                    {isCorrect && question.correctAnswer === choiceIndex ? '✔' : userAnswer === choiceIndex ? '✘' : ''}
                                                </Text>
                                            )}
                                        </View>
                                    ))}
                                </View>

                                {/* Correct/Incorrect Message */}
                                {userAnswer !== null && (
                                    <View style={styles.resultContainer}>
                                        <Text style={[styles.resultText, isCorrect ? styles.correctResult : styles.incorrectResult]}>
                                            {isCorrect ? 'Correct!' : 'Incorrect!'}
                                        </Text>
                                        <Text style={styles.rationaleTitle}>Rationale:</Text>
                                        <Text style={styles.rationaleText}>
                                            {question.rationale ? question.rationale : 'There was no rationale for this question.'}
                                        </Text>
                                    </View>
                                )}
                            </View>
                        );
                    })}
                </View>

                <Text style={styles.footer} render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} fixed />
            </Page>


            {/* Reflection Section */}
            <Page size="A4" style={styles.page}>
                <Text style={styles.sectionTitle}>REFLECTION</Text>
                <View style={styles.divider}></View>

                <View>
                    {grade?.scenario?.reflection?.map((question, index) => {
                        const answer = grade?.reflection?.[index] !== "unanswered"
                            ? grade?.reflection?.[index]
                            : "You did not answer this question.";
                        return (
                            <View key={index} style={styles.reflectionCard}>
                                {/* Question */}
                                <Text style={styles.questionTitle}>{question || 'No question provided'}</Text>

                                {/* User details (without profile picture) */}
                                <View style={styles.reflectionFooter}>
                                    <Text style={styles.userName}>
                                        {userData?.firstName} {userData?.lastName}
                                    </Text>
                                    <Text style={styles.reflectionDate}>{grade?.creationDate || 'Date not available'}</Text>
                                </View>

                                {/* User answer */}
                                <Text style={styles.reflectionAnswer}>
                                    {answer && answer.trim() !== '' ? answer : 'You did not answer this question.'}
                                </Text>
                            </View>
                        );
                    })}
                </View>

                <Text style={styles.footer} render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} fixed />
            </Page>


            {/* Transcript Section */}
            <Page size="A4" style={styles.page}>
                <Text style={styles.sectionTitle}>SIMULATION TRANSCRIPT</Text>
                <View style={styles.divider}></View>
                {grade?.transcript?.map((line, index) => {
                    const [speaker, time, message] = line.split('/');
                    const isPlayer = speaker === 'Player';
                    return (
                        <TranscriptItem
                            key={index}
                            speaker={speaker}
                            message={message}
                            time={time}
                            isPlayer={isPlayer}
                        />
                    );
                })}
                <Text style={styles.footer} render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} fixed />
            </Page>
        </Document>
    );
};

export { PDFDocument };
