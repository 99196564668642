import { useState } from "react";
import Header from "../../components/shared/Header";
import Searchbar from "../../components/shared/Searchbar";
import useAuth from "../../hooks/useAuth";
import useSchool from "../../hooks/useSchool";
import Roles from "../../utils/roles";
import { useNavigate } from "react-router-dom";
import GenericButton from "../../components/shared/GenericButton";
import { HiPlus, HiX } from "react-icons/hi";
import { usePopUp } from "../../hooks/usePopUp";
import { SkeletonLoaderRow } from "../../components/shared/SkeletonLoader";

const Tabs = {
    SCHOOLS: "SchoolSelection",
    DATA: "Data",
    USAGE: "Usage"
}

const tabs = [Tabs.SCHOOLS, Tabs.DATA, Tabs.USAGE];


const SchoolSelection = () => {
    const { schools, selectSchool, loading } = useSchool();
    const { user, role } = useAuth();
    const { showPopUp } = usePopUp();

    const navigate = useNavigate();

    const [filteredSchools, setFilteredSchools] = useState([]);

    if (role != Roles.ADMIN) {
        throw new Error("Entered school selection without being an admin!");
    }

    const handleCreate = () => {
        showPopUp(<CreateSchool />);
    }

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
            <Header />
            <div className="w-full max-w-3xl m-40">
                <Searchbar
                    data={schools}
                    filterKeys={["name"]}
                    searchLabel={"school name"}
                    onFilter={setFilteredSchools}
                />


                <header className="text-center mb-8">
                    <h1 className="text-3xl font-semibold text-gray-800">School selection</h1>
                </header>

                <div className="bg-white shadow-lg rounded-lg p-8">
                    <div className="flex justify-between items-center">
                        <h2 className="text-xl font-semibold text-gray-700 mb-4">{`Welcome ${user.firstName} ${user.lastName}`}</h2>
                        <GenericButton icon={<HiPlus />} label="Create School" handleClick={handleCreate} />
                    </div>
                    <p className="text-gray-600 mb-6">Pick a school:</p>

                    <div className="space-y-4">
                        {loading ? <SkeletonLoaderRow /> : filteredSchools.map((school) => (
                            <div
                                key={school.id}
                                onClick={() => {
                                    selectSchool(school.id)
                                    navigate(`/${role}/dashboard`);
                                }}
                                className="flex items-center justify-between bg-gray-100 hover:bg-gray-200 transition-all p-4 rounded-lg cursor-pointer border border-gray-200"
                            >
                                <div className="flex items-center space-x-4">
                                    <div className="w-12 h-12 bg-blue-500 text-white rounded-full flex items-center justify-center text-lg font-semibold">
                                        {school.name.charAt(0)}
                                    </div>
                                    <div>
                                        <h3 className="text-lg font-medium text-gray-700">{school.name}</h3>
                                        <p className="text-sm text-gray-500">{school.lastOpened}</p>
                                    </div>
                                </div>
                                <button className="text-blue-500 hover:text-blue-600">
                                    ➡️
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

const CreateSchool = () => {
    const { showPopUp } = usePopUp();
    const [name, setName] = useState("");
    const { createSchool } = useSchool();

    const onClose = () => {
        showPopUp(null);
    }

    const handleSubmit = async () => {
        onClose();
        await createSchool(name);
    }

    return (
        <div className={`fixed inset-0 z-50 flex items-center justify-center`}>
            <div className="relative w-full max-w-2xl bg-white rounded-lg shadow dark:bg-gray-800 overflow-hidden max-h-screen p-4">
                <div className="overflow-auto max-h-[80vh]">
                    <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
                        <h3 className="text-lg font-medium text-gray-900 dark:text-gray-300 mb-2">Create School</h3>
                        <button
                            onClick={onClose}
                            type="button"
                            className="text-red-600 bg-transparent hover:bg-red-100 hover:text-red-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center justify-center dark:hover:bg-red-800 dark:hover:text-red-400"
                            style={{ marginTop: '8px', marginRight: '8px' }}  // Extra marge toegevoegd
                        >
                            <span className="sr-only">Close modal</span>
                            <HiX />
                        </button>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">School name</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                                placeholder="Enter school name"
                                required
                            />
                        </div>
                        <button type="submit" className="w-full justify-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300">
                            Create School
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SchoolSelection;