import defaultImage from '../../assets/images/22.png';

const UserRow = ({ user }) => {
    return (
        user ? <div className="flex items-center space-x-4">
            <img src={user.photoUrl || defaultImage} alt={`${user.firstName} ${user.lastName}`} className="h-10 w-10 rounded-full object-cover" />
            <div>
                <div className="text-sm font-medium text-gray-900 dark:text-white">
                    {`${user.firstName} ${user.lastName}`}
                </div>
                <div className="text-sm text-gray-500 dark:text-gray-300">{user.email}</div>
            </div>
        </div> : <div>Deleted user</div>);
}

export default UserRow;