import React from "react";
import CourseAnalyticsGraph from "../../components/analytics/GradingAnalytics";
import RecentGrades from "../../components/courses/RecentGrades";
import UpcomingAssignments from "../../components/courses/UpcomingAssignments";
import { HiArrowRight, HiChevronRight, HiDocumentText, HiClipboardCheck, HiBookOpen, HiStar, HiHome } from "react-icons/hi";
import CoursesScroller from "../../components/shared/CoursesScroller";
import useGrades from "../../hooks/useGrades";
import useCourses from "../../hooks/useCourses";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { calculateArrayFieldAverage } from "../../utils/analytics";
import useAssignments from "../../hooks/useAssignments";

const TeacherDashboard = () => {
    const { courses } = useCourses();
    const { grades } = useGrades();
    const { getActiveAssignments } = useAssignments();

    const navigate = useNavigate();
    const { role } = useAuth();

    const overallStats = {
        totalGrades: grades?.length,
        completedAssignments: getActiveAssignments().length,
        totalCourses: courses.length,
        averageScore: `${calculateArrayFieldAverage(grades, 'result')}%`,
    };

    const icons = {
        totalGrades: <HiDocumentText className="w-10 h-10 text-green-500" />,
        completedAssignments: <HiClipboardCheck className="w-10 h-10 text-blue-500" />,
        totalCourses: <HiBookOpen className="w-10 h-10 text-purple-500" />,
        averageScore: <HiStar className="w-10 h-10 text-orange-500" />,
    };

    const renderCard = (title, value, icon, onClick) => {
        return (
            <div
                className="flex items-center justify-between p-4 rounded-lg shadow-md border hover:shadow-lg transition-shadow cursor-pointer"
                onClick={onClick}
            >
                <div className="flex items-center space-x-4">
                    <div>{icon}</div>
                    <div>
                        <h2 className="text-2xl font-bold text-gray-900">{value}</h2>
                        <p className="text-sm text-gray-700">{title}</p>
                    </div>
                </div>
                <HiChevronRight className="w-8 h-8 text-gray-500" />
            </div>
        );
    };

    return (
        <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen w-full h-full">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 h-full flex flex-col">
                <div className="flex items-center space-x-2 p-8">
                    <HiHome className="text-4xl text-gray-700 dark:text-gray-300" />
                    <h1 className="text-xl font-semibold text-gray-900 dark:text-white">Dashboard</h1>
                </div>
                {/* <div className="bg-red-100 border-l-4 border-red-500 p-4 rounded-lg shadow-md flex items-center justify-between mb-8">
                    <div>
                        <h2 className="text-lg font-semibold text-gray-900">
                            (1) Unfinished Assignments
                        </h2>
                        <p className="text-sm text-gray-700">AssignmentName</p>
                    </div>
                    <div className="flex items-center space-x-2 text-red-600 cursor-pointer">
                        <h4 className="text-lg font-semibold">Click here to finish</h4>
                        <HiChevronRight className="w-6 h-6" />
                    </div>
                </div> */}

                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 mb-12">
                    {renderCard(
                        "Number of Grades",
                        overallStats.totalGrades,
                        icons.totalGrades,
                        () => navigate(`/${role}/grades`)
                    )}
                    {renderCard(
                        "Active Assignments",
                        overallStats.completedAssignments,
                        icons.completedAssignments,
                        () => navigate(`/${role}/assignments`)
                    )}
                    {renderCard(
                        "Total Courses",
                        overallStats.totalCourses,
                        icons.totalCourses,
                        () => navigate(`/${role}/courses`)
                    )}
                    {renderCard(
                        "Average Score",
                        overallStats.averageScore,
                        icons.averageScore,
                        () => navigate(`/${role}/grades`)
                    )}
                </div>

                <div className="mb-12">
                    <CoursesScroller />
                </div>


                {/* Recent Grades & Upcoming Assignments */}
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
                    <RecentGrades onViewAll={() => navigate(`/${role}/grades`)} />
                    <UpcomingAssignments onViewAll={() => navigate(`/${role}/assignments`)} />
                </div>

                {/* <div className="mb-12">
                    <CourseAnalyticsGraph label={'All Analytics'} navigateTo="teacher/analytics" />
                </div> */}
            </div>
        </div>
    );
};

export default TeacherDashboard;
