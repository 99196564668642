import React, { useState, useRef } from "react";
import useAuth from "../useAuth";
import Roles from "../../utils/roles";
import { useNavigate } from "react-router-dom";
import { usePopUp } from "../usePopUp";
import SignInCodePopup from "../../components/authentication/SignInCode";
import useSchool from "../useSchool";

/**
 * Hook for all header functionality such as creating a 4-digit code
 * @returns A object with sidebar specific data
 */
const useHeader = () => {
    const { user, role } = useAuth();
    const navigate = useNavigate();
    const { selectSchool } = useSchool();

    const handleInstitutionClick = () => {
        if (role != Roles.ADMIN) {
            return;
        }

        //Only admins can switch schools
        selectSchool(null);
    }


    return { handleInstitutionClick };
}

export default useHeader;