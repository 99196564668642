import React, { useState } from "react";
import { TabsUnderlined } from "../components/shared/TabNavigation";
import { HiCalculator, HiChartBar } from "react-icons/hi";
import useAuth from '../hooks/useAuth';
import AANCCoreCompetencies from "../components/analytics/AACN";
import CourseAnalyticsGraph from "../components/analytics/GradingAnalytics";
import ProgressionGraph from "../components/analytics/ProgressionGraph";
import GradingAnalyticsSeperated from "../components/analytics/GradingAnalyticsSeperated";
import InstitutionAnalytics from "../components/analytics/InstitutionAnalytics";


const Tabs = {
   INSTITUTION: "Institution Overview",
   GRADING: "Grading Analytics",
   AACN: "AACN Core Competencies",
   GENERAL: "General Data(Admin)"
}
const tabs = [Tabs.INSTITUTION, Tabs.GRADING, Tabs.AACN, Tabs.GENERAL];


const Analytics = () => {
   const [tab, setTab] = useState(0);
   const { user, role } = useAuth();

   return (
      <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen w-full h-full">
         <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 h-full flex flex-col">
            <div className="flex items-center space-x-2 p-8">
               <HiChartBar className="text-4xl text-gray-700 dark:text-gray-300" />
               <h1 className="text-xl font-semibold text-gray-900 dark:text-white">Analytics</h1>
            </div>
            <br></br>
            <div className='flex flex-wrap gap-4'>
               <TabsUnderlined tabs={tabs} activeTab={tab} setActiveTab={setTab} />
            </div>
            {tabs[tab] === Tabs.INSTITUTION && (
               <div>
                  <InstitutionAnalytics />
               </div>
            )}
            {tabs[tab] === Tabs.GRADING && (
               <div>
                  <GradingAnalyticsSeperated />
               </div>
            )}
            {tabs[tab] === Tabs.AACN && (
               <div>
                  <AANCCoreCompetencies />
               </div>
            )}
            {tabs[tab] === Tabs.GENERAL && (
               <div>
                  <ProgressionGraph />
               </div>
            )}
         </div>
      </div>
   );
}

export default Analytics;