export const DIALOGUE_STYLES = [
    'Default',
    'Bubbly',
    'Formal',
    'Blunt',
    'Inquisitive',
    'Commanding',
    'Empathetic',
    'Entertaining',
    'Hypochondriac',
    'Laid back',
    'Long-winded',
    'Moral',
    'Mysterious',
    'Raconteur',
    'Sarcastic',
    'Tenacious',
    'Villainous'
];


export const COLLOQUIALISM_OPTIONS = [
    "business abbreviations",
    "cowboy lingo",
    "hackneyed cliches",
    "Internet meme slang",
    "medical terminology",
    "movie quotes",
    "obscure idioms",
    "Orwellian newspeak",
    "sports metaphors",
    "technical jargon"
];


export const VOICE_OPTIONS = [
    { baseName: 'Ashley', description: 'warm, mellow' },
    { baseName: 'Bobbie', description: 'articulate, bright' },
    { baseName: 'Caleb', description: 'professional, energetic' },
    { baseName: 'Donna', description: 'delicate, crisp' },
    { baseName: 'Agnes', description: 'sassy, casual' },
    { baseName: 'Alex', description: 'friendly, expressive' },
    { baseName: 'Amanda', description: 'informative, neutral' },
    { baseName: 'Aoi', description: 'excited, upbeat' },
    { baseName: 'Austin', description: 'pleasant, comforting' },
    { baseName: 'Brandon', description: 'upbeat, professional' },
    { baseName: 'Brett', description: 'charismatic, bright' },
    { baseName: 'Brittany', description: 'anxious, energetic' },
    { baseName: 'Christopher', description: 'comforting, mellow' },
    { baseName: 'Cody', description: 'soothing, calm' },
    { baseName: 'Craig', description: 'posh, raspy' },
    { baseName: 'Dawn', description: 'resonant, composed' },
    { baseName: 'Deborah', description: 'gentle, elegant' },
    { baseName: 'Dennis', description: 'outgoing, upbeat' },
    { baseName: 'Dohyun', description: 'reassuring, professional' },
    { baseName: 'Dongmei', description: 'upbeat, pleasant' },
    { baseName: 'Douglas', description: 'deliberate, relaxed' },
    { baseName: 'Dylan', description: 'mellow, meditative' },
    { baseName: 'Edward', description: 'emphatic, shouty' },
    { baseName: 'Elizabeth', description: 'informative, calm' },
    { baseName: 'Emily', description: 'timid, gentle' },
    { baseName: 'Emma', description: 'pleasant, serene' },
    { baseName: 'Ethan', description: 'professional, reassuring' },
    { baseName: 'Gregory', description: 'soothing, pleasant' },
    { baseName: 'Guocheng', description: 'casual, friendly' },
    { baseName: 'Hades', description: 'commanding, gruff' },
    { baseName: 'Haruto', description: 'engaging, outgoing' },
    { baseName: 'Heather', description: 'youthful, lively' },
    { baseName: 'Hua', description: 'informative, neutral' },
    { baseName: 'Jennifer', description: 'confident, spirited' },
    { baseName: 'Jessica', description: 'upbeat, crisp' },
    { baseName: 'Jiho', description: 'casual, informative' },
    { baseName: 'Jing', description: 'youthful, excited' },
    { baseName: 'Jisoo', description: 'shy, secretive' },
    { baseName: 'Joonhyuk', description: 'relaxed, calm' },
    { baseName: 'Jordan', description: 'casual, engaging' },
    { baseName: 'Julia', description: 'childish, quirky' },
    { baseName: 'Julie', description: 'informative, engaging' },
    { baseName: 'Junyan', description: 'youthful, lively' },
    { baseName: 'Justin', description: 'confident, informative' },
    { baseName: 'Kimberly', description: 'gentle, pleasant' },
    { baseName: 'Kristen', description: 'confident, pleasant' },
    { baseName: 'Kyle', description: 'upbeat, informative' },
    { baseName: 'Lei', description: 'calm, informative' },
    { baseName: 'Lina', description: 'reassuring, confident' },
    { baseName: 'Ling', description: 'engaging, outgoing' },
    { baseName: 'Logan', description: 'casual, relaxed' },
    { baseName: 'Lori', description: 'childish, angelic' },
    { baseName: 'Malik', description: 'confident, professional' },
    { baseName: 'Mark', description: 'articulate, engaging' },
    { baseName: 'Mary', description: 'calm, friendly' },
    { baseName: 'Megan', description: 'anxious, delicate' },
    { baseName: 'Michael', description: 'casual, calm' },
    { baseName: 'Ming', description: 'excited, upbeat' },
    { baseName: 'Minho', description: 'upbeat, positive' },
    { baseName: 'Minji', description: 'upbeat, spirited' },
    { baseName: 'Nicole', description: 'casual, optimistic' },
    { baseName: 'Olivia', description: 'cute, upbeat' },
    { baseName: 'Philip', description: 'distinguished, smooth' },
    { baseName: 'Rachel', description: 'casual, pleasant' },
    { baseName: 'Ronald', description: 'confident, expressive' },
    { baseName: 'Ryan', description: 'professional, confident' },
    { baseName: 'Samantha', description: 'confident, outgoing' },
    { baseName: 'Sarah', description: 'upbeat, excited' },
    { baseName: 'Seojun', description: 'easygoing, casual' },
    { baseName: 'Shaun', description: 'calm, casual' },
    { baseName: 'Soojin', description: 'friendly, calm' },
    { baseName: 'Sunhao', description: 'informative, calm' },
    { baseName: 'Taeyang', description: 'enthusiastic, energetic' },
    { baseName: 'Timothy', description: 'hyped, upbeat' },
    { baseName: 'Travis', description: 'excited, confident' },
    { baseName: 'Tyler', description: 'serious, reassuring' },
    { baseName: 'Wei', description: 'energetic, upbeat' },
    { baseName: 'Wendy', description: 'pleasant, casual' },
    { baseName: 'William', description: 'calm, professional' },
    { baseName: 'Xuan', description: 'easygoing, casual' },
    { baseName: 'Yui', description: 'upbeat, spirited' },
    { baseName: 'Yuna', description: 'informative, confident' },
    { baseName: 'Yuto', description: 'confident, spirited' },
];


export const GENDER_OPTIONS = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'nonbinary', label: 'Non-Binary' },
    { value: 'all', label: 'All' },
];

export const AGE_OPTIONS = [
    { value: 'young', label: 'Young' },
    { value: 'middle-aged', label: 'Middle-aged' },
    { value: 'old', label: 'Old' },
    { value: 'all', label: 'All' },
];

export const ACCENT_OPTIONS = [
    { value: 'american', label: 'American' },
    { value: 'british', label: 'British' },
    { value: 'australian', label: 'Australian' },
    { value: 'all', label: 'All' },
];

export const PRONOUN_OPTIONS = [
    { value: 'PRONOUN_FEMALE', label: 'she / her / hers' },
    { value: 'PRONOUN_MALE', label: 'he / him / his' },
    { value: 'PRONOUN_OTHER', label: 'they / them / theirs' },
    { value: 'PRONOUN_UNSPECIFIED', label: 'Unspecified' }
];

export const STAGE_OF_LIFE_OPTIONS = [
    { value: 'LIFE_STAGE_UNSPECIFIED', label: 'Unspecified' },
    { value: 'LIFE_STAGE_CHILDHOOD', label: 'Childhood' },
    { value: 'LIFE_STAGE_ADOLESCENCE', label: 'Adolescence' },
    { value: 'LIFE_STAGE_YOUNG_ADULTHOOD', label: 'Young adulthood' },
    { value: 'LIFE_STAGE_MIDDLE_ADULTHOOD', label: 'Middle adulthood' },
    { value: 'LIFE_STAGE_LATE_ADULTHOOD', label: 'Late adulthood' }
];