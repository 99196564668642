import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react';
import { HashRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import AdminDashboard from './pages/admin/AdminDashboard';
import TeacherDashboard from './pages/teacher/TeacherDashboard';
import StudentDashboard from './pages/student/StudentDashboard';
import Roles from './utils/roles';
import SchoolAdminDashboard from './pages/school_admin/SchoolAdminDashboard';
import RoleRoute from './components/authentication/RoleRoute';
import Login from './components/authentication/Login';
import Unauthorized from './components/authentication/Unauthorized';
import { AuthProvider } from './contexts/AuthContext';
import Sidebar from './components/shared/Sidebar';
import NotFound from './pages/NotFound';
import Layout from './components/shared/Layout';
import SchoolSelection from './pages/admin/SchoolSelection';
import { SchoolProvider } from './contexts/SchoolContext';
import { UsersProvider } from './contexts/UsersContext';
import Users from './pages/Users';
import Grades from './pages/Grades';
import { GradesProvider } from './contexts/GradesContext';
import { NotificationProvider } from './contexts/NotificationContext';
import Notification from './components/shared/Notification';
import { PopUpProvider } from './contexts/PopupContext';
import PopUp from './components/shared/PopUp';
import { CoursesProvider } from './contexts/CoursesContext';
import AdminCourses from './pages/admin/AdminCourses';
import { ScenariosProvider } from './contexts/ScenariosContext';
import { ModulesProvider } from './contexts/ModulesContext';
import { AssignmentsProvider } from './contexts/AssignmentsContext';
import AdminAssignments from './pages/admin/AdminAssignments';
import UserProfile from './components/users/UserProfile';
import Signup from './components/authentication/Signup';
import { RegistrationsProvider } from './contexts/RegistrationsContext';
import ForgotPassword from './components/authentication/ForgotPassword';
import AdminSchoolInfo from './pages/admin/AdminSchoolInfo';
import Analytics from './pages/Analytics';
import TeacherAssignments from './pages/teacher/TeacherAssignments';
import PerformAssignment from './components/assignments/PerformAssignment';
import Library from './pages/Library';
import StudentAssignments from './pages/student/StudentAssignments';
import TeacherCourses from './pages/teacher/TeacherCourses';
import Modules from './components/courses/Modules';
import SimStudio from './pages/SimStudio';
import ScenarioDetails from './components/simstudio/ScenarioDetails';
import CharacterDetails from './components/simstudio/CharacterDetails';
import StudentCourses from './pages/student/StudentCourses';
import { ScriptingProvider } from './contexts/ScriptingContext';
import Medications from './pages/admin/Medications';
import Conditions from './pages/admin/Conditions';
import GradeScripting from './pages/admin/GradesScripting';
import { ScenarioCustomizationProvider } from './contexts/ScenarioCustomizationContext';
import { InworldProvider } from './contexts/InworldContext';

// BELLA Eberhardt
// E72hsz4S1IKbdxQunfSt
// zVtSMLA8pRgGZwFVpAhW

// AVA BICE
// Cb69W6wry8HNjavI424n
// 7BbZS5vDweuNTqTPSRk2

// EMILE BUTLER
// V1VoA2nUJ0DzWaZJKCP7
// ARuOZ7120zQF0vPEo7fX

// KENNEDY LIGGETT
// B9wFX35HS9QeoXnfU4Xr
// PkeHqk5V2rEcHEmFODw1

function App() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  // Handle window resize
  const handleResize = () => {
    setIsDesktop(window.innerWidth > 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Conditional rendering for non-desktop
  if (!isDesktop) {
    return (
      <div style={styles.fallbackContainer}>
        <div style={styles.fallbackContent}>
          <img src={logo} alt="App Logo" style={styles.logo} />
          <h1 style={styles.title}>Desktop Required</h1>
          <p style={styles.message}>
            This website is only available on desktop. Please switch to a desktop device to continue.
          </p>
        </div>
      </div>
    );
  }

  return (
    <Router>
      <PopUpProvider>
        <NotificationProvider>
          <AuthProvider>
            <SchoolProvider>
              <RegistrationsProvider>
                <ScenariosProvider>
                  <UsersProvider>
                    <CoursesProvider>
                      <AssignmentsProvider>
                        <GradesProvider>
                          <ScriptingProvider>
                            <InworldProvider>
                              <Notification />
                              <PopUp />
                              <Routes>
                                {/* Public route */}
                                <Route path="/login" element={<Login />} />

                                {/* Public route */}
                                <Route path="/signup" element={<Signup />} />

                                {/* Public route */}
                                <Route path="/forgotpassword" element={<ForgotPassword />} />

                                {/* Unauthorized access page */}
                                <Route path="/unauthorized" element={<Unauthorized />} />

                                {/* Admin school selection */}
                                <Route path="/school-selection" element={
                                  <RoleRoute allowedRoles={[Roles.ADMIN]}>
                                    <SchoolSelection />
                                  </RoleRoute>} />

                                {/* Admin routes */}
                                <Route
                                  path="/admin"
                                  element={
                                    <Layout />
                                  }>
                                  <Route path="/admin/dashboard" element={
                                    <RoleRoute allowedRoles={[Roles.ADMIN]}>
                                      <AdminDashboard />
                                    </RoleRoute>} />
                                  <Route path="/admin/analytics" element={
                                    <RoleRoute allowedRoles={[Roles.ADMIN]}>
                                      <Analytics />
                                    </RoleRoute>} />
                                  <Route path="/admin/users" element={
                                    <RoleRoute allowedRoles={[Roles.ADMIN]}>
                                      <Users />
                                    </RoleRoute>} />
                                  <Route path="/admin/grades" element={
                                    <RoleRoute allowedRoles={[Roles.ADMIN]}>
                                      <Grades />
                                    </RoleRoute>} />
                                  <Route path="/admin/courses" element={
                                    <RoleRoute allowedRoles={[Roles.ADMIN]}>
                                      <AdminCourses />
                                    </RoleRoute>} />
                                  <Route path="/admin/courses/:courseId" element={
                                    <RoleRoute allowedRoles={[Roles.ADMIN]}>
                                      <ModulesProvider>
                                        <AdminCourses />
                                      </ModulesProvider>
                                    </RoleRoute>} />
                                  <Route path="/admin/assignments" element={
                                    <RoleRoute allowedRoles={[Roles.ADMIN]}>
                                      <AdminAssignments />
                                    </RoleRoute>} />
                                  <Route path="/admin/assignments/:assignmentId" element={
                                    <RoleRoute allowedRoles={[Roles.ADMIN]}>
                                      <TeacherAssignments />
                                    </RoleRoute>} />
                                  {/* <Route path="/admin/simstudio" element={
                                  <RoleRoute allowedRoles={[Roles.ADMIN]}>
                                    <InworldProvider>
                                      <SimStudio />
                                    </InworldProvider>
                                  </RoleRoute>} /> */}
                                  <Route path="/admin/library/scenario/:scenarioId" element={
                                    <RoleRoute allowedRoles={[Roles.ADMIN]}>
                                      <ScenarioCustomizationProvider >
                                        <ScenarioDetails />
                                      </ScenarioCustomizationProvider>
                                    </RoleRoute>} />
                                  <Route path="/admin/library/character/:characterId" element={
                                    <RoleRoute allowedRoles={[Roles.ADMIN]}>
                                      <CharacterDetails />
                                    </RoleRoute>} />
                                  <Route path="/admin/library" element={
                                    <RoleRoute allowedRoles={[Roles.ADMIN]}>
                                      <Library />
                                    </RoleRoute>} />
                                  <Route path="/admin/profile" element={
                                    <RoleRoute allowedRoles={[Roles.ADMIN]}>
                                      <UserProfile />
                                    </RoleRoute>} />
                                  <Route path="/admin/school-info" element={
                                    <RoleRoute allowedRoles={[Roles.ADMIN]}>
                                      <AdminSchoolInfo />
                                    </RoleRoute>} />
                                  <Route path="/admin/medications" element={
                                    <RoleRoute allowedRoles={[Roles.ADMIN]}>
                                      <Medications />
                                    </RoleRoute>} />
                                  <Route path="/admin/medications/:medicationId" element={
                                    <RoleRoute allowedRoles={[Roles.ADMIN]}>
                                      <Medications />
                                    </RoleRoute>} />
                                  <Route path="/admin/conditions" element={
                                    <RoleRoute allowedRoles={[Roles.ADMIN]}>
                                      <Conditions />
                                    </RoleRoute>} />
                                  <Route path="/admin/conditions/:conditionId" element={
                                    <RoleRoute allowedRoles={[Roles.ADMIN]}>
                                      <Conditions />
                                    </RoleRoute>} />
                                  <Route path="/admin/gradesscript" element={
                                    <RoleRoute allowedRoles={[Roles.ADMIN]}>
                                      <GradeScripting />
                                    </RoleRoute>} />
                                  <Route path="/admin/gradesscript/:gradesscriptId" element={
                                    <RoleRoute allowedRoles={[Roles.ADMIN]}>
                                      <GradeScripting />
                                    </RoleRoute>} />
                                </Route>


                                {/* School Admin routes */}
                                <Route
                                  path="/school_admin/"
                                  element={
                                    <Layout />
                                  }
                                >
                                  <Route path="/school_admin/dashboard" element={
                                    <RoleRoute allowedRoles={[Roles.SCHOOLADMIN]}>
                                      <AdminDashboard />
                                    </RoleRoute>} />
                                  <Route path="/school_admin/analytics" element={
                                    <RoleRoute allowedRoles={[Roles.SCHOOLADMIN]}>
                                      <Analytics />
                                    </RoleRoute>} />
                                  <Route path="/school_admin/users" element={
                                    <RoleRoute allowedRoles={[Roles.SCHOOLADMIN]}>
                                      <Users />
                                    </RoleRoute>} />
                                  <Route path="/school_admin/grades" element={
                                    <RoleRoute allowedRoles={[Roles.SCHOOLADMIN]}>
                                      <Grades />
                                    </RoleRoute>} />
                                  <Route path="/school_admin/courses" element={
                                    <RoleRoute allowedRoles={[Roles.SCHOOLADMIN]}>
                                      <AdminCourses />
                                    </RoleRoute>} />
                                  <Route path="/school_admin/courses/:courseId" element={
                                    <RoleRoute allowedRoles={[Roles.SCHOOLADMIN]}>
                                      <ModulesProvider>
                                        <AdminCourses />
                                      </ModulesProvider>
                                    </RoleRoute>} />
                                  <Route path="/school_admin/assignments" element={
                                    <RoleRoute allowedRoles={[Roles.SCHOOLADMIN]}>
                                      <AdminAssignments />
                                    </RoleRoute>} />
                                  <Route path="/school_admin/simstudio" element={
                                    <RoleRoute allowedRoles={[Roles.SCHOOLADMIN]}>
                                      <SimStudio />
                                    </RoleRoute>} />
                                  <Route path="/school_admin/simstudio/:scenarioId" element={
                                    <RoleRoute allowedRoles={[Roles.SCHOOLADMIN]}>
                                      <ScenarioDetails />
                                    </RoleRoute>} />
                                  <Route path="/school_admin/simstudio/:characterId" element={
                                    <RoleRoute allowedRoles={[Roles.SCHOOLADMIN]}>
                                      <CharacterDetails />
                                    </RoleRoute>} />
                                  <Route path="/school_admin/library" element={
                                    <RoleRoute allowedRoles={[Roles.SCHOOLADMIN]}>
                                      <Library />
                                    </RoleRoute>} />
                                  <Route path="/school_admin/profile" element={
                                    <RoleRoute allowedRoles={[Roles.SCHOOLADMIN]}>
                                      <UserProfile />
                                    </RoleRoute>} />
                                </Route>

                                {/* Teacher routes */}
                                <Route
                                  path="/teacher/"
                                  element={
                                    <Layout />
                                  }
                                >
                                  <Route path="/teacher/dashboard" element={
                                    <RoleRoute allowedRoles={[Roles.TEACHER]}>
                                      <TeacherDashboard />
                                    </RoleRoute>} />
                                  <Route path="/teacher/analytics" element={
                                    <RoleRoute allowedRoles={[Roles.TEACHER]}>
                                      <Analytics />
                                    </RoleRoute>} />
                                  <Route path="/teacher/users" element={
                                    <RoleRoute allowedRoles={[Roles.TEACHER]}>
                                      <Users />
                                    </RoleRoute>} />
                                  <Route path="/teacher/grades" element={
                                    <RoleRoute allowedRoles={[Roles.TEACHER]}>
                                      <Grades />
                                    </RoleRoute>} />
                                  <Route path="/teacher/courses" element={
                                    <RoleRoute allowedRoles={[Roles.TEACHER]}>
                                      <TeacherCourses />
                                    </RoleRoute>} />
                                  <Route path="/teacher/courses/:courseId" element={
                                    <RoleRoute allowedRoles={[Roles.TEACHER]}>
                                      <ModulesProvider>
                                        <TeacherCourses />
                                      </ModulesProvider>
                                    </RoleRoute>} />
                                  <Route path="/teacher/assignments" element={
                                    <RoleRoute allowedRoles={[Roles.TEACHER]}>
                                      <TeacherAssignments />
                                    </RoleRoute>} />
                                  {/* <Route path="/teacher/simstudio" element={
                                  <RoleRoute allowedRoles={[Roles.TEACHER]}>
                                    <InworldProvider>
                                      <SimStudio />
                                    </InworldProvider>
                                  </RoleRoute>} /> */}
                                  <Route path="/teacher/library" element={
                                    <RoleRoute allowedRoles={[Roles.TEACHER]}>
                                      <Library />
                                    </RoleRoute>} />
                                  <Route path="/teacher/library/scenario/:scenarioId" element={
                                    <RoleRoute allowedRoles={[Roles.TEACHER]}>
                                      <ScenarioCustomizationProvider >
                                        <ScenarioDetails />
                                      </ScenarioCustomizationProvider>
                                    </RoleRoute>} />
                                  <Route path="/teacher/library/character/:characterId" element={
                                    <RoleRoute allowedRoles={[Roles.TEACHER]}>
                                      <CharacterDetails />
                                    </RoleRoute>} />
                                  <Route path="/teacher/assignments/:assignmentId" element={
                                    <RoleRoute allowedRoles={[Roles.TEACHER]}>
                                      <TeacherAssignments />
                                    </RoleRoute>} />
                                  <Route path="/teacher/profile" element={
                                    <RoleRoute allowedRoles={[Roles.TEACHER]}>
                                      <UserProfile />
                                    </RoleRoute>} />
                                </Route>

                                {/* Student routes */}
                                <Route
                                  path="/student/"
                                  element={
                                    <Layout />
                                  }
                                >
                                  <Route path="/student/dashboard" element={
                                    <RoleRoute allowedRoles={[Roles.STUDENT]}>
                                      <StudentDashboard />
                                    </RoleRoute>} />
                                  <Route path="/student/analytics" element={
                                    <RoleRoute allowedRoles={[Roles.STUDENT]}>
                                      <Analytics />
                                    </RoleRoute>} />
                                  <Route path="/student/grades" element={
                                    <RoleRoute allowedRoles={[Roles.STUDENT]}>
                                      <Grades />
                                    </RoleRoute>} />
                                  <Route path="/student/courses" element={
                                    <RoleRoute allowedRoles={[Roles.STUDENT]}>
                                      <StudentCourses />
                                    </RoleRoute>} />
                                  <Route path="/student/courses/:courseId" element={
                                    <RoleRoute allowedRoles={[Roles.STUDENT]}>
                                      <ModulesProvider>
                                        <StudentCourses />
                                      </ModulesProvider>
                                    </RoleRoute>} />
                                  <Route path="/student/assignments" element={
                                    <RoleRoute allowedRoles={[Roles.STUDENT]}>
                                      <StudentAssignments />
                                    </RoleRoute>} />
                                  <Route path="/student/assignments/:assignmentId" element={
                                    <RoleRoute allowedRoles={[Roles.STUDENT]}>
                                      <StudentAssignments />
                                    </RoleRoute>} />
                                  <Route path="/student/library" element={
                                    <RoleRoute allowedRoles={[Roles.STUDENT]}>
                                      <Library />
                                    </RoleRoute>} />
                                  <Route path="/student/profile" element={
                                    <RoleRoute allowedRoles={[Roles.STUDENT]}>
                                      <UserProfile />
                                    </RoleRoute>} />
                                </Route>

                                {/* Default fallback */}
                                <Route path="*" element={<NotFound />} />
                              </Routes>
                            </InworldProvider>
                          </ScriptingProvider>
                        </GradesProvider>
                      </AssignmentsProvider>
                    </CoursesProvider>
                  </UsersProvider>
                </ScenariosProvider>
              </RegistrationsProvider>
            </SchoolProvider>
          </AuthProvider >
        </NotificationProvider>
      </PopUpProvider>
    </Router >
  );
}

const styles = {
  fallbackContainer: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f8f9fa',
  },
  fallbackContent: {
    textAlign: 'center',
    backgroundColor: '#ffffff',
    padding: '40px',
    borderRadius: '8px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  },
  logo: {
    width: '150px',
    marginBottom: '20px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#343a40',
  },
  message: {
    fontSize: '16px',
    color: '#6c757d',
    marginTop: '10px',
  },
};

export default App;
