import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';

// Register the necessary Chart.js elements
Chart.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

// Helper component for counting total items
const TotalItems = ({ title, total }) => (
  <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
    <div className="w-full">
      <h3 className="text-base font-normal text-gray-500 dark:text-gray-400">{title}</h3>
      <span className="text-2xl font-bold leading-none text-gray-900 sm:text-3xl dark:text-white">{total}</span>
    </div>
  </div>
);

export default function ProgressionGraph() {
  const [analyticsData, setAnalyticsData] = useState([]);

  // Mock data for roles count and courses
  const rolesCount = {
    admin: 50,
    student: 500,
    teacher: 120,
    totalPendingUsers: 80,
    totalSchools: 30,
    totalGrades: 1500,
    totalAssessments: 2000,
    totalModules: 100,
    totalScenarios: 75,
    totalCharacters: 50,
  };

  const courses = Array.from({ length: 50 }, (_, index) => ({
    id: index + 1,
    name: `Course ${index + 1}`,
  }));

  useEffect(() => {
    // Mock data for testing without Firebase
    const mockData = [
      {
        date: '2024-11-01',
        totalUsers: 1500,
        totalSchools: 50,
        totalCourses: 200,
        totalAssessments: 300,
        totalScenarios: 80,
        totalGrades: 1000
      },
      {
        date: '2024-11-02',
        totalUsers: 1550,
        totalSchools: 52,
        totalCourses: 205,
        totalAssessments: 305,
        totalScenarios: 85,
        totalGrades: 1100
      },
      {
        date: '2024-11-03',
        totalUsers: 1600,
        totalSchools: 55,
        totalCourses: 210,
        totalAssessments: 310,
        totalScenarios: 90,
        totalGrades: 1200
      },
      {
        date: '2024-11-04',
        totalUsers: 1650,
        totalSchools: 57,
        totalCourses: 215,
        totalAssessments: 320,
        totalScenarios: 95,
        totalGrades: 1300
      },
      {
        date: '2024-11-05',
        totalUsers: 1700,
        totalSchools: 60,
        totalCourses: 220,
        totalAssessments: 330,
        totalScenarios: 100,
        totalGrades: 1400
      }
    ];

    // Set mock data to analyticsData
    setAnalyticsData(mockData);
  }, []);

  const prepareChartData = () => {
    const dates = analyticsData.map(data => data.date);
    const totalUsers = analyticsData.map(data => data.totalUsers);
    const totalSchools = analyticsData.map(data => data.totalSchools);
    const totalCourses = analyticsData.map(data => data.totalCourses);
    const totalAssessments = analyticsData.map(data => data.totalAssessments);
    const totalScenarios = analyticsData.map(data => data.totalScenarios);
    const totalGrades = analyticsData.map(data => data.totalGrades);

    return {
      labels: dates,
      datasets: [
        {
          label: 'Total Users',
          data: totalUsers,
          borderColor: 'rgba(75, 192, 192, 1)',
          fill: false,
          tension: 0.3,
        },
        {
          label: 'Total Schools',
          data: totalSchools,
          borderColor: 'rgba(153, 102, 255, 1)',
          fill: false,
          tension: 0.3,
        },
        {
          label: 'Total Courses',
          data: totalCourses,
          borderColor: 'rgba(255, 159, 64, 1)',
          fill: false,
          tension: 0.3,
        },
        {
          label: 'Total Assessments',
          data: totalAssessments,
          borderColor: 'rgba(0, 204, 102, 1)',
          fill: false,
          tension: 0.3,
        },
        {
          label: 'Total Scenarios',
          data: totalScenarios,
          borderColor: 'rgba(54, 162, 235, 1)',
          fill: false,
          tension: 0.3,
        },
        {
          label: 'Total Grades',
          data: totalGrades,
          borderColor: 'rgba(75, 192, 192, 0.6)',
          fill: false,
          tension: 0.3,
        }
      ]
    };
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Ensures the chart scales well
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
        grid: {
          display: false, // Removes gridlines from the x-axis
        },
      },
      y: {
        title: {
          display: true,
          text: 'Total Counts',
        },
        beginAtZero: true, // Y-axis starts from 0 and grows dynamically
        ticks: {
          stepSize: 1000, // Optional: Set step size for the y-axis
        },
        grid: {
          borderDash: [5, 5], // Dotted gridlines for the y-axis
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.parsed.y}`,
        },
      },
    },
  };

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
      <div className="mb-8">

        {/* Progression Graph */}
        <div className="mb-6">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">App Progress Analytics</h3>
          {analyticsData.length > 0 ? (
            <div style={{ height: '400px' }}>
              <Line data={prepareChartData()} options={chartOptions} />
            </div>
          ) : (
            <p className="text-gray-600 dark:text-gray-300">No analytics data available.</p>
          )}
        </div>

          {/* Display Total Items */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-6">
          <TotalItems title="Total Users" total={rolesCount.admin + rolesCount.student + rolesCount.teacher} />
          <TotalItems title="Total Pending Registrations" total={rolesCount.totalPendingUsers || 0} />
          <TotalItems title="Total Courses" total={courses.length} />
          <TotalItems title="Total Schools" total={rolesCount.totalSchools || 0} />
          <TotalItems title="Total Grades" total={rolesCount.totalGrades || 0} />
          <TotalItems title="Total Assessments" total={rolesCount.totalAssessments || 0} />
          <TotalItems title="Total Modules" total={rolesCount.totalModules || 0} />
          <TotalItems title="Total Scenarios" total={rolesCount.totalScenarios || 0} />
          <TotalItems title="Total AI Characters" total={rolesCount.totalCharacters || 0} />
        </div>
      </div>
    </div>
  );
}
