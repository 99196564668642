import { useContext } from "react";
import { PopUpContext } from "../contexts/PopupContext";

/**
 * Hook for Popup functionality
 * @returns 
 */
export const usePopUp = () => {
    const context = useContext(PopUpContext);

    if(!context){
        throw new Error("UsePopup must be used within a PopupProvider")
    }

    return context;
}
