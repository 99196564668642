import React, { useEffect, useState } from 'react';
import { Handle } from 'reactflow';
import NodeParent from './NodeParent';
import { FaCogs } from 'react-icons/fa';
import { NodeTypes, EventTypes, PatientInteraction, MedicationIDs, getEnumKeyByValue, EffectTypes, prettyPrintEnum, VitalTypes, HeartRhythms, BehaviourTypes, PhysiologySystems, CardiacFindings, PulmonaryFindings, ChestExpansion, ChestShape, RespiratoryPattern, LungSounds, NeurologicalFindings, PupilShape, DirectionType, PupilReaction, EyeGaze, EyeSclera, FacialSymmetry, MotorFunction, PatientExtremity, Ataxia, SensoryLocations, SensoryFunction, Speech, LungSoundFields, HeartSoundFields, HeartSounds, PulseFields, Pulses, Edema, GastrointestinalFindings, BowelSounds, BowelSoundFields, AbdominalPalpation, AbdominalContourTypes, IntegumentaryFindings, RashTypes, WoundSites, WoundTypes, MusculoskeletalFindings, StrengthTypes, ClubbingTypes, AICharacterActions, LabTests, OrderTypes, GradeCategory, MedicationTypes, MedicationUnit, MedicationRoute, InworldTarget, MaternityFindings, MedicationRouteInclIVBag, TitrationUnit } from '../../../utils/ScriptingEnumLibrary';
import useScripting from '../../../hooks/useScripting';
import { ScriptingEnumLabel, ScriptingEnumLabelMultiple, ScriptingIntegerInput, ScriptingStringInput } from '../library/ScriptingComponentLibrary';

const EffectNode = ({ id, data, selected }) => {
  const { updateNodeData } = useScripting();

  //TODO: Erase fields that are no longer used after switching effect type. (e.g. vital_id if effect_type is no longer ChangeVital)
  const createMetadata = () => {
    switch (data?.effect_type) {
      case EffectTypes.ChangeVital:
        return (
          <div className="mb-4">
            <label className="block text-sm mb-1">Change</label>
            <ScriptingEnumLabel id={id} data={data} label="vital_id" enumType={VitalTypes} />
            <div
              className="w-full p-3 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded-lg">{data?.absolute ? "to" : "by"}</div>
            <ScriptingIntegerInput id={id} data={data} label="change_by" />
            <div
              className="w-full p-3 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded-lg">over</div>
            <div className="flex items-center space-x-2">
              <ScriptingIntegerInput id={id} data={data} label="period" />
              <div
                className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded-lg">
                seconds
              </div>
            </div>
            <div className="mt-4 flex items-center">
              <label className="relative inline-flex items-center cursor-pointer pr-3">
                <input
                  type="checkbox" className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded-lg"
                  checked={data?.absolute || false}
                  onChange={(e) => {
                    //console.log(e);
                    updateNodeData(id, { absolute: e.target.checked })
                  }}
                />
              </label>
              <label className="mr-2 text-sm">Absolute change (override)</label>
            </div>
          </div>);
      case EffectTypes.ChangeBehavior:
        return (
          <div className="mb-4">
            <label className="block text-sm mb-1">New Behavior</label>
            <ScriptingEnumLabel id={id} data={data} label="behavior" enumType={BehaviourTypes} />
          </div>);
      case EffectTypes.ChangePhysiology:
        return (
          <div className="mb-4">
            <label className="block text-sm mb-1">Physiology System</label>
            <ScriptingEnumLabel id={id} data={data} label="system" enumType={PhysiologySystems} />
            <div className="mt-4 mb-4">
              {(() => {
                switch (data?.system) {
                  case PhysiologySystems.Neurological:
                    return (<div>
                      <label className="block text-sm mb-1">Finding</label>
                      <ScriptingEnumLabel id={id} data={data} label="finding" enumType={NeurologicalFindings} />
                      <div className="mt-4 mb-4">
                        {(() => {
                          switch (data?.finding) {
                            case NeurologicalFindings.PupilShape:
                              return (<div>
                                <label className="block text-sm mb-1">Pupil</label>
                                <ScriptingEnumLabel id={id} data={data} label="location" enumType={DirectionType} />
                                <div className="mb-4"></div>
                                <label className="block text-sm mb-1">Pupil Shape</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={PupilShape} />
                              </div>
                              );
                            case NeurologicalFindings.PupilReaction:
                              return (<div>
                                <label className="block text-sm mb-1">Pupil</label>
                                <ScriptingEnumLabel id={id} data={data} label="location" enumType={DirectionType} />
                                <div className="mb-4"></div>
                                <label className="block text-sm mb-1">Pupil Reaction</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={PupilReaction} />
                              </div>
                              );
                            case NeurologicalFindings.EyeGaze:
                              return (<div>
                                <label className="block text-sm mb-1">Eye Gaze</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={EyeGaze} />
                              </div>
                              );
                            case NeurologicalFindings.EyeSclera:
                              return (<div>
                                <label className="block text-sm mb-1">Eye Sclera</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={EyeSclera} />
                              </div>
                              );
                            case NeurologicalFindings.FacialSymmetry:
                              return (<div>
                                <label className="block text-sm mb-1">Facial Symmetry</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={FacialSymmetry} />
                              </div>
                              );
                            case NeurologicalFindings.MotorFunction:
                              return (<div>
                                <label className="block text-sm mb-1">Location</label>
                                <ScriptingEnumLabel id={id} data={data} label="location" enumType={PatientExtremity} />
                                <label className="block text-sm mt-4 mb-1">Motor Function</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={MotorFunction} />
                              </div>
                              );
                            case NeurologicalFindings.Ataxia:
                              return (<div>
                                <label className="block text-sm mb-1">Location</label>
                                <ScriptingEnumLabel id={id} data={data} label="location" enumType={PatientExtremity} />
                                <label className="block text-sm mt-4 mb-1">Ataxia</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={Ataxia} />
                              </div>
                              );
                            case NeurologicalFindings.SensoryFunction:
                              return (<div>
                                <label className="block text-sm mb-1">Location</label>
                                <ScriptingEnumLabel id={id} data={data} label="location" enumType={SensoryLocations} />
                                <label className="block text-sm mt-4 mb-1">Sensory Function</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={SensoryFunction} />
                              </div>
                              );
                            case NeurologicalFindings.Speech:
                              return (<div>
                                <label className="block text-sm mt-4 mb-1">Speech</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={Speech} />
                              </div>
                              );
                            default:
                              return <div>Unrecognized finding.</div>;
                          }
                        })()}
                      </div>
                    </div>
                    );
                  case PhysiologySystems.Cardiac:
                    return (<div>
                      <label className="block text-sm mb-1">Finding</label>
                      <ScriptingEnumLabel id={id} data={data} label="finding" enumType={CardiacFindings} />
                      <div className="mt-4 mb-4">
                        {(() => {
                          switch (data?.finding) {
                            case CardiacFindings.HeartAuscultation:
                              return (<div>
                                <label className="block text-sm mb-1">Locations</label>
                                <ScriptingEnumLabelMultiple id={id} data={data} label="location" enumType={HeartSoundFields} />
                                <label className="block text-sm mt-4 mb-1">Heart auscultation</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={HeartSounds} />
                              </div>
                              );
                            case CardiacFindings.HeartRhythm:
                              return (<div>
                                <label className="block text-sm mb-1">Heart rhythm</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={HeartRhythms} />
                              </div>
                              );
                            case CardiacFindings.Pulses:
                              return (<div>
                                <label className="block text-sm mb-1">Locations</label>
                                <ScriptingEnumLabelMultiple id={id} data={data} label="location" enumType={PulseFields} />
                                <label className="block text-sm mt-4 mb-1">Pulse</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={Pulses} />
                              </div>
                              );
                            case CardiacFindings.Edema:
                              return (<div>
                                <label className="block text-sm mb-1">Pitting Edema</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={Edema} />
                              </div>
                              );
                            default:
                              return <div>Unrecognized finding.</div>;
                          }
                        })()}
                      </div>
                    </div>
                    );
                  case PhysiologySystems.Pulmonary:
                    return (<div>
                      <label className="block text-sm mb-1">Finding</label>
                      <ScriptingEnumLabel id={id} data={data} label="finding" enumType={PulmonaryFindings} />
                      <div className="mt-4 mb-4">
                        {(() => {
                          switch (data?.finding) {
                            case PulmonaryFindings.ChestExpansion:
                              return (<div>
                                <label className="block text-sm mb-1">Chest expansion</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={ChestExpansion} />
                              </div>
                              );
                            case PulmonaryFindings.ChestShape:
                              return (<div>
                                <label className="block text-sm mb-1">Chest shape</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={ChestShape} />
                              </div>
                              );
                            case PulmonaryFindings.LungAuscultation:
                              return (<div>
                                <label className="block text-sm mb-1">Location</label>
                                <ScriptingEnumLabelMultiple id={id} data={data} label="location" enumType={LungSoundFields} />
                                <label className="block text-sm mt-4 mb-1">Lung auscultation</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={LungSounds} />
                              </div>
                              );
                            case PulmonaryFindings.RespiratoryPattern:
                              return (<div>
                                <label className="block text-sm mb-1">Respiratory pattern</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={RespiratoryPattern} />
                              </div>
                              );
                            case PulmonaryFindings.Drooling:
                              return (<div>
                                <label className="block text-sm mb-1">Drooling</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={ClubbingTypes} />
                              </div>
                              );
                            default:
                              return <div>Unrecognized finding.</div>;
                          }
                        })()}
                      </div>
                    </div>
                    );
                  case PhysiologySystems.Gastrointestinal:
                    return (<div>
                      <label className="block text-sm mb-1">Finding</label>
                      <ScriptingEnumLabel id={id} data={data} label="finding" enumType={GastrointestinalFindings} />
                      <div className="mt-4 mb-4">
                        {(() => {
                          switch (data?.finding) {
                            case GastrointestinalFindings.BowelSounds:
                              return (<div>
                                <label className="block text-sm mb-1">Locations</label>
                                <ScriptingEnumLabelMultiple id={id} data={data} label="location" enumType={BowelSoundFields} />
                                <label className="block text-sm mt-4 mb-1">Bowel sounds</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={BowelSounds} />
                              </div>
                              );
                            case GastrointestinalFindings.AbdominalPalpation:
                              return (<div>
                                <label className="block text-sm mb-1">Locations</label>
                                <ScriptingEnumLabelMultiple id={id} data={data} label="location" enumType={BowelSoundFields} />
                                <label className="block text-sm mt-4 mb-1">Palpation finding</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={AbdominalPalpation} />
                              </div>
                              );
                            case GastrointestinalFindings.AbdominalContour:
                              return (<div>
                                <label className="block text-sm mb-1">Contour</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={AbdominalContourTypes} />
                              </div>
                              );
                            default:
                              return <div>Unrecognized finding.</div>;
                          }
                        })()}
                      </div>
                    </div>
                    );
                  case PhysiologySystems.Integumentary:
                    return (<div>
                      <label className="block text-sm mb-1">Finding</label>
                      <ScriptingEnumLabel id={id} data={data} label="finding" enumType={IntegumentaryFindings} />
                      <div className="mt-4 mb-4">
                        {(() => {
                          switch (data?.finding) {
                            case IntegumentaryFindings.GeneralizedRashes:
                              return (<div>
                                <label className="block text-sm mb-1">Rash</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={RashTypes} />
                              </div>
                              );
                            case IntegumentaryFindings.Wounds:
                              return (<div>
                                <label className="block text-sm mb-1">Locations</label>
                                <ScriptingEnumLabel id={id} data={data} label="location" enumType={WoundSites} />
                                <label className="block text-sm mt-4 mb-1">Wound</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={WoundTypes} />
                              </div>
                              );
                            default:
                              return <div>Unrecognized finding.</div>;
                          }
                        })()}
                      </div>
                    </div>
                    );
                  case PhysiologySystems.Musculoskeletal:
                    return (<div>
                      <label className="block text-sm mb-1">Finding</label>
                      <ScriptingEnumLabel id={id} data={data} label="finding" enumType={MusculoskeletalFindings} />
                      <div className="mt-4 mb-4">
                        {(() => {
                          switch (data?.finding) {
                            case MusculoskeletalFindings.HandGrip:
                              return (<div>
                                <label className="block text-sm mb-1">Strength</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={StrengthTypes} />
                                <label className="block text-sm mb-1">Location</label>
                                <ScriptingEnumLabel id={id} data={data} label="location" enumType={DirectionType} />
                              </div>
                              );
                            case MusculoskeletalFindings.FootDorsalFlexion:
                              return (<div>
                                <label className="block text-sm mb-1">Strength</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={StrengthTypes} />
                                <label className="block text-sm mb-1">Location</label>
                                <ScriptingEnumLabel id={id} data={data} label="location" enumType={DirectionType} />
                              </div>
                              );
                            case MusculoskeletalFindings.FootPlantarFlexion:
                              return (<div>
                                <label className="block text-sm mb-1">Strength</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={StrengthTypes} />
                                <label className="block text-sm mb-1">Location</label>
                                <ScriptingEnumLabel id={id} data={data} label="location" enumType={DirectionType} />
                              </div>
                              );
                            case MusculoskeletalFindings.Clubbing:
                              return (<div>
                                <label className="block text-sm mb-1">Clubbing</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={ClubbingTypes} />
                              </div>
                              );
                            default:
                              return <div>Unrecognized finding.</div>;
                          }
                        })()}
                      </div>
                    </div>
                    );
                  case PhysiologySystems.Maternity:
                    return (<div>
                      <label className="block text-sm mb-1">Finding</label>
                      <ScriptingEnumLabel id={id} data={data} label="finding" enumType={MaternityFindings} />
                      <div className="mt-4 mb-4">
                        {(() => {
                          switch (data?.finding) {
                            case MaternityFindings.FetalHeartRate:
                              return (<div>
                                <label className="block text-sm mb-1">Fetal Heart Rate</label>
                                <ScriptingIntegerInput id={id} data={data} label="value" />
                              </div>
                              );
                            case MaternityFindings.FundalHeight:
                              return (<div>
                                <label className="block text-sm mb-1">Fundal Height</label>
                                <ScriptingIntegerInput id={id} data={data} label="value" />
                              </div>
                              );
                            case MaternityFindings.VaginalHemorrhage:
                              return (<div>
                                <label className="block text-sm mb-1">Hemorrhage</label>
                                <ScriptingEnumLabel id={id} data={data} label="value" enumType={ClubbingTypes} />
                              </div>
                              );
                            default:
                              return <div>Unrecognized finding.</div>;
                          }
                        })()}
                      </div>
                    </div>
                    );
                  default:
                    return <div>Unrecognized system.</div>;
                }
              })()}
            </div>
          </div>);
      case EffectTypes.SetCustomField:
        return (<div className="mb-4">
          <label className="block text-sm mb-1">Custom field name</label>
          <ScriptingStringInput id={id} data={data} label="custom_field" />
          <label className="block text-sm mt-4 mb-1">Value</label>
          <ScriptingStringInput id={id} data={data} label="custom_field_value" />
        </div>);
      case EffectTypes.AiCharacterActions:
        return (<div className="mb-4">
          <label className="block text-sm mb-1">Action</label>
          <ScriptingEnumLabel id={id} data={data} label="action" enumType={AICharacterActions} />
          <label className="block text-sm mt-4 mb-1">Input</label>
          <ScriptingStringInput id={id} data={data} label="input" />
          <label className="block text-sm mt-4 mb-1">Target person</label>
          <ScriptingEnumLabel id={id} data={data} label="target" enumType={InworldTarget} />
          <label className="block text-sm mt-4 mb-1">{data?.action == AICharacterActions.Say ? "Have the AI character say a phrase (e.g. 'Hi, how are you?')" : data?.action == AICharacterActions.Instruction ? "Instruct the AI character to say something (e.g. 'Tell a joke')" : "Send a custom trigger for the character's goals (e.g. 'sit_up')"}</label>
        </div>);
      case EffectTypes.NewLabResult:
        return (<div className="mb-4">
          <label className="block text-sm mb-1">Lab</label>
          <ScriptingStringInput id={id} data={data} label="lab_category" />
          <label className="block text-sm mt-4 mb-1">Name</label>
          <ScriptingStringInput id={id} data={data} label="lab_field" />
          <label className="block text-sm mt-4 mb-1">Result</label>
          <ScriptingIntegerInput id={id} data={data} label="lab_value" />
        </div>);
      case EffectTypes.NewPhoneCall:
        return (<div className="mb-4">
          <label className="block text-sm mt-4 mb-1">By: (Inworld brain name)</label>
          <ScriptingStringInput id={id} data={data} label="brain_name" />
        </div>);
      case EffectTypes.NewOrder:
        return (<div className="mb-4">
          <label className="block text-sm mt-4 mb-1">Type</label>
          <ScriptingEnumLabel id={id} data={data} label="order_type" enumType={OrderTypes} />
          {(() => {
            switch (data?.order_type) {
              case OrderTypes.Medications:
                return (
                  <div className="mb-4">
                    <label className="block text-sm mt-4 mb-1">Medication</label>
                    <ScriptingEnumLabel id={id} data={data} label="medication" enumType={MedicationTypes} />
                    <label className="block text-sm mt-4 mb-1">Route</label>
                    <ScriptingEnumLabel id={id} data={data} label="route" enumType={MedicationRouteInclIVBag} />
                    <label className="block text-sm mt-4 mb-1">Dosage</label>
                    <ScriptingIntegerInput id={id} data={data} label="dose" />
                    <label className="block text-sm mt-4 mb-1">Dosage Unit</label>
                    <ScriptingEnumLabel id={id} data={data} label="unit" enumType={MedicationUnit} />
                    {(() => {
                      if (data?.route == MedicationRouteInclIVBag.IvBag) {
                        return (
                          <div>
                            <label className="block text-sm mt-4 mb-1">Rate</label>
                            <ScriptingIntegerInput id={id} data={data} label="rate" />
                            <label className="block text-sm mt-4 mb-1">Rate Unit</label>
                            <ScriptingEnumLabel id={id} data={data} label="titration_unit" enumType={TitrationUnit} />
                          </div>
                        );
                      }
                      return (<div></div>);
                    })()}
                    <label className="block text-sm mt-4 mb-1">Frequency</label>
                    <ScriptingStringInput id={id} data={data} label="frequency" />
                  </div>)
              default:
                return (
                  <div className="mb-4">
                    <label className="block text-sm mt-4 mb-1">Order</label>
                    <ScriptingStringInput id={id} data={data} label="order" />
                  </div>)
            }
          })()}
        </div>);
      case EffectTypes.Grade:
        return (<div className="mb-4">
          <label className="block text-sm mt-4 mb-1">Category</label>
          <ScriptingEnumLabel id={id} data={data} label="grade_category" enumType={GradeCategory} />
          <label className="block text-sm mt-4 mb-1">Name</label>
          <ScriptingStringInput id={id} data={data} label="grade_name" />
          <label className="block text-sm mt-4 mb-1">Set result to: </label>
          <ScriptingIntegerInput id={id} data={data} label="grade_result" />
          <label className="block text-sm mt-1 mb-1">out of</label>
          <ScriptingIntegerInput id={id} data={data} label="grade_max_points" />
          <label className="block text-sm mt-1 mb-4">Points</label>
        </div>);
      default:
        return (<div className="mb-4">
          <label className="block text-sm mb-1">Invalid effect type</label>
        </div>);
    }
  };

  return (
    <NodeParent title="Effect" icon={<FaCogs />} selected={selected} description="Changes a vital sign">
      <div className="mb-4">
        <label className="block text-sm mb-1">Effect Type</label>
        <ScriptingEnumLabel id={id} data={data} label="effect_type" enumType={EffectTypes} />
      </div>

      {createMetadata()}

      <Handle type="source" position="right" className="bg-cyan-700" style={{ width: '12px', height: '12px' }} />
      <Handle type="target" position="left" className="bg-cyan-700" style={{ width: '12px', height: '12px' }} />
    </NodeParent>
  );
};

export default EffectNode;
