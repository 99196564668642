
import useSchool from "./useSchool";
import { useContext, useEffect } from "react";
import { RegistrationsContext } from "../contexts/RegistrationsContext";


const useRegistrations = () => {
    const { registrations, registrationsMap, loading, addRegistration, completeRegistration, deleteRegistration} = useContext(RegistrationsContext)

    const getRegistrationByID = (registrationId) => {
        if (!registrationsMap || registrationsMap.size <= 0) {
            console.error("Error trying to get registration by ID: No registrations!");
            return;
        }

        return registrationsMap.get(registrationId);
    }

    return { registrations, registrationsMap, addRegistration, getRegistrationByID, loading, completeRegistration, deleteRegistration };
}

export default useRegistrations;