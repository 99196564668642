import React, { useEffect, useState } from "react";
import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw, AtomicBlockUtils } from "draft-js";
import "draft-js/dist/Draft.css"; // Draft.js default styles
import { HiCamera, HiOutlineCheck, HiOutlineDocumentText, HiVideoCamera } from "react-icons/hi";
import { BsTypeBold, BsTypeItalic } from "react-icons/bs";
import { HiOutlineListBullet } from "react-icons/hi2";
import SaveButton from "./SaveButton";
import _ from 'lodash';
import { transformYoutubeLink } from "../../utils/utils";

// Toolbar button component
const ToolbarButton = ({ label, icon, active, onClick }) => (
    <button
        onMouseDown={(e) => {
            e.preventDefault();
            onClick();
        }}
        className={`flex items-center space-x-1 px-3 py-2 rounded-md text-sm font-medium 
        ${active ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-600 hover:bg-blue-100 dark:bg-gray-700 dark:text-gray-300"}
      `}
    >
        {icon && <span>{icon}</span>}
        <span>{label}</span>
    </button>
);

const RichTextEditor = ({ onSave, initialContent = null }) => {
    const [editorState, setEditorState] = useState(() => {
        if (!initialContent) return EditorState.createEmpty();
        const contentState = convertFromRaw(initialContent);
        return EditorState.createWithContent(contentState)
    }
    );

    const [saving, setSaving] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const handleKeyCommand = (command, editorState) => {
        setHasUnsavedChanges(true);
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return "handled";
        }
        return "not-handled";
    };

    const toggleInlineStyle = (style) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    };

    const toggleBlockType = (blockType) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    };

    // Handle adding media blocks
    const addMedia = (type) => {
        const url = prompt(
            `Enter the URL for the ${type === "YOUTUBE" ? "YouTube video" : type.toLowerCase()}:`
        );
        if (!url) return;

        let data = { src: url };
        if (type === "YOUTUBE") {
            const videoId = extractYouTubeVideoId(url);
            if (!videoId) {
                alert("Invalid YouTube URL");
                return;
            }
            data = { src: `https://www.youtube.com/embed/${videoId}` };
        }

        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(type, "IMMUTABLE", data);
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(
            editorState,
            entityKey,
            " "
        );

        setEditorState(newEditorState);
        setHasUnsavedChanges(true);
    };

    // Function to extract YouTube video ID
    const extractYouTubeVideoId = (url) => {
        const regex = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.+\?v=)|youtu\.be\/)([^"&?/ ]{11})/;
        const match = url.match(regex);
        return match ? match[1] : null;
    };

    // Custom renderer for media blocks
    const mediaBlockRenderer = (block) => {
        if (block.getType() === "atomic") {
            return {
                component: Media,
                editable: false,
            };
        }
        return null;
    };

    const saveContent = async () => {
        setSaving(true);
        const rawContent = convertToRaw(editorState.getCurrentContent());
        await onSave(rawContent);
        setSaving(false);
        setHasUnsavedChanges(false);
    };

    const blockStyleFn = (block) => {
        switch (block.getType()) {
            case "header-one":
                return "text-2xl font-bold";
            case "header-two":
                return "text-xl font-semibold";
            case "header-three":
                return "text-lg font-medium";
            case "unordered-list-item":
                return "list-disc ml-6";
            default:
                return "";
        }
    };

    return (
        <div className="rounded-lg shadow-lg bg-white dark:bg-gray-800 p-4 space-y-4">
            {/* Header Toolbar */}
            <div className="flex space-x-2 border-b pb-2 mb-4">
                <ToolbarButton
                    label="H1"
                    active={RichUtils.getCurrentBlockType(editorState) === "header-one"}
                    onClick={() => toggleBlockType("header-one")}
                />
                <ToolbarButton
                    label="H2"
                    active={RichUtils.getCurrentBlockType(editorState) === "header-two"}
                    onClick={() => toggleBlockType("header-two")}
                />
                <ToolbarButton
                    label="H3"
                    active={RichUtils.getCurrentBlockType(editorState) === "header-three"}
                    onClick={() => toggleBlockType("header-three")}
                />
                <ToolbarButton
                    icon={<HiOutlineListBullet />}
                    active={editorState.getCurrentInlineStyle().has("ITALIC")}
                    onClick={() => toggleBlockType("unordered-list-item")}
                />
                {/* <ToolbarButton
                    icon={<HiCamera />}
                    label="Embed Image"
                    onClick={() => addMedia("IMAGE")}
                >
                </ToolbarButton>
                <ToolbarButton
                    icon={<HiVideoCamera />}
                    label="Embed Video"
                    onClick={() => addMedia("VIDEO")}
                >
                </ToolbarButton> */}
                <ToolbarButton
                    icon={<HiVideoCamera />}
                    label="Embed Youtube Video"
                    onClick={() => addMedia("YOUTUBE")}
                >
                </ToolbarButton>
            </div>
            {/* Toolbar */}
            <div className="flex space-x-2">
                <ToolbarButton
                    icon={<BsTypeBold />}
                    active={editorState.getCurrentInlineStyle().has("BOLD")}
                    onClick={() => toggleInlineStyle("BOLD")}
                />
                <ToolbarButton
                    icon={<BsTypeItalic />}
                    active={editorState.getCurrentInlineStyle().has("ITALIC")}
                    onClick={() => toggleInlineStyle("ITALIC")}
                />
            </div>

            {/* Editor */}
            <div
                className="border border-gray-300 rounded-lg p-4 dark:border-gray-600 dark:bg-gray-700"
            // onClick={() => editorState.getSelection().hasFocus || editorState.focus()}
            >
                <Editor
                    editorState={editorState}
                    onChange={setEditorState}
                    handleKeyCommand={handleKeyCommand}
                    placeholder="Type your content here..."
                    blockStyleFn={blockStyleFn}
                    blockRendererFn={mediaBlockRenderer}
                />
            </div>

            {/* Save Button */}
            {/* <div className="flex justify-end">
                <button
                    onClick={saveContent}
                    className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:outline-none"
                >
                    <HiOutlineCheck className="inline-block mr-2" /> Save
                </button>
            </div> */}
            <div className="flex justify-end">
                <SaveButton hasUnsavedChanges={hasUnsavedChanges} saveChanges={saveContent} saving={saving} />
            </div>
        </div>
    );
};

// Media component for rendering embedded media
const Media = (props) => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0));
    const { src } = entity.getData();
    const type = entity.getType();

    return (<iframe
        width="853"
        height="480"
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
    />);

    let media;
    if (type === "IMAGE") {
        media = <img src={src} alt="Embedded" className="w-full h-auto rounded-lg" />;
    } else if (type === "YOUTUBE") {
        media =
            <iframe
                src={src}
                frameBorder="0"
                allowFullScreen
                title="YouTube Video"
                className="w-full aspect-video rounded-lg"
            ></iframe>
            ;
    }
    return media;
};

export default RichTextEditor;
