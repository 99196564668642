import { useContext, useEffect, useState } from "react";

/**
 * Hook for all datatable functionality
 * @returns 
 */
const useDatatable = () => {
    const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleNumItemsPerPageChange = (num) => {
        setItemsPerPage(num);
        setCurrentPage(1); // Reset naar pagina 1 bij wijziging van items per pagina
    };

    return {
        sortConfig,
        setSortConfig,
        currentPage,
        itemsPerPage,
        handleSort,
        handlePageChange,
        handleNumItemsPerPageChange,
    }; // Access all context values directly
};

export default useDatatable;