import { useContext, useEffect, useState } from "react";
import { ModulesContext } from "../contexts/ModulesContext";

/**
 * Hook for all modules functionality
 * @returns All usable variables and functions from this hook
 */
const useModules = () => {
    const { modules, modulesMap, loading, module, submodule, addModule, removeModule, updateModule, addSubmodule, updateSubmodule, addFilesToSubmodule, removeFileFromSubmodule, deleteSubmodule } = useContext(ModulesContext);

    const getModuleByID = (moduleId) => {
        if (!modulesMap || modulesMap.size <= 0) {
            console.error("Error trying to get module by ID: No modules!");
            return;
        }

        return modulesMap.get(moduleId);
    }

    return { modules, loading, getModuleByID, module, submodule, addModule, removeModule, updateModule, addSubmodule, updateSubmodule, addFilesToSubmodule, removeFileFromSubmodule, deleteSubmodule }; // Access all context values directly
};

export default useModules;