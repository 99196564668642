import React, { createContext, useState, useEffect } from 'react';
import { addDocument, deleteDocument, subscribeCollection, updateDocument } from '../firebase/firestore';
import useSchool from '../hooks/useSchool';
import { Timestamp } from 'firebase/firestore';
import useAuth from '../hooks/useAuth';
import useScenarios from '../hooks/useScenarios';
import { sortByDate } from '../utils/analytics';
import { useNotification } from '../hooks/useNotification';
import { NotificationTypes } from '../utils/notifications';
import useCourses from '../hooks/useCourses';
import Roles from '../utils/roles';

const AssignmentsContext = createContext();

/**
 * The context for all authentication related data such as the signed in user data, authentication loading or authentication error messages
 * @param {*} children The provider's children
 * @returns 
 */
const AssignmentsProvider = ({ children }) => {
    const { user, role } = useAuth();
    const { school } = useSchool();
    const { courses } = useCourses();
    const { scenarios, userScenariosMap, sharedScenariosMap } = useScenarios();
    const [assignments, setAssignments] = useState([]);
    const [assignmentsMap, setAssignmentsMap] = useState(new Map());
    const [loading, setLoading] = useState(true);
    const { showNotification } = useNotification();

    //The school context listens to changes to school ID and loads the school data for the newly set school ID on changes
    useEffect(() => {
        if (school && user) {
            const unsubscribe = subscribeCollection(`institutions/${school.id}/assignments`, (data) => {
                // setAssignments(sortByDate(data, "dueDate"));
                setLoading(false);
                if (data) {
                    console.log(courses);
                    console.log(data);
                    const filteredAssignments = data.filter(a => (courses.some(c => c.id === a.courseId) && (a.admin === user.id || a.published)) || role === Roles.ADMIN || role === Roles.SCHOOLADMIN);
                    console.log("Refreshed assignments: ", filteredAssignments)
                    setAssignments(filteredAssignments);
                    setAssignmentsMap(new Map(filteredAssignments.map((item) => [item.id, item])));
                }
                else {
                    setAssignmentsMap(new Map());
                    setAssignments([]);
                }
            }, { orderBy: [["dueDate", "asc"]] });

            return () => unsubscribe(); // Cleanup on unmount
        }
        else {
            console.log("Cleared assignments");
            setAssignments([]);
            setLoading(true);
        }
    }, [school, courses, user])

    const publishAssignment = async (assignmentId, publish = true) => {
        try {
            setLoading(true);
            await updateDocument(assignmentId, `institutions/${school.id}/assignments`, { published: publish });
            showNotification(NotificationTypes.SUCCESS, `Successfully ${publish ? "published" : "unpublished"} assignment.`);
        }
        catch (error) {
            showNotification(NotificationTypes.DANGER, `An error occured.`);
            console.error(error);
        }
    }

    const createAssignment = async (name, dueDate, courseId, scenarioId) => {
        try {
            if (scenarios.length <= 0) {
                throw new Error("No available scenario to create assignment with.");
            }
            if (scenarioId && !school.availableScenarios.includes(scenarioId) && !userScenariosMap.has(scenarioId) && !sharedScenariosMap.has(scenarioId)) {
                throw new Error("This scenario is not available for your school.");
            }
            setLoading(true);
            const id = await addDocument({ name: name, dueDate: dueDate, courseId: courseId, createdAt: new Date(), admin: user.id, published: false, scenarioId: scenarioId ? scenarioId : scenarios[0].id }, `institutions/${school.id}/assignments`);
            showNotification(NotificationTypes.SUCCESS, `Successfully created assignment: ${name}`);
            return id;
        }
        catch (error) {
            showNotification(NotificationTypes.DANGER, `An error occured: ${error.message}`);
            console.error(error);
        }
    }

    const deleteAssignment = async (assignmentId) => {
        setLoading(true);
        try {
            await deleteDocument(assignmentId, `institutions/${school.id}/assignments`);
            showNotification(NotificationTypes.SUCCESS, "Successfully deleted assignment.");
        }
        catch (error) {
            showNotification(NotificationTypes.DANGER, "An error occured.");
        }
    }

    return (
        <AssignmentsContext.Provider value={{ assignments, assignmentsMap, loading, publishAssignment, deleteAssignment, createAssignment }}>
            {children}
        </AssignmentsContext.Provider>
    );
}

export { AssignmentsProvider, AssignmentsContext };