import React, { useState } from 'react'
import DataTable from '../components/shared/Datatable';
import useUsers from '../hooks/useUsers';
import { DateTimeFormat } from 'intl';
import { HiOutlineAcademicCap, HiUsers } from 'react-icons/hi';
import useSchool from '../hooks/useSchool';
import { render } from '@testing-library/react';
import Searchbar from '../components/shared/Searchbar';
import useGrades from '../hooks/useGrades';
import { usePopUp } from '../hooks/usePopUp';
import GradingDetails from '../components/grades/GradingDetails';
import UserRow from '../components/shared/UserRow';
import useScenarios from '../hooks/useScenarios';
import useAuth from '../hooks/useAuth';



const Grades = () => {
    const { grades, loading, resultBadgeStyle, resultText, handleGradeClick } = useGrades();
    const { getScenarioByID } = useScenarios();
    //Filtered users by searchbar, this is allowed in the page since this is only required as gateway between the search bar and the datatable
    const [filteredGrades, setFilteredGrades] = useState(grades);
    const { getUserByID } = useUsers();
    const { showPopUp } = usePopUp();
    const { isAdmin } = useAuth();

    const columns = [
        {
            key: "name",
            label: "NAME",
            render: (_, grade) => {
                //Get user by grade.userId
                const user = getUserByID(grade.userId);
                return (
                    <UserRow user={user} />);
            }
        },
        {
            key: "assignmentName",
            label: "ASSIGNMENT TITLE",
        },
        {
            key: "courseName",
            label: "COURSE",
        },
        {
            key: "scenarioName",
            label: "SCENARIO",
        },
        {
            key: "createdAt",
            label: "DATE SUBMITTED",
            render: (submissionDate, grade) => {
                return <div>{submissionDate ? submissionDate.toDate().toLocaleString() : "N/A"}</div>
            }
        },
        {
            key: "result",
            label: "RESULT",
            render: (_, grade) => {
                return (
                    <td className="px-6 py-4">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${resultBadgeStyle(grade.result)}`}>
                            {resultText(grade.result)}
                        </span>
                    </td>);
            }
        },
        {
            key: "status",
            label: "STATUS",
            render: (_, grade) => {
                // const formattedDate = date.toDate().toISOString().split('T')[0];
                return (<td className="px-6 py-4">
                    <span
                        className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${grade.submissionDate
                            ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                            : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300'
                            }`}
                    >
                        {grade.submissionDate
                            ? 'Completed'
                            : 'Incomplete'}
                    </span>
                </td>);
            }
        },
        isAdmin ? {
            key: "id",
            label: "ID",
            render: (id, grade) => {
                return <p className='text-xs'>{id}</p>
            }
        } : {}
    ];

    return (
        <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen w-full h-full">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 h-full flex flex-col">
                <div className="flex items-center space-x-2 p-8">
                    <HiOutlineAcademicCap className="text-4xl text-gray-700 dark:text-gray-300" />
                    <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                        Grades
                    </h1>
                </div>

                {/* Searchbar */}
                <Searchbar
                    data={grades} // Pass the original users array
                    searchLabel={"name"}
                    onFilter={setFilteredGrades} // Update the filtered data
                    customFilter={(grade, value) => {
                        const user = getUserByID(grade.userId);
                        if (user) {
                            const name = `${user.firstName} ${user.lastName}`.toLowerCase();
                            return name.includes(value);
                        }
                        return "deleted user".includes(value);
                    }}
                />

                {/* Data table */}
                <div className="flex-grow">
                    <DataTable
                        rows={filteredGrades}
                        columns={columns}
                        isLoading={loading}
                        onViewClick={(e) => {
                            handleGradeClick(e);
                        }} />
                </div>
            </div>
        </div>
    )
}

export default Grades;