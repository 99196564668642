import { createContext, useEffect, useState } from "react";
import useScenarios from "../hooks/useScenarios";
import { useParams, useSearchParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Roles from "../utils/roles";
import { addDocument, updateDocument } from "../firebase/firestore";
import { useNotification } from "../hooks/useNotification";
import { NotificationTypes } from "../utils/notifications";
import useSchool from "../hooks/useSchool";
import { serverTimestamp } from "firebase/firestore";
import _ from "lodash";
import { uploadFile } from "../firebase/storage";

const defaultPatient = {
    equipment: {}, metahuman: 0, brainName: "workspaces/courseta/characters/amelia_donald", character: "", conditions: [], chartSummary: {}, emrNotes: {}, rubrics: [], lda: {}, mar: [], orders: {}, patho: {}, presentation: { spo2: 100, pulse: 72, systolicPressure: 120, diastolicPressure: 80, bloodGlucose: 70, respiratoryRate: 12, temperature: 98.6 },
    // results: {
    //     'CBC (Complete Blood Count)': {
    //         WBC: { result: 5.0, reference: '4.0 - 11.0', status: 'Final', lab: 'General Lab' },
    //         RBC: { result: 4.7, reference: '4.2 - 5.9', status: 'Final', lab: 'General Lab' },
    //         Hemoglobin: { result: 14.0, reference: '13.0 - 17.0', status: 'Final', lab: 'General Lab' },
    //         Hematocrit: { result: '42%', reference: '38% - 50%', status: 'Final', lab: 'General Lab' },
    //     },
    //     'CMP (Comprehensive Metabolic Panel)': {
    //         Glucose: { result: 90, reference: '70 - 99', status: 'Final', lab: 'General Lab' },
    //         Calcium: { result: 9.5, reference: '8.5 - 10.5', status: 'Final', lab: 'General Lab' },
    //     },
    //     'Lipid Panel': {
    //         'Total Cholesterol': { result: 180, reference: '<200', status: 'Final', lab: 'General Lab' },
    //         HDL: { result: 50, reference: '>40', status: 'Final', lab: 'General Lab' },
    //     },
    // }
    results: {}
};

const ScenarioCustomizationContext = createContext();

const ScenarioCustomizationProvider = ({ children }) => {
    const [scenario, setScenario] = useState(null);
    const [patient, setPatient] = useState(null);
    const { scenarios, getScenarioByID } = useScenarios();
    const [loading, setLoading] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [saving, setSaving] = useState(false);
    const [files, setFiles] = useState({});

    const { scenarioId } = useParams();

    const { school } = useSchool();
    const { user, role } = useAuth();

    const [searchParams] = useSearchParams();

    const { showNotification } = useNotification();

    useEffect(() => {
        // if (scenarioId) {
        //     setScenario(getScenarioByID(scenarioId));
        // }
        // else {
        //     setScenario(null);
        // }
        console.log(scenarioId);
        if (scenarioId) {
            const s = getScenarioByID(scenarioId);
            if (s.patients && s.patients[0]) {
                setScenario(s);
                setPatient(s.patients[0]);
            }
            else {
                setScenario({ ...s, patients: [defaultPatient] });
                setPatient(defaultPatient);
            }
        }
    }, [])

    const setField = (fieldName, fieldValue) => {
        const updatedScenario = ({ ...scenario, [fieldName]: fieldValue })
        setScenario(updatedScenario);
        const oldScenario = getScenarioByID(scenarioId);
        setIsDirty(
            !_.isEqual(oldScenario, updatedScenario)
        );
    };

    const setPatientField = (fieldName, fieldValue) => {
        const updatedPatient = ({ ...patient, [fieldName]: fieldValue })
        setPatient(updatedPatient);
        setField("patients", [updatedPatient]);
    };

    const getFile = (fieldName) => {
        const fromCourseta = searchParams.get("courseta") != null && role === Roles.ADMIN;
        return (fromCourseta ? `scenarios/${scenario.id}/${scenario[fieldName]}` : `institutions/${school.id}/scenarios/${scenario.id}/${scenario[fieldName]}`);
    }

    const setFile = async (file, fieldName) => {
        try {
            const fromCourseta = searchParams.get("courseta") != null && role === Roles.ADMIN;
            const path = fromCourseta ? `scenarios/${scenario.id}` : `institutions/${school.id}/scenarios/${scenario.id}`;
            await uploadFile(file, path);
            setField(fieldName, file.name);
        }
        catch (error) {
            console.error("Error setting file: ", error);
            showNotification(NotificationTypes.DANGER, `Error uploading file: ${error.code}`);
        }
    }

    //Save the scenario to firebase
    const save = async () => {
        if (!scenario) {
            throw new Error("Tried to save scenario without valid scenario!");
        }

        const fromCourseta = searchParams.get("courseta") != null && role === Roles.ADMIN;

        try {
            setLoading(true);
            setSaving(true);
            await updateDocument(scenario.id, fromCourseta ? `simulations` : `institutions/${school.id}/scenarios/`, { ...scenario });
            setIsDirty(false);
        }
        catch (error) {
            console.error("Error saving scenario: ", error);
            throw error;
        }
        finally {
            setSaving(false);
        }
    }

    //Create a new scenario
    // const createScenario = async (name, fromCourseta = false) => {
    //     try {
    //         setLoading(true);
    //         await addDocument({ name: name, creationDate: serverTimestamp(), admin: user.id }, fromCourseta && role === Roles.ADMIN ? `simulations` : `institutions/${school.id}/scenarios/`);
    //         showNotification(NotificationTypes.SUCCESS, `Scenario: ${name} created successfully.`);
    //     }
    //     catch (error) {
    //         console.error("Error saving scenario: ", error);
    //         showNotification(NotificationTypes.DANGER, "An error occurred.");
    //     }
    // }

    return (
        <ScenarioCustomizationContext.Provider
            value={{
                scenario,
                save,
                // createScenario,
                setField,
                isDirty,
                saving,
                setFile,
                getFile,
                setPatientField,
                patient
            }}
        >
            {children}
        </ScenarioCustomizationContext.Provider>
    );
}


export { ScenarioCustomizationContext, ScenarioCustomizationProvider };