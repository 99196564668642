import React from 'react';
import useSchool from '../../hooks/useSchool';
import { NotificationTypes } from '../../utils/notifications';
import { useNotification } from '../../hooks/useNotification';

const SaveButton = ({ hasUnsavedChanges, saveChanges, saving }) => {

    const { showNotification } = useNotification();

    const handleSave = async (e) => {
        e.preventDefault();
        try {
            await saveChanges();
            showNotification(NotificationTypes.SUCCESS, "Changes saved sucessfully.");
        }
        catch (error) {
            showNotification(NotificationTypes.DANGER, `An error occured: ${error.code}`);
        }
    }

    return (
        <div>
            {/* Unsaved changes indicator */}
            {hasUnsavedChanges && !saving && (
                <span className="text-red-700 font-small p-4">Unsaved changes</span>
            )}

            {/* Save button */}
            <button
                onClick={handleSave}
                disabled={!hasUnsavedChanges || saving}
                className={`px-4 py-2 rounded-lg text-white ${(hasUnsavedChanges && !saving)
                    ? "bg-blue-500 hover:bg-blue-600"
                    : "bg-gray-300 cursor-not-allowed"
                    }`}
            >
                <p className='px-2 py-1'>{saving ? "Saving" : "Save"}</p>
            </button>
        </div>
    );
};

export default SaveButton;
