import React from "react";
import { useNavigate } from "react-router-dom";
import { HiArrowLeft } from "react-icons/hi";
import GenericButton from "./GenericButton";
import { usePopUp } from "../../hooks/usePopUp";
import ConfirmPopup from "./ConfirmPopup";
import { HiExclamationTriangle } from "react-icons/hi2";

const ReturnButton = ({ label = "Return", to = null, className = "", customLabel = null, isDirty = false }) => {
    const navigate = useNavigate();
    const { showPopUp } = usePopUp();

    const onConfirm = () => {
        if (to) {
            navigate(to); // Navigate to the specified route
        } else {
            navigate(-1); // Navigate to the previous page
        }
    }

    const handleClick = () => {
        if (!isDirty) {
            if (to) {
                navigate(to); // Navigate to the specified route
            } else {
                navigate(-1); // Navigate to the previous page
            }
        }
        else {
            showPopUp(< ConfirmPopup icon={<HiExclamationTriangle />} color={"red"} label={"Exit"} description={"Are you sure you want to exit? Your unsaved changes will be lost."} onConfirm={onConfirm} />);
        }
    };

    return (
        <button
            onClick={handleClick}
            className={`flex items-center space-x-2 px-4 py-2 rounded-md text-white bg-blue-600 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600 transition duration-150 ease-in-out shadow ${className}`}
        >
            <div className="px-2 py-1 flex items-center">
                <HiArrowLeft className="h-5 w-5" />
                <span className="ml-4">{customLabel ? customLabel : label}</span>
            </div>
        </button>
    );
};

export default ReturnButton;
