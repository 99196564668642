import { useContext, useEffect, useState } from "react";
import { ScenariosContext } from "../contexts/ScenariosContext";
import useAuth from "./useAuth";

/**
 * Hook for all scenarios functionality
 * @returns All usable variables and functions from this hook
 */
const useScenarios = () => {
    const context = useContext(ScenariosContext);
    const { isAdmin } = useAuth();

    const getScenarioByID = (scenarioId) => {
        //Get available courseta scenario
        if (context.scenariosMap.has(scenarioId)) {
            return context.scenariosMap.get(scenarioId);
        }

        //Try and get courseta scenario as admin
        if (isAdmin && context.allScenarios.find(s => s.id === scenarioId)) {
            return context.allScenarios.find(s => s.id === scenarioId);
        }

        //Try and get user scenario
        if (context.userScenariosMap.has(scenarioId)) {
            return context.userScenariosMap.get(scenarioId);
        }

        //Try and get shared scenario
        if (context.sharedScenariosMap.has(scenarioId)) {
            return context.sharedScenariosMap.get(scenarioId);
        }

        return null;
    }

    const isCoursetaScenario = (scenarioId) => {
        return context.scenariosMap.has(scenarioId);
    }

    return { ...context, getScenarioByID, isCoursetaScenario }; // Access all context values directly
};

export default useScenarios;