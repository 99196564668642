import React, { createContext, useState, useEffect } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase/firebase';
import _ from "lodash";
import { useParams, useSearchParams } from 'react-router-dom';
import { addDocument, deleteDocument, subscribeCollection, updateDocument } from '../firebase/firestore';
import useSchool from '../hooks/useSchool';
import useAuth from '../hooks/useAuth';
import Roles from '../utils/roles';
import { serverTimestamp } from 'firebase/firestore';
import { useNotification } from '../hooks/useNotification';
import { NotificationTypes } from '../utils/notifications';
import { LegendToggleSharp } from '@mui/icons-material';
import { uploadFile } from '../firebase/storage';

const InworldContext = createContext();

/**
 * The InworldProvider manages the character-related state and provides functions for fetching character data.
 * @param {*} children The provider's children
 * @returns {JSX.Element}
 */
const InworldProvider = ({ children }) => {
    const { school } = useSchool();
    const { user, role, isAdmin } = useAuth();

    //Available characters
    const [characters, setCharacters] = useState([]);
    const [charactersMap, setCharactersMap] = useState(new Map());

    //Shared characters
    const [sharedCharacters, setSharedCharacters] = useState([]);
    const [sharedCharactersMap, setSharedCharactersMap] = useState(new Map());

    //User characters
    const [userMadeCharacters, setUserMadeCharacters] = useState([]);
    const [userCharactersMap, setUserCharactersMap] = useState(new Map());

    //The inworld character objects pulled from inworld
    const [inworldCharacters, setInworldCharacters] = useState([]);

    //All characters, for admins to give schools access to characters
    const [allCharacters, setAllCharacters] = useState([]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [character, setCharacter] = useState(null);
    const [previousCharacter, setPreviousCharacter] = useState(null);
    const [knowledge, setKnowledge] = useState([]);
    const [previousKnowledge, setPreviousKnowledge] = useState([]);
    const [isDirty, setIsDirty] = useState(false);
    const [saving, setSaving] = useState(false);

    const [searchParams] = useSearchParams();

    const [characterId, setCharacterId] = useState(null);

    const { showNotification } = useNotification();

    //The school context listens to changes to school ID and loads the school data for the newly set school ID on changes
    useEffect(() => {
        if (school && user) {
            // Function to fetch all characters from inworld API
            const fetchCharacters = async () => {
                console.log("Fetching all characters...");
                //Update to fetch firebase
                const getCharacters = httpsCallable(functions, 'getCharacters');
                try {
                    setLoading(true);
                    const result = await getCharacters();
                    setInworldCharacters(result.data.data || []);
                    for (const char of result.data.data) {
                        // await addDocument({ name: char.defaultCharacterDescription.givenName, description: char.defaultCharacterDescription.description, thumbnail: char.defaultCharacterAssets.avatarImg, createdAt: serverTimestamp(), brainName: char.name }, `characters`);
                    }
                    console.log("Fetched Characters:", result.data.data);
                } catch (err) {
                    setError(err.message);
                    console.error("Error fetching characters:", err);
                } finally {
                    setLoading(false);
                }
            };

            fetchCharacters();

            const unsubscribeCoursetaCharacters = subscribeCollection(`characters`, (data) => {
                if (data) {
                    console.log("Refreshed courseta characters.");
                    if (role === Roles.ADMIN) {
                        setAllCharacters(data);
                    }
                    // if (school.availableCharacters && school.availableCharacters.length > 0) {
                    // const available = data.filter((s) => school.availableCharacters.includes(s.id));
                    setCharacters(data);
                    setCharactersMap(new Map(data.map((item) => [item.id, item])));
                    // }
                }
                else {
                    //No characters
                    setCharacters([]);
                    setCharactersMap(new Map());
                    setAllCharacters([]);
                }
            });

            const unsubscribeUserCharacters = subscribeCollection(`institutions/${school.id}/characters`, (data) => {
                // console.log("Refreshed characters: ", data)
                // setLoading(false);
                if (data) {
                    console.log("Refreshed user characters.");
                    //Only set characters that the user himself created unless I'm an admin account
                    const filteredCharacters = data.filter(s => s.admin === user.id || role === Roles.ADMIN);
                    setUserMadeCharacters(filteredCharacters);
                    setUserCharactersMap(new Map(filteredCharacters.map((item) => [item.id, item])));

                    //Get all shared characters
                    const shared = data.filter(s => s.admin !== user.id && s.shared?.includes(user.id));
                    setSharedCharacters(shared);
                    setSharedCharactersMap(new Map(shared.map((item) => [item.id, item])));
                }
                else {
                    setUserMadeCharacters();
                    setUserCharactersMap(new Map());
                    setSharedCharacters([]);
                    setSharedCharactersMap(new Map());
                }
            });

            return () => {
                unsubscribeCoursetaCharacters();
                unsubscribeUserCharacters();
            }
        }
        else {
            console.log("Cleared characters");
            setCharacters([]);
            setCharactersMap(new Map());
            setUserMadeCharacters();
            setUserCharactersMap(new Map());
            // setLoading(true);
        }
    }, [school, user])

    //Loads a single character
    useEffect(() => {
        // Function to fetch a specific character's details
        const fetchCharacterDetails = async (id) => {
            if (!id) {
                console.warn("Character ID is missing. Cannot fetch character details.");
                return;
            }

            console.log(`Fetching details for character ID: ${id}`);
            const getCharacterDetails = httpsCallable(functions, 'getCharacterDetails');
            try {
                const result = await getCharacterDetails({ characterId: id });
                setCharacter(result.data.data);
                setPreviousCharacter(result.data.data);
                console.log("Fetched Character Details:", result.data.data);

                // Fetch knowledge details if available
                const knowledgeArray = result.data.data.commonKnowledge;
                if (knowledgeArray && knowledgeArray.length > 0) {
                    const knowledgeId = knowledgeArray[0].split('/').pop();
                    console.log(`Fetching knowledge details for knowledge ID: ${knowledgeId}`);
                    const getKnowledgeDetails = httpsCallable(functions, 'getKnowledgeDetails');
                    const knowledg = await getKnowledgeDetails({ knowledgeId });

                    setKnowledge(knowledg.data.data.knowledgeRecords);
                    setPreviousKnowledge(knowledg.data.data.knowledgeRecords);
                    console.log("Fetched Knowledge Records:", knowledg.data.data.knowledgeRecords);
                } else {
                    console.log("No knowledge details available for this character.");
                    setKnowledge([]);
                    setPreviousKnowledge([]);
                }
            } catch (error) {
                console.error("Error fetching character details:", error);
            }
        };

        if (characterId) {
            fetchCharacterDetails(characterId);
        }
        else {
            setCharacter(null);
            setPreviousCharacter(null);
            setKnowledge([]);
            setPreviousKnowledge([]);
        }
    }, [characterId])

    const setCharacterField = (fieldName, fieldValue) => {
        const updatedCharacter = { ...character, [fieldName]: fieldValue };
        setCharacter(updatedCharacter);
        setIsDirty(!_.isEqual(updatedCharacter, previousCharacter));
    }

    const setKnowledgeField = (index, value) => {
        const updatedArray = [...knowledge];
        updatedArray[index] = { ...knowledge[index], text: value };
        setKnowledge(updatedArray);
        setIsDirty(!_.isEqual(updatedArray, previousKnowledge));
    }

    // Add a new empty knowledge entry
    const addKnowledgeField = () => {
        const updatedArray = [...knowledge, { text: "" }];
        setKnowledge(updatedArray);
        setIsDirty(!_.isEqual(updatedArray, previousKnowledge));
        console.log("Test");
    };

    // Remove a specific knowledge entry
    const removeKnowledgeField = (index) => {
        const updatedArray = knowledge.filter((k, i) => i !== index);
        setKnowledge(updatedArray);
        setIsDirty(!_.isEqual(updatedArray, previousKnowledge));
    };

    const save = async () => {
        //Save character and knowledge to firebase and inworld
        // Deploy both the character and knowledge 
        setSaving(true);
        try {
            const deployCharacter = httpsCallable(functions, 'deployCharacter')
            const updateCharacter = httpsCallable(functions, 'updateCharacter');

            console.log("Updating inworld character....");

            await updateCharacter({ characterId: characterId, ...character, updatedKnowledge: [...knowledge] });
            //Gives error for now
            await deployCharacter({ characterId: characterId, knowledgeId: character.commonKnowledge[0].split("/").pop() });

            console.log("Inworld character updated successfully.");

            const characterDoc = characters.find(c => c.brainName.split("/").pop() === characterId);
            const userCharacterDoc = userMadeCharacters.find(c => c.brainName.split("/").pop() === characterId);
            const data = { name: character.defaultCharacterDescription.givenName, description: character.defaultCharacterDescription.description, thumbnail: character.defaultCharacterAssets.avatarImg }
            if (characterDoc) {
                await updateDocument(characterDoc.id, `characters`, data);
            }
            else if (userCharacterDoc) {
                await updateDocument(userCharacterDoc.id, `institutions/${school.id}/characters`, data);
            }
            else {
                throw new Error("Couldn't find character document to save.");
            }

            // Use addOrUpdateDocument function to add or update the document in Firestore
            // const result = await updateDocument( , 'characters');
            // if (!result.updated) {
            //     showNotification('danger', 'Character update failed!');
            // }
            // alert('Character updated successfully!');

        } catch (err) {
            console.error('Error updating character:', err);
            //Throw error for save button to handle
            throw err;
        }
        finally {
            setPreviousCharacter(character);
            setPreviousKnowledge(knowledge);
            setIsDirty(false);
            setSaving(false);
        }
    }

    const createCharacter = async (name, description, motivation, fromCourseta, character = null) => {
        try {
            setLoading(true);

            const deployCharacter = httpsCallable(functions, 'deployCharacter');
            const createCharacter = httpsCallable(functions, 'createCharacter');

            const response = await createCharacter({ name, description, motivation });
            await deployCharacter({ characterId: response.data.data.name.split("/").pop(), knowledgeId: response.data.data.commonKnowledge[0].split("/").pop() });

            const brainName = response.data.data.name;

            const path = fromCourseta ? `characters` : `institutions/${school.id}/characters`
            const characterDoc = fromCourseta ? { name: name, description: description, thumbnail: "", brainName: brainName, createdAt: serverTimestamp() } : { name: name, description: description, thumbnail: "", brainName: brainName, admin: user.id, createdAt: serverTimestamp() };
            console.log(characterDoc);
            await addDocument(characterDoc, path)
            showNotification(NotificationTypes.SUCCESS, `Character: ${name} created successfully.`);
        }
        catch (error) {
            console.error("Error creating character: ", error.details);
            showNotification(NotificationTypes.DANGER, `An error occurred: ${error.message}`);
            setLoading(false);
        }
    }

    const updateCharacter = async (characterId, thumbnail) => {
        try {
            setLoading(true);
            const fromCourseta = searchParams.get("courseta") !== null && isAdmin;
            const path = fromCourseta ? `characters` : `institutions/${school.id}/characters`
            if (thumbnail) {
                await uploadFile(thumbnail, `${path}/${characterId}`);
            }
            await updateDocument(characterId, path, { thumbnail: thumbnail.name });
            showNotification(NotificationTypes.SUCCESS, "Thumbnail uploaded succesfully.");
        }
        catch (error) {
            console.error("Error updating character: ", error);
            showNotification(NotificationTypes.DANGER, "An error occured.");
            setLoading(false);
        }
    }

    const deleteCharacter = async (id, fromCourseta) => {
        try {
            setLoading(true);
            const path = fromCourseta ? `characters` : `institutions/${school.id}/characters`
            //TODO: Delete character from inworld too
            await deleteDocument(id, path);
            showNotification(NotificationTypes.SUCCESS, `Character deleted successfully.`);
        }
        catch (error) {
            console.error("Error deleting character: ", error);
            showNotification(NotificationTypes.DANGER, `An error occurred: ${error.code}`);
            setLoading(false);
        }
    }

    const loadCharacter = (id) => {
        if (id && !characterId) {
            setCharacterId(id);
        }
        else if (!id && characterId) {
            setCharacterId(null);
        }
    }

    return (
        <InworldContext.Provider
            value={{
                characters,
                inworldCharacters,
                allCharacters,
                charactersMap,
                sharedCharacters,
                sharedCharactersMap,
                userCharactersMap,
                userMadeCharacters,
                loading,
                createCharacter,
                deleteCharacter,
                loadCharacter,
                updateCharacter,
                error, character, knowledge, setKnowledgeField, addKnowledgeField, removeKnowledgeField, isDirty, saving, save, setCharacterField
            }}
        >
            {children}
        </InworldContext.Provider>
    );
};

export { InworldProvider, InworldContext };
