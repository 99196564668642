import React, { useState } from 'react';
import { useInworld } from "../../../hooks/useInworld";
import SideSteps from "../../shared/SideSteps";
import CustomDialogueStyle from './CustomDialoqueStyle';
import { VOICE_OPTIONS } from '../../../utils/inworld';
import Personality from './Personality';

const steps = ['Info', 'Voice', 'Personality', 'Mood'];

const GeneralInfo = () => {
    const [step, setStep] = useState(0);
    const { character, setCharacterField } = useInworld();

    if (!character) return <div>Loading...</div>;

    // Update fields in defaultCharacterDescription
    const setCharacterDescriptionField = (fieldName, value) => {
        const updatedCharacterDescription = { 
            ...character.defaultCharacterDescription, 
            [fieldName]: value 
        };
        setCharacterField("defaultCharacterDescription", updatedCharacterDescription);
    };

    // Update fields in defaultCharacterAssets.voice
    const setCharacterVoiceField = (fieldName, value) => {
        const updatedCharacterAssets = { 
            ...character.defaultCharacterAssets, 
            voice: { 
                ...character.defaultCharacterAssets.voice, 
                [fieldName]: value 
            } 
        };
        setCharacterField("defaultCharacterAssets", updatedCharacterAssets);
    };

    return (
        <div className="flex space-x-8 min-h-screen">
            <div className="w-1/5">
                <SideSteps currentStep={step} steps={steps} setCurrentStep={setStep} />
            </div>

            {/* Step 0: Character Info */}
            {step === 0 && (
                <div className="w-full row">
                    <div className="w-full">
                        <label className="font-bold mb-2 block" htmlFor="characterDescription">Description:</label>
                        <textarea
                            id="characterDescription"
                            placeholder="Provide a description of the character"
                            value={character.defaultCharacterDescription?.description || ""}
                            onChange={(e) => setCharacterDescriptionField('description', e.target.value)}
                            className="w-full h-64 p-3 mt-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white"
                        />
                    </div>
                    <div className="w-full">
                        <label className="font-bold mb-2 block" htmlFor="characterMotivation">Motivation:</label>
                        <textarea
                            id="characterMotivation"
                            placeholder="What motivates this character?"
                            value={character.defaultCharacterDescription?.motivation || ""}
                            onChange={(e) => setCharacterDescriptionField('motivation', e.target.value)}
                            className="w-full h-64 p-3 mt-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white"
                        />
                    </div>
                    <div className="w-full">
                        <label className="font-bold mb-2 block" htmlFor="characterFlaws">Flaws:</label>
                        <textarea
                            id="characterFlaws"
                            placeholder="What are the character's flaws?"
                            value={character.defaultCharacterDescription?.flaws || ""}
                            onChange={(e) => setCharacterDescriptionField('flaws', e.target.value)}
                            className="w-full h-64 p-3 mt-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white"
                        />
                    </div>
                    {/* <div className="w-full">
                        <label className="font-bold mb-2 block" htmlFor="characterHobbies">Hobbies or Interests:</label>
                        <textarea
                            id="characterHobbies"
                            placeholder="What are the character's hobbies or interests?"
                            value={character.defaultCharacterDescription?.hobbyOrInterests || ""}
                            onChange={(e) => setCharacterDescriptionField('hobbyOrInterests', e.target.value)}
                            className="w-full h-64 p-3 mt-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white"
                        />
                    </div> */}
                </div>
            )}

            {/* Step 1: Voice Settings */}
            {step === 1 && (
                <div className="w-full">
                    <div className="mt-6">
                        <h3 className="text-xl font-bold text-gray-900 dark:text-white">Choose Voice</h3>
                        <div className="grid grid-cols-2 gap-4 mt-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Voice</label>
                                <select
                                    name="voice"
                                    value={character.defaultCharacterAssets.voice.baseName || ""}
                                    onChange={(e) => setCharacterVoiceField('baseName', e.target.value)}
                                    className="w-full p-3 mt-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white"
                                >
                                    {VOICE_OPTIONS.map((option) => (
                                        <option key={option.baseName} value={option.baseName}>
                                            {option.baseName} - {option.description}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 pb-40">
                        <h3 className="text-xl font-bold text-gray-900 dark:text-white">Customize Voice</h3>
                        <div className="flex space-x-8 items-center mt-4">
                            <div className="w-1/2">
                                <div className="flex justify-between">
                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Pitch</label>
                                    <span className="text-lg font-semibold text-gray-900 dark:text-white ml-2">
                                        {character.defaultCharacterAssets.voice.pitch}
                                    </span>
                                </div>
                                <div className="flex items-center mt-2">
                                    <span className="text-gray-500 dark:text-gray-400">-5</span>
                                    <input
                                        type="range"
                                        name="pitch"
                                        min="-5"
                                        max="5"
                                        value={character.defaultCharacterAssets.voice.pitch || 0}
                                        onChange={(e) => setCharacterVoiceField('pitch', parseInt(e.target.value))}
                                        className="ml-4 mr-4 w-full"
                                    />
                                    <span className="ml-2 text-gray-500 dark:text-gray-400">5</span>
                                </div>
                            </div>

                            <div className="w-1/2">
                                <div className="flex justify-between">
                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Talking Speed</label>
                                    <span className="text-lg font-semibold text-gray-900 dark:text-white ml-2">
                                        {character.defaultCharacterAssets.voice.speakingRate}
                                    </span>
                                </div>
                                <div className="flex items-center mt-2">
                                    <span className="text-gray-500 dark:text-gray-400">0.5</span>
                                    <input
                                        type="range"
                                        name="speakingRate"
                                        min="0.5"
                                        max="1.5"
                                        step="0.1"
                                        value={character.defaultCharacterAssets.voice.speakingRate || 1.0}
                                        onChange={(e) => setCharacterVoiceField('speakingRate', parseFloat(e.target.value))}
                                        className="ml-4 mr-4 w-full"
                                    />
                                    <span className="ml-2 text-gray-500 dark:text-gray-400">1.5</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Step 2: Personality */}
            {step === 2 && (
                <div className="flex flex-col flex-grow p-8 rounded-lg border">
                    <CustomDialogueStyle />
                </div>
            )}

            {/* Step 3: Mood */}
            {step === 3 && (
                <div className="flex flex-col flex-grow p-8 rounded-lg border">
                    <Personality />
                </div>
            )}
        </div>
    );
};

export default GeneralInfo;
