import React, { createContext, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import Roles from '../utils/roles';
import { addDocument, deleteDocument, getDocument, getDocuments, subscribeCollection, subscribeDocument, updateDocument } from '../firebase/firestore';
import { useLocation, useNavigate } from "react-router-dom";
import { convertDocumentsToMap } from '../utils/utils';
import { useNotification } from '../hooks/useNotification';
import { serverTimestamp } from 'firebase/firestore';
import { NotificationTypes } from '../utils/notifications';

const SchoolContext = createContext();

/**
 * The context for all authentication related data such as the signed in user data, authentication loading or authentication error messages
 * @param {*} children The provider's children
 * @returns 
 */
const SchoolProvider = ({ children }) => {
    const { schoolId, role } = useAuth();
    const [school, setSchool] = useState(null);
    const [schools, setSchools] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    const { showNotification } = useNotification();

    let unsubscribe = null;

    //The school context listens to changes to school ID and loads the school data for the newly set school ID on changes
    useEffect(() => {
        if (unsubscribe) {
            unsubscribe();
            unsubscribe = null;
        }

        unsubscribe = subscribeDocument(`institutions/${schoolId}`, (data) => {
            if (schoolId && data) {
                console.log("Refreshed school data: ", data)
                setSchool(data);
                setLoading(false);
            }
            else {
                console.log("Cleared school data.");
                setSchool(null);
                setLoading(true);

                if (role == Roles.ADMIN) {
                    navigate("/school-selection");
                }
            }
        });

        let unsubscribeSchools = null;
        if (role == Roles.ADMIN) {
            unsubscribeSchools = subscribeCollection(`institutions`, (data) => {
                setLoading(false);
                if (data) {
                    setSchools(data);
                }
                else {
                    setSchools([]);
                }
            });
        }

        return () => {
            unsubscribe(); // Cleanup on unmount

            if (unsubscribeSchools) {
                unsubscribeSchools();
            }
        }
    }, [schoolId])

    const saveSchool = async (newSchool) => {
        if (!school) {
            throw new Error("Tried to save school with no valid school!");
        }

        setLoading(true);
        await updateDocument(school.id, "institutions", { ...school, ...newSchool });
    }

    const createSchool = async (name) => {
        try {
            setLoading(true);
            await addDocument({ name: name, capacity: 1, availableScenarios: [], createdAt: serverTimestamp(), licenseExpirationDate: serverTimestamp() }, `institutions`);
            showNotification(NotificationTypes.SUCCESS, `School: ${name} created successfully.`);
        }
        catch (error) {
            console.error("Error creating school: ", error);
            showNotification(NotificationTypes.DANGER, `An error occurred: ${error.code}`);
        }
    }

    const deleteSchool = async (id) => {
        try {
            setLoading(true);
            await deleteDocument(id, `institutions`);
            showNotification(NotificationTypes.SUCCESS, `School deleted successfully.`);
        }
        catch (error) {
            console.error("Error deleting school: ", error);
            showNotification(NotificationTypes.DANGER, `An error occurred: ${error.code}`);
        }
    }

    // Load school data whenever we have an authenticated user and role
    // useEffect(() => {
    //     //Only load data if we haven't loaded data yet
    //     if (user && !school) {
    //         if (role == Roles.ADMIN) {
    //             if (schools.length <= 0) {
    //                 loadAllSchoolsData();
    //             }
    //             //Handle admins and navigate them to the school selection if there is no valid school
    //             navigate(`/school-selection`);
    //         }
    //         else {
    //             initSchoolContext();
    //         }
    //     }
    // }, [role, user]);

    return (
        <SchoolContext.Provider value={{ school, schools, loading, saveSchool, createSchool, deleteSchool }}>
            {children}
        </SchoolContext.Provider>
    );
}

export { SchoolProvider, SchoolContext };