import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import useUsers from "../../hooks/useUsers";
import useGrades from "../../hooks/useGrades";
import { useNotification } from "../../hooks/useNotification";
import logo from '../../assets/images/Courseta_Logo.png';
import LoadingSpinner from "../shared/LoadingSpinner";
import { HiX, HiDownload, HiOutlinePrinter, HiChatAlt2, HiCheckCircle, HiXCircle, HiExclamationCircle } from 'react-icons/hi';
import LazyPDFDownloadButton from "./PdfDownload";
import ProfilePicture from '../shared/ProfilePicture';
import { usePopUp } from '../../hooks/usePopUp';
import useScenarios from '../../hooks/useScenarios';
import useAssignments from '../../hooks/useAssignments';
import useCourses from '../../hooks/useCourses';

const GradingDetails = ({ grade, isPopup = true }) => {
    const { getUserByID } = useUsers();
    const { loading } = useGrades();

    // I need this for the pdf renderen so it can create pages
    const contentRef = useRef();
    const { showPopUp } = usePopUp();

    const user = getUserByID(grade.userId);

    const onClose = () => {
        showPopUp(null);
    }

    const RenderGradeCards = ({ categoryData }) => {
        if (!categoryData) {
            return <div>Invalid data</div>
        }

        console.log(categoryData);

        return Object.entries(categoryData).map(([key, value], index) => {
            const [title, description] = key.split('/').map((text) => text.trim());
            const [achievedScore, maxScore] = value.split('/').map(Number);

            let status;
            if (achievedScore === maxScore) {
                status = 'success';
            } else if (achievedScore === 0) {
                status = 'error';
            } else {
                status = 'alert';
            }

            return (
                <div key={`${title}-${index}`} className="pt-4 px-4">
                    <GradeCard
                        title={title}
                        description={description}
                        score={achievedScore}
                        maxScore={maxScore}
                        status={status}
                    />
                </div>
            );
        }).filter(Boolean);
    };

    const renderQuestions = (questions, grade) => {
        if (!questions || questions.length <= 0) {
            return (<div>No questions available.</div>);
        }

        return (
            <div className="mb-8 mt-8 p-6 bg-white shadow-lg rounded-md">
                <table className="w-full border-collapse border border-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="py-3 px-6 text-left border border-gray-200">Question</th>
                            <th scope="col" className="py-3 px-6 text-right border border-gray-200">Selected Answer(s)</th>
                            <th scope="col" className="py-3 px-6 text-right border border-gray-200">Points Earned</th>
                        </tr>
                    </thead>
                    <tbody>
                        {questions?.map((question, index) => {
                            const answerIndicesString = grade?.answers && grade.answers[String(index)] !== undefined
                                ? grade.answers[String(index)]
                                : '';
                            const answerIndices = answerIndicesString.split(';').map(Number).filter(index => !isNaN(index));
                            const hasAnswered = answerIndices.length > 0;

                            const selectedChoices = hasAnswered
                                ? answerIndices.map(answerIndex => question.options[answerIndex]).filter(Boolean).join(' AND ')
                                : 'No answer given';

                            const correctanswersCount = answerIndices.reduce((count, answerIndex) => {
                                return count + (question.correctAnswers.includes(answerIndex) ? 1 : 0);
                            }, 0);

                            const isCorrect = correctanswersCount === answerIndices.length;
                            const pointsText = hasAnswered
                                ? `${correctanswersCount}/${answerIndices.length} Points Earned`
                                : '0/1 Points Earned';

                            return (
                                <tr key={index}>
                                    <td className="py-3 px-6 border border-gray-200">{question.title}</td>
                                    <td className={`py-3 px-6 text-right border border-gray-200 ${isCorrect ? 'text-green-500' : 'text-red-500'}`}>
                                        {selectedChoices}
                                    </td>
                                    <td className={`py-3 px-6 text-right border border-gray-200 ${isCorrect ? 'text-green-500' : 'text-red-500'}`}>
                                        {pointsText}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    const renderDebriefing2 = () => {
        //console.log(grade?.debriefing_2);
        const scenarioDebriefing = grade?.scenario?.debriefing_2;
        const gradeDebriefing = grade?.debriefing2;
        return scenarioDebriefing?.map((data, index) => (
            <ClosedQuestionCard2
                key={index}
                data={{
                    question: data.question,
                    answers: data.answers,
                    correctAnswer: data.correctAnswer,
                    userAnswer: gradeDebriefing ? gradeDebriefing[index] : "unanswered",
                    rationale: data.rationale,
                }}
            />
        ));
    };

    const renderOpenQuestions = (questions, answers) => {
        if (!questions || questions.length === 0) {
            return <div>No questions available.</div>;
        }

        return questions.map((question, index) => {
            const answer = answers && answers[index] !== "unanswered" ? answers[index] : "You did not answer this question.";

            return (
                <OpenQuestionCard
                    key={index}
                    question={question}
                    answer={answer}
                    date={grade?.creationDate}
                    firstName={user?.firstName}
                    lastName={user?.lastName}
                />
            );
        });
    };

    return (
        <div className={`${isPopup ? "fixed inset-0 z-50 bg-gray-900 bg-opacity-50 flex items-center justify-center p-8" : "flex justify-center"}`}>
            {/* <div className="fixed inset-0 z-50 bg-gray-900 bg-opacity-50 flex items-center justify-center p-8"> */}
            {loading ? (
                <div className="bg-gray-800 rounded-lg shadow-lg w-full max-w-6xl p-4 flex items-center justify-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <div className={`${isPopup ? "relative bg-gray-800 rounded-lg shadow-lg w-full max-w-6xl p-4" : "relative w-full max-w-8xl p-4"}`}>
                    {isPopup && <div className="flex justify-between items-center bg-black text-white p-4 rounded-t-lg no-print">
                        <h2 className="text-xl font-semibold">
                            {`${grade?.assignmentName} -- ${user?.firstName} ${user?.lastName}`}
                        </h2>
                        <div className="flex space-x-4 z-100">
                            {grade && (
                                <LazyPDFDownloadButton
                                    userData={user}
                                    grade={grade}
                                    logo={logo}
                                />
                            )}
                            <button onClick={onClose} className="text-white hover:text-gray-300">
                                <HiX className="h-6 w-6" />
                            </button>
                        </div>
                    </div>}

                    {/* Content Area */}
                    <div ref={contentRef} className={`${isPopup ? "bg-white p-6 overflow-y-auto max-h-[80vh] printable" : "bg-white p-6 printable"}`}>
                        <div className="space-y-8">
                            {/* PERFORMANCE OVERVIEW */}
                            <section className="bg-blue-50 rounded-lg p-4">
                                <div className="flex justify-between items-center">
                                    <h3 className="text-xl font-semibold text-blue-900">PERFORMANCE OVERVIEW</h3>
                                    <img src={logo} alt="CourseTA" className="h-20 w-28" />
                                </div>
                                <div className="bg-blue-600 h-1 mt-4"></div>

                                <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
                                    <div>
                                        <p className="text-sm font-medium text-gray-700">Student:</p>
                                        <p className="text-lg font-semibold text-gray-900">{user?.firstName + ' ' + user?.lastName}</p>
                                    </div>
                                    <div>
                                        <p className="text-sm font-medium text-gray-700">Assignment:</p>
                                        <p className="text-lg font-semibold text-gray-900">{grade?.assignmentName}</p>
                                    </div>
                                    <div>
                                        <p className="text-sm font-medium text-gray-700">Course:</p>
                                        <p className="text-lg font-semibold text-gray-900">{grade?.courseName}</p>
                                    </div>
                                    <div>
                                        <p className="text-sm font-medium text-gray-700">VR Submission Date:</p>
                                        <p className="text-lg font-semibold text-gray-900">{grade?.createdAt ? grade?.createdAt.toDate().toLocaleString() : "N/A"}</p>
                                    </div>
                                    <div>
                                        <p className="text-sm font-medium text-gray-700">Status:</p>
                                        <p className={`text-lg font-semibold ${grade?.submissionDate ? "text-green-700" : "text-red-700"}`}>{grade?.submissionDate ? "Completed" : "Not yet completed"}</p>
                                    </div>
                                    <div>
                                        <p className="text-sm font-medium text-gray-700">Completion Date:</p>
                                        <p className="text-lg font-semibold text-gray-900">{grade?.submissionDate ? grade?.submissionDate.toDate().toLocaleString() : "N/A"}</p>
                                    </div>
                                </div>

                                <div className="mt-6">
                                    <h4 className="text-lg font-semibold text-blue-900">Scores</h4>
                                    <table className="w-full mt-4 bg-white shadow-sm rounded-lg">
                                        <thead className="bg-gray-100">
                                            <tr>
                                                <th className="p-2 text-left text-sm font-medium text-gray-700">Metric</th>
                                                <th className="p-2 text-right text-sm font-medium text-gray-700">Score</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                            {/* {Object.keys(categoryPercentagesMemo).map((metric) => (
                                                <tr key={metric}>
                                                    <td className="p-2 text-sm text-gray-700">{metric}</td>
                                                    <td className="p-2 text-right text-sm text-gray-900">{categoryPercentagesMemo[metric]}%</td>
                                                </tr>
                                            ))} */}
                                            <tr key={'answers'}>
                                                <td className="p-2 text-sm text-gray-700">Answers</td>
                                                <td className="p-2 text-right text-sm text-gray-900">{grade?.answersResult}%</td>
                                            </tr>
                                            <tr key={'accuracy'}>
                                                <td className="p-2 text-sm text-gray-700">Accuracy</td>
                                                <td className="p-2 text-right text-sm text-gray-900">{grade?.accuracyResult}%</td>
                                            </tr>
                                            <tr key={'communication'}>
                                                <td className="p-2 text-sm text-gray-700">Communication</td>
                                                <td className="p-2 text-right text-sm text-gray-900">{grade?.communicationResult}%</td>
                                            </tr>
                                            <tr key={'safety'}>
                                                <td className="p-2 text-sm text-gray-700">Safety</td>
                                                <td className="p-2 text-right text-sm text-gray-900">{grade?.safetyResult}%</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="flex justify-between items-center mt-6 p-4 bg-blue-50 rounded-lg">
                                        <div>
                                            <p className="text-sm font-medium text-blue-900">Total Score:</p>
                                            <p className="text-lg font-semibold text-blue-900">{grade?.result}%</p>
                                        </div>
                                        <p className={`font-bold text-lg ${grade?.result >= 70 ? 'text-green-500' : grade?.result >= 50 ? 'text-yellow-500' : 'text-red-500'}`}>
                                            {grade?.result >= 70 ? 'Awesome Job!' :
                                                grade?.result >= 50 ? 'Good Job!' :
                                                    grade?.result >= 30 ? 'Almost there!' :
                                                        'You can do better!'}
                                        </p>
                                    </div>
                                </div>
                            </section>

                            {/* ACCURACY PERFORMANCE */}
                            {grade?.accuracy && Object.keys(grade?.accuracy).length > 0 &&
                                <section className="bg-blue-50 rounded-lg p-4">
                                    <div className="flex justify-between items-center">
                                        <h3 className="text-xl font-semibold text-blue-900">ACCURACY PERFORMANCE</h3>
                                        <img src={logo} alt="CourseTA" className="h-20 w-28" />
                                    </div>
                                    <div className="bg-blue-600 h-1 mt-4"></div>
                                    <RenderGradeCards categoryData={grade?.accuracy} />
                                </section>}

                            {/* COMMUNICATION PERFORMANCE */}
                            {grade?.communication && Object.keys(grade?.communication).length > 0 &&
                                < section className="bg-blue-50 rounded-lg p-4">
                                    <div className="flex justify-between items-center">
                                        <h3 className="text-xl font-semibold text-blue-900">COMMUNICATION PERFORMANCE</h3>
                                        <img src={logo} alt="CourseTA" className="h-20 w-28" />
                                    </div>
                                    <div className="bg-blue-600 h-1 mt-4"></div>
                                    <RenderGradeCards categoryData={grade?.communication} />
                                </section>}

                            {/* SAFETY PERFORMANCE */}
                            {grade?.safety && Object.keys(grade?.safety).length > 0 &&
                                <section className="bg-blue-50 rounded-lg p-4">
                                    <div className="flex justify-between items-center">
                                        <h3 className="text-xl font-semibold text-blue-900">SAFETY PERFORMANCE</h3>
                                        <img src={logo} alt="CourseTA" className="h-20 w-28" />
                                    </div>
                                    <div className="bg-blue-600 h-1 mt-4"></div>
                                    <RenderGradeCards categoryData={grade?.safety} />
                                </section>}

                            {/* ANSWERS ACCURACY */}
                            {grade?.scenario?.documentation && grade?.scenario?.documentation.length > 0 &&
                                <section className="bg-blue-50 rounded-lg p-4">
                                    <div className="flex justify-between items-center">
                                        <h3 className="text-xl font-semibold text-blue-900">ANSWERS ACCURACY</h3>
                                        <img src={logo} alt="CourseTA" className="h-20 w-28" />
                                    </div>
                                    <div className="bg-blue-600 h-1 mt-4"></div>
                                    {renderQuestions(grade?.scenario?.documentation, grade)}
                                </section>}

                            {/* DEBRIEFING */}
                            {grade?.debriefing && grade?.debriefing.length > 0 &&
                                <section className="bg-blue-50 rounded-lg p-4">
                                    <div className="flex justify-between items-center">
                                        <h3 className="text-xl font-semibold text-blue-900">DEBRIEFING</h3>
                                        <img src={logo} alt="CourseTA" className="h-20 w-28" />
                                    </div>
                                    <div className="bg-blue-600 h-1 mt-4"></div>
                                    {renderOpenQuestions(grade?.scenario?.debriefing, grade?.debriefing)}
                                </section>}

                            {/* CLINICAL JUDGEMENT DEBRIEFING */}
                            {grade?.debriefing2 && grade?.debriefing2.length > 0 &&
                                <section className="bg-blue-50 rounded-lg p-4">
                                    <h3 className="text-xl font-semibold text-blue-900">CLINICAL JUDGEMENT DEBRIEFING</h3>
                                    <div className="bg-blue-600 h-1 mt-4"></div>
                                    {renderDebriefing2(grade?.scenario?.debriefing_2, grade?.debriefing2)}
                                </section>}

                            {/* REFLECTION DATA */}
                            {grade?.reflection && grade?.reflection.length > 0 &&
                                <section className="bg-blue-50 rounded-lg p-4">
                                    <h3 className="text-xl font-semibold text-blue-900">REFLECTION DATA</h3>
                                    <div className="bg-blue-600 h-1 mt-4"></div>
                                    {renderOpenQuestions(grade?.scenario?.reflection, grade?.reflection)}
                                </section>}

                            {/* SIMULATION TRANSCRIPT */}
                            {grade?.transcript && grade?.transcript.length > 0 &&
                                <section className="bg-blue-50 rounded-lg p-4">
                                    <h3 className="text-xl font-semibold text-blue-900">SIMULATION TRANSCRIPT</h3>
                                    <div className="bg-blue-600 h-1 mt-4"></div>
                                    <div className="p-4">
                                        <Transcripts transcripts={grade?.transcript} />
                                    </div>
                                </section>}
                        </div>
                    </div>
                </div>
            )
            }
        </div >
    );

    // return isPopup ? (
    //     // <div>
    //     //     {grade.accuracyResult}
    //     //     {grade.answersResult}
    //     //     {grade.assignmentId}
    //     //     {grade.assignmentName}
    //     //     {grade.communicationResult}
    //     //     {grade.courseId}
    //     //     {grade.courseName}
    //     //     {grade.creationDate}
    //     //     {grade.result}
    //     //     {grade.userId}
    //     //     {gradeDetails}
    //     //     TEST
    //     // </div>

    //     <div className="fixed inset-0 z-50 bg-gray-900 bg-opacity-50 flex items-center justify-center p-8">
    //         {loading ? (
    //             <div className="bg-gray-800 rounded-lg shadow-lg w-full max-w-6xl p-4 flex items-center justify-center">
    //                 <LoadingSpinner />
    //             </div>
    //         ) : (
    //             <div className="relative bg-gray-800 rounded-lg shadow-lg w-full max-w-6xl p-4">
    //                 <div className="flex justify-between items-center bg-black text-white p-4 rounded-t-lg no-print">
    //                     <h2 className="text-xl font-semibold">
    //                         {`${grade?.assignmentName} -- ${user?.firstName} ${user?.lastName}`}
    //                     </h2>
    //                     <div className="flex space-x-4 z-100">
    //                         {grade && (
    //                             <LazyPDFDownloadButton
    //                                 userData={user}
    //                                 grade={grade}
    //                                 logo={logo}
    //                                 scenario={scenario}
    //                                 questionnaire={questionnaire}
    //                             />
    //                         )}
    //                         <button onClick={onClose} className="text-white hover:text-gray-300">
    //                             <HiX className="h-6 w-6" />
    //                         </button>
    //                     </div>
    //                 </div>

    //                 {/* Content Area */}
    //                 <div ref={contentRef} className="bg-white p-6 overflow-y-auto max-h-[80vh] printable">
    //                     <div className="space-y-8">
    //                         {/* PERFORMANCE OVERVIEW */}
    //                         <section className="bg-blue-50 rounded-lg p-4">
    //                             <div className="flex justify-between items-center">
    //                                 <h3 className="text-xl font-semibold text-blue-900">PERFORMANCE OVERVIEW</h3>
    //                                 <img src={logo} alt="CourseTA" className="h-20 w-28" />
    //                             </div>
    //                             <div className="bg-blue-600 h-1 mt-4"></div>

    //                             <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
    //                                 <div>
    //                                     <p className="text-sm font-medium text-gray-700">Student:</p>
    //                                     <p className="text-lg font-semibold text-gray-900">{user?.firstName + ' ' + user?.lastName}</p>
    //                                 </div>
    //                                 <div>
    //                                     <p className="text-sm font-medium text-gray-700">Assignment:</p>
    //                                     <p className="text-lg font-semibold text-gray-900">{grade?.assignmentName}</p>
    //                                 </div>
    //                                 <div>
    //                                     <p className="text-sm font-medium text-gray-700">Course:</p>
    //                                     <p className="text-lg font-semibold text-gray-900">{grade?.courseName}</p>
    //                                 </div>
    //                                 <div>
    //                                     <p className="text-sm font-medium text-gray-700">Submission Date:</p>
    //                                     <p className="text-lg font-semibold text-gray-900">{grade?.createdAt ? grade?.createdAt.toDate().toLocaleString() : "N/A"}</p>
    //                                 </div>
    //                                 <div>
    //                                     <p className="text-sm font-medium text-gray-700">Status:</p>
    //                                     <p className={`text-lg font-semibold ${grade?.submissionDate ? "text-green-700" : "text-red-700"}`}>{grade?.submissionDate ? "Completed" : "Not yet completed"}</p>
    //                                 </div>
    //                                 <div>
    //                                     <p className="text-sm font-medium text-gray-700">Completion Date:</p>
    //                                     <p className="text-lg font-semibold text-gray-900">{grade?.submissionDate ? new Date(grade?.submissionDate).toLocaleString() : "N/A"}</p>
    //                                 </div>
    //                             </div>

    //                             <div className="mt-6">
    //                                 <h4 className="text-lg font-semibold text-blue-900">Scores</h4>
    //                                 <table className="w-full mt-4 bg-white shadow-sm rounded-lg">
    //                                     <thead className="bg-gray-100">
    //                                         <tr>
    //                                             <th className="p-2 text-left text-sm font-medium text-gray-700">Metric</th>
    //                                             <th className="p-2 text-right text-sm font-medium text-gray-700">Score</th>
    //                                         </tr>
    //                                     </thead>
    //                                     <tbody className="divide-y divide-gray-200">
    //                                         {/* {Object.keys(categoryPercentagesMemo).map((metric) => (
    //                                             <tr key={metric}>
    //                                                 <td className="p-2 text-sm text-gray-700">{metric}</td>
    //                                                 <td className="p-2 text-right text-sm text-gray-900">{categoryPercentagesMemo[metric]}%</td>
    //                                             </tr>
    //                                         ))} */}
    //                                         <tr key={'answers'}>
    //                                             <td className="p-2 text-sm text-gray-700">answers</td>
    //                                             <td className="p-2 text-right text-sm text-gray-900">{grade?.answersResult}%</td>
    //                                         </tr>
    //                                         <tr key={'accuracy'}>
    //                                             <td className="p-2 text-sm text-gray-700">Accuracy</td>
    //                                             <td className="p-2 text-right text-sm text-gray-900">{grade?.accuracyResult}%</td>
    //                                         </tr>
    //                                         <tr key={'communication'}>
    //                                             <td className="p-2 text-sm text-gray-700">Communication</td>
    //                                             <td className="p-2 text-right text-sm text-gray-900">{grade?.communicationResult}%</td>
    //                                         </tr>
    //                                     </tbody>
    //                                 </table>

    //                                 <div className="flex justify-between items-center mt-6 p-4 bg-blue-50 rounded-lg">
    //                                     <div>
    //                                         <p className="text-sm font-medium text-blue-900">Total Score:</p>
    //                                         <p className="text-lg font-semibold text-blue-900">{grade?.result}%</p>
    //                                     </div>
    //                                     <p className={`font-bold text-lg ${grade?.result >= 70 ? 'text-green-500' : grade?.result >= 50 ? 'text-yellow-500' : 'text-red-500'}`}>
    //                                         {grade?.result >= 70 ? 'Awesome Job!' :
    //                                             grade?.result >= 50 ? 'Good Job!' :
    //                                                 grade?.result >= 30 ? 'Almost there!' :
    //                                                     'You can do better!'}
    //                                     </p>
    //                                 </div>
    //                             </div>
    //                         </section>

    //                         {/* ACCURACY PERFORMANCE */}
    //                         <section className="bg-blue-50 rounded-lg p-4">
    //                             <div className="flex justify-between items-center">
    //                                 <h3 className="text-xl font-semibold text-blue-900">ACCURACY PERFORMANCE</h3>
    //                                 <img src={logo} alt="CourseTA" className="h-20 w-28" />
    //                             </div>
    //                             <div className="bg-blue-600 h-1 mt-4"></div>
    //                             <RenderGradeCards categoryData={grade?.accuracy} />
    //                         </section>

    //                         {/* COMMUNICATION PERFORMANCE */}
    //                         <section className="bg-blue-50 rounded-lg p-4">
    //                             <div className="flex justify-between items-center">
    //                                 <h3 className="text-xl font-semibold text-blue-900">COMMUNICATION PERFORMANCE</h3>
    //                                 <img src={logo} alt="CourseTA" className="h-20 w-28" />
    //                             </div>
    //                             <div className="bg-blue-600 h-1 mt-4"></div>
    //                             <RenderGradeCards categoryData={grade?.communication} />
    //                         </section>

    //                         {/* SAFETY PERFORMANCE */}
    //                         <section className="bg-blue-50 rounded-lg p-4">
    //                             <div className="flex justify-between items-center">
    //                                 <h3 className="text-xl font-semibold text-blue-900">SAFETY PERFORMANCE</h3>
    //                                 <img src={logo} alt="CourseTA" className="h-20 w-28" />
    //                             </div>
    //                             <div className="bg-blue-600 h-1 mt-4"></div>
    //                             <RenderGradeCards categoryData={grade?.safety} />
    //                         </section>

    //                         {/* ANSWERS ACCURACY */}
    //                         <section className="bg-blue-50 rounded-lg p-4">
    //                             <div className="flex justify-between items-center">
    //                                 <h3 className="text-xl font-semibold text-blue-900">ANSWERS ACCURACY</h3>
    //                                 <img src={logo} alt="CourseTA" className="h-20 w-28" />
    //                             </div>
    //                             <div className="bg-blue-600 h-1 mt-4"></div>
    //                             {questionnaire ? renderQuestions(questionnaire.questions, grade) : "Loading..."}
    //                         </section>

    //                         {/* DEBRIEFING */}
    //                         <section className="bg-blue-50 rounded-lg p-4">
    //                             <div className="flex justify-between items-center">
    //                                 <h3 className="text-xl font-semibold text-blue-900">DEBRIEFING</h3>
    //                                 <img src={logo} alt="CourseTA" className="h-20 w-28" />
    //                             </div>
    //                             <div className="bg-blue-600 h-1 mt-4"></div>
    //                             {renderOpenQuestions(grade?.scenario?.debriefing, grade?.debriefing)}
    //                         </section>

    //                         {/* CLINICAL JUDGEMENT DEBRIEFING */}
    //                         <section className="bg-blue-50 rounded-lg p-4">
    //                             <h3 className="text-xl font-semibold text-blue-900">CLINICAL JUDGEMENT DEBRIEFING</h3>
    //                             <div className="bg-blue-600 h-1 mt-4"></div>
    //                             {renderDebriefing2(grade?.scenario?.debriefing_2, grade?.debriefing2)}
    //                         </section>

    //                         {/* REFLECTION DATA */}
    //                         <section className="bg-blue-50 rounded-lg p-4">
    //                             <h3 className="text-xl font-semibold text-blue-900">REFLECTION DATA</h3>
    //                             <div className="bg-blue-600 h-1 mt-4"></div>
    //                             {renderOpenQuestions(grade?.scenario?.reflection, grade?.reflection)}
    //                         </section>

    //                         {/* SIMULATION TRANSCRIPT */}
    //                         <section className="bg-blue-50 rounded-lg p-4">
    //                             <h3 className="text-xl font-semibold text-blue-900">SIMULATION TRANSCRIPT</h3>
    //                             <div className="bg-blue-600 h-1 mt-4"></div>
    //                             <div className="p-4">
    //                                 <Transcripts transcripts={grade?.transcript} />
    //                             </div>
    //                         </section>
    //                     </div>
    //                 </div>
    //             </div>
    //         )}
    //     </div>
    // ) : (
    //     <div ref={contentRef} className="bg-white p-6 overflow-y-auto max-h-[80vh] printable">
    //         <div className="space-y-8">
    //             {/* PERFORMANCE OVERVIEW */}
    //             <section className="bg-blue-50 rounded-lg p-4">
    //                 <div className="flex justify-between items-center">
    //                     <h3 className="text-xl font-semibold text-blue-900">PERFORMANCE OVERVIEW</h3>
    //                     <img src={logo} alt="CourseTA" className="h-20 w-28" />
    //                 </div>
    //                 <div className="bg-blue-600 h-1 mt-4"></div>

    //                 <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
    //                     <div>
    //                         <p className="text-sm font-medium text-gray-700">Student:</p>
    //                         <p className="text-lg font-semibold text-gray-900">{user?.firstName + ' ' + user?.lastName}</p>
    //                     </div>
    //                     <div>
    //                         <p className="text-sm font-medium text-gray-700">Assignment:</p>
    //                         <p className="text-lg font-semibold text-gray-900">{assignment?.assignmentName}</p>
    //                     </div>
    //                     <div>
    //                         <p className="text-sm font-medium text-gray-700">Course:</p>
    //                         <p className="text-lg font-semibold text-gray-900">{course?.courseName}</p>
    //                     </div>
    //                     <div>
    //                         <p className="text-sm font-medium text-gray-700">Submission Date:</p>
    //                         <p className="text-lg font-semibold text-gray-900">{grade?.createdAt}</p>
    //                     </div>
    //                 </div>

    //                 <div className="mt-6">
    //                     <h4 className="text-lg font-semibold text-blue-900">Scores</h4>
    //                     <table className="w-full mt-4 bg-white shadow-sm rounded-lg">
    //                         <thead className="bg-gray-100">
    //                             <tr>
    //                                 <th className="p-2 text-left text-sm font-medium text-gray-700">Metric</th>
    //                                 <th className="p-2 text-right text-sm font-medium text-gray-700">Score</th>
    //                             </tr>
    //                         </thead>
    //                         <tbody className="divide-y divide-gray-200">
    //                             {/* {Object.keys(categoryPercentagesMemo).map((metric) => (
    //                                 <tr key={metric}>
    //                                     <td className="p-2 text-sm text-gray-700">{metric}</td>
    //                                     <td className="p-2 text-right text-sm text-gray-900">{categoryPercentagesMemo[metric]}%</td>
    //                                 </tr>
    //                             ))} */}
    //                             <tr key={'answers'}>
    //                                 <td className="p-2 text-sm text-gray-700">answers</td>
    //                                 <td className="p-2 text-right text-sm text-gray-900">{grade?.answersResult}%</td>
    //                             </tr>
    //                             <tr key={'accuracy'}>
    //                                 <td className="p-2 text-sm text-gray-700">Accuracy</td>
    //                                 <td className="p-2 text-right text-sm text-gray-900">{grade?.accuracyResult}%</td>
    //                             </tr>
    //                             <tr key={'communication'}>
    //                                 <td className="p-2 text-sm text-gray-700">Communication</td>
    //                                 <td className="p-2 text-right text-sm text-gray-900">{grade?.communicationResult}%</td>
    //                             </tr>
    //                         </tbody>
    //                     </table>

    //                     <div className="flex justify-between items-center mt-6 p-4 bg-blue-50 rounded-lg">
    //                         <div>
    //                             <p className="text-sm font-medium text-blue-900">Total Score:</p>
    //                             <p className="text-lg font-semibold text-blue-900">{grade?.result}%</p>
    //                         </div>
    //                         <p className={`font-bold text-lg ${grade?.result >= 70 ? 'text-green-500' : grade?.result >= 50 ? 'text-yellow-500' : 'text-red-500'}`}>
    //                             {grade?.result >= 70 ? 'Awesome Job!' :
    //                                 grade?.result >= 50 ? 'Good Job!' :
    //                                     grade?.result >= 30 ? 'Almost there!' :
    //                                         'You can do better!'}
    //                         </p>
    //                     </div>
    //                 </div>
    //             </section>

    //             {/* ACCURACY PERFORMANCE */}
    //             <section className="bg-blue-50 rounded-lg p-4">
    //                 <div className="flex justify-between items-center">
    //                     <h3 className="text-xl font-semibold text-blue-900">ACCURACY PERFORMANCE</h3>
    //                     <img src={logo} alt="CourseTA" className="h-20 w-28" />
    //                 </div>
    //                 <div className="bg-blue-600 h-1 mt-4"></div>
    //                 <RenderGradeCards categoryData={grade?.accuracy} />
    //             </section>

    //             {/* COMMUNICATION PERFORMANCE */}
    //             <section className="bg-blue-50 rounded-lg p-4">
    //                 <div className="flex justify-between items-center">
    //                     <h3 className="text-xl font-semibold text-blue-900">COMMUNICATION PERFORMANCE</h3>
    //                     <img src={logo} alt="CourseTA" className="h-20 w-28" />
    //                 </div>
    //                 <div className="bg-blue-600 h-1 mt-4"></div>
    //                 <RenderGradeCards categoryData={grade?.communication} />
    //             </section>

    //             {/* SAFETY PERFORMANCE */}
    //             <section className="bg-blue-50 rounded-lg p-4">
    //                 <div className="flex justify-between items-center">
    //                     <h3 className="text-xl font-semibold text-blue-900">SAFETY PERFORMANCE</h3>
    //                     <img src={logo} alt="CourseTA" className="h-20 w-28" />
    //                 </div>
    //                 <div className="bg-blue-600 h-1 mt-4"></div>
    //                 <RenderGradeCards categoryData={grade?.safety} />
    //             </section>

    //             {/* ANSWERS ACCURACY */}
    //             <section className="bg-blue-50 rounded-lg p-4">
    //                 <div className="flex justify-between items-center">
    //                     <h3 className="text-xl font-semibold text-blue-900">ANSWERS ACCURACY</h3>
    //                     <img src={logo} alt="CourseTA" className="h-20 w-28" />
    //                 </div>
    //                 <div className="bg-blue-600 h-1 mt-4"></div>
    //                 {questionnaire ? renderQuestions(questionnaire.questions, grade) : "No questions available."}
    //             </section>

    //             {/* SIMULATION TRANSCRIPT */}
    //             <section className="bg-blue-50 rounded-lg p-4">
    //                 <h3 className="text-xl font-semibold text-blue-900">SIMULATION TRANSCRIPT</h3>
    //                 <div className="bg-blue-600 h-1 mt-4"></div>
    //                 <div className="p-4">
    //                     <Transcripts transcripts={grade?.transcript} />
    //                 </div>
    //             </section>
    //         </div>
    //     </div>
    // );
};




const ClosedQuestionCard2 = ({ data }) => {
    // isCorrect checks if the index matches the correct answer
    const isCorrect = index => index === data.correctAnswer;
    // isUserAnswer checks if the index matches the user's answer
    const isUserAnswer = index => index === data.userAnswer;
    // isAnswered checks if the user has answered (i.e., userAnswer is not null)
    const isAnswered = data.userAnswer !== null;

    return (
        <div className="mb-8 mt-8 p-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">{data.question}</h2>
            <div className="grid gap-4">
                {data.answers?.map((choice, index) => (
                    <div
                        key={index}
                        className={`relative flex items-center justify-between p-4 rounded-lg
                        ${isUserAnswer(index) ? "bg-blue-100 dark:bg-blue-200 border-blue-500" : "border border-gray-300 dark:border-gray-600"}
                        ${isAnswered && !isUserAnswer(index) ? "opacity-50" : ""}
                        `}
                    >
                        <span className={`block w-full text-left font-normal dark:text-gray-200`}>
                            {choice}
                        </span>
                        {isAnswered && (
                            <span className="absolute inset-y-0 right-4 flex items-center">
                                {isCorrect(index) ? (
                                    <HiCheckCircle className="w-6 h-6 text-green-500" />
                                ) : isUserAnswer(index) ? (
                                    <HiXCircle className="w-6 h-6 text-red-500" />
                                ) : null}
                            </span>
                        )}
                    </div>
                ))}
            </div>
            {isAnswered && (
                <div className="mt-4 p-4 rounded-lg border border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-800">
                    <p className={`text-md font-semibold ${isCorrect(data.userAnswer) ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
                        {isCorrect(data.userAnswer) ? 'Correct!' : 'Incorrect!'}
                    </p>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Rationale:</h3>
                    <p className="mt-2 text-md text-gray-600 dark:text-gray-300">
                        {data.rationale ? data.rationale : 'There was no rationale for this question.'}
                    </p>
                </div>
            )}
        </div>
    );
};


const Transcripts = ({ transcripts }) => {

    const parseTranscriptString = (transcriptString) => {
        const [speaker, time, message] = transcriptString.split('/');
        return { speaker, time, message };
    };

    return (
        <div className="relative mx-auto mb-28" style={{ maxWidth: '640px' }}>
            <div className="absolute left-1/2 transform -translate-x-1/2 border-l-2 border-gray-300" style={{ height: '100%' }} />
            {transcripts?.map((transcript, index) => {
                const { speaker, time, message } = parseTranscriptString(transcript);
                const isPlayer = speaker === 'Player';

                const bubbleClasses = isPlayer
                    ? 'bg-blue-500 text-white rounded-br-lg rounded-tl-lg'
                    : 'bg-gray-100 text-gray-900 rounded-bl-lg rounded-tr-lg';
                const iconClasses = isPlayer
                    ? 'text-blue-500'
                    : 'text-gray-600';
                const bubblePositioning = isPlayer ? 'right-3/4' : 'left-3/4';
                const marginTop = index === 0 ? 'mt-0' : 'mt-40';

                return (
                    <div key={index} className={`flex justify-center items-start relative ${marginTop}`}>
                        <div className={`absolute ${bubblePositioning} transform ${isPlayer ? 'translate-x-1/4' : '-translate-x-1/4'} max-w-xs w-full px-4 py-2 shadow-lg rounded-lg ${bubbleClasses}`}>
                            <div className="font-bold">
                                {isPlayer ? 'Player' : 'Patient'}
                            </div>
                            <p className="whitespace-pre-line text-sm">
                                {message}
                            </p>
                            <div className={`text-xs mt-2 ${isPlayer ? 'text-right' : 'text-left'}`}>
                                {time}
                            </div>
                        </div>
                        <div className="absolute mt-8 w-6 h-6 bg-white rounded-full shadow flex items-center justify-center">
                            <HiChatAlt2 className={`h-4 w-4 ${iconClasses}`} />
                        </div>
                    </div>
                );
            })}
        </div>
    );
}


const OpenQuestionCard = ({ question, answer, date, firstName, lastName, photourl, user }) => {

    return (
        <div className="mb-8 mt-8 p-6 bg-white shadow-lg rounded-md items-center">
            <h2 className="mt-4 text-xl font-bold text-gray-800 mb-4">{question}</h2>
            <article className="text-base pt-6 border-t border-gray-200">
                <footer className="flex justify-between items-center mb-2">
                    <div className="flex items-center">
                        <div className="inline-flex items-center mr-3 text-sm text-gray-900 font-semibold">
                            <div className='mr-2'><ProfilePicture userToShow={photourl} size={8} /></div>
                            {firstName} {lastName}
                        </div>
                        <p className="text-sm text-gray-600"><time dateTime={date}>{date}</time></p>
                    </div>
                </footer>
                <p className="text-gray-500">{answer}</p>
            </article>
        </div>
    );
}

const GradeCard = ({ title, description, score, maxScore }) => {
    let Icon;
    let color;

    if (score === maxScore) {
        // Groen als de score maximaal is
        Icon = HiCheckCircle;
        color = 'text-green-500';
    } else if (score > 1 && score < maxScore) {
        // Oranje als de score meer dan 1 is maar niet maximaal
        Icon = HiExclamationCircle;
        color = 'text-yellow-200';
    } else {
        // Rood in alle andere gevallen (dus als het fout is of de score 1 of minder is)
        Icon = HiXCircle;
        color = 'text-red-500';
    }

    return (
        <div className={`p-4 p-6 bg-gray-50 shadow-lg rounded-md flex justify-between items-center`}>
            <div className="flex items-start">
                <Icon className={`h-16 w-16 ${color} mr-4 flex-shrink-0`} aria-hidden="true" />
                <div>
                    <h3 className="text-xl font-bold text-gray-800 mb-4">{title}</h3>
                    <p className="text-gray-800">{description}</p>
                </div>
            </div>
            <div className="text-l font-bold text-gray-800 flex-shrink-0 w-1/4">
                {score} / {maxScore} Points Earned
            </div>
        </div>
    );
}



export default GradingDetails;