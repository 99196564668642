import React, { useState } from 'react';
import SaveButton from '../../shared/SaveButton';
import { HiPhotograph } from 'react-icons/hi';
import useScripting from '../../../hooks/useScripting'
import useScenarioCustomization from '../../../hooks/useScenarioCustomization';
import Thumbnail from '../../shared/Thumbnail';
import placeholder from '../../../assets/images/Rubric Image Placeholder.png'

const RubricTab = () => {
    const { patient, setPatientField } = useScenarioCustomization();

    const { grades } = useScripting();
    const rubrics = grades ?? [];

    const handleSelect = (rubricId) => {
        const updatedRubrics = patient.rubrics?.includes(rubricId) ? patient.rubrics.filter(r => r !== rubricId) : [...patient.rubrics ?? [], rubricId];
        setPatientField('rubrics', updatedRubrics);
    }

    return (
        <div className="p-4 bg-white border dark:bg-gray-800 min-h-screen w-full h-full">
            <div className="p-6">
                <h2 className="text-2xl font-semibold mb-4">Rubrics</h2>
                <p className="text-gray-600 text-lg mb-6">Select The Rubrics</p>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
                    {rubrics.map((rubric) => (
                        <div
                            key={rubric.id}
                            className={`p-2 rounded-lg cursor-pointer border-2 transition duration-300 ease-in-out ${patient.rubrics?.includes(rubric.id) ? 'border-blue-500' : 'border-gray-300'} hover:shadow-lg`}
                            onClick={() => handleSelect(rubric.id)}
                        >
                            <Thumbnail thumbnailURL={`gradesScripting/${rubric.thumbnail}`} defaultImg={placeholder} />
                            <p className={`mt-2 font-medium text-center ${patient.rubrics?.includes(rubric.id) ? 'text-blue-500' : 'text-gray-700'}`}>
                                {rubric.name}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default RubricTab;