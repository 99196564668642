import React from 'react';
import { HiChevronRight, HiArrowRight } from "react-icons/hi";
import useCourses from '../../hooks/useCourses';
import useGrades from '../../hooks/useGrades';
import { sortByDate } from '../../utils/analytics';
import { Timestamp } from 'firebase/firestore';
import useUsers from '../../hooks/useUsers';
import { usePopUp } from '../../hooks/usePopUp';

const RecentGrades = ({ course, onViewAll }) => {
    const { grades, getGradesForCourse, handleGradeClick } = useGrades();
    const { getUserByID } = useUsers();

    // if (!course) {
    //     return <div>Loading...</div>
    // }

    const filteredGrades = course ? (sortByDate(getGradesForCourse(course.id).filter((g) => g.submissionDate != null && g.submissionDate instanceof Timestamp).slice(0, 3), "submissionDate")) : (sortByDate(grades.filter((g) => g.submissionDate != null && g.submissionDate instanceof Timestamp).slice(0, 3), "submissionDate"));

    const handleRowClick = (grade) => {
        handleGradeClick(grade.id);
    };

    return (
        <div className="bg-gray-10 p-6 rounded-lg border">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-bold text-gray-900">Recent Submissions</h3>
                <button
                    onClick={() => { onViewAll() }}
                    className="flex items-center text-gray-500 text-sm font-medium">
                    View All
                    <HiArrowRight className="w-5 h-5 ml-1" />
                </button>
            </div>
            <ul>
                {filteredGrades.map((grade, index) => {
                    const user = getUserByID(grade.userId);
                    return (
                        <li
                            key={index}
                            className="flex justify-between items-center py-4 border-b last:border-0 cursor-pointer hover:bg-gray-50"
                            onClick={() => handleRowClick(grade)}
                        >
                            <div>
                                <p className="font-medium text-gray-900">{user ? `${user.firstName} ${user.lastName}` : "Deleted user"}</p>
                                <p className="text-sm text-gray-500">{grade.assignmentName}</p>
                            </div>
                            <div className="flex items-center">
                                <span className="bg-green-100 text-green-600 text-xs font-medium px-3 py-1 rounded-lg">
                                    Complete
                                </span>
                                <HiChevronRight className="w-5 h-5 ml-4 text-gray-400" />
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
};

export default RecentGrades;
