import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from '../firebase/firebase';
import React, { createContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom/dist';
import Roles from '../utils/roles';
import { getDocument, subscribeDocument, updateDocument } from '../firebase/firestore';
import { NotificationTypes } from '../utils/notifications';
import { useNotification } from '../hooks/useNotification';

const AuthContext = createContext();

//A list of pages that should not redirect unauthenticated users to the login page
const nonRedictedPages = ["/signup", "/forgotpassword"];

/**
 * The context for all authentication related data such as the signed in user data, authentication loading or authentication error messages
 * @param {*} children The provider's children
 * @returns 
 */
const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [role, setRole] = useState(null);
    const [schoolId, setSchoolId] = useState(null);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { showNotification } = useNotification();

    const location = useLocation();
    const navigate = useNavigate();

    const isStudent = role === Roles.STUDENT;
    const isTeacher = role === Roles.TEACHER;
    const isAdmin = role === Roles.ADMIN;

    // Monitor authentication state changes
    useEffect(() => {
        let unsubscribeUserInfo = null;

        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            console.log("Auth state changed. Current user:", currentUser); // Log user state

            // Reset user-related state
            setUser(null);
            setRole(null);
            setSchoolId(null);
            setError(null);

            // Clean up the previous subscription
            if (unsubscribeUserInfo) {
                unsubscribeUserInfo();
                unsubscribeUserInfo = null;
            }

            if (currentUser) {
                let role = null;
                let schoolId = null;

                //Hack to override signing in when on signup page
                // if (location.pathname.includes("/signup")) {
                //     logout();
                //     return;
                // }

                // Fetch custom claims after login
                try {
                    const tokenResult = await currentUser.getIdTokenResult();
                    role = tokenResult.claims.role;
                    schoolId = tokenResult.claims.schoolId;

                    console.log("User role:", role);
                    console.log("User schoolId:", schoolId);
                } catch (err) {
                    console.error("Error fetching token claims:", err);
                    setError("Failed to fetch user claims. Please contact your admin.");
                    setLoading(false);
                    return;
                }

                setRole(role);
                setSchoolId(schoolId);

                if (role === Roles.ADMIN) {
                    navigate("/school-selection");
                    unsubscribeUserInfo = subscribeDocument(`admins/${currentUser.uid}`, (userInfo) => {
                        console.log("Real-time admin user info:", userInfo);
                        setUser({ ...currentUser, ...userInfo });
                        setLoading(false);
                    });
                } else if (role === Roles.STUDENT || role === Roles.TEACHER) {
                    navigate(`/${role}/dashboard`);
                    if (schoolId) {
                        unsubscribeUserInfo = subscribeDocument(
                            `institutions/${schoolId}/users/${currentUser.uid}`,
                            (userInfo) => {
                                console.log("User info:", userInfo);
                                setUser({ ...currentUser, ...userInfo });
                                setLoading(false);
                            }
                        );
                    } else {
                        setError("No institution assigned to your account. Please contact your admin.");
                        setLoading(false);
                    }
                } else {
                    setError("No valid role assigned to your account. Please contact your admin.");
                    setLoading(false);
                }
            } else {
                setLoading(false);
                setUser(null);
                setRole(null);
                setSchoolId(null);

                console.log("Signed out!");

                //Redirect to login page
                if (!nonRedictedPages.includes(location.pathname)) {
                    navigate("/login");
                }
            }
        });

        return () => unsubscribe(); // Clean up listener on unmount
    }, [auth]);

    // Login function, can be called using useAuth hook
    const login = async (email, password) => {
        setError(null);
        setLoading(true);
        try {
            //const userCredential = 
            await signInWithEmailAndPassword(auth, email, password);
            showNotification(NotificationTypes.SUCCESS, "Signed in successfully.");
        } catch (err) {
            if (err.code === 'auth/wrong-password') {
                setError("wrong password.");
            }
            else if (err.code === 'auth/user-not-found') {
                setError("wrong email.");
            }
            else {
                setError(err.message);
            }
        } finally {
            setLoading(false);
        }
    };

    // Logout function, can be called using useAuth hook
    const logout = async () => {
        setError(null);
        try {
            await signOut(auth);
        } catch (err) {
            setError(err.message);
        }
    };

    const updateUser = async (data) => {
        if (!user) {
            throw new Error("Tried to update user without having a valid user!");
        }
        if (!schoolId) {
            throw new Error("Tried to update user without having a valid school ID!");
        }
        await updateDocument(user.id, `institutions/${schoolId}/users`, data);
    }

    return (
        <AuthContext.Provider value={{ user, role, schoolId, setSchoolId, loading, error, login, logout, updateUser, isStudent, isAdmin, isTeacher }}>
            {children}
        </AuthContext.Provider>
    );
}

export { AuthProvider, AuthContext };