import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar"; // Sidebar component
import Header from "./Header"; // Header component
import useAuth from "../../hooks/useAuth";

const Layout = () => {
    const { user } = useAuth();

    if (!user) {
        return null; // Return nothing if no user is authenticated
    }

    return (
        <div className="antialiased bg-gray-50 dark:bg-gray-900 min-h-screen flex flex-col">
            <Header />
            <div className="flex flex-grow">
                <Sidebar/>
                <main className="flex-grow p-4 ml-64 mt-16"> {/* mt-16 zorgt voor ruimte boven de content */}
                    <Outlet />
                </main>
            </div>
        </div>
    );
};

export default Layout;