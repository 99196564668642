

export const TabsUnderlined = ({ tabs, activeTab, setActiveTab }) => {


    return (
        <div className="flex mb-4">
            {tabs.map((tab, index) => {
                return <button
                    className={`${activeTab === index ? 'px-4 py-2 font-medium text-blue-600 border-b-2 border-blue-600' : 'px-4 py-2 font-medium text-gray-500 hover:text-blue-600'} dark:text-white`}
                    onClick={() => setActiveTab(index)}
                >
                    {tab}
                </button>
            })}
        </div>
    );
}

export const TabsBubble = ({ tabs, activeTab, setActiveTab }) => {
    return (
        <div className="p-4">
            <div className="flex flex-wrap items-center justify-center space-x-1 pt-2">
                {/* Bubble-style Tab Navigation */}
                <div className="p-2 flex flex-wrap justify-center bg-white dark:bg-gray-800 rounded-full shadow-sm border border-gray-200 dark:border-gray-700 w-full md:w-auto">
                    {tabs.map((tab, index) => (
                        <button
                            key={index}
                            onClick={() => setActiveTab(index)}
                            className={`py-2 px-6 md:px-7 rounded-full focus:outline-none transition-colors duration-300 ${
                                activeTab === index
                                    ? 'bg-blue-600 text-white'
                                    : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                            } mr-2 text-base font-medium`}
                        >
                            {tab}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};
