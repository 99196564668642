import { useContext, useEffect, useState } from "react";
import { CoursesContext } from "../contexts/CoursesContext";
import { useParams } from "react-router-dom";
import useUsers from "./useUsers";
import { updateDocument } from "../firebase/firestore";

/**
 * Hook for all courses functionality
 * @returns All usable variables and functions from this hook
 */
const useCourses = () => {
    const { courses, coursesMap, loading, addLearningMaterials, removeLearningMaterial, addAttendee, removeAttendee, updateCourse, createCourse, publishCourse, updateOpenLab, deleteCourse } = useContext(CoursesContext);
    const { getUserByID } = useUsers();
    const [course, setCourse] = useState(null);
    const { courseId } = useParams();

    useEffect(() => {
        if (courseId) {
            const data = getCourseById(courseId);
            console.log("Loaded course data: ", data)
            setCourse(data);
        }
    }, [courses])

    const getCourseById = (id) => {
        if (!coursesMap || coursesMap.size <= 0) {
            console.error("Error trying to get course by ID: No courses!");
            return null;
        }

        return coursesMap.get(id);
    }

    /**
     * Gets all attendees as User objects
     * @param {String} id The course ID
     */
    const getCourseAttendees = (id) => {
        const course = getCourseById(id);
        if (!course) {
            throw new Error("Error getting course attendees: no course with ID: ", id);
        }
        return course.attendees?.map((attendee) => getUserByID(attendee)).filter((user) => user != null);
    }

    return { courses, loading, getCourseById, getCourseAttendees, course, addLearningMaterials, removeLearningMaterial, addAttendee, removeAttendee, updateCourse, createCourse, publishCourse, updateOpenLab, deleteCourse }; // Access all context values directly
};

export default useCourses;