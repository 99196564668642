import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion } from 'flowbite-react';
import useAuth from '../../hooks/useAuth';
import Roles from '../../utils/roles';
import Searchbar from '../shared/Searchbar';
import FileUpload from '../shared/FileUpload';
import { usePopUp } from '../../hooks/usePopUp';
import DeleteButton from '../shared/DeleteButton';
import useCourses from '../../hooks/useCourses';
import CourseAssignments from './CourseAssignments';
import { SkeletonLoaderRow } from '../shared/SkeletonLoader';
import { getFileUrl } from '../../firebase/storage';
import { useNotification } from '../../hooks/useNotification';
import FileList, { fileIcons } from '../shared/FileList';
import { HiDocumentMagnifyingGlass } from 'react-icons/hi2';
import useSchool from '../../hooks/useSchool';


const readableCategories = {
    pdf: "PDFs",
    ppt: "PowerPoints",
    pptx: "PowerPoints",
    xls: "Excel Files",
    xlsx: "Excel Files",
    mp4: "Videos",
    png: "Photos",
    jpg: "Photos",
    other: "Other Files",
};

const Materials = () => {
    const { isStudent, isAdmin, user } = useAuth();
    const { school } = useSchool();
    const { showPopUp } = usePopUp();
    const { course, addLearningMaterials, removeLearningMaterial, loading } = useCourses();
    const { showNotification } = useNotification();

    if (!course) {
        return <div>
            Loading...
        </div>
    }

    const canEdit = (course.admin === user.id || isAdmin);

    const handleUpload = async (files) => {
        // console.log(files);
        await addLearningMaterials(course.id, files);
    }

    const handleDelete = async (material) => {
        console.log("Deleting material: ", material);
        await removeLearningMaterial(course.id, material);
    }

    const handleOpen = async (material) => {
        console.log("Opening material: ", material);
        const url = await getFileUrl(`institutions/${school.id}/courses/${course.id}/learningMaterials/${material}`);
        if (url) {
            window.open(url, '_blank');
        } else {
            showNotification('danger', 'Error: Unable to open file');
        }
    }


    const categorizeMaterials = (materials) => {
        const categorized = {};
        materials.forEach((material) => {
            const ext = material.split('.').pop().toLowerCase();
            const category = fileIcons[ext] ? ext : 'other';
            if (!categorized[category]) {
                categorized[category] = [];
            }
            categorized[category].push(material);
        });
        return categorized;
    };

    const categorizedMaterials = categorizeMaterials(course.learningMaterials ?? []);

    return (
        <div className="p-4 bg-white border dark:bg-gray-800 min-h-screen w-full h-full">
            <div className="flex justify-between items-center space-x-2 p-8">
                <div className='flex gap-2 items-center'>
                    <HiDocumentMagnifyingGlass className="text-4xl text-gray-700 dark:text-gray-300" />
                    <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                        Learning Materials
                    </h1>
                </div>
                {canEdit && (
                    <div className="flex items-center">
                        <button
                            onClick={() => { showPopUp(<FileUpload onUpload={handleUpload} />) }}
                            className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300"
                        >
                            Upload Material
                        </button>
                    </div>
                )}
            </div>

            {/* Render Flowbite Accordions for different categories */}
            {
                loading ? (<div className='w-full'>
                    <SkeletonLoaderRow />
                </div>) :
                    (<Accordion alwaysOpen>
                        {Object.keys(categorizedMaterials).length > 0 ? (
                            Object.keys(categorizedMaterials).map((category, index) => (
                                <Accordion.Panel key={index}>
                                    <Accordion.Title>
                                        {readableCategories[category] || "Other Files"} ({categorizedMaterials[category].length})
                                    </Accordion.Title>
                                    <Accordion.Content>
                                        <div className="flex flex-wrap">
                                            <FileList files={categorizedMaterials[category]} handleDelete={handleDelete} handleOpen={handleOpen} />
                                        </div>
                                    </Accordion.Content>
                                </Accordion.Panel>
                            ))
                        ) : (
                            <div className="text-center py-12">
                                <img
                                    className="w-32 h-32 mx-auto"
                                    src="https://res.cloudinary.com/daqsjyrgg/image/upload/v1690261234/di7tvpnzsesyo7vvsrq4.svg"
                                    alt="No materials"
                                />
                                <p className="text-gray-700 dark:text-white font-medium text-lg">No learning materials.</p>
                                <p className="text-gray-500 dark:text-gray-400">You can upload new learning materials for students to see.</p>
                            </div>
                        )}
                    </Accordion>)
            }
        </div >
    );
};

export default Materials;
