import React, { useState } from 'react'
import DataTable from '../shared/Datatable';
import defaultImage from '../../assets/images/22.png';
import useUsers from '../../hooks/useUsers';
import { DateTimeFormat } from 'intl';
import { HiExclamation, HiPlus, HiUsers } from 'react-icons/hi';
import Searchbar from '../shared/Searchbar';
import { usePopUp } from '../../hooks/usePopUp';
import InviteUser from '../authentication/IniviteUser';
import UserInfo from '../users/UserInfo';
import { TabsUnderlined } from '../shared/TabNavigation';
import useRegistrations from '../../hooks/useRegistrations';
import { NotificationTypes } from '../../utils/notifications';
import { useNotification } from '../../hooks/useNotification';
import { auth } from '../../firebase/firebase';
import UserRow from '../shared/UserRow';
import useCourses from '../../hooks/useCourses';
import { HiOutlineUserMinus, HiOutlineUserPlus, HiUserMinus } from 'react-icons/hi2';
import ConfirmPopup from '../shared/ConfirmPopup';
import useAuth from '../../hooks/useAuth';

const Tabs = {
    USERS: "Current",
    REGISTRATIONS: "Add users"
}

const tabs = [Tabs.USERS, Tabs.REGISTRATIONS];

const CoursePeople = () => {
    const { showPopUp } = usePopUp();
    const [tab, setTab] = useState(0);

    return (
        <div>
            <div className="border rounded-lg p-4">
                <div className="flex justify-between items-center space-x-2 p-8">
                    <div className="flex items-center">
                        <HiUsers className="text-4xl text-gray-700 dark:text-gray-300" />
                        <h1 className="text-xl px-2 font-semibold text-gray-900 dark:text-white">
                            Attendees
                        </h1>
                    </div>
                    <div className="flex space-x-2 justify-end">
                        {/* <button
                            onClick={handleAddUserClick}
                            className="flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium"
                        >
                            <HiPlus className="h-5 w-5 mr-2" />
                            Invite User
                        </button>
                        <button
                            onClick={handleAddMultipleUserClick}
                            className="flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium"
                        >
                            <HiPlus className="h-5 w-5 mr-2" />
                            Invite Multiple
                        </button> */}
                    </div>
                </div>

                <TabsUnderlined tabs={tabs} activeTab={tab} setActiveTab={setTab} />

                {tabs[tab] === Tabs.USERS ? <UsersTable /> : <UsersTable add={true} />}
            </div>
        </div>
    )
}

const UsersTable = ({ add = false }) => {
    const { showPopUp } = usePopUp();
    const { course, getCourseAttendees, loading, addAttendee, removeAttendee } = useCourses();
    const { users } = useUsers();
    const { user, isAdmin } = useAuth();
    const [filteredUsers, setFilteredUsers] = useState([]);

    if (!course) {
        return <div>Loading...</div>
    }

    const canEdit = (course.admin === user.id || isAdmin);

    const attendees = getCourseAttendees(course?.id);
    const nonAttendees = users.filter(u => !attendees.includes(u)); //&& u.role === "Student");

    const handleShowUserClick = (userId) => {
        showPopUp(<UserInfo userId={userId} onClose={() => showPopUp(null)} />);
    }

    const handleAddUser = async (user) => {
        await addAttendee(course.id, user.id);
    }

    const handleRemoveUser = async (user) => {
        await removeAttendee(course.id, user.id);
    }

    const columns = [
        {
            key: "name",
            label: "NAME",
            render: (_, user) => {
                return (
                    <UserRow user={user} />);
            }
        },
        {
            key: "role",
            label: "ROLE",
            render: (_, user) => {
                return (
                    (
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${user.role?.trim() === 'Teacher'
                            ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                            : user.role?.trim() === 'Student'
                                ? 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300'
                                : user.role?.trim() === 'Admin'
                                    ? 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300'
                                    : 'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-300'
                            }`}>
                            {user.role?.trim() || 'N/A'}
                        </span>
                    )
                );
            }
        },
        {
            key: "createdAt",
            label: "DATE JOINED",
            render: (date, user) => {
                const formattedDate = date.toDate().toLocaleString().split(",")[0];
                return (<div>{formattedDate}</div>)
            }
        }
    ];

    return (
        <div>
            {/* Searchbar */}
            <Searchbar
                data={add ? nonAttendees : attendees} // Pass the original users array
                searchLabel={"name"}
                filterKeys={["firstName", "lastName"]} // Define the key to filter by
                onFilter={setFilteredUsers} // Update the filtered data
            />

            {/* Data table */}
            <div className="flex-grow">
                <DataTable
                    rows={filteredUsers}
                    columns={columns}
                    isLoading={loading}
                    onViewClick={
                        // (e) => { console.log(e) }
                        handleShowUserClick
                    }
                    customActions={canEdit ? [
                        {
                            icon: add ? <HiOutlineUserPlus /> : <HiOutlineUserMinus />, onClick: (user) => {
                                if (add) {
                                    handleAddUser(user);
                                }
                                else {
                                    showPopUp(<ConfirmPopup label={"Remove"} icon={<HiExclamation />} onConfirm={() => { handleRemoveUser(user); }} color={"yellow"} colorTint={300} description={`Are you sure you want to remove ${user.firstName} ${user.lastName} from this course?`} />);
                                }
                            }
                        }
                    ] : []}
                />
            </div>
        </div>
    );
}

export default CoursePeople;