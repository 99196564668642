import { HiXMark } from "react-icons/hi2";
import { usePopUp } from "../../hooks/usePopUp";
import React from "react";


const ConfirmPopup = ({ label, description, onConfirm, icon, color, colorTint=600 }) => {
    const { showPopUp } = usePopUp();

    const onClose = () => {
        showPopUp(null);
    }

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 w-full max-w-md">
                {/* Close button */}
                <button
                    onClick={onClose}
                    className="absolute top-3 right-3 text-gray-400 hover:text-gray-700 dark:hover:text-white"
                >
                    <HiXMark className="h-5 w-5" />
                </button>

                {/* Icon and Title */}
                <div className="flex flex-col items-center">
                    {React.cloneElement(icon, { className: `h-12 w-12 text-${color}-${colorTint} mb-4` })}
                    <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                        Confirm {label}
                    </h2>
                </div>

                {/* Dialog Message */}
                <div className="text-center mt-4 mb-6">
                    <p className="text-gray-700 dark:text-gray-300">
                        {description}
                    </p>
                    {/* <h2 className="text-gray-900 dark:text-gray-500 font-medium">{assignment.name}</h2> */}
                </div>

                {/* Action Buttons */}
                <div className="flex justify-between space-x-4">
                    {/* Cancel button */}
                    <button
                        onClick={onClose}
                        className="flex-1 px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600"
                    >
                        Cancel
                    </button>
                    {/* Confirm button */}
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            showPopUp(null);
                            onConfirm();
                        }}
                        className={`flex-1 px-4 py-2 bg-${color}-${colorTint} text-white rounded-lg hover:bg-${color}-${colorTint+100} dark:bg-${color}-${colorTint-100} dark:hover:bg-${color}-${colorTint}`}
                    >
                        {label}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ConfirmPopup;