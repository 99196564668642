import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const CalendarAssignments = ({ assignments, onAssignmentClicked }) => {
    const localizer = momentLocalizer(moment);

    // Transform assignments into calendar events
    const calendarEvents = assignments.map((assignment) => ({
        title: assignment.name, // Ensure 'title' exists in your data
        start: assignment.dueDate.toDate(), // Convert timestamp to Date object
        end: assignment.dueDate.toDate(), // Same as start for all-day events
        id: assignment.id
    }));

    const calendarLegend = (
        <div className="flex justify-between mb-6">
            <div className="flex items-center space-x-2">
                <span className="w-4 h-4 bg-blue-500 inline-block rounded"></span>
                <span className="text-gray-600 dark:text-gray-300">Assignment</span>
            </div>
        </div>
    );

    return (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
            {calendarLegend}
            <Calendar
                localizer={localizer}
                events={calendarEvents}
                startAccessor="start"
                endAccessor="end"
                onSelectEvent={(e) => { onAssignmentClicked(e.id) }}
                style={{ height: 600 }}
                eventPropGetter={(event) => ({
                    style: {
                        backgroundColor: '#1E90FF',
                        color: 'white',
                        borderRadius: '8px',
                        padding: '5px',
                        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
                    },
                })}
                views={['month', 'week', 'day']}
                defaultView="month"
            />
        </div>
    );
};

export default CalendarAssignments;
