import useAuth from "../../hooks/useAuth";
import useCourses from "../../hooks/useCourses";
import useSchool from "../../hooks/useSchool";
import logo from '../../assets/images/22.png';
import ReturnButton from "../shared/ReturnButton";


export default function UserProfile() {
    const { user, role, loading, handleNameChange, handlePasswordChange } = useAuth();
    // const { handleProfilePictureUpload } = useStorage();
    const { getCoursesForUser, getGradesForUser, getAssignmentForUser } = useCourses();
    const { school } = useSchool();

    // const courses = getCoursesForUser(userId);
    // const grades = getGradesForUser(userId);

    const grades = [];
    const courses = [];
    const assignments = [];

    return (
        <div>
            <br></br>
            <ReturnButton />
            <br></br>
            <div className="bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 dark:bg-gray-800 p-6 mb-6">
                <div className="flex items-center mb-6">
                    <img className="w-24 h-24 rounded-full object-cover" src={user?.photoUrl || logo} alt="Profile" />
                    <div className="ml-4">
                        <h2 className="text-xl font-bold text-gray-900 dark:text-white">{user.firstName + " " + user.lastName}</h2>
                        <p className="text-sm text-gray-500 dark:text-gray-400">Role: {role?.toUpperCase() ?? ''}</p>
                        <p className="text-sm text-gray-500 dark:text-gray-400">Joined Date: {user?.createdAt?.toDate().toISOString().split('T')[0] ?? 'N/A'}</p>
                        <div className="flex mt-2">
                            <button
                                type="button"
                                className="mr-2 px-3 py-2 text-sm font-medium text-white bg-primary-600 rounded-lg hover:bg-primary-700 focus:ring-4 focus:ring-primary-300 dark:bg-primary-700 dark:hover:bg-primary-800 dark:focus:ring-primary-800"
                                onClick={() => document.getElementById('upload-profile-picture').click()}
                            >
                                Change Profile Picture
                            </button>
                            <input
                                type="file"
                                // onChange={handleProfilePictureUpload}
                                className="hidden"
                                id="upload-profile-picture"
                                accept="image/*"
                            />
                        </div>
                    </div>
                </div>


                <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
                    <div className="lg:col-span-2">
                        <div className="p-6 mb-6">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Details</h3>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">FirstName</label>
                                    <p className="mt-1 text-sm text-gray-900 dark:text-white">{user?.firstName}</p>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">LastName</label>
                                    <p className="mt-1 text-sm text-gray-900 dark:text-white">{user?.lastName}</p>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">Email</label>
                                    <p className="mt-1 text-sm text-gray-900 dark:text-white">{user?.email}</p>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">Institute</label>
                                    <p className="mt-1 text-sm text-gray-900 dark:text-white">{school?.name}</p>
                                </div>

                            </div>
                        </div>
                        {/* <div className="p-6 mb-6">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Privacy & Security</h3>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="flex items-center justify-between col-span-2">
                                <label htmlFor="two-step-verification" className="text-sm font-medium text-gray-900 dark:text-white">Allow Two Step Verification</label>
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input type="checkbox" id="two-step-verification" className="sr-only peer" checked={isMFAEnabled} onChange={() => setIsMFAEnabled(!isMFAEnabled)} />
                                    <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary-600"></div>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="p-6">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Notification Settings</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-900 dark:text-white">Email Notifications</label>
                                <div className="mt-2 space-y-2">
                                    <div>
                                        <input type="checkbox" className="text-primary-600 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:bg-gray-700" />
                                        <label className="ml-2 text-sm font-medium text-gray-900 dark:text-white">Assignment Submission</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" className="text-primary-600 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:bg-gray-700" />
                                        <label className="ml-2 text-sm font-medium text-gray-900 dark:text-white">Late Submissions</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" className="text-primary-600 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:bg-gray-700" />
                                        <label className="ml-2 text-sm font-medium text-gray-900 dark:text-white">Overdue Assignments</label>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-900 dark:text-white">Phone Notifications</label>
                                <div className="mt-2 space-y-2">
                                    <div>
                                        <input type="checkbox" className="text-primary-600 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:bg-gray-700" />
                                        <label className="ml-2 text-sm font-medium text-gray-900 dark:text-white">Assignment Submission</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" className="text-primary-600 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:bg-gray-700" />
                                        <label className="ml-2 text-sm font-medium text-gray-900 dark:text-white">Late Submissions</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" className="text-primary-600 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:bg-gray-700" />
                                        <label className="ml-2 text-sm font-medium text-gray-900 dark:text-white">Overdue Assignments</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                        <div className="p-6 mb-6">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Recent Grades</h3>
                            {grades.length === 0 ? ( // Check if there are no grades
                                <p className="text-sm text-gray-500 dark:text-gray-400">No recent grades available.</p>
                            ) : (
                                <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                                    {grades.map((grade, index) => {
                                        const assignmentName = assignments.find((assignment) => assignment.id === grade.assessmentID)?.assignmentName || 'Unknown assignment';
                                        const averageResult = grade.averageResult || 'N/A';
                                        const resultBadgeStyle = (averageResult) => {
                                            if (averageResult === 'N/A') {
                                                return 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-300';
                                            }
                                            const result = parseFloat(averageResult);
                                            if (result >= 0 && result < 50) {
                                                return 'bg-red-500 dark:bg-red-700 text-white';
                                            } else if (result >= 50 && result < 70) {
                                                return 'bg-yellow-500 dark:bg-yellow-700 text-white';
                                            } else if (result >= 70 && result <= 100) {
                                                return 'bg-green-500 dark:bg-green-700 text-white';
                                            }
                                            return 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-300';
                                        };

                                        return (
                                            <li key={index} className="py-4 flex justify-between items-center">
                                                <div className="flex items-center space-x-4">
                                                    <div className="flex-shrink-0">
                                                        <svg className="w-5 h-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                            <path fillRule="evenodd" d="M2 5a2 2 0 012-2h12a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V5z" clipRule="evenodd" />
                                                            <path d="M4 9h12v2H4z" />
                                                        </svg>
                                                    </div>
                                                    <div className="flex-1 min-w-0">
                                                        <span className="block text-base font-semibold text-gray-900 truncate dark:text-white">{assignmentName}</span>
                                                        <span className="block text-base font-semibold text-gray-900 truncate dark:text-white">{averageResult}</span>
                                                    </div>
                                                </div>
                                                <div className="flex items-center space-x-2">
                                                    <button
                                                        //  onClick={() => handleGradeClick(grade)}
                                                        className="text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-600"
                                                    >
                                                        View
                                                    </button>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                        </div>

                        <div className="p-6">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Enrolled Courses</h3>
                            {courses.length === 0 ? ( // Check if there are no courses
                                <p className="text-sm text-gray-500 dark:text-gray-400">No enrolled courses available.</p>
                            ) : (
                                <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                                    {courses.map((classRoom, index) => (
                                        <li key={index} className="py-4">
                                            <div className="flex items-center space-x-4">
                                                <div className="flex-shrink-0">
                                                    <svg className="w-5 h-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                        <path d="M4 2a1 1 0 011-1h10a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V2z" />
                                                        <path fillRule="evenodd" d="M3 5a1 1 0 011 1v11a1 1 0 001 1h10a1 1 0 001-1V6a1 1 0 112 0v11a3 3 0 01-3 3H5a3 3 0 01-3-3V6a1 1 0 011-1z" clipRule="evenodd" />
                                                    </svg>
                                                </div>
                                                <div className="flex-1 min-w-0">
                                                    <span className="block text-base font-semibold text-gray-900 truncate dark:text-white">{classRoom?.className}</span>
                                                </div>
                                                {/* <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    navigate(`/main/classroomdetails/${classRoom?.id}`);
                                                }}
                                                className="inline-flex items-center rounded-lg p-2 text-xs font-medium uppercase text-primary-700 hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-gray-700 sm:text-sm"
                                            >
                                                View Course
                                                <svg
                                                    className="ml-1 h-4 w-4 sm:h-5 sm:w-5"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M9 5l7 7-7 7"
                                                    />
                                                </svg>
                                            </button> */}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>

                    <div className="lg:col-span-1">
                        <div className="bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 dark:bg-gray-800 p-6 mb-6">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Reset Password</h3>
                            <form
                            //onSubmit={handlePasswordChange}
                            >
                                <div className="grid grid-cols-1 gap-4">
                                    <div>
                                        <label htmlFor="current-password" className="block text-sm font-medium text-gray-900 dark:text-white">Current Password</label>
                                        <input type="password" id="current-password"
                                            //value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} 
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="••••••••" required />
                                    </div>
                                    <div>
                                        <label htmlFor="new-password" className="block text-sm font-medium text-gray-900 dark:text-white">New Password</label>
                                        <input type="password" id="new-password"
                                            //value={newPassword} onChange={(e) => setNewPassword(e.target.value)} 
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="••••••••" required />
                                    </div>
                                    <div>
                                        <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-900 dark:text-white">Confirm Password</label>
                                        <input type="password" id="confirm-password"
                                            //value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="••••••••" required />
                                    </div>
                                    <div>
                                        {/* <button type="submit" className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-700 dark:hover:bg-primary-800 dark:focus:ring-primary-800">Confirm New Password</button> */}
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/* Name update form */}
                        <div className="lg:col-span-1">
                            <div className="bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 dark:bg-gray-800 p-6 mb-6">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Update Name</h3>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">First Name</label>
                                        <input
                                            type="text"
                                            value={user.firstName}
                                            //onChange={(e) => setFirstName(e.target.value)}
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">Last Name</label>
                                        <input
                                            type="text"
                                            value={user.lastName}
                                            //onChange={(e) => setLastName(e.target.value)}
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                        />
                                    </div>
                                </div>
                                {/* <button
                                // onClick={handleNameChange}
                                className="mt-4 px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-lg hover:bg-primary-700 focus:ring-4 focus:ring-primary-300 dark:bg-primary-700 dark:hover:bg-primary-800 dark:focus:ring-primary-800"
                            >
                                Save Changes
                            </button> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}