import { useContext, useEffect, useState } from "react";
import { SchoolContext } from "../contexts/SchoolContext";
import useAuth from "./useAuth";
import Roles from "../utils/roles";

/**
 * Hook for all school functionality
 * @returns 
 */
const useSchool = () => {
    const { role, setSchoolId } = useAuth();
    const { school, schools, saveSchool, createSchool, deleteSchool } = useContext(SchoolContext);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [saving, setSaving] = useState(false);

    const markAsChanged = () => setHasUnsavedChanges(true);
    const saveChanges = async (capacity, availableScenarios) => {
        // Save logic here
        setSaving(true);
        await saveSchool({ capacity: capacity, availableScenarios: availableScenarios });
        setHasUnsavedChanges(false);
        setSaving(false);
    };

    /**
     * Simple function that selects a school
     * @param {String} schoolID The ID of the school you wish to select
     */
    const selectSchool = async (schoolId) => {
        // if (!role || role != Roles.ADMIN) {
        //     throw new Error("Attempted to select school without being an admin");
        // }
        setSchoolId(schoolId);
    }

    return { school, schools, selectSchool, markAsChanged, saveChanges, hasUnsavedChanges, saving, createSchool, deleteSchool }; // Access all context values directly
};

export default useSchool;