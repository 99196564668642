import { useContext, useEffect, useState } from 'react';
import { NotificationContext } from '../contexts/NotificationContext';

/**
 * Hook for managing notifications.
 * Handles visibility and timers for auto-hiding notifications.
 */
export const useNotification = () => {
    const context = useContext(NotificationContext);

    if (!context) {
        throw new Error("useNotification must be used within a NotificationProvider");
    }

    const { notifications, hideNotification } = context;

    // State to manage visibility of notifications
    const [visibleNotifications, setVisibleNotifications] = useState({});

    useEffect(() => {
        const timers = {};

        notifications.forEach((notification) => {
            // Check if a timer has already been set for this notification
            if (!visibleNotifications[notification.id]) {
                // Make notification visible
                setVisibleNotifications((prev) => ({
                    ...prev,
                    [notification.id]: true,
                }));

                // Set a timer for auto-hiding
                timers[notification.id] = setTimeout(() => {
                    setVisibleNotifications((prev) => ({
                        ...prev,
                        [notification.id]: false,
                    }));

                    // Remove the notification after a brief delay
                    setTimeout(() => {
                        hideNotification(notification.id);
                    }, 500); // Match transition duration
                }, 5000); // Default delay: 5 seconds
            }
        });

        // Cleanup timers on unmount or when notifications change
        return () => {
            Object.values(timers).forEach(clearTimeout);
        };
    }, [notifications, hideNotification]);

    const handleCloseNotification = (id) => {
        // Manually close the notification
        setVisibleNotifications((prev) => ({
            ...prev,
            [id]: false,
        }));

        setTimeout(() => {
            hideNotification(id);
        }, 500); // Match transition duration
    };

    return {
        ...context,
        visibleNotifications,
        handleCloseNotification,
    };
};
