import React, { useState } from "react";
import ReactECharts from "echarts-for-react";
import { Accordion, Dropdown, Checkbox } from "flowbite-react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";


const mockDatasets = {
    students: {
        "Student A": {
            courses: {
                'Course A': [{
                    'mainCompetency': 'Domain 1: Knowledge for Nursing Practice',
                    'percentage': 10,
                    'subCompetencies': [{
                        'name': '1.1 Demonstrate an understanding of the discipline of nursing’s distinct perspective and where shared perspectives exist with other disciplines',
                        'percentage': 89
                    },
                    {
                        'name': '1.2 Apply theory and research-based knowledge from nursing, the arts, humanities, and other sciences.',
                        'percentage': 85
                    },
                    {
                        'name': '1.3 Demonstrate clinical judgment founded on a broad knowledge base.',
                        'percentage': 76
                    }]
                },
                {
                    'mainCompetency': 'Domain 2: Person-Centered Care',
                    'percentage': 20,
                    'subCompetencies': [{
                        'name': '2.1 Engage with the individual in establishing a caring relationship.',
                        'percentage': 52
                    },
                    {
                        'name': '2.2 Communicate effectively with individuals.',
                        'percentage': 88
                    },
                    { 'name': '2.3 Integrate assessment skills in practice.', 'percentage': 91 },
                    {
                        'name': '2.4 Diagnose actual or potential health problems and needs.',
                        'percentage': 91
                    },
                    { 'name': '2.5 Develop a plan of care.', 'percentage': 72 },
                    {
                        'name': '2.6 Demonstrate accountability for care delivery.',
                        'percentage': 66
                    },
                    { 'name': '2.7 Evaluate outcomes of care.', 'percentage': 64 },
                    { 'name': '2.8 Promote self-care management.', 'percentage': 77 },
                    { 'name': '2.9 Provide care coordination.', 'percentage': 83 }]
                },
                {
                    'mainCompetency': 'Domain 3: Population Health',
                    'percentage': 30,
                    'subCompetencies': [{
                        'name': '3.1 Manage population health.',
                        'percentage': 66
                    },
                    { 'name': '3.2 Engage in effective partnerships.', 'percentage': 55 },
                    {
                        'name': '3.3 Consider the socioeconomic impact of the delivery of health care.',
                        'percentage': 77
                    },
                    {
                        'name': '3.4 Advance equitable population health policy.',
                        'percentage': 92
                    },
                    { 'name': '3.5 Demonstrate advocacy strategies.', 'percentage': 74 },
                    {
                        'name': '3.6 Advance preparedness to protect population health during disasters and public health emergencies.',
                        'percentage': 94
                    }]
                },
                {
                    'mainCompetency': 'Domain 4: Scholarship for Nursing Discipline',
                    'percentage': 25,
                    'subCompetencies': [{
                        'name': '4.1 Advance the scholarship of nursing.',
                        'percentage': 96
                    },
                    {
                        'name': '4.2 Integrate best evidence into nursing practice.',
                        'percentage': 91
                    },
                    {
                        'name': '4.3 Promote the ethical conduct of scholarly activities.',
                        'percentage': 93
                    }]
                },
                {
                    'mainCompetency': 'Domain 5: Quality and Safety',
                    'percentage': 85,
                    'subCompetencies': [{
                        'name': '5.1 Apply quality improvement principles in care delivery.',
                        'percentage': 66
                    },
                    {
                        'name': '5.2 Contribute to a culture of patient safety.',
                        'percentage': 59
                    },
                    {
                        'name': '5.3 Contribute to a culture of provider and work environment safety.',
                        'percentage': 64
                    }]
                },
                {
                    'mainCompetency': 'Domain 6: Interprofessional Partnerships',
                    'percentage': 70,
                    'subCompetencies': [{
                        'name': '6.1 Communicate in a manner that facilitates a partnership approach to quality care delivery.',
                        'percentage': 60
                    },
                    {
                        'name': '6.2 Perform effectively in different team roles, using principles and values of team dynamics.',
                        'percentage': 59
                    },
                    {
                        'name': '6.3 Use knowledge of nursing and other professions to address healthcare needs.',
                        'percentage': 59
                    },
                    {
                        'name': '6.4 Work with other professions to maintain a climate of mutual learning, respect, and shared values.',
                        'percentage': 73
                    }]
                },
                {
                    'mainCompetency': 'Domain 7: Systems-Based Practice',
                    'percentage': 65,
                    'subCompetencies': [{
                        'name': '7.1 Apply knowledge of systems to work effectively across the continuum of care.',
                        'percentage': 70
                    },
                    {
                        'name': '7.2 Incorporate consideration of cost-effectiveness of care.',
                        'percentage': 74
                    },
                    {
                        'name': '7.3 Optimize system effectiveness through application of innovation and evidence-based practice.',
                        'percentage': 73
                    }]
                },
                {
                    'mainCompetency': 'Domain 8: Informatics and Healthcare Technologies',
                    'percentage': 78,
                    'subCompetencies': [{
                        'name': '8.1 Describe the various information and communication technology tools used in the care of patients, communities, and populations.',
                        'percentage': 56
                    },
                    {
                        'name': '8.2 Use information and communication technology to gather data, create information, and generate knowledge.',
                        'percentage': 63
                    },
                    {
                        'name': '8.3 Use information and communication technologies and informatics processes to deliver safe nursing care to diverse populations in a variety of settings.',
                        'percentage': 85
                    },
                    {
                        'name': '8.4 Use information and communication technology to support documentation of care and communication among providers, patients, and all system levels.',
                        'percentage': 58
                    },
                    {
                        'name': '8.5 Use information and communication technologies in accordance with ethical, legal, professional, and regulatory standards, and workplace policies in the delivery of care.',
                        'percentage': 82
                    }]
                },
                {
                    'mainCompetency': 'Domain 9: Professionalism',
                    'percentage': 82,
                    'subCompetencies': [{
                        'name': '9.1 Demonstrate an ethical comportment in one’s practice reflective of nursing’s mission to society.',
                        'percentage': 83
                    },
                    {
                        'name': '9.2 Employ a participatory approach to nursing care.',
                        'percentage': 50
                    },
                    {
                        'name': '9.3 Demonstrate accountability to the individual, society, and the profession.',
                        'percentage': 79
                    },
                    {
                        'name': '9.4 Comply with relevant laws, policies, and regulations.',
                        'percentage': 61
                    },
                    {
                        'name': '9.5 Demonstrate the professional identity of nursing.',
                        'percentage': 71
                    },
                    {
                        'name': '9.6 Integrate diversity, equity, and inclusion as core to one’s professional identity.',
                        'percentage': 88
                    }]
                },
                {
                    'mainCompetency': 'Domain 10: Personal, Professional, and Leadership Development',
                    'percentage': 73,
                    'subCompetencies': [{
                        'name': '10.1 Demonstrate a commitment to personal health and well-being.',
                        'percentage': 93
                    },
                    {
                        'name': '10.2 Demonstrate a spirit of inquiry that fosters flexibility and professional maturity.',
                        'percentage': 50
                    },
                    { 'name': '10.3 Develop capacity for leadership.', 'percentage': 54 }]
                }],
                'Course B': [{
                    'mainCompetency': 'Domain 1: Knowledge for Nursing Practice',
                    'percentage': 70,
                    'subCompetencies': [{
                        'name': '1.1 Demonstrate an understanding of the discipline of nursing’s distinct perspective and where shared perspectives exist with other disciplines',
                        'percentage': 97
                    },
                    {
                        'name': '1.2 Apply theory and research-based knowledge from nursing, the arts, humanities, and other sciences.',
                        'percentage': 99
                    },
                    {
                        'name': '1.3 Demonstrate clinical judgment founded on a broad knowledge base.',
                        'percentage': 76
                    }]
                },
                {
                    'mainCompetency': 'Domain 2: Person-Centered Care',
                    'percentage': 80,
                    'subCompetencies': [{
                        'name': '2.1 Engage with the individual in establishing a caring relationship.',
                        'percentage': 82
                    },
                    {
                        'name': '2.2 Communicate effectively with individuals.',
                        'percentage': 73
                    },
                    { 'name': '2.3 Integrate assessment skills in practice.', 'percentage': 70 },
                    {
                        'name': '2.4 Diagnose actual or potential health problems and needs.',
                        'percentage': 76
                    },
                    { 'name': '2.5 Develop a plan of care.', 'percentage': 61 },
                    {
                        'name': '2.6 Demonstrate accountability for care delivery.',
                        'percentage': 62
                    },
                    { 'name': '2.7 Evaluate outcomes of care.', 'percentage': 82 },
                    { 'name': '2.8 Promote self-care management.', 'percentage': 55 },
                    { 'name': '2.9 Provide care coordination.', 'percentage': 73 }]
                },
                {
                    'mainCompetency': 'Domain 3: Population Health',
                    'percentage': 60,
                    'subCompetencies': [{
                        'name': '3.1 Manage population health.',
                        'percentage': 70
                    },
                    { 'name': '3.2 Engage in effective partnerships.', 'percentage': 78 },
                    {
                        'name': '3.3 Consider the socioeconomic impact of the delivery of health care.',
                        'percentage': 82
                    },
                    {
                        'name': '3.4 Advance equitable population health policy.',
                        'percentage': 83
                    },
                    { 'name': '3.5 Demonstrate advocacy strategies.', 'percentage': 91 },
                    {
                        'name': '3.6 Advance preparedness to protect population health during disasters and public health emergencies.',
                        'percentage': 90
                    }]
                },
                {
                    'mainCompetency': 'Domain 4: Scholarship for Nursing Discipline',
                    'percentage': 75,
                    'subCompetencies': [{
                        'name': '4.1 Advance the scholarship of nursing.',
                        'percentage': 85
                    },
                    {
                        'name': '4.2 Integrate best evidence into nursing practice.',
                        'percentage': 57
                    },
                    {
                        'name': '4.3 Promote the ethical conduct of scholarly activities.',
                        'percentage': 86
                    }]
                },
                {
                    'mainCompetency': 'Domain 5: Quality and Safety',
                    'percentage': 85,
                    'subCompetencies': [{
                        'name': '5.1 Apply quality improvement principles in care delivery.',
                        'percentage': 75
                    },
                    {
                        'name': '5.2 Contribute to a culture of patient safety.',
                        'percentage': 98
                    },
                    {
                        'name': '5.3 Contribute to a culture of provider and work environment safety.',
                        'percentage': 86
                    }]
                },
                {
                    'mainCompetency': 'Domain 6: Interprofessional Partnerships',
                    'percentage': 70,
                    'subCompetencies': [{
                        'name': '6.1 Communicate in a manner that facilitates a partnership approach to quality care delivery.',
                        'percentage': 93
                    },
                    {
                        'name': '6.2 Perform effectively in different team roles, using principles and values of team dynamics.',
                        'percentage': 62
                    },
                    {
                        'name': '6.3 Use knowledge of nursing and other professions to address healthcare needs.',
                        'percentage': 64
                    },
                    {
                        'name': '6.4 Work with other professions to maintain a climate of mutual learning, respect, and shared values.',
                        'percentage': 94
                    }]
                },
                {
                    'mainCompetency': 'Domain 7: Systems-Based Practice',
                    'percentage': 65,
                    'subCompetencies': [{
                        'name': '7.1 Apply knowledge of systems to work effectively across the continuum of care.',
                        'percentage': 74
                    },
                    {
                        'name': '7.2 Incorporate consideration of cost-effectiveness of care.',
                        'percentage': 72
                    },
                    {
                        'name': '7.3 Optimize system effectiveness through application of innovation and evidence-based practice.',
                        'percentage': 54
                    }]
                },
                {
                    'mainCompetency': 'Domain 8: Informatics and Healthcare Technologies',
                    'percentage': 78,
                    'subCompetencies': [{
                        'name': '8.1 Describe the various information and communication technology tools used in the care of patients, communities, and populations.',
                        'percentage': 62
                    },
                    {
                        'name': '8.2 Use information and communication technology to gather data, create information, and generate knowledge.',
                        'percentage': 56
                    },
                    {
                        'name': '8.3 Use information and communication technologies and informatics processes to deliver safe nursing care to diverse populations in a variety of settings.',
                        'percentage': 67
                    },
                    {
                        'name': '8.4 Use information and communication technology to support documentation of care and communication among providers, patients, and all system levels.',
                        'percentage': 69
                    },
                    {
                        'name': '8.5 Use information and communication technologies in accordance with ethical, legal, professional, and regulatory standards, and workplace policies in the delivery of care.',
                        'percentage': 85
                    }]
                },
                {
                    'mainCompetency': 'Domain 9: Professionalism',
                    'percentage': 82,
                    'subCompetencies': [{
                        'name': '9.1 Demonstrate an ethical comportment in one’s practice reflective of nursing’s mission to society.',
                        'percentage': 84
                    },
                    {
                        'name': '9.2 Employ a participatory approach to nursing care.',
                        'percentage': 82
                    },
                    {
                        'name': '9.3 Demonstrate accountability to the individual, society, and the profession.',
                        'percentage': 86
                    },
                    {
                        'name': '9.4 Comply with relevant laws, policies, and regulations.',
                        'percentage': 56
                    },
                    {
                        'name': '9.5 Demonstrate the professional identity of nursing.',
                        'percentage': 54
                    },
                    {
                        'name': '9.6 Integrate diversity, equity, and inclusion as core to one’s professional identity.',
                        'percentage': 67
                    }]
                },
                {
                    'mainCompetency': 'Domain 10: Personal, Professional, and Leadership Development',
                    'percentage': 73,
                    'subCompetencies': [{
                        'name': '10.1 Demonstrate a commitment to personal health and well-being.',
                        'percentage': 57
                    },
                    {
                        'name': '10.2 Demonstrate a spirit of inquiry that fosters flexibility and professional maturity.',
                        'percentage': 61
                    },
                    { 'name': '10.3 Develop capacity for leadership.', 'percentage': 53 }]
                }],
                'Course C': [{
                    'mainCompetency': 'Domain 1: Knowledge for Nursing Practice',
                    'percentage': 70,
                    'subCompetencies': [{
                        'name': '1.1 Demonstrate an understanding of the discipline of nursing’s distinct perspective and where shared perspectives exist with other disciplines',
                        'percentage': 50
                    },
                    {
                        'name': '1.2 Apply theory and research-based knowledge from nursing, the arts, humanities, and other sciences.',
                        'percentage': 88
                    },
                    {
                        'name': '1.3 Demonstrate clinical judgment founded on a broad knowledge base.',
                        'percentage': 54
                    }]
                },
                {
                    'mainCompetency': 'Domain 2: Person-Centered Care',
                    'percentage': 80,
                    'subCompetencies': [{
                        'name': '2.1 Engage with the individual in establishing a caring relationship.',
                        'percentage': 57
                    },
                    {
                        'name': '2.2 Communicate effectively with individuals.',
                        'percentage': 98
                    },
                    { 'name': '2.3 Integrate assessment skills in practice.', 'percentage': 89 },
                    {
                        'name': '2.4 Diagnose actual or potential health problems and needs.',
                        'percentage': 59
                    },
                    { 'name': '2.5 Develop a plan of care.', 'percentage': 83 },
                    {
                        'name': '2.6 Demonstrate accountability for care delivery.',
                        'percentage': 70
                    },
                    { 'name': '2.7 Evaluate outcomes of care.', 'percentage': 91 },
                    { 'name': '2.8 Promote self-care management.', 'percentage': 69 },
                    { 'name': '2.9 Provide care coordination.', 'percentage': 66 }]
                },
                {
                    'mainCompetency': 'Domain 3: Population Health',
                    'percentage': 60,
                    'subCompetencies': [{
                        'name': '3.1 Manage population health.',
                        'percentage': 78
                    },
                    { 'name': '3.2 Engage in effective partnerships.', 'percentage': 69 },
                    {
                        'name': '3.3 Consider the socioeconomic impact of the delivery of health care.',
                        'percentage': 74
                    },
                    {
                        'name': '3.4 Advance equitable population health policy.',
                        'percentage': 75
                    },
                    { 'name': '3.5 Demonstrate advocacy strategies.', 'percentage': 93 },
                    {
                        'name': '3.6 Advance preparedness to protect population health during disasters and public health emergencies.',
                        'percentage': 88
                    }]
                },
                {
                    'mainCompetency': 'Domain 4: Scholarship for Nursing Discipline',
                    'percentage': 75,
                    'subCompetencies': [{
                        'name': '4.1 Advance the scholarship of nursing.',
                        'percentage': 71
                    },
                    {
                        'name': '4.2 Integrate best evidence into nursing practice.',
                        'percentage': 66
                    },
                    {
                        'name': '4.3 Promote the ethical conduct of scholarly activities.',
                        'percentage': 70
                    }]
                },
                {
                    'mainCompetency': 'Domain 5: Quality and Safety',
                    'percentage': 85,
                    'subCompetencies': [{
                        'name': '5.1 Apply quality improvement principles in care delivery.',
                        'percentage': 86
                    },
                    {
                        'name': '5.2 Contribute to a culture of patient safety.',
                        'percentage': 85
                    },
                    {
                        'name': '5.3 Contribute to a culture of provider and work environment safety.',
                        'percentage': 94
                    }]
                },
                {
                    'mainCompetency': 'Domain 6: Interprofessional Partnerships',
                    'percentage': 70,
                    'subCompetencies': [{
                        'name': '6.1 Communicate in a manner that facilitates a partnership approach to quality care delivery.',
                        'percentage': 79
                    },
                    {
                        'name': '6.2 Perform effectively in different team roles, using principles and values of team dynamics.',
                        'percentage': 67
                    },
                    {
                        'name': '6.3 Use knowledge of nursing and other professions to address healthcare needs.',
                        'percentage': 63
                    },
                    {
                        'name': '6.4 Work with other professions to maintain a climate of mutual learning, respect, and shared values.',
                        'percentage': 55
                    }]
                },
                {
                    'mainCompetency': 'Domain 7: Systems-Based Practice',
                    'percentage': 65,
                    'subCompetencies': [{
                        'name': '7.1 Apply knowledge of systems to work effectively across the continuum of care.',
                        'percentage': 75
                    },
                    {
                        'name': '7.2 Incorporate consideration of cost-effectiveness of care.',
                        'percentage': 79
                    },
                    {
                        'name': '7.3 Optimize system effectiveness through application of innovation and evidence-based practice.',
                        'percentage': 82
                    }]
                },
                {
                    'mainCompetency': 'Domain 8: Informatics and Healthcare Technologies',
                    'percentage': 78,
                    'subCompetencies': [{
                        'name': '8.1 Describe the various information and communication technology tools used in the care of patients, communities, and populations.',
                        'percentage': 53
                    },
                    {
                        'name': '8.2 Use information and communication technology to gather data, create information, and generate knowledge.',
                        'percentage': 95
                    },
                    {
                        'name': '8.3 Use information and communication technologies and informatics processes to deliver safe nursing care to diverse populations in a variety of settings.',
                        'percentage': 69
                    },
                    {
                        'name': '8.4 Use information and communication technology to support documentation of care and communication among providers, patients, and all system levels.',
                        'percentage': 55
                    },
                    {
                        'name': '8.5 Use information and communication technologies in accordance with ethical, legal, professional, and regulatory standards, and workplace policies in the delivery of care.',
                        'percentage': 99
                    }]
                },
                {
                    'mainCompetency': 'Domain 9: Professionalism',
                    'percentage': 82,
                    'subCompetencies': [{
                        'name': '9.1 Demonstrate an ethical comportment in one’s practice reflective of nursing’s mission to society.',
                        'percentage': 77
                    },
                    {
                        'name': '9.2 Employ a participatory approach to nursing care.',
                        'percentage': 98
                    },
                    {
                        'name': '9.3 Demonstrate accountability to the individual, society, and the profession.',
                        'percentage': 65
                    },
                    {
                        'name': '9.4 Comply with relevant laws, policies, and regulations.',
                        'percentage': 92
                    },
                    {
                        'name': '9.5 Demonstrate the professional identity of nursing.',
                        'percentage': 66
                    },
                    {
                        'name': '9.6 Integrate diversity, equity, and inclusion as core to one’s professional identity.',
                        'percentage': 52
                    }]
                },
                {
                    'mainCompetency': 'Domain 10: Personal, Professional, and Leadership Development',
                    'percentage': 73,
                    'subCompetencies': [{
                        'name': '10.1 Demonstrate a commitment to personal health and well-being.',
                        'percentage': 67
                    },
                    {
                        'name': '10.2 Demonstrate a spirit of inquiry that fosters flexibility and professional maturity.',
                        'percentage': 75
                    },
                    { 'name': '10.3 Develop capacity for leadership.', 'percentage': 67 }]
                }]
            },
        },
        "Student B": {
            courses: {
                'Course A': [{
                    'mainCompetency': 'Domain 1: Knowledge for Nursing Practice',
                    'percentage': 70,
                    'subCompetencies': [{
                        'name': '1.1 Demonstrate an understanding of the discipline of nursing’s distinct perspective and where shared perspectives exist with other disciplines',
                        'percentage': 89
                    },
                    {
                        'name': '1.2 Apply theory and research-based knowledge from nursing, the arts, humanities, and other sciences.',
                        'percentage': 85
                    },
                    {
                        'name': '1.3 Demonstrate clinical judgment founded on a broad knowledge base.',
                        'percentage': 76
                    }]
                },
                {
                    'mainCompetency': 'Domain 2: Person-Centered Care',
                    'percentage': 80,
                    'subCompetencies': [{
                        'name': '2.1 Engage with the individual in establishing a caring relationship.',
                        'percentage': 52
                    },
                    {
                        'name': '2.2 Communicate effectively with individuals.',
                        'percentage': 88
                    },
                    { 'name': '2.3 Integrate assessment skills in practice.', 'percentage': 91 },
                    {
                        'name': '2.4 Diagnose actual or potential health problems and needs.',
                        'percentage': 91
                    },
                    { 'name': '2.5 Develop a plan of care.', 'percentage': 72 },
                    {
                        'name': '2.6 Demonstrate accountability for care delivery.',
                        'percentage': 66
                    },
                    { 'name': '2.7 Evaluate outcomes of care.', 'percentage': 64 },
                    { 'name': '2.8 Promote self-care management.', 'percentage': 77 },
                    { 'name': '2.9 Provide care coordination.', 'percentage': 83 }]
                },
                {
                    'mainCompetency': 'Domain 3: Population Health',
                    'percentage': 60,
                    'subCompetencies': [{
                        'name': '3.1 Manage population health.',
                        'percentage': 66
                    },
                    { 'name': '3.2 Engage in effective partnerships.', 'percentage': 55 },
                    {
                        'name': '3.3 Consider the socioeconomic impact of the delivery of health care.',
                        'percentage': 77
                    },
                    {
                        'name': '3.4 Advance equitable population health policy.',
                        'percentage': 92
                    },
                    { 'name': '3.5 Demonstrate advocacy strategies.', 'percentage': 74 },
                    {
                        'name': '3.6 Advance preparedness to protect population health during disasters and public health emergencies.',
                        'percentage': 94
                    }]
                },
                {
                    'mainCompetency': 'Domain 4: Scholarship for Nursing Discipline',
                    'percentage': 75,
                    'subCompetencies': [{
                        'name': '4.1 Advance the scholarship of nursing.',
                        'percentage': 96
                    },
                    {
                        'name': '4.2 Integrate best evidence into nursing practice.',
                        'percentage': 91
                    },
                    {
                        'name': '4.3 Promote the ethical conduct of scholarly activities.',
                        'percentage': 93
                    }]
                },
                {
                    'mainCompetency': 'Domain 5: Quality and Safety',
                    'percentage': 85,
                    'subCompetencies': [{
                        'name': '5.1 Apply quality improvement principles in care delivery.',
                        'percentage': 66
                    },
                    {
                        'name': '5.2 Contribute to a culture of patient safety.',
                        'percentage': 59
                    },
                    {
                        'name': '5.3 Contribute to a culture of provider and work environment safety.',
                        'percentage': 64
                    }]
                },
                {
                    'mainCompetency': 'Domain 6: Interprofessional Partnerships',
                    'percentage': 70,
                    'subCompetencies': [{
                        'name': '6.1 Communicate in a manner that facilitates a partnership approach to quality care delivery.',
                        'percentage': 60
                    },
                    {
                        'name': '6.2 Perform effectively in different team roles, using principles and values of team dynamics.',
                        'percentage': 59
                    },
                    {
                        'name': '6.3 Use knowledge of nursing and other professions to address healthcare needs.',
                        'percentage': 59
                    },
                    {
                        'name': '6.4 Work with other professions to maintain a climate of mutual learning, respect, and shared values.',
                        'percentage': 73
                    }]
                },
                {
                    'mainCompetency': 'Domain 7: Systems-Based Practice',
                    'percentage': 65,
                    'subCompetencies': [{
                        'name': '7.1 Apply knowledge of systems to work effectively across the continuum of care.',
                        'percentage': 70
                    },
                    {
                        'name': '7.2 Incorporate consideration of cost-effectiveness of care.',
                        'percentage': 74
                    },
                    {
                        'name': '7.3 Optimize system effectiveness through application of innovation and evidence-based practice.',
                        'percentage': 73
                    }]
                },
                {
                    'mainCompetency': 'Domain 8: Informatics and Healthcare Technologies',
                    'percentage': 78,
                    'subCompetencies': [{
                        'name': '8.1 Describe the various information and communication technology tools used in the care of patients, communities, and populations.',
                        'percentage': 56
                    },
                    {
                        'name': '8.2 Use information and communication technology to gather data, create information, and generate knowledge.',
                        'percentage': 63
                    },
                    {
                        'name': '8.3 Use information and communication technologies and informatics processes to deliver safe nursing care to diverse populations in a variety of settings.',
                        'percentage': 85
                    },
                    {
                        'name': '8.4 Use information and communication technology to support documentation of care and communication among providers, patients, and all system levels.',
                        'percentage': 58
                    },
                    {
                        'name': '8.5 Use information and communication technologies in accordance with ethical, legal, professional, and regulatory standards, and workplace policies in the delivery of care.',
                        'percentage': 82
                    }]
                },
                {
                    'mainCompetency': 'Domain 9: Professionalism',
                    'percentage': 82,
                    'subCompetencies': [{
                        'name': '9.1 Demonstrate an ethical comportment in one’s practice reflective of nursing’s mission to society.',
                        'percentage': 83
                    },
                    {
                        'name': '9.2 Employ a participatory approach to nursing care.',
                        'percentage': 50
                    },
                    {
                        'name': '9.3 Demonstrate accountability to the individual, society, and the profession.',
                        'percentage': 79
                    },
                    {
                        'name': '9.4 Comply with relevant laws, policies, and regulations.',
                        'percentage': 61
                    },
                    {
                        'name': '9.5 Demonstrate the professional identity of nursing.',
                        'percentage': 71
                    },
                    {
                        'name': '9.6 Integrate diversity, equity, and inclusion as core to one’s professional identity.',
                        'percentage': 88
                    }]
                },
                {
                    'mainCompetency': 'Domain 10: Personal, Professional, and Leadership Development',
                    'percentage': 73,
                    'subCompetencies': [{
                        'name': '10.1 Demonstrate a commitment to personal health and well-being.',
                        'percentage': 93
                    },
                    {
                        'name': '10.2 Demonstrate a spirit of inquiry that fosters flexibility and professional maturity.',
                        'percentage': 50
                    },
                    { 'name': '10.3 Develop capacity for leadership.', 'percentage': 54 }]
                }],
                'Course B': [{
                    'mainCompetency': 'Domain 1: Knowledge for Nursing Practice',
                    'percentage': 70,
                    'subCompetencies': [{
                        'name': '1.1 Demonstrate an understanding of the discipline of nursing’s distinct perspective and where shared perspectives exist with other disciplines',
                        'percentage': 97
                    },
                    {
                        'name': '1.2 Apply theory and research-based knowledge from nursing, the arts, humanities, and other sciences.',
                        'percentage': 99
                    },
                    {
                        'name': '1.3 Demonstrate clinical judgment founded on a broad knowledge base.',
                        'percentage': 76
                    }]
                },
                {
                    'mainCompetency': 'Domain 2: Person-Centered Care',
                    'percentage': 80,
                    'subCompetencies': [{
                        'name': '2.1 Engage with the individual in establishing a caring relationship.',
                        'percentage': 82
                    },
                    {
                        'name': '2.2 Communicate effectively with individuals.',
                        'percentage': 73
                    },
                    { 'name': '2.3 Integrate assessment skills in practice.', 'percentage': 70 },
                    {
                        'name': '2.4 Diagnose actual or potential health problems and needs.',
                        'percentage': 76
                    },
                    { 'name': '2.5 Develop a plan of care.', 'percentage': 61 },
                    {
                        'name': '2.6 Demonstrate accountability for care delivery.',
                        'percentage': 62
                    },
                    { 'name': '2.7 Evaluate outcomes of care.', 'percentage': 82 },
                    { 'name': '2.8 Promote self-care management.', 'percentage': 55 },
                    { 'name': '2.9 Provide care coordination.', 'percentage': 73 }]
                },
                {
                    'mainCompetency': 'Domain 3: Population Health',
                    'percentage': 60,
                    'subCompetencies': [{
                        'name': '3.1 Manage population health.',
                        'percentage': 70
                    },
                    { 'name': '3.2 Engage in effective partnerships.', 'percentage': 78 },
                    {
                        'name': '3.3 Consider the socioeconomic impact of the delivery of health care.',
                        'percentage': 82
                    },
                    {
                        'name': '3.4 Advance equitable population health policy.',
                        'percentage': 83
                    },
                    { 'name': '3.5 Demonstrate advocacy strategies.', 'percentage': 91 },
                    {
                        'name': '3.6 Advance preparedness to protect population health during disasters and public health emergencies.',
                        'percentage': 90
                    }]
                },
                {
                    'mainCompetency': 'Domain 4: Scholarship for Nursing Discipline',
                    'percentage': 75,
                    'subCompetencies': [{
                        'name': '4.1 Advance the scholarship of nursing.',
                        'percentage': 85
                    },
                    {
                        'name': '4.2 Integrate best evidence into nursing practice.',
                        'percentage': 57
                    },
                    {
                        'name': '4.3 Promote the ethical conduct of scholarly activities.',
                        'percentage': 86
                    }]
                },
                {
                    'mainCompetency': 'Domain 5: Quality and Safety',
                    'percentage': 85,
                    'subCompetencies': [{
                        'name': '5.1 Apply quality improvement principles in care delivery.',
                        'percentage': 75
                    },
                    {
                        'name': '5.2 Contribute to a culture of patient safety.',
                        'percentage': 98
                    },
                    {
                        'name': '5.3 Contribute to a culture of provider and work environment safety.',
                        'percentage': 86
                    }]
                },
                {
                    'mainCompetency': 'Domain 6: Interprofessional Partnerships',
                    'percentage': 70,
                    'subCompetencies': [{
                        'name': '6.1 Communicate in a manner that facilitates a partnership approach to quality care delivery.',
                        'percentage': 93
                    },
                    {
                        'name': '6.2 Perform effectively in different team roles, using principles and values of team dynamics.',
                        'percentage': 62
                    },
                    {
                        'name': '6.3 Use knowledge of nursing and other professions to address healthcare needs.',
                        'percentage': 64
                    },
                    {
                        'name': '6.4 Work with other professions to maintain a climate of mutual learning, respect, and shared values.',
                        'percentage': 94
                    }]
                },
                {
                    'mainCompetency': 'Domain 7: Systems-Based Practice',
                    'percentage': 65,
                    'subCompetencies': [{
                        'name': '7.1 Apply knowledge of systems to work effectively across the continuum of care.',
                        'percentage': 74
                    },
                    {
                        'name': '7.2 Incorporate consideration of cost-effectiveness of care.',
                        'percentage': 72
                    },
                    {
                        'name': '7.3 Optimize system effectiveness through application of innovation and evidence-based practice.',
                        'percentage': 54
                    }]
                },
                {
                    'mainCompetency': 'Domain 8: Informatics and Healthcare Technologies',
                    'percentage': 78,
                    'subCompetencies': [{
                        'name': '8.1 Describe the various information and communication technology tools used in the care of patients, communities, and populations.',
                        'percentage': 62
                    },
                    {
                        'name': '8.2 Use information and communication technology to gather data, create information, and generate knowledge.',
                        'percentage': 56
                    },
                    {
                        'name': '8.3 Use information and communication technologies and informatics processes to deliver safe nursing care to diverse populations in a variety of settings.',
                        'percentage': 67
                    },
                    {
                        'name': '8.4 Use information and communication technology to support documentation of care and communication among providers, patients, and all system levels.',
                        'percentage': 69
                    },
                    {
                        'name': '8.5 Use information and communication technologies in accordance with ethical, legal, professional, and regulatory standards, and workplace policies in the delivery of care.',
                        'percentage': 85
                    }]
                },
                {
                    'mainCompetency': 'Domain 9: Professionalism',
                    'percentage': 82,
                    'subCompetencies': [{
                        'name': '9.1 Demonstrate an ethical comportment in one’s practice reflective of nursing’s mission to society.',
                        'percentage': 84
                    },
                    {
                        'name': '9.2 Employ a participatory approach to nursing care.',
                        'percentage': 82
                    },
                    {
                        'name': '9.3 Demonstrate accountability to the individual, society, and the profession.',
                        'percentage': 86
                    },
                    {
                        'name': '9.4 Comply with relevant laws, policies, and regulations.',
                        'percentage': 56
                    },
                    {
                        'name': '9.5 Demonstrate the professional identity of nursing.',
                        'percentage': 54
                    },
                    {
                        'name': '9.6 Integrate diversity, equity, and inclusion as core to one’s professional identity.',
                        'percentage': 67
                    }]
                },
                {
                    'mainCompetency': 'Domain 10: Personal, Professional, and Leadership Development',
                    'percentage': 73,
                    'subCompetencies': [{
                        'name': '10.1 Demonstrate a commitment to personal health and well-being.',
                        'percentage': 57
                    },
                    {
                        'name': '10.2 Demonstrate a spirit of inquiry that fosters flexibility and professional maturity.',
                        'percentage': 61
                    },
                    { 'name': '10.3 Develop capacity for leadership.', 'percentage': 53 }]
                }],
                'Course C': [{
                    'mainCompetency': 'Domain 1: Knowledge for Nursing Practice',
                    'percentage': 70,
                    'subCompetencies': [{
                        'name': '1.1 Demonstrate an understanding of the discipline of nursing’s distinct perspective and where shared perspectives exist with other disciplines',
                        'percentage': 50
                    },
                    {
                        'name': '1.2 Apply theory and research-based knowledge from nursing, the arts, humanities, and other sciences.',
                        'percentage': 88
                    },
                    {
                        'name': '1.3 Demonstrate clinical judgment founded on a broad knowledge base.',
                        'percentage': 54
                    }]
                },
                {
                    'mainCompetency': 'Domain 2: Person-Centered Care',
                    'percentage': 80,
                    'subCompetencies': [{
                        'name': '2.1 Engage with the individual in establishing a caring relationship.',
                        'percentage': 57
                    },
                    {
                        'name': '2.2 Communicate effectively with individuals.',
                        'percentage': 98
                    },
                    { 'name': '2.3 Integrate assessment skills in practice.', 'percentage': 89 },
                    {
                        'name': '2.4 Diagnose actual or potential health problems and needs.',
                        'percentage': 59
                    },
                    { 'name': '2.5 Develop a plan of care.', 'percentage': 83 },
                    {
                        'name': '2.6 Demonstrate accountability for care delivery.',
                        'percentage': 70
                    },
                    { 'name': '2.7 Evaluate outcomes of care.', 'percentage': 91 },
                    { 'name': '2.8 Promote self-care management.', 'percentage': 69 },
                    { 'name': '2.9 Provide care coordination.', 'percentage': 66 }]
                },
                {
                    'mainCompetency': 'Domain 3: Population Health',
                    'percentage': 60,
                    'subCompetencies': [{
                        'name': '3.1 Manage population health.',
                        'percentage': 78
                    },
                    { 'name': '3.2 Engage in effective partnerships.', 'percentage': 69 },
                    {
                        'name': '3.3 Consider the socioeconomic impact of the delivery of health care.',
                        'percentage': 74
                    },
                    {
                        'name': '3.4 Advance equitable population health policy.',
                        'percentage': 75
                    },
                    { 'name': '3.5 Demonstrate advocacy strategies.', 'percentage': 93 },
                    {
                        'name': '3.6 Advance preparedness to protect population health during disasters and public health emergencies.',
                        'percentage': 88
                    }]
                },
                {
                    'mainCompetency': 'Domain 4: Scholarship for Nursing Discipline',
                    'percentage': 75,
                    'subCompetencies': [{
                        'name': '4.1 Advance the scholarship of nursing.',
                        'percentage': 71
                    },
                    {
                        'name': '4.2 Integrate best evidence into nursing practice.',
                        'percentage': 66
                    },
                    {
                        'name': '4.3 Promote the ethical conduct of scholarly activities.',
                        'percentage': 70
                    }]
                },
                {
                    'mainCompetency': 'Domain 5: Quality and Safety',
                    'percentage': 85,
                    'subCompetencies': [{
                        'name': '5.1 Apply quality improvement principles in care delivery.',
                        'percentage': 86
                    },
                    {
                        'name': '5.2 Contribute to a culture of patient safety.',
                        'percentage': 85
                    },
                    {
                        'name': '5.3 Contribute to a culture of provider and work environment safety.',
                        'percentage': 94
                    }]
                },
                {
                    'mainCompetency': 'Domain 6: Interprofessional Partnerships',
                    'percentage': 70,
                    'subCompetencies': [{
                        'name': '6.1 Communicate in a manner that facilitates a partnership approach to quality care delivery.',
                        'percentage': 79
                    },
                    {
                        'name': '6.2 Perform effectively in different team roles, using principles and values of team dynamics.',
                        'percentage': 67
                    },
                    {
                        'name': '6.3 Use knowledge of nursing and other professions to address healthcare needs.',
                        'percentage': 63
                    },
                    {
                        'name': '6.4 Work with other professions to maintain a climate of mutual learning, respect, and shared values.',
                        'percentage': 55
                    }]
                },
                {
                    'mainCompetency': 'Domain 7: Systems-Based Practice',
                    'percentage': 65,
                    'subCompetencies': [{
                        'name': '7.1 Apply knowledge of systems to work effectively across the continuum of care.',
                        'percentage': 75
                    },
                    {
                        'name': '7.2 Incorporate consideration of cost-effectiveness of care.',
                        'percentage': 79
                    },
                    {
                        'name': '7.3 Optimize system effectiveness through application of innovation and evidence-based practice.',
                        'percentage': 82
                    }]
                },
                {
                    'mainCompetency': 'Domain 8: Informatics and Healthcare Technologies',
                    'percentage': 78,
                    'subCompetencies': [{
                        'name': '8.1 Describe the various information and communication technology tools used in the care of patients, communities, and populations.',
                        'percentage': 53
                    },
                    {
                        'name': '8.2 Use information and communication technology to gather data, create information, and generate knowledge.',
                        'percentage': 95
                    },
                    {
                        'name': '8.3 Use information and communication technologies and informatics processes to deliver safe nursing care to diverse populations in a variety of settings.',
                        'percentage': 69
                    },
                    {
                        'name': '8.4 Use information and communication technology to support documentation of care and communication among providers, patients, and all system levels.',
                        'percentage': 55
                    },
                    {
                        'name': '8.5 Use information and communication technologies in accordance with ethical, legal, professional, and regulatory standards, and workplace policies in the delivery of care.',
                        'percentage': 99
                    }]
                },
                {
                    'mainCompetency': 'Domain 9: Professionalism',
                    'percentage': 82,
                    'subCompetencies': [{
                        'name': '9.1 Demonstrate an ethical comportment in one’s practice reflective of nursing’s mission to society.',
                        'percentage': 77
                    },
                    {
                        'name': '9.2 Employ a participatory approach to nursing care.',
                        'percentage': 98
                    },
                    {
                        'name': '9.3 Demonstrate accountability to the individual, society, and the profession.',
                        'percentage': 65
                    },
                    {
                        'name': '9.4 Comply with relevant laws, policies, and regulations.',
                        'percentage': 92
                    },
                    {
                        'name': '9.5 Demonstrate the professional identity of nursing.',
                        'percentage': 66
                    },
                    {
                        'name': '9.6 Integrate diversity, equity, and inclusion as core to one’s professional identity.',
                        'percentage': 52
                    }]
                },
                {
                    'mainCompetency': 'Domain 10: Personal, Professional, and Leadership Development',
                    'percentage': 73,
                    'subCompetencies': [{
                        'name': '10.1 Demonstrate a commitment to personal health and well-being.',
                        'percentage': 67
                    },
                    {
                        'name': '10.2 Demonstrate a spirit of inquiry that fosters flexibility and professional maturity.',
                        'percentage': 75
                    },
                    { 'name': '10.3 Develop capacity for leadership.', 'percentage': 67 }]
                }]
            },
        },
        "Student C": {
            courses: {
                'Course A': [{
                    'mainCompetency': 'Domain 1: Knowledge for Nursing Practice',
                    'percentage': 70,
                    'subCompetencies': [{
                        'name': '1.1 Demonstrate an understanding of the discipline of nursing’s distinct perspective and where shared perspectives exist with other disciplines',
                        'percentage': 89
                    },
                    {
                        'name': '1.2 Apply theory and research-based knowledge from nursing, the arts, humanities, and other sciences.',
                        'percentage': 85
                    },
                    {
                        'name': '1.3 Demonstrate clinical judgment founded on a broad knowledge base.',
                        'percentage': 76
                    }]
                },
                {
                    'mainCompetency': 'Domain 2: Person-Centered Care',
                    'percentage': 80,
                    'subCompetencies': [{
                        'name': '2.1 Engage with the individual in establishing a caring relationship.',
                        'percentage': 52
                    },
                    {
                        'name': '2.2 Communicate effectively with individuals.',
                        'percentage': 88
                    },
                    { 'name': '2.3 Integrate assessment skills in practice.', 'percentage': 91 },
                    {
                        'name': '2.4 Diagnose actual or potential health problems and needs.',
                        'percentage': 91
                    },
                    { 'name': '2.5 Develop a plan of care.', 'percentage': 72 },
                    {
                        'name': '2.6 Demonstrate accountability for care delivery.',
                        'percentage': 66
                    },
                    { 'name': '2.7 Evaluate outcomes of care.', 'percentage': 64 },
                    { 'name': '2.8 Promote self-care management.', 'percentage': 77 },
                    { 'name': '2.9 Provide care coordination.', 'percentage': 83 }]
                },
                {
                    'mainCompetency': 'Domain 3: Population Health',
                    'percentage': 60,
                    'subCompetencies': [{
                        'name': '3.1 Manage population health.',
                        'percentage': 66
                    },
                    { 'name': '3.2 Engage in effective partnerships.', 'percentage': 55 },
                    {
                        'name': '3.3 Consider the socioeconomic impact of the delivery of health care.',
                        'percentage': 77
                    },
                    {
                        'name': '3.4 Advance equitable population health policy.',
                        'percentage': 92
                    },
                    { 'name': '3.5 Demonstrate advocacy strategies.', 'percentage': 74 },
                    {
                        'name': '3.6 Advance preparedness to protect population health during disasters and public health emergencies.',
                        'percentage': 94
                    }]
                },
                {
                    'mainCompetency': 'Domain 4: Scholarship for Nursing Discipline',
                    'percentage': 75,
                    'subCompetencies': [{
                        'name': '4.1 Advance the scholarship of nursing.',
                        'percentage': 96
                    },
                    {
                        'name': '4.2 Integrate best evidence into nursing practice.',
                        'percentage': 91
                    },
                    {
                        'name': '4.3 Promote the ethical conduct of scholarly activities.',
                        'percentage': 93
                    }]
                },
                {
                    'mainCompetency': 'Domain 5: Quality and Safety',
                    'percentage': 85,
                    'subCompetencies': [{
                        'name': '5.1 Apply quality improvement principles in care delivery.',
                        'percentage': 66
                    },
                    {
                        'name': '5.2 Contribute to a culture of patient safety.',
                        'percentage': 59
                    },
                    {
                        'name': '5.3 Contribute to a culture of provider and work environment safety.',
                        'percentage': 64
                    }]
                },
                {
                    'mainCompetency': 'Domain 6: Interprofessional Partnerships',
                    'percentage': 70,
                    'subCompetencies': [{
                        'name': '6.1 Communicate in a manner that facilitates a partnership approach to quality care delivery.',
                        'percentage': 60
                    },
                    {
                        'name': '6.2 Perform effectively in different team roles, using principles and values of team dynamics.',
                        'percentage': 59
                    },
                    {
                        'name': '6.3 Use knowledge of nursing and other professions to address healthcare needs.',
                        'percentage': 59
                    },
                    {
                        'name': '6.4 Work with other professions to maintain a climate of mutual learning, respect, and shared values.',
                        'percentage': 73
                    }]
                },
                {
                    'mainCompetency': 'Domain 7: Systems-Based Practice',
                    'percentage': 65,
                    'subCompetencies': [{
                        'name': '7.1 Apply knowledge of systems to work effectively across the continuum of care.',
                        'percentage': 70
                    },
                    {
                        'name': '7.2 Incorporate consideration of cost-effectiveness of care.',
                        'percentage': 74
                    },
                    {
                        'name': '7.3 Optimize system effectiveness through application of innovation and evidence-based practice.',
                        'percentage': 73
                    }]
                },
                {
                    'mainCompetency': 'Domain 8: Informatics and Healthcare Technologies',
                    'percentage': 78,
                    'subCompetencies': [{
                        'name': '8.1 Describe the various information and communication technology tools used in the care of patients, communities, and populations.',
                        'percentage': 56
                    },
                    {
                        'name': '8.2 Use information and communication technology to gather data, create information, and generate knowledge.',
                        'percentage': 63
                    },
                    {
                        'name': '8.3 Use information and communication technologies and informatics processes to deliver safe nursing care to diverse populations in a variety of settings.',
                        'percentage': 85
                    },
                    {
                        'name': '8.4 Use information and communication technology to support documentation of care and communication among providers, patients, and all system levels.',
                        'percentage': 58
                    },
                    {
                        'name': '8.5 Use information and communication technologies in accordance with ethical, legal, professional, and regulatory standards, and workplace policies in the delivery of care.',
                        'percentage': 82
                    }]
                },
                {
                    'mainCompetency': 'Domain 9: Professionalism',
                    'percentage': 82,
                    'subCompetencies': [{
                        'name': '9.1 Demonstrate an ethical comportment in one’s practice reflective of nursing’s mission to society.',
                        'percentage': 83
                    },
                    {
                        'name': '9.2 Employ a participatory approach to nursing care.',
                        'percentage': 50
                    },
                    {
                        'name': '9.3 Demonstrate accountability to the individual, society, and the profession.',
                        'percentage': 79
                    },
                    {
                        'name': '9.4 Comply with relevant laws, policies, and regulations.',
                        'percentage': 61
                    },
                    {
                        'name': '9.5 Demonstrate the professional identity of nursing.',
                        'percentage': 71
                    },
                    {
                        'name': '9.6 Integrate diversity, equity, and inclusion as core to one’s professional identity.',
                        'percentage': 88
                    }]
                },
                {
                    'mainCompetency': 'Domain 10: Personal, Professional, and Leadership Development',
                    'percentage': 73,
                    'subCompetencies': [{
                        'name': '10.1 Demonstrate a commitment to personal health and well-being.',
                        'percentage': 93
                    },
                    {
                        'name': '10.2 Demonstrate a spirit of inquiry that fosters flexibility and professional maturity.',
                        'percentage': 50
                    },
                    { 'name': '10.3 Develop capacity for leadership.', 'percentage': 54 }]
                }],
                'Course B': [{
                    'mainCompetency': 'Domain 1: Knowledge for Nursing Practice',
                    'percentage': 70,
                    'subCompetencies': [{
                        'name': '1.1 Demonstrate an understanding of the discipline of nursing’s distinct perspective and where shared perspectives exist with other disciplines',
                        'percentage': 97
                    },
                    {
                        'name': '1.2 Apply theory and research-based knowledge from nursing, the arts, humanities, and other sciences.',
                        'percentage': 99
                    },
                    {
                        'name': '1.3 Demonstrate clinical judgment founded on a broad knowledge base.',
                        'percentage': 76
                    }]
                },
                {
                    'mainCompetency': 'Domain 2: Person-Centered Care',
                    'percentage': 80,
                    'subCompetencies': [{
                        'name': '2.1 Engage with the individual in establishing a caring relationship.',
                        'percentage': 82
                    },
                    {
                        'name': '2.2 Communicate effectively with individuals.',
                        'percentage': 73
                    },
                    { 'name': '2.3 Integrate assessment skills in practice.', 'percentage': 70 },
                    {
                        'name': '2.4 Diagnose actual or potential health problems and needs.',
                        'percentage': 76
                    },
                    { 'name': '2.5 Develop a plan of care.', 'percentage': 61 },
                    {
                        'name': '2.6 Demonstrate accountability for care delivery.',
                        'percentage': 62
                    },
                    { 'name': '2.7 Evaluate outcomes of care.', 'percentage': 82 },
                    { 'name': '2.8 Promote self-care management.', 'percentage': 55 },
                    { 'name': '2.9 Provide care coordination.', 'percentage': 73 }]
                },
                {
                    'mainCompetency': 'Domain 3: Population Health',
                    'percentage': 60,
                    'subCompetencies': [{
                        'name': '3.1 Manage population health.',
                        'percentage': 70
                    },
                    { 'name': '3.2 Engage in effective partnerships.', 'percentage': 78 },
                    {
                        'name': '3.3 Consider the socioeconomic impact of the delivery of health care.',
                        'percentage': 82
                    },
                    {
                        'name': '3.4 Advance equitable population health policy.',
                        'percentage': 83
                    },
                    { 'name': '3.5 Demonstrate advocacy strategies.', 'percentage': 91 },
                    {
                        'name': '3.6 Advance preparedness to protect population health during disasters and public health emergencies.',
                        'percentage': 90
                    }]
                },
                {
                    'mainCompetency': 'Domain 4: Scholarship for Nursing Discipline',
                    'percentage': 75,
                    'subCompetencies': [{
                        'name': '4.1 Advance the scholarship of nursing.',
                        'percentage': 85
                    },
                    {
                        'name': '4.2 Integrate best evidence into nursing practice.',
                        'percentage': 57
                    },
                    {
                        'name': '4.3 Promote the ethical conduct of scholarly activities.',
                        'percentage': 86
                    }]
                },
                {
                    'mainCompetency': 'Domain 5: Quality and Safety',
                    'percentage': 85,
                    'subCompetencies': [{
                        'name': '5.1 Apply quality improvement principles in care delivery.',
                        'percentage': 75
                    },
                    {
                        'name': '5.2 Contribute to a culture of patient safety.',
                        'percentage': 98
                    },
                    {
                        'name': '5.3 Contribute to a culture of provider and work environment safety.',
                        'percentage': 86
                    }]
                },
                {
                    'mainCompetency': 'Domain 6: Interprofessional Partnerships',
                    'percentage': 70,
                    'subCompetencies': [{
                        'name': '6.1 Communicate in a manner that facilitates a partnership approach to quality care delivery.',
                        'percentage': 93
                    },
                    {
                        'name': '6.2 Perform effectively in different team roles, using principles and values of team dynamics.',
                        'percentage': 62
                    },
                    {
                        'name': '6.3 Use knowledge of nursing and other professions to address healthcare needs.',
                        'percentage': 64
                    },
                    {
                        'name': '6.4 Work with other professions to maintain a climate of mutual learning, respect, and shared values.',
                        'percentage': 94
                    }]
                },
                {
                    'mainCompetency': 'Domain 7: Systems-Based Practice',
                    'percentage': 65,
                    'subCompetencies': [{
                        'name': '7.1 Apply knowledge of systems to work effectively across the continuum of care.',
                        'percentage': 74
                    },
                    {
                        'name': '7.2 Incorporate consideration of cost-effectiveness of care.',
                        'percentage': 72
                    },
                    {
                        'name': '7.3 Optimize system effectiveness through application of innovation and evidence-based practice.',
                        'percentage': 54
                    }]
                },
                {
                    'mainCompetency': 'Domain 8: Informatics and Healthcare Technologies',
                    'percentage': 78,
                    'subCompetencies': [{
                        'name': '8.1 Describe the various information and communication technology tools used in the care of patients, communities, and populations.',
                        'percentage': 62
                    },
                    {
                        'name': '8.2 Use information and communication technology to gather data, create information, and generate knowledge.',
                        'percentage': 56
                    },
                    {
                        'name': '8.3 Use information and communication technologies and informatics processes to deliver safe nursing care to diverse populations in a variety of settings.',
                        'percentage': 67
                    },
                    {
                        'name': '8.4 Use information and communication technology to support documentation of care and communication among providers, patients, and all system levels.',
                        'percentage': 69
                    },
                    {
                        'name': '8.5 Use information and communication technologies in accordance with ethical, legal, professional, and regulatory standards, and workplace policies in the delivery of care.',
                        'percentage': 85
                    }]
                },
                {
                    'mainCompetency': 'Domain 9: Professionalism',
                    'percentage': 82,
                    'subCompetencies': [{
                        'name': '9.1 Demonstrate an ethical comportment in one’s practice reflective of nursing’s mission to society.',
                        'percentage': 84
                    },
                    {
                        'name': '9.2 Employ a participatory approach to nursing care.',
                        'percentage': 82
                    },
                    {
                        'name': '9.3 Demonstrate accountability to the individual, society, and the profession.',
                        'percentage': 86
                    },
                    {
                        'name': '9.4 Comply with relevant laws, policies, and regulations.',
                        'percentage': 56
                    },
                    {
                        'name': '9.5 Demonstrate the professional identity of nursing.',
                        'percentage': 54
                    },
                    {
                        'name': '9.6 Integrate diversity, equity, and inclusion as core to one’s professional identity.',
                        'percentage': 67
                    }]
                },
                {
                    'mainCompetency': 'Domain 10: Personal, Professional, and Leadership Development',
                    'percentage': 73,
                    'subCompetencies': [{
                        'name': '10.1 Demonstrate a commitment to personal health and well-being.',
                        'percentage': 57
                    },
                    {
                        'name': '10.2 Demonstrate a spirit of inquiry that fosters flexibility and professional maturity.',
                        'percentage': 61
                    },
                    { 'name': '10.3 Develop capacity for leadership.', 'percentage': 53 }]
                }],
                'Course C': [{
                    'mainCompetency': 'Domain 1: Knowledge for Nursing Practice',
                    'percentage': 70,
                    'subCompetencies': [{
                        'name': '1.1 Demonstrate an understanding of the discipline of nursing’s distinct perspective and where shared perspectives exist with other disciplines',
                        'percentage': 50
                    },
                    {
                        'name': '1.2 Apply theory and research-based knowledge from nursing, the arts, humanities, and other sciences.',
                        'percentage': 88
                    },
                    {
                        'name': '1.3 Demonstrate clinical judgment founded on a broad knowledge base.',
                        'percentage': 54
                    }]
                },
                {
                    'mainCompetency': 'Domain 2: Person-Centered Care',
                    'percentage': 80,
                    'subCompetencies': [{
                        'name': '2.1 Engage with the individual in establishing a caring relationship.',
                        'percentage': 57
                    },
                    {
                        'name': '2.2 Communicate effectively with individuals.',
                        'percentage': 98
                    },
                    { 'name': '2.3 Integrate assessment skills in practice.', 'percentage': 89 },
                    {
                        'name': '2.4 Diagnose actual or potential health problems and needs.',
                        'percentage': 59
                    },
                    { 'name': '2.5 Develop a plan of care.', 'percentage': 83 },
                    {
                        'name': '2.6 Demonstrate accountability for care delivery.',
                        'percentage': 70
                    },
                    { 'name': '2.7 Evaluate outcomes of care.', 'percentage': 91 },
                    { 'name': '2.8 Promote self-care management.', 'percentage': 69 },
                    { 'name': '2.9 Provide care coordination.', 'percentage': 66 }]
                },
                {
                    'mainCompetency': 'Domain 3: Population Health',
                    'percentage': 60,
                    'subCompetencies': [{
                        'name': '3.1 Manage population health.',
                        'percentage': 78
                    },
                    { 'name': '3.2 Engage in effective partnerships.', 'percentage': 69 },
                    {
                        'name': '3.3 Consider the socioeconomic impact of the delivery of health care.',
                        'percentage': 74
                    },
                    {
                        'name': '3.4 Advance equitable population health policy.',
                        'percentage': 75
                    },
                    { 'name': '3.5 Demonstrate advocacy strategies.', 'percentage': 93 },
                    {
                        'name': '3.6 Advance preparedness to protect population health during disasters and public health emergencies.',
                        'percentage': 88
                    }]
                },
                {
                    'mainCompetency': 'Domain 4: Scholarship for Nursing Discipline',
                    'percentage': 75,
                    'subCompetencies': [{
                        'name': '4.1 Advance the scholarship of nursing.',
                        'percentage': 71
                    },
                    {
                        'name': '4.2 Integrate best evidence into nursing practice.',
                        'percentage': 66
                    },
                    {
                        'name': '4.3 Promote the ethical conduct of scholarly activities.',
                        'percentage': 70
                    }]
                },
                {
                    'mainCompetency': 'Domain 5: Quality and Safety',
                    'percentage': 85,
                    'subCompetencies': [{
                        'name': '5.1 Apply quality improvement principles in care delivery.',
                        'percentage': 86
                    },
                    {
                        'name': '5.2 Contribute to a culture of patient safety.',
                        'percentage': 85
                    },
                    {
                        'name': '5.3 Contribute to a culture of provider and work environment safety.',
                        'percentage': 94
                    }]
                },
                {
                    'mainCompetency': 'Domain 6: Interprofessional Partnerships',
                    'percentage': 70,
                    'subCompetencies': [{
                        'name': '6.1 Communicate in a manner that facilitates a partnership approach to quality care delivery.',
                        'percentage': 79
                    },
                    {
                        'name': '6.2 Perform effectively in different team roles, using principles and values of team dynamics.',
                        'percentage': 67
                    },
                    {
                        'name': '6.3 Use knowledge of nursing and other professions to address healthcare needs.',
                        'percentage': 63
                    },
                    {
                        'name': '6.4 Work with other professions to maintain a climate of mutual learning, respect, and shared values.',
                        'percentage': 55
                    }]
                },
                {
                    'mainCompetency': 'Domain 7: Systems-Based Practice',
                    'percentage': 65,
                    'subCompetencies': [{
                        'name': '7.1 Apply knowledge of systems to work effectively across the continuum of care.',
                        'percentage': 75
                    },
                    {
                        'name': '7.2 Incorporate consideration of cost-effectiveness of care.',
                        'percentage': 79
                    },
                    {
                        'name': '7.3 Optimize system effectiveness through application of innovation and evidence-based practice.',
                        'percentage': 82
                    }]
                },
                {
                    'mainCompetency': 'Domain 8: Informatics and Healthcare Technologies',
                    'percentage': 78,
                    'subCompetencies': [{
                        'name': '8.1 Describe the various information and communication technology tools used in the care of patients, communities, and populations.',
                        'percentage': 53
                    },
                    {
                        'name': '8.2 Use information and communication technology to gather data, create information, and generate knowledge.',
                        'percentage': 95
                    },
                    {
                        'name': '8.3 Use information and communication technologies and informatics processes to deliver safe nursing care to diverse populations in a variety of settings.',
                        'percentage': 69
                    },
                    {
                        'name': '8.4 Use information and communication technology to support documentation of care and communication among providers, patients, and all system levels.',
                        'percentage': 55
                    },
                    {
                        'name': '8.5 Use information and communication technologies in accordance with ethical, legal, professional, and regulatory standards, and workplace policies in the delivery of care.',
                        'percentage': 99
                    }]
                },
                {
                    'mainCompetency': 'Domain 9: Professionalism',
                    'percentage': 82,
                    'subCompetencies': [{
                        'name': '9.1 Demonstrate an ethical comportment in one’s practice reflective of nursing’s mission to society.',
                        'percentage': 77
                    },
                    {
                        'name': '9.2 Employ a participatory approach to nursing care.',
                        'percentage': 98
                    },
                    {
                        'name': '9.3 Demonstrate accountability to the individual, society, and the profession.',
                        'percentage': 65
                    },
                    {
                        'name': '9.4 Comply with relevant laws, policies, and regulations.',
                        'percentage': 92
                    },
                    {
                        'name': '9.5 Demonstrate the professional identity of nursing.',
                        'percentage': 66
                    },
                    {
                        'name': '9.6 Integrate diversity, equity, and inclusion as core to one’s professional identity.',
                        'percentage': 52
                    }]
                },
                {
                    'mainCompetency': 'Domain 10: Personal, Professional, and Leadership Development',
                    'percentage': 73,
                    'subCompetencies': [{
                        'name': '10.1 Demonstrate a commitment to personal health and well-being.',
                        'percentage': 67
                    },
                    {
                        'name': '10.2 Demonstrate a spirit of inquiry that fosters flexibility and professional maturity.',
                        'percentage': 75
                    },
                    { 'name': '10.3 Develop capacity for leadership.', 'percentage': 67 }]
                }]
            },
        },
    },
};


const AANCCoreCompetencies = () => {
    const [selectedStudent, setSelectedStudent] = useState("Student A");
    const [selectedCourse, setSelectedCourse] = useState("Course A");

    const studentCourses = mockDatasets.students[selectedStudent]?.courses || {};
    const data = studentCourses[selectedCourse] || [];

    const getCompetencyChartOptions = () => {
        const chartData = data.map((competency) => ({
            mainCompetency: competency.mainCompetency,
            Percentage: competency.percentage,
        }))
            .reverse();

        return {
            tooltip: {
                trigger: "item",
                formatter: (params) => `${params.data.mainCompetency}: ${params.value.Percentage}%`,
            },
            dataset: { source: chartData },
            grid: {
                left: "2%",
                right: "2%",
                bottom: "5%",
                top: "10%",
                containLabel: true,
            },
            xAxis: {
                min: 0,
                max: 100,
                axisLabel: { formatter: "{value}%" },
                splitLine: { show: false },
            },
            yAxis: {
                type: "category",
                axisLabel: { show: false },
                splitLine: { show: false },
            },
            series: [
                {
                    type: "bar",
                    barWidth: 15,
                    encode: { x: "Percentage", y: "mainCompetency" },
                    itemStyle: {
                        borderRadius: [0, 8, 8, 0],
                        color: (params) => {
                            const colorList = [
                                "#3182CE",
                                "#48BB78",
                                "#F6AD55",
                                "#9F7AEA",
                                "#ECC94B",
                            ];
                            return colorList[params.dataIndex % colorList.length];
                        },
                    },
                    label: {
                        show: true,
                        position: "right",
                        formatter: (params) => `${params.value.Percentage}%`,
                    },
                },
            ],
        };
    };

    return (
        <div className="p-6 space-y-6">
            {/* Filters */}
            <div className="flex justify-between mb-4 space-x-4">
                <Dropdown
                    label={`Student: ${selectedStudent}`}
                    inline
                    className="text-base font-semibold text-gray-900 dark:text-white"
                >
                    {Object.keys(mockDatasets.students).map((student) => (
                        <Dropdown.Item
                            key={student}
                            onClick={() => {
                                setSelectedStudent(student);
                                const defaultCourse = Object.keys(mockDatasets.students[student]?.courses || [])[0];
                                setSelectedCourse(defaultCourse);
                            }}
                            className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center space-x-2"
                        >
                            <Checkbox
                                checked={selectedStudent === student}
                                onChange={() => setSelectedStudent(student)}
                                id={`student-checkbox-${student}`}
                                className="mr-2"
                            />
                            <label htmlFor={`student-checkbox-${student}`}>{student}</label>
                        </Dropdown.Item>
                    ))}
                </Dropdown>

                <Dropdown
                    label={`Course: ${selectedCourse}`}
                    inline
                    className="text-base font-semibold text-gray-900 dark:text-white"
                >
                    {Object.keys(studentCourses).map((course) => (
                        <Dropdown.Item
                            key={course}
                            onClick={() => setSelectedCourse(course)}
                            className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center space-x-2"
                        >
                            <Checkbox
                                checked={selectedCourse === course}
                                onChange={() => setSelectedCourse(course)}
                                id={`course-checkbox-${course}`}
                                className="mr-2"
                            />
                            <label htmlFor={`course-checkbox-${course}`}>{course}</label>
                        </Dropdown.Item>
                    ))}
                </Dropdown>
            </div>

            {/* Competency Chart */}
            <div className="bg-white border rounded-lg p-6">
                <ReactECharts option={getCompetencyChartOptions()} style={{ height: "400px" }} />
            </div>

            {/* Accordion for Domains */}
            <Accordion>
                {data.map((competency, index) => (
                    <Accordion.Panel key={index} open={index === 0}>
                        <Accordion.Title>{competency.mainCompetency}</Accordion.Title>
                        <Accordion.Content>
                            <div className="mb-2 font-semibold flex justify-between">
                                <span>Overall Competency Score</span>
                                <div className="w-12 h-12 mr-4">
                                    <CircularProgressbar
                                        value={competency.percentage}
                                        text={`${competency.percentage}%`}
                                        styles={buildStyles({
                                            textSize: "34px",
                                            pathColor: "#4f46e5", // Customize the progress bar color
                                            textColor: "#4f46e5",
                                        })}
                                    />
                                </div>

                            </div>
                            <ul className="list-disc pl-5">
                                {competency.subCompetencies.map((sub, subIndex) => (
                                    <li key={subIndex} className="mb-4 flex justify-between">
                                        <span>{sub.name}</span>
                                        <div className="w-10 h-10 mr-4">
                                            <CircularProgressbar
                                                value={sub.percentage}
                                                text={`${sub.percentage}%`}
                                                styles={buildStyles({
                                                    textSize: "30px",
                                                    pathColor: "#4ade80", // Customize the progress bar color
                                                    textColor: "#4ade80",
                                                })}
                                            />
                                        </div>

                                    </li>
                                ))}
                            </ul>
                        </Accordion.Content>
                    </Accordion.Panel>
                ))}

            </Accordion>
        </div>
    );
};

export default AANCCoreCompetencies;