import { useState } from "react";
import SaveButton from "../../components/shared/SaveButton";
import useScenarios from "../../hooks/useScenarios";
import useSchool from "../../hooks/useSchool";
import { HiHome } from "react-icons/hi";
import { Accordion } from "flowbite-react";
import DeleteButton from "../../components/shared/DeleteButton";
import { useNavigate } from "react-router-dom";


const AdminSchoolInfo = () => {
    const { school, markAsChanged, saveChanges, hasUnsavedChanges, saving, deleteSchool } = useSchool();
    const { allScenarios } = useScenarios();

    const [capacity, setCapacity] = useState(school.capacity || "");
    const [availableScenarios, setAvailableScenarios] = useState(school.availableScenarios);

    const navigate = useNavigate();

    const handleDelete = () => {
        deleteSchool(school.id);
        navigate(`school-selection`);
    }

    return (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
            {/* Header Section */}
            <div className="flex justify-between items-center mb-4 border-b pb-4 border-gray-200 dark:border-gray-700">
                <div className="flex items-center space-x-2">
                    <HiHome className="text-4xl text-gray-700 dark:text-gray-300" />
                    <h1 className="pl-5 text-2xl font-semibold text-gray-900 dark:text-white">School Details</h1>
                </div>
                <SaveButton hasUnsavedChanges={hasUnsavedChanges} saveChanges={() => { saveChanges(Number(capacity) ?? 0, availableScenarios) }} saving={saving} />
            </div>

            {/* Capacity Input */}
            <div className="mb-4">
                <label htmlFor="capacity" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Capacity:</label>
                <input
                    type="number"
                    id="capacity"
                    value={capacity}
                    onSubmit={(e) => console.log("Test")}
                    onChange={(e) => { setCapacity(e.target.value); markAsChanged(); }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                />
            </div>

            <Accordion className="my-4">
                <Accordion.Panel>
                    <Accordion.Title>
                        Available Scenarios
                    </Accordion.Title>
                    <Accordion.Content>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
                            {allScenarios
                                .map(scenario => (
                                    <label key={scenario.id} className="inline-flex items-center mt-3">
                                        <input
                                            type="checkbox"
                                            checked={availableScenarios.includes(scenario.id)}
                                            onChange={() => {
                                                setAvailableScenarios((prev) => {
                                                    return availableScenarios.includes(scenario.id) ?
                                                        prev.filter((s) => s !== scenario.id) :
                                                        [...prev, scenario.id]
                                                });
                                                markAsChanged();
                                            }}
                                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <span className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{scenario.name}</span>
                                    </label>
                                ))}
                        </div>
                    </Accordion.Content>
                </Accordion.Panel>
            </Accordion>

            <DeleteButton onDelete={handleDelete} />
        </div>
    );
}

export default AdminSchoolInfo;