/**
 * Calculates the average of a Number field in an array of objects
 * @param {} array The array of objects
 * @param {*} fieldName The name of the field you wish to calculate the average from
 * @returns A number with the calculated average
 */
export const calculateArrayFieldAverage = (array, fieldName) => {
    const arr = Object.values(array).filter(i => i[fieldName] >= 0 <= 100);
    if (arr.length <= 0) return 0;
    const sum = (accumulator, obj) => accumulator + (obj[fieldName] ?? 0);
    return Math.max(0, Math.round((arr.reduce(sum, 0) / arr.length) ?? 0.0));
}

/**
 * Sorts an array based on a date field
 * @param {*} array The array you want to sort 
 * @param {*} dateFieldName The fieldName of the date field
 * @returns The sorted array
 */
export const sortByDate = (array, dateFieldName) => {
    return array.sort((a, b) => {
        return (a[dateFieldName]).toDate().getTime() -
            (b[dateFieldName]).toDate().getTime()
    });
}