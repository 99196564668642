import React from "react";
import { FaCheckCircle, FaCircle } from "react-icons/fa";

const SideSteps = ({ steps, currentStep, setCurrentStep }) => {
    const getStatus = (index) => {
        if (index < currentStep) return "Completed";
        if (index === currentStep) return "In Progress";
        return "Pending";
    };

    return (
        <div className="p-6">
            <div className="flex flex-col">
                {steps.map((step, index) => {
                    const isActive = index === currentStep;
                    const isCompleted = index < currentStep;

                    return (
                        <React.Fragment key={index}>
                            {/* Step Item */}
                            <div
                                className={`flex items-center space-x-4 cursor-pointer`}
                                onClick={() => setCurrentStep(index)}
                            >
                                <div
                                    className={`w-10 h-10 rounded-full flex items-center justify-center 
                  ${isActive ? "bg-blue-500 text-white" : isCompleted ? "bg-green-500 text-white" : "bg-gray-300 text-gray-500"}`}
                                >
                                    {isCompleted ? (
                                        <FaCheckCircle className="text-white" />
                                    ) : (
                                        isActive ? <FaCircle /> : <div />
                                    )}
                                </div>
                                <div className="flex flex-col">
                                    <span
                                        className={`text-sm ${isActive
                                            ? "text-blue-500 font-semibold"
                                            : isCompleted
                                                ? "text-green-500"
                                                : "text-gray-600"
                                            }`}
                                    >
                                        {step}
                                    </span>
                                    <span
                                        className={`text-xs ${isActive
                                            ? "text-blue-500"
                                            : isCompleted
                                                ? "text-green-500"
                                                : "text-gray-500"
                                            }`}
                                    >
                                        {getStatus(index)}
                                    </span>
                                </div>
                            </div>

                            {/* Connecting Line */}
                            {index < steps.length - 1 && (
                                <div
                                    className={`h-24 border-l-2 ml-5 ${isCompleted ? "border-green-500" : "border-gray-300"}`}
                                ></div>
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export default SideSteps;
