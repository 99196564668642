import React, { useState, useEffect } from 'react';
import useScenarios from '../../../hooks/useScenarios';
import useScenarioCustomization from '../../../hooks/useScenarioCustomization';
import { useNotification } from '../../../hooks/useNotification';
import { NotificationTypes } from '../../../utils/notifications';
import Accordion from '../../shared/Accordion.js';
import { LabTests, prettyPrintEnum } from '../../../utils/ScriptingEnumLibrary.js';

const ResultRow = ({ categoryName, resultName }) => {
    const { patient, setPatientField } = useScenarioCustomization();

    const updateResult = (category, fieldName, fieldValue) => {
        const updatedResults = { ...patient.results };
        updatedResults[category][fieldName] = fieldValue;
        setPatientField('results', updatedResults);
    }

    const removeResult = (category, fieldName) => {
        const updatedResults = { ...patient.results };
        if (!updatedResults[category][fieldName]) {
            console.error("Error removing result: result does not exist");
            return;
        }
        delete updatedResults[category][fieldName];
        setPatientField('results', updatedResults);
    }

    const result = patient.results[categoryName][resultName];

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedData = { ...result, [name]: String(value) };
        updateResult(categoryName, resultName, updatedData);
    };

    const handleNameChange = (e) => {
        const updatedResults = { ...patient.results };
        const result = { ...updatedResults[categoryName][resultName] };
        delete updatedResults[categoryName][resultName];
        // updatedResults[categoryName][e.target.value] = result;
        updateResult(categoryName, e.target.value, result);
    };

    return (
        <tr>
            <td className="border px-4 py-2">
                <input
                    type="text"
                    name="name"
                    value={resultName}
                    onChange={handleNameChange}
                    className="w-full border rounded px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                />
            </td>
            <td className="border px-4 py-2">
                <input
                    type="text"
                    name="result"
                    value={result.result || ''}
                    onChange={handleChange}
                    placeholder="Result"
                    className="w-full border rounded px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                />
            </td>
            <td className="border px-4 py-2">
                <input
                    type="text"
                    name="reference"
                    value={result.reference || ''}
                    onChange={handleChange}
                    placeholder="Reference"
                    className="w-full border rounded px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                />
            </td>
            <td className="border px-4 py-2">
                <input
                    type="text"
                    name="status"
                    value={result.status || ''}
                    onChange={handleChange}
                    placeholder="Status"
                    className="w-full border rounded px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                />
            </td>
            <td className="border px-4 py-2">
                <input
                    type="text"
                    name="lab"
                    value={result.lab || ''}
                    onChange={handleChange}
                    placeholder="Lab"
                    className="w-full border rounded px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                />
            </td>
            {/* {!defaultCategories[categoryName] && <td className="border px-4 py-2 text-center">
                <button
                    onClick={() => removeResult(categoryName, resultName)}
                    className="text-red-500 hover:text-red-700"
                >
                    &#10005;
                </button>
            </td>} */}
            <td className="border px-4 py-2 text-center">
                <button
                    onClick={() => removeResult(categoryName, resultName)}
                    className="text-red-500 hover:text-red-700"
                >
                    &#10005; {/* X (close) icon */}
                </button>
            </td>
        </tr>
    );
};

// const defaultCategories = {
//     'CBC (Complete Blood Count)': {
//         WBC: { result: 5.0, reference: '4.0 - 11.0', status: 'Final', lab: 'General Lab' },
//         RBC: { result: 4.7, reference: '4.2 - 5.9', status: 'Final', lab: 'General Lab' },
//         Hemoglobin: { result: 14.0, reference: '13.0 - 17.0', status: 'Final', lab: 'General Lab' },
//         Hematocrit: { result: '42%', reference: '38% - 50%', status: 'Final', lab: 'General Lab' },
//     },
//     'CMP (Comprehensive Metabolic Panel)': {
//         Glucose: { result: 90, reference: '70 - 99', status: 'Final', lab: 'General Lab' },
//         Calcium: { result: 9.5, reference: '8.5 - 10.5', status: 'Final', lab: 'General Lab' },
//     },
//     'Lipid Panel': {
//         'Total Cholesterol': { result: 180, reference: '<200', status: 'Final', lab: 'General Lab' },
//         HDL: { result: 50, reference: '>40', status: 'Final', lab: 'General Lab' },
//     },
// };

const Results = () => {
    // const { scenario, updateResult, addResultCategory, removeResultCategory } = useScenarios();
    const { patient, setPatientField } = useScenarioCustomization();
    const [newCategoryName, setNewCategoryName] = useState('');

    const { showNotification } = useNotification();

    const categories = patient.results;

    const addResultCategory = (categoryName) => {
        const updatedResults = { ...patient.results };
        if (updatedResults[categoryName]) {
            showNotification(NotificationTypes.WARNING, `Category: ${categoryName} already exists.`);
            return;
        }
        updatedResults[categoryName] = {};
        setPatientField('results', updatedResults);
    }

    return (
        <div className="p-6 bg-white rounded-md border">
            <h2 className="text-2xl font-semibold mb-4">Results</h2>
            <p className="mb-4 text-gray-600">Enter the information you want inside the patient EMR</p>

            {Object.keys(categories).map((categoryName) => (
                <ResultTable categoryName={categoryName} />
            ))}

            <form className="mt-4" onSubmit={(e) => {
                e.preventDefault();
                addResultCategory(newCategoryName);
                setNewCategoryName('');
            }}>
                <input
                    type="text"
                    value={newCategoryName}
                    onChange={(e) => setNewCategoryName(e.target.value)}
                    placeholder="Enter new category name"
                    className="border rounded w-1/2 px-4 py-2 mr-2 focus:outline-none focus:ring focus:border-blue-300"
                    required
                />
                <button
                    type='submit'
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                >
                    Add New Category
                </button>
            </form>
        </div >
    );
};

const ResultTable = ({ categoryName }) => {
    const { patient, setPatientField } = useScenarioCustomization();
    const [newResultName, setNewResultName] = useState(''); // New input for adding results

    const categories = patient.results;

    const updateResultCategoryCollected = (categoryName, notCollected) => {
        const updatedResults = { ...patient.results };
        updatedResults[categoryName].notCollected = notCollected;
        setPatientField('results', updatedResults);
    }

    const updateResultCategoryLabtype = (categoryName, labtype) => {
        const updatedResults = { ...patient.results };
        updatedResults[categoryName].labType = labtype;
        setPatientField('results', updatedResults);
    }

    const removeResultCategory = (categoryName) => {
        const updatedResults = { ...patient.results };
        if (!updatedResults[categoryName]) {
            console.error("Error removing category: category does not exist");
            return;
        }
        delete updatedResults[categoryName];
        setPatientField('results', updatedResults);
    }

    const addResult = (categoryName) => {
        if (newResultName.trim() !== '' && !categories[categoryName][newResultName]) {
            // Ensure new results have the correct structure matching defaultCategories
            const newResult = {
                result: '',
                reference: '',
                status: '',
                lab: ''
            };
            const updatedResults = { ...patient.results };
            updatedResults[categoryName][newResultName] = newResult;
            setPatientField('results', updatedResults);
            setNewResultName(''); // Clear the input field
        }
    };

    const handleCheckboxChange = (categoryName, checkbox) => {
        // setCheckboxStates((prev) => {
        //     const updatedState = {
        //         ...prev,
        //         [categoryName]: {
        //             ...prev[categoryName],
        //             [checkbox]: !prev[categoryName]?.[checkbox],
        //         },
        //     };
        //     return updatedState;
        // });

    };

    return (
        <Accordion
            key={categoryName}
            title={categoryName}
            onRemove={() => {
                removeResultCategory(categoryName);
            }}
            isRemovable={true}
        >
            <div className="mb-4 flex space-x-2">
                {/* <label className="inline-flex items-center">
                    <input
                        type="checkbox"
                        checked={!!checkboxStates[categoryName]?.collected}
                        onChange={() => handleCheckboxChange(categoryName, 'collected')}
                        className="form-checkbox"
                    />
                    <span className="ml-2">Collected</span>
                </label>
                <label className="inline-flex items-center">
                    <input
                        type="checkbox"
                        checked={!!checkboxStates[categoryName]?.notCollected}
                        onChange={() => handleCheckboxChange(categoryName, 'notCollected')}
                        className="form-checkbox"
                    />
                    <span className="ml-2">Not Collected</span>
                </label> */}
                <div className="inline-flex w-full items-center">
                    <div className='flex items-center w-1/3'>
                        <span className="mr-2">Not Collected</span>
                        <label className="relative inline-flex items-center cursor-pointer">
                            <input
                                type="checkbox"
                                checked={!categories[categoryName]?.notCollected}
                                onChange={() =>
                                    updateResultCategoryCollected(categoryName, !categories[categoryName]?.notCollected)
                                }
                                className="sr-only peer" // Hides the default checkbox for styling
                            />
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-opacity-50 rounded-full peer-checked:bg-blue-500 peer-checked:after:translate-x-5 after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
                        </label>
                        <span className="ml-2">Collected</span>
                    </div>
                    {categories[categoryName]?.notCollected &&
                        <select
                            value={categories[categoryName]?.labType ?? 0}
                            onChange={(e) => {
                                const value = Number(e.target.value);
                                updateResultCategoryLabtype(categoryName, value);
                            }
                            }
                            className="w-2/3 p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:text-gray-200"
                        >
                            {
                                Object.entries(LabTests).map(([key, value]) => (
                                    <option key={value} value={value}>
                                        {prettyPrintEnum(key)}
                                    </option>
                                ))
                            }
                        </select >
                    }
                </div>
            </div>
            <table className="w-full table-auto mb-4">
                <thead>
                    <tr>
                        <th className="border px-4 py-2 text-left">Name</th>
                        <th className="border px-4 py-2 text-left">Result</th>
                        <th className="border px-4 py-2 text-left">Reference Range</th>
                        <th className="border px-4 py-2 text-left">Status</th>
                        <th className="border px-4 py-2 text-left">Category</th>
                        <th className="border px-4 py-2 text-center">Actions</th>
                        {/* {!defaultCategories[categoryName] && <th className="border px-4 py-2 text-center">Actions</th>} */}
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(categories[categoryName]).filter(f => f !== 'notCollected' && f !== 'labType').map((resultKey) => (
                        <ResultRow
                            categoryName={categoryName}
                            resultName={resultKey}
                        />
                    ))}
                </tbody>
            </table>
            {/* {!defaultCategories[categoryName] && <div className="mt-4 flex items-center">
                <input
                    type="text"
                    value={newResultName}
                    onChange={(e) => setNewResultName(e.target.value)}
                    placeholder="Enter new result name"
                    className="border rounded px-4 py-2 mr-2 focus:outline-none focus:ring focus:border-blue-300"
                />
                <button
                    onClick={() => addResult(categoryName)}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    Add New Result
                </button>
            </div>} */}
            <div className="mt-4 flex items-center">
                <input
                    type="text"
                    value={newResultName}
                    onChange={(e) => setNewResultName(e.target.value)}
                    placeholder="Enter new result name"
                    className="border rounded w-1/2 px-4 py-2 mr-2 focus:outline-none focus:ring focus:border-blue-300"
                />
                <button
                    onClick={() => addResult(categoryName)}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    Add New Result
                </button>
            </div>
        </Accordion>
    );
}

export default Results;
