import React, { useEffect, useState } from 'react';
import { Handle } from 'reactflow';
import NodeParent from './NodeParent';
import { FaBolt } from 'react-icons/fa';
import { NodeTypes, EventTypes, PatientInteraction, prettyPrintEnum, MedicationTypes, MedicationRoute, LabTests, PatientPositionTypes, AssessmentType, OxygenType } from '../../../utils/ScriptingEnumLibrary';
import { ScriptingEnumLabel, ScriptingEnumLabelMultiple } from '../library/ScriptingComponentLibrary';


const EventNode = ({ id, data, selected }) => {
  const createMetadata = () => {
    switch (data?.event_type) {
      case EventTypes.WhenMedicationAdministered:
        return (
          <div className="mb-4">
            <label className="block text-sm mb-1">Medication</label>
            <ScriptingEnumLabel id={id} data={data} label="medication" enumType={MedicationTypes} />
            <label className="block text-sm mb-1 mt-4">Route</label>
            <ScriptingEnumLabel id={id} data={data} label="route" enumType={MedicationRoute} />
          </div>
        );
      case EventTypes.SimulationStarted:
        return (
          <div className="mb-4">
            <label className="block text-sm mb-1">This event is called once when the simulation has been started</label>
          </div>
        );
      case EventTypes.WhenIvAdministered:
        return (
          <div className="mb-4">
            <label className="block text-sm mb-1">Medication</label>
            <ScriptingEnumLabel id={id} data={data} label="medication" enumType={MedicationTypes} />
          </div>
        );
      case EventTypes.WhenLabCollected:
        return (
          <div className="mb-4">
            <label className="block text-sm mb-1">Lab</label>
            <ScriptingEnumLabelMultiple id={id} data={data} label="medication" enumType={LabTests} />
            <label className="block text-sm mt-4">This event will fire every time ANY of the selected lab tube colors have been collected.</label>
          </div>
        );
      case EventTypes.WhenPatientRepositioned:
        return (
          <div className="mb-4">
            <label className="block text-sm mt-4">This event will fire every time the patient is repositioned.</label>
          </div>
        );
      case EventTypes.WhenDoctorCalled:
        return (
          <div className="mb-4">
            <label className="block text-sm mb-1">This event is called every time the user calls the doctor</label>
          </div>
        );
      case EventTypes.WhenAssessmentPerformed:
        return (
          <div className="mb-4">
            <ScriptingEnumLabelMultiple id={id} data={data} label="assessments" enumType={AssessmentType} />
            <label className="block text-sm mt-4 mb-1">This event is called every time the user performs an assessment</label>
          </div>
        );
      case EventTypes.WhenRestraintsRemoved:
        return (
          <div className="mb-4">
            <label className="block text-sm mb-1">This event is called every time the user removes the patient's restraints</label>
          </div>
        );
      case EventTypes.WhenRestraintsApplied:
        return (
          <div className="mb-4">
            <label className="block text-sm mb-1">This event is called every time the user applied the restraints</label>
          </div>
        );
      case EventTypes.WhenGlucoseTaken:
        return (
          <div className="mb-4">
            <label className="block text-sm mb-1">This event is called every time the user takes the patient's glucose using the glucose meter</label>
          </div>
        );
      case EventTypes.WhenBloodPressureTaken:
        return (
          <div className="mb-4">
            <label className="block text-sm mb-1">This event is called every time the user takes the patient's blood pressure using the cuff</label>
          </div>
        );
      case EventTypes.WhenTemperatureTaken:
        return (
          <div className="mb-4">
            <label className="block text-sm mb-1">This event is called every time the user takes the patient's temperature using the oral thermometer</label>
          </div>
        );
      case EventTypes.WhenSimulationSubmitted:
        return (
          <div className="mb-4">
            <label className="block text-sm mb-1">This event is called when the simulated is submitted.
              <br />Note: Do NOT add delays on this event, as  the simulation will return to the menu after this event</label>
          </div>
        );
      case EventTypes.WhenOxygenAdminstered:
        return (
          <div className="mb-4">
            <label className="block text-sm mb-1">This event is called when the user administers oxygen.</label>
          </div>
        );
      default:
        return (
          <div className="mb-4">
            <label className="block text-sm mb-1">Invalid event type</label>
          </div>
        );
    }
  };

  return (
    <NodeParent title="Event" icon={<FaBolt />} selected={selected} description="Triggers an action">
      <div className="mb-4">
        <label className="block text-sm mb-1">Event Type</label>
        <ScriptingEnumLabel id={id} data={data} label="event_type" enumType={EventTypes} />
      </div>
      {createMetadata()}
      <Handle type="source" position="right" className="bg-blue-700" style={{ width: '12px', height: '12px' }} />
    </NodeParent>
  );
};

export default EventNode;
