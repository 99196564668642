import React from 'react';
import { useInworld } from '../../../hooks/useInworld';

const Personality = () => {
    const { character, setCharacterField } = useInworld();

    const setCharacterFieldInState = (fieldName, value) => {
        const updatedCharacter = { ...character, [fieldName]: value };
        setCharacterField(fieldName, updatedCharacter[fieldName]);
    };

    // Update personality adjectives
    const handleAddPersonalityAdjective = (e) => {
        if (e.key === 'Enter' && e.target.value.trim()) {
            const adjectives = character.defaultCharacterDescription.personalityAdjectives || [];
            if (adjectives.length < 3) {
                const updatedAdjectives = [...adjectives, e.target.value.trim()];
                setCharacterFieldInState('defaultCharacterDescription', {
                    ...character.defaultCharacterDescription,
                    personalityAdjectives: updatedAdjectives,
                });
                e.target.value = ''; // Clear input
            }
        }
    };

    const handleRemovePersonalityAdjective = (adjectiveToRemove) => {
        const adjectives = character.defaultCharacterDescription.personalityAdjectives || [];
        const updatedAdjectives = adjectives.filter((adj) => adj !== adjectiveToRemove);
        setCharacterFieldInState('defaultCharacterDescription', {
            ...character.defaultCharacterDescription,
            personalityAdjectives: updatedAdjectives,
        });
    };

    // Update mood values
    const handleMoodChange = (moodKey, value) => {
        const updatedMood = { ...character.initialMood, [moodKey]: parseInt(value) };
        setCharacterFieldInState('initialMood', updatedMood);
    };

    // Update personality traits
    const handlePersonalityChange = (traitKey, value) => {
        const updatedPersonality = { ...character.personality, [traitKey]: parseInt(value) };
        setCharacterFieldInState('personality', updatedPersonality);
    };

    // Update emotional fluidity
    const handleEmotionalFluidityChange = (value) => {
        setCharacterFieldInState('emotionalFluidity', parseInt(value));
    };

    // Update relationship fluidity
    const handleRelationshipFluidityChange = (value) => {
        setCharacterFieldInState('relationship', {
            ...character.relationship,
            fluidity: parseInt(value),
        });
    };

    return (
        <div className="dark:bg-gray-900 p-6 flex justify-center">
            <div className="max-w-4xl w-full bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
                <h3 className="text-xl font-bold text-gray-900 dark:text-gray-50 mb-4">Personality</h3>

                {/* Personality Adjectives */}
                <div className="mb-6">
                    <label className="block text-lg font-semibold text-gray-900 dark:text-gray-50 mb-2">Character traits</label>
                    <div className="flex flex-wrap gap-2 mb-4">
                        {character.defaultCharacterDescription.personalityAdjectives.map((adj, index) => (
                            <div
                                key={index}
                                className="bg-gray-300 dark:bg-gray-700 px-2 py-1 rounded-full text-sm text-gray-900 dark:text-white flex items-center"
                            >
                                {adj}
                                <button
                                    onClick={() => handleRemovePersonalityAdjective(adj)}
                                    className="ml-2 text-red-500"
                                >
                                    &times;
                                </button>
                            </div>
                        ))}
                        {character.defaultCharacterDescription.personalityAdjectives.length < 3 && (
                            <input
                                type="text"
                                onKeyDown={handleAddPersonalityAdjective}
                                className="bg-gray-100 dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg p-2"
                                placeholder="Add adjective"
                            />
                        )}
                    </div>
                </div>

                {/* Mood Sliders */}
                <div className="grid grid-cols-2 gap-8 mb-8">
                    <div>
                        <h4 className="text-lg font-semibold text-gray-900 dark:text-gray-50 mb-2">Mood</h4>
                        {[
                            ['joy', 'Sadness', 'Joy'],
                            ['fear', 'Anger', 'Fear'],
                            ['trust', 'Disgust', 'Trust'],
                            ['surprise', 'Anticipation', 'Surprise'],
                            // ['static emotion', 'Dynamic emotions', 'Static emotion'],
                        ].map(([moodKey, leftLabel, rightLabel]) => (
                            <div key={moodKey} className="mb-6">
                                <div className="flex justify-between mb-1">
                                    <span className="text-sm text-gray-600 dark:text-gray-400">{leftLabel}</span>
                                    <span className="text-sm text-gray-600 dark:text-gray-400">{rightLabel}</span>
                                </div>
                                <input
                                    type="range"
                                    min="-100"
                                    max="100"
                                    step="25"
                                    value={character.initialMood[moodKey] || 0}
                                    onChange={(e) => handleMoodChange(moodKey, e.target.value)}
                                    className="w-full"
                                />
                            </div>
                        ))}
                    </div>

                    {/* Personality Trait Sliders */}
                    <div>
                        <h4 className="text-lg font-semibold text-gray-900 dark:text-gray-50 mb-2">Personality Traits</h4>
                        {[
                            ['positive', 'Negative', 'Positive'],
                            ['peaceful', 'Aggressive', 'Peaceful'],
                            ['open', 'Cautious', 'Open'],
                            ['extravert', 'Introvert', 'Extravert'],
                            // ['insecure', 'Confident', 'Insecure'],
                        ].map(([traitKey, leftLabel, rightLabel]) => (
                            <div key={traitKey} className="mb-6">
                                <div className="flex justify-between mb-1">
                                    <span className="text-sm text-gray-600 dark:text-gray-400">{leftLabel}</span>
                                    <span className="text-sm text-gray-600 dark:text-gray-400">{rightLabel}</span>
                                </div>
                                <input
                                    type="range"
                                    min="-100"
                                    max="100"
                                    step="25"
                                    value={character.personality[traitKey] || 0}
                                    onChange={(e) => handlePersonalityChange(traitKey, e.target.value)}
                                    className="w-full"
                                />
                            </div>
                        ))}
                    </div>
                </div>

                {/* Emotional Fluidity Slider */}
                <div className="mb-8">
                    <h4 className="text-lg font-semibold text-gray-900 dark:text-gray-50 mb-2">Emotional Fluidity</h4>
                    <div className="flex justify-between mb-1">
                        <span className="text-sm text-gray-600 dark:text-gray-400">Rigid</span>
                        <span className="text-sm text-gray-600 dark:text-gray-400">Fluid</span>
                    </div>
                    <input
                        type="range"
                        min="0"
                        max="100"
                        step="1"
                        value={character.emotionalFluidity || 50}
                        onChange={(e) => handleEmotionalFluidityChange(e.target.value)}
                        className="w-full"
                    />
                </div>

                {/* Relationship Fluidity Slider */}
                <div className="mb-8">
                    <h4 className="text-lg font-semibold text-gray-900 dark:text-gray-50 mb-2">Relationship Fluidity</h4>
                    <div className="flex justify-between mb-1">
                        <span className="text-sm text-gray-600 dark:text-gray-400">Rigid Relationships</span>
                        <span className="text-sm text-gray-600 dark:text-gray-400">Fluid Relationships</span>
                    </div>
                    <input
                        type="range"
                        min="0"
                        max="10"
                        step="1"
                        value={character.relationship?.fluidity || 5}
                        onChange={(e) => handleRelationshipFluidityChange(e.target.value)}
                        className="w-full"
                    />
                </div>
            </div>
        </div>
    );
};

export default Personality;
