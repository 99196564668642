import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

/**
 * Manages a specific route, given an allowed role and handles authorization for that route.
 */
const RoleRoute = ({ children, allowedRoles }) => {
    const { user, role, loading } = useAuth();

    //Still loading/signing in....
    if (loading) return <p>Loading...</p>;
    //Not logged in
    if (!user) return <Navigate to="/login" />;
    //Not authorized to access this page
    if (!allowedRoles.includes(role)) return <Navigate to="/unauthorized" />;

    return children;
};

export default RoleRoute;