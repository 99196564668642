import React, { createContext, useState, useEffect } from 'react';
import { addDocument, deleteDocument, subscribeCollection, updateDocument } from '../firebase/firestore';
import useSchool from '../hooks/useSchool';
import { deleteFile, uploadFile } from '../firebase/storage';
import { BsShopWindow } from 'react-icons/bs';
import { NotificationTypes } from '../utils/notifications';
import { useNotification } from '../hooks/useNotification';
import useAuth from '../hooks/useAuth';
import { serverTimestamp } from 'firebase/firestore';
import Roles from '../utils/roles';

const CoursesContext = createContext();

/**
 * The context for all authentication related data such as the signed in user data, authentication loading or authentication error messages
 * @param {*} children The provider's children
 * @returns 
 */
const CoursesProvider = ({ children }) => {
    const { school } = useSchool();
    const [courses, setCourses] = useState([]);
    const [coursesMap, setCoursesMap] = useState(new Map());
    const [loading, setLoading] = useState(true);
    const { showNotification } = useNotification();
    const { user, role } = useAuth();

    //The school context listens to changes to school ID and loads the school data for the newly set school ID on changes
    useEffect(() => {
        if (school && user) {
            const unsubscribe = subscribeCollection(`institutions/${school.id}/courses`, (data) => {
                console.log("Refreshed school courses: ", data)
                setLoading(false);
                if (data) {
                    const filteredCourses = data.filter(c => c.admin === user.id || (c.attendees.includes(user.id) && c.published) || role === Roles.ADMIN || role === Roles.SCHOOLADMIN)
                    setCourses(filteredCourses);
                    setCoursesMap(new Map(filteredCourses.map((item) => [item.id, item])));
                }
                else {
                    setCoursesMap(new Map());
                    setCourses([]);
                }
            });

            return () => unsubscribe(); // Cleanup on unmount
        }
        else {
            console.log("Cleared courses");
            setCourses([]);
            setLoading(true);
        }
    }, [school, user])

    const addLearningMaterials = async (courseId, files) => {
        const course = coursesMap.get(courseId);
        if (!course) {
            throw new Error("Tried to add learning material to non-existent course!");
        }
        setLoading(true);
        try {
            for (const file of files) {
                if (course.learningMaterials?.includes(file.name)) {
                    showNotification(NotificationTypes.WARNING, `Material ${file.name} already exists.`);
                    continue;
                }
                console.log(`Uploading file: ${file.name} to ${course.id}(${course.name})`);
                await uploadFile(file, `institutions/${school.id}/courses/${course.id}/learningMaterials/`);
                console.log("Succesfully uploaded material:", file);
                showNotification(NotificationTypes.SUCCESS, `Uploaded material: ${file.name}.`);
            }
            const filteredFiles = files.map(f => f.name).filter(f => !course.learningMaterials.includes(f));

            if (JSON.stringify(course.learningMaterials) !== JSON.stringify([...course.learningMaterials, ...filteredFiles])) {
                await updateDocument(course.id, `institutions/${school.id}/courses`, {
                    learningMaterials: [...(course.learningMaterials ?? []), ...filteredFiles]
                });
            }
            else {
                setLoading(false);
            }
        } catch (e) {
            console.log("Error uploading material", e);
            showNotification(NotificationTypes.DANGER, "An error occured.");
        }
    }

    const removeLearningMaterial = async (courseId, material) => {
        const course = coursesMap.get(courseId);
        if (!course) {
            throw new Error("Tried to remove learning material from non-existent course!");
        }
        setLoading(true);
        try {
            await deleteFile(`institutions/${school.id}/courses/${course.id}/learningMaterials/${material}`);
            await updateDocument(course.id, `institutions/${school.id}/courses`, {
                learningMaterials: (course.learningMaterials ?? []).filter((l) => l !== material)
            });
            console.log("Succesfully removed material.");
            showNotification(NotificationTypes.SUCCESS, `Removed material: ${material}.`);
        }
        catch (e) {
            console.log("Error removing material", e);
            showNotification(NotificationTypes.DANGER, "An error occured.");
        }
    }

    const addAttendee = async (courseId, userId) => {
        const course = coursesMap.get(courseId);
        if (!course) {
            throw new Error("Tried to add attendee to non-existent course!");
        }
        setLoading(true);
        await updateDocument(course.id, `institutions/${school.id}/courses`, {
            attendees: [...(course.attendees ?? []), userId]
        });
    }

    const removeAttendee = async (courseId, userId) => {
        const course = coursesMap.get(courseId);
        if (!course) {
            throw new Error("Tried to add attendee to non-existent course!");
        }
        setLoading(true);
        await updateDocument(course.id, `institutions/${school.id}/courses`, {
            attendees: [...(course.attendees ?? []).filter((a) => a !== userId)]
        });
    }

    const updateCourse = async (courseId, name, thumbnail) => {
        try {
            setLoading(true);
            if (thumbnail) {
                await uploadFile(thumbnail, `institutions/${school.id}/courses/${courseId}`);
            }
            await updateDocument(courseId, `institutions/${school.id}/courses`, { name: name, thumbnail: thumbnail?.name ?? "" });
            showNotification(NotificationTypes.SUCCESS, "Course updated successfully.");
        }
        catch (error) {
            console.error("Error updating course: ", error);
            showNotification(NotificationTypes.DANGER, "An error occured.");
        }
    }

    const publishCourse = async (courseId, publish = true) => {
        try {
            setLoading(true);
            await updateDocument(courseId, `institutions/${school.id}/courses`, { published: publish });
            showNotification(NotificationTypes.SUCCESS, `Course ${publish ? "published" : "unpublished"} successfully`);
        }
        catch (error) {
            showNotification(NotificationTypes.DANGER, "An error occured.");
            console.error("Error publishing course", error);
            // throw error;
        }
    }

    const createCourse = async (name) => {
        try {
            setLoading(true);
            const id = await addDocument({ name: name, admin: user.id, attendees: [], openLab: [], createdAt: serverTimestamp(), learningMaterials: [], published: false }, `institutions/${school.id}/courses`);
            showNotification(NotificationTypes.SUCCESS, `Course: ${name} created successfully.`);
            return id;
        }
        catch (error) {
            showNotification(NotificationTypes.DANGER, `An error occured.`);
            console.error("Error updating course", error);
        }
    }

    const deleteCourse = async (courseId) => {
        try {
            setLoading(true);
            await deleteDocument(courseId, `institutions/${school.id}/courses`);
            showNotification(NotificationTypes.SUCCESS, `Course deleted successfully.`);
        }
        catch (error) {
            showNotification(NotificationTypes.DANGER, `An error occured.`);
            console.error("Error updating course", error);
        }
    }

    const updateOpenLab = async (courseId, openLab) => {
        try {
            setLoading(true);
            await updateDocument(courseId, `institutions/${school.id}/courses`, { openLab: openLab });
            showNotification(NotificationTypes.SUCCESS, `Open lab updated successfully.`);
        }
        catch (error) {
            console.error("Error updating open lab: ", error);
            showNotification(NotificationTypes.DANGER, `An error occured.`);
        }
    }

    return (
        <CoursesContext.Provider value={{ courses, coursesMap, loading, addLearningMaterials, removeLearningMaterial, addAttendee, removeAttendee, updateCourse, publishCourse, createCourse, deleteCourse, updateOpenLab }}>
            {children}
        </CoursesContext.Provider>
    );
}

export { CoursesProvider, CoursesContext };