import { useContext, useEffect, useState } from "react";
import { GradesContext } from "../contexts/GradesContext";
import useUsers from "./useUsers";
import UserRow from "../components/shared/UserRow";
import { usePopUp } from "./usePopUp";
import GradingDetails from "../components/grades/GradingDetails";
import useAssignments from "./useAssignments";
import useAuth from "./useAuth";
import { getDocument, updateDocument } from "../firebase/firestore";
import useSchool from "./useSchool";

/**
 * Hook for all grades functionality
 * @returns All usable variables and functions from this hook
 */
const useGrades = () => {
    const { grades, loading, gradesMap } = useContext(GradesContext);
    const { school } = useSchool();
    const { assignment, getAssignmentsForCourse } = useAssignments();
    const { user } = useAuth();
    const { showPopUp } = usePopUp();
    const [grade, setGrade] = useState(null);
    const [gradeId, setGradeId] = useState(null);

    useEffect(() => {
        const fetchGradeData = async () => {
            let g = null;

            //Get grade either by selected assignment or selected grade
            if (gradeId) {
                g = getGradeByID(gradeId);
            }
            else if (assignment) {
                g = grades.find((g) => g.assignmentId === assignment.id && g.userId === user.id);
            }

            //Load additional grade data
            if (g) {
                const details = await getDocument("details", `institutions/${school.id}/grades/${g.id}/data`);
                const scenario = await getDocument("scenario", `institutions/${school.id}/grades/${g.id}/data`);
                console.log("Got grade: ", { ...g, ...details, ...scenario, id: g.id });
                setGrade({ ...g, ...details, scenario: scenario, id: g.id });
            }
            //No selected grade
            else {
                setGrade(null);
                setGradeId(null);
            }
        }

        fetchGradeData();
    }, [grades, assignment, gradeId])

    useEffect(() => {
        if (gradeId && grade) {
            showPopUp(<GradingDetails grade={grade} />);
        }
    }, [grade])

    /**
     * Returns all grades submitted for a specific assignment
     * @param {String} assignmentId The assignment ID
     * @returns An array of submitted grades for the requested assignment
     */
    const getGradesForAssignment = (assignmentId) => {
        return grades.filter((grade) => grade.assignmentId === assignmentId);
    }

    /**
     * Returns a grade submitted for a specific assignment
     * @param {String} assignmentId The assignment ID
     * @returns An array of submitted grades for the requested assignment
     */
    const getGradeForAssignment = (assignmentId) => {
        return grades.find((grade) => grade.assignmentId === assignmentId);
    }

    /**
     * Returns all grades submitted for the given Course
     * @param {String} courseId The course ID
     * @returns An array of submitted grades for the requested course
     */
    const getGradesForCourse = (courseId) => {
        // const courseAssignments = getAssignmentsForCourse(courseId);
        // const courseGrades = courseAssignments.map((assignment) => getGradesForAssignment(assignment.id)).flat();
        // return courseGrades;
        return grades.filter((grade) => grade.courseId === courseId);
    }

    const getGradeByID = (gradeId) => {
        if (!gradesMap || gradesMap.size <= 0) {
            console.error("Error trying to get grade by ID: No grades!");
            return;
        }
        return gradesMap.get(gradeId);
    }

    /**
     * Updates the grade data in Firebase where grade is the current grade state in the hook
     * @param {*} data The data to update firebase with. Note that this merges with any existing data in the document
     */
    const updateGradeData = async (data) => {
        if (!grade) {
            throw new Error("Error updating grade document: Grade was null");
        }
        return await updateDocument("details", `institutions/${school.id}/grades/${grade.id}/data`, data);
    }

    const updateGrade = async (data) => {
        if (!grade) {
            throw new Error("Error updating grade: Grade was null");
        }
        return await updateDocument(grade.id, `institutions/${school.id}/grades`, data);
    }

    const resultBadgeStyle = (result) => {
        if (result === 'N/A') {
            return 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-300';
        }
        const parsedResult = parseFloat(result);
        if (parsedResult >= 0 && parsedResult < 50) {
            return 'bg-red-500 dark:bg-red-700 text-white';
        } else if (parsedResult >= 50 && parsedResult < 70) {
            return 'bg-blue-500 dark:bg-blue-700 text-white';
        } else if (parsedResult >= 70 && parsedResult <= 100) {
            return 'bg-green-500 dark:bg-green-700 text-white';
        }
        return 'bg-gray-800 dark:bg-gray-700 text-gray-200 dark:text-gray-300';
    };

    const resultText = (result) => {
        if (result === 'N/A') {
            return "N/A";
        }
        const parsedResult = parseFloat(result);
        if (parsedResult >= 0 && parsedResult <= 100) {
            return result;
        }
        return "NONE";
    }

    const handleGradeClick = (gradeId) => {
        if (grade && grade.id === gradeId) {
            showPopUp(
                <GradingDetails grade={grade} />
            );
        }
        else {
            setGradeId(gradeId);
        }
    }

    return { grades, loading, getGradeByID, getGradesForAssignment, resultBadgeStyle, resultText, handleGradeClick, grade, updateGrade, updateGradeData, getGradesForCourse, getGradeForAssignment }; // Access all context values directly
};

export default useGrades;