
import { faTrash, faFilePdf, faFilePowerpoint, faFileExcel, faFileImage, faFileVideo, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import useAuth from '../../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Roles from '../../utils/roles';
import DeleteButton from './DeleteButton';

export const fileIcons = {
    pdf: faFilePdf,
    ppt: faFilePowerpoint,
    pptx: faFilePowerpoint,
    xls: faFileExcel,
    xlsx: faFileExcel,
    mp4: faFileVideo,
    png: faFileImage,
    jpg: faFileImage,
    other: faFileAlt,
};

const FileList = ({ files, handleDelete, handleOpen, simple = false }) => {
    const { role } = useAuth();

    return files.map((material, idx) => {
        const ext = material.split('.').pop().toLowerCase();
        const icon = fileIcons[ext] || fileIcons['other'];

        return (
            <div
                key={idx}
                className="flex items-center p-4 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 mb-4 w-full"
            >
                {/* File icon */}
                <FontAwesomeIcon icon={icon} className={simple ? "text-gray-500 w-4 h-4" : "text-gray-500 w-16 h-16"} />

                {/* File details */}
                <div className="flex-grow ml-4">
                    <button
                        onClick={() => { handleOpen(material) }}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-xs font-medium text-blue-600 hover:underline"
                    >
                        {material}
                    </button>
                </div>

                {/* Trash icon for Admins or non-students */}
                {role !== Roles.STUDENT && (
                    // <button
                    //     onClick={() => {

                    //     }}
                    //     className="text-red-500 hover:text-red-700 ml-4"
                    // >
                    //     <FontAwesomeIcon icon={faTrash} />
                    // </button>
                    <DeleteButton iconOnly={true} onDelete={() => { handleDelete(material) }} />
                )}
            </div>
        );
    });
}

export default FileList;