import React from 'react';
import { HiOutlineInbox } from 'react-icons/hi';

const Empty = ({ icon: Icon = HiOutlineInbox, message = "No items to show", subMessage = "", className = "" }) => {
    return (
        <div className={`flex flex-col items-center justify-center text-center p-6 space-y-4 ${className}`}>
            <Icon className="text-gray-400 w-16 h-16" />
            <h2 className="text-lg font-semibold text-gray-600">{message}</h2>
            {subMessage && <p className="text-sm text-gray-500">{subMessage}</p>}
        </div>
    );
};

export default Empty;
