import { useEffect, useState } from "react";
import useRegistrations from "./useRegistrations";
import useSchool from "./useSchool";
import { useSearchParams } from "react-router-dom";
import useAuth from "./useAuth";


const useSignup = () => {
    const { getRegistrationByID, registrations, completeRegistration } = useRegistrations();
    const { user, logout } = useAuth();
    const [registration, setRegistration] = useState(null);
    const [searchParams] = useSearchParams();
    const { selectSchool, school } = useSchool();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);

    const token = searchParams.get("token");
    const schoolId = searchParams.get("institution");

    if (!error && (!token || !schoolId)) {
        setError("No valid token provided!");
    }

    selectSchool(schoolId);

    //Load the registration
    useEffect(() => {
        const reg = getRegistrationByID(token);
        if (reg) {
            setRegistration(reg);
            setLoading(false);
        }
        else {
            if (school && registrations.length > 0)
                setError("registration not found");
        }
    }, [school, registrations]);

    const handleSubmit = async (password, confirmPassword) => {
        if (password !== confirmPassword) {
            setError("passwords do not match!");
            return;
        }
        setError("");
        setLoading(true);
        await completeRegistration(
            { token: token, schoolId: schoolId, password: password }, registration.email
        );
    }

    return { registration, loading, error, handleSubmit };
}

export default useSignup;