import React, { useEffect, useState } from 'react';
import { useNotification } from '../../hooks/useNotification';
import { NotificationTypes } from '../../utils/notifications';

const icons = {
    [NotificationTypes.SUCCESS]: (
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
    ),
    [NotificationTypes.DANGER]: (
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
    ),
    [NotificationTypes.WARNING]: (
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
    ),
    [NotificationTypes.INFO]: (
        <path d="M10 0.5C4.753 0.5 0.5 4.753 0.5 10S4.753 19.5 10 19.5 19.5 15.247 19.5 10 15.247 0.5 10 0.5zm0 17A7.5 7.5 0 1 1 17.5 10 7.508 7.508 0 0 1 10 17.5zm.75-11.25h-1.5v-1.5h1.5zm-1.5 7.5v-6h1.5v6z" />
    ),
};

const colorClasses = {
    [NotificationTypes.SUCCESS]: 'text-green-500 bg-green-100',
    [NotificationTypes.DANGER]: 'text-red-500 bg-red-100',
    [NotificationTypes.WARNING]: 'text-orange-500 bg-orange-100',
    [NotificationTypes.INFO]: 'text-blue-500 bg-blue-100',
};

const progressBarColorClasses = {
    [NotificationTypes.SUCCESS]: 'bg-green-500',
    [NotificationTypes.DANGER]: 'bg-red-500',
    [NotificationTypes.WARNING]: 'bg-orange-500',
    [NotificationTypes.INFO]: 'bg-blue-500',
};

const Notification = () => {
    const { notifications, hideNotification, processingNotification } = useNotification();

    return (
        <div className="fixed top-0 right-0 mt-4 mr-4 z-[1000] flex flex-col-reverse space-y-4 space-y-reverse">
            {notifications.map((notification) => (
                <NotificationItem
                    key={notification.id}
                    notification={notification}
                    hideNotification={hideNotification}
                />
            ))}

            {processingNotification && (
                <div className="relative flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-yellow-100 rounded-lg shadow">
                    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg text-yellow-500 bg-yellow-100">
                        <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                            {icons[NotificationTypes.INFO]}
                        </svg>
                    </div>
                    <div className="ml-3 text-sm font-normal">All notifications are being processed.</div>
                </div>
            )}
        </div>
    );
};

const NotificationItem = ({ notification }) => {
    const { visibleNotifications, handleCloseNotification } = useNotification();

    // Determine if the notification is visible
    const isVisible = visibleNotifications[notification.id];
    const Icon = icons[notification.type];

    return (
        <div
            className={`relative flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800 transition-transform duration-500 ${isVisible ? 'animate-slideDown' : 'animate-slideUp'
                } ${colorClasses[notification.type]}`}
            role="alert"
        >
            <div
                className={`inline-flex items-center justify-center pl-2 flex-shrink-0 w-8 h-8 rounded-lg dark:text-white ${colorClasses[notification.type]}`}
            >
                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                    {Icon}
                </svg>
                <span className="sr-only">{notification.type} icon</span>
            </div>
            <div className="ml-3 text-sm font-normal pr-5">{notification.message}</div>

            <button
                type="button"
                className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                onClick={() => handleCloseNotification(notification.id)}
                aria-label="Close"
            >
                <span className="sr-only">Close</span>
                <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                >
                    <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                </svg>
            </button>
            <div
                className={`absolute bottom-0 left-0 h-1 rounded-full ${progressBarColorClasses[notification.type]} animate-progress`}
                style={{ width: '100%' }}
            />
        </div>
    );
};
export default Notification;

