import React from "react";
import { Pagination, Table } from 'flowbite-react';
// import SkeletonLoader from "./SkeletonLoader"; // Replace with your actual SkeletonLoader component
import { HiOutlineEye, HiOutlineTrash, HiPencil } from "react-icons/hi";
import useDatatable from "../../hooks/useDatatable";
import { SkeletonLoaderRow } from "./SkeletonLoader";
import DeleteButton from "./DeleteButton";

const DataTable = ({
    isLoading,
    rows,
    columns,
    selectable = false,
    onSelectAll,
    onRowCheckboxChange,
    selectedRowIds = [],
    onViewClick,
    onDeleteClick,
    onEditClick,
    actionsEnabled = true,
    customActions = [],
}) => {
    const { currentPage, handlePageChange, itemsPerPage, handleNumItemsPerPageChange } = useDatatable();

    const totalPages = Math.ceil(rows?.length / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage
    const currentRows = rows?.slice(startIndex, startIndex + itemsPerPage) ?? [];

    return (
        <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg w-full">
            {isLoading ? (
                <SkeletonLoaderRow />
            ) : (
                <Table hoverable={true}>
                    <Table.Head>
                        {selectable && (
                            <Table.HeadCell>
                                <input
                                    type="checkbox"
                                    onChange={onSelectAll}
                                    className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                />
                            </Table.HeadCell>)}
                        {columns?.map((column) => (
                            <Table.HeadCell
                                key={column.key}
                                className="text-gray-700 dark:text-gray-300"
                            >
                                {column.label}
                            </Table.HeadCell>
                        ))}
                        {actionsEnabled && (
                            <Table.HeadCell className="text-gray-700 dark:text-gray-300">
                                Actions
                            </Table.HeadCell>)}
                    </Table.Head>
                    <Table.Body className="divide-y divide-gray-200 dark:divide-gray-700">
                        {currentRows.map((row, index) => (
                            <Table.Row
                                key={row.id}
                                className="hover:bg-gray-100 dark:hover:bg-gray-700"
                            >
                                {selectable && (
                                    <Table.Cell>
                                        <input
                                            type="checkbox"
                                            checked={selectedRowIds.includes(row.id)}
                                            onChange={() => onRowCheckboxChange(row.id)}
                                            className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                        />
                                    </Table.Cell>
                                )}
                                {columns?.map((column) => (
                                    <Table.Cell key={column.key}>
                                        {column.render
                                            ? column.render(row[column.key], row)
                                            : row[column.key]}
                                    </Table.Cell>
                                ))}
                                {actionsEnabled && (
                                    <Table.Cell className="flex items-center space-x-2">
                                        {onViewClick && <HiOutlineEye
                                            className="text-gray-500 dark:text-gray-300 cursor-pointer hover:text-blue-500 dark:hover:text-blue-400 h-6 w-6"
                                            onClick={() => onViewClick(row.id)}
                                        />}
                                        {onDeleteClick && <DeleteButton onDelete={() => { onDeleteClick(row) }} iconOnly={true} />}
                                        {onEditClick && <HiPencil
                                            className="text-gray-500 dark:text-gray-300 cursor-pointer hover:text-blue-500 dark:hover:text-blue-400 h-6 w-6"
                                            onClick={() => onEditClick(row.id)}
                                        />}
                                        {customActions.map((customAction, index) =>
                                            <div
                                                key={index}
                                                className="text-gray-500 dark:text-gray-300 cursor-pointer hover:text-blue-500 dark:hover:text-blue-400 h-6 w-6"
                                                onClick={() => customAction.onClick(row)}
                                            >
                                                {React.cloneElement(customAction.icon, {
                                                    className: "h-full w-full"
                                                })}
                                            </div>)}
                                    </Table.Cell>
                                )}
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>)}
            <div className="flex justify-center items-center mt-4">
                <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    numItemsPerPage={itemsPerPage}
                />
            </div>
            <div className="flex justify-center items-center p-6">
                <label htmlFor="itemsPerPage" className="mr-2 text-sm text-gray-700 dark:text-gray-300">Items per page:</label>
                <select
                    id="itemsPerPage"
                    value={itemsPerPage}
                    onChange={(e) => {
                        e.preventDefault();
                        handleNumItemsPerPageChange(Number(e.target.value))
                    }}
                    className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                >
                    {[5, 10, 15, 20, 100].map((num) => (
                        <option key={num} value={num}>
                            {num}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default DataTable;