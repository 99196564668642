import React, { useEffect, useState } from "react";
import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw, AtomicBlockUtils } from "draft-js";
import "draft-js/dist/Draft.css"; // Draft.js default styles
import { HiCamera, HiOutlineCheck, HiOutlineDocumentText, HiVideoCamera } from "react-icons/hi";
import { BsTypeBold, BsTypeItalic } from "react-icons/bs";
import { HiOutlineListBullet } from "react-icons/hi2";
import SaveButton from "./SaveButton";
import _ from 'lodash';

const RichTextField = ({ initialContent = null }) => {
    const [editorState, setEditorState] = useState(() => {
        if (!initialContent) return EditorState.createEmpty();
        const contentState = convertFromRaw(initialContent);
        return EditorState.createWithContent(contentState)
    }
    );

    // Custom renderer for media blocks
    const mediaBlockRenderer = (block) => {
        if (block.getType() === "atomic") {
            return {
                component: Media,
                editable: false,
            };
        }
        return null;
    };

    const blockStyleFn = (block) => {
        switch (block.getType()) {
            case "header-one":
                return "text-2xl font-bold";
            case "header-two":
                return "text-xl font-semibold";
            case "header-three":
                return "text-lg font-medium";
            case "unordered-list-item":
                return "list-disc ml-6";
            default:
                return "";
        }
    };

    return (
        <div className="rounded-lg shadow-lg bg-white dark:bg-gray-800 p-4 space-y-4">
            {/* Editor */}
            <div
                className="border border-gray-300 rounded-lg p-4 dark:border-gray-600 dark:bg-gray-700"
            // onClick={() => editorState.getSelection().hasFocus || editorState.focus()}
            >
                <Editor
                    editorState={editorState}
                    readOnly={true}
                    placeholder="It's pretty empty over here..."
                    blockStyleFn={blockStyleFn}
                    blockRendererFn={mediaBlockRenderer}
                />
            </div>
        </div>
    );
};

// Media component for rendering embedded media
const Media = (props) => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0));
    const { src } = entity.getData();
    const type = entity.getType();

    return (<iframe
        width="853"
        height="480"
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
    />);

    let media;
    if (type === "IMAGE") {
        media = <img src={src} alt="Embedded" className="w-full h-auto rounded-lg" />;
    } else if (type === "YOUTUBE") {
        media =
            <iframe
                src={src}
                frameBorder="0"
                allowFullScreen
                title="YouTube Video"
                className="w-full aspect-video rounded-lg"
            ></iframe>
            ;
    }
    return media;
};

export default RichTextField;
