// PopUp.js
import React from 'react';
import { usePopUp } from '../../hooks/usePopUp';

/**
 * Serves as a container for the actual PopUp content to 'live' in
 * @returns 
 */
const PopUp = () => {
    //Perfection
    const { isPopUpVisible, hidePopUp, popUpContent } = usePopUp();

    if (!isPopUpVisible || !popUpContent) return null;

    return (
        <div
            className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-20"
            id="my-modal"
            onClick={hidePopUp}
        >
            <div
                className="relative mx-auto p-5 border w-96 shadow-lg rounded-md bg-white"
                onClick={(e) => e.stopPropagation()}
            >
                {popUpContent}
                <button
                    className="mt-3 z-20 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    style={{ transition: "all .9s ease" }}
                    onClick={hidePopUp}
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default PopUp;
