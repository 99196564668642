import React, { createContext, useState, useEffect } from 'react';
import { subscribeCollection } from '../firebase/firestore';
import useSchool from '../hooks/useSchool';

const UsersContext = createContext();

/**
 * The context for all authentication related data such as the signed in user data, authentication loading or authentication error messages
 * @param {*} children The provider's children
 * @returns 
 */
const UsersProvider = ({ children }) => {
    const { school } = useSchool();
    const [users, setUsers] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [usersMap, setUsersMap] = useState(new Map());
    const [loading, setLoading] = useState(true);

    //The school context listens to changes to school ID and loads the school data for the newly set school ID on changes
    useEffect(() => {
        if (school) {
            const unsubscribeUsers = subscribeCollection(`institutions/${school.id}/users`, (data) => {
                console.log("Refreshed school users: ", data)
                setUsers(data);
                setLoading(false);
                if (data)
                    setUsersMap(new Map(data.map((item) => [item.id, item])));
                else
                    setUsersMap(new Map());
            });

            const unsubscribeAdmins = subscribeCollection(`admins`, (data) => {
                console.log("Refreshed admins: ", data)
                setAdmins(data);
            })

            return () => {
                unsubscribeUsers(); // Cleanup on unmount
                unsubscribeAdmins();
            }
        }
        else {
            console.log("Cleared users");
            setUsers([]);
            setUsersMap(new Map());
            setLoading(true);
        }
    }, [school])

    return (
        <UsersContext.Provider value={{ users, usersMap, admins, loading }}>
            {children}
        </UsersContext.Provider>
    );
}

export { UsersProvider, UsersContext };