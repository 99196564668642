import React from "react";

const GenericButton = ({ label, icon, handleClick, className = "" }) => {
    return (
        <button
            onClick={handleClick}
            className={`flex items-center m-5 text-white bg-blue-600 hover:bg-blue-700 rounded-lg dark:bg-blue-500 dark:hover:bg-blue-600 transition duration-150 ease-in-out shadow ${className}`}
        >
            <div className="gap-1 py-3 px-4 flex items-center">
                {/* <icon className="h-5 w-5" /> */}
                <div className="flex items-center h-5 w-5">
                    {icon}
                </div>
                <span>{label}</span>
            </div>
        </button>
    );
}

export default GenericButton;