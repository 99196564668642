import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const SkeletonLoaderRow = () => {
    return (
        <div className="space-y-4">
            {Array.from({ length: 5 }).map((_, index) => (
                <div key={index} className="flex items-center space-x-4 p-4 border rounded-lg">
                    <Skeleton circle={true} height={40} width={40} />
                    <Skeleton height={20} width="20%" />
                    <Skeleton height={20} width="10%" />
                    <Skeleton height={20} width="30%" />
                    <Skeleton height={20} width="20%" />
                    <Skeleton height={20} width="10%" />
                </div>
            ))}
        </div>
    );
};

export const SkeletonLoaderCard = () => {
    return (
        <div className="p-4 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
            {Array.from({ length: 6 }).map((_, index) => (
                <div
                    key={index}
                    className="w-full h-full p-4 border rounded-lg flex flex-col justify-between"
                >
                    <Skeleton height={120} className="mb-4" />
                    <Skeleton height={20} className="mb-2" />
                    <Skeleton height={20} width="80%" />
                </div>
            ))}
        </div>
    );

};