import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiHeart, HiOutlineFlag, HiPencil, HiPlus, HiTrash, HiX } from 'react-icons/hi';
import useScripting from '../../hooks/useScripting';
import { usePopUp } from '../../hooks/usePopUp';
import Searchbar from '../../components/shared/Searchbar';
import DataTable from '../../components/shared/Datatable';
import GenericButton from '../../components/shared/GenericButton';
import VisualScripting from '../../components/scripting/VisualScripting';
import EditPopup from '../../components/shared/EditPopup';

const Conditions = () => {
    const { conditionId, loading, conditions, deleteCondition, updateCondition, getConditionById } = useScripting();

    const [filteredConditions, setFilteredConditions] = useState([]);

    const navigate = useNavigate();
    const { showPopUp } = usePopUp();

    if (conditionId) {
        return <VisualScripting />
    }

    const handleCreate = () => {
        showPopUp(
            <CreateConditionPopUp />
        );
    };

    const columns = [
        {
            key: "name",
            label: "CONDITION NAME",
            render: (name, condition) => {
                return (
                    <div className="text-sm font-medium text-gray-900 dark:text-white">
                        {name}
                    </div>);
            }
        },
        {
            key: "nodes",
            label: "NODES",
            render: (nodes, condition) => {
                return (
                    <div className="text-sm font-medium text-gray-900 dark:text-white">
                        {nodes?.length ?? 0}
                    </div>);
            }
        },
        {
            key: "thumbnail",
            label: "THUMBNAIL SET",
            render: (thumbnail, condition) => {
                const hasThumbnail = thumbnail && thumbnail !== "";
                return (
                    <td className="px-6 py-4">
                        <span
                            className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${hasThumbnail
                                ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                                : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300'
                                }`}
                        >
                            {hasThumbnail
                                ? 'Set'
                                : 'Not set'}
                        </span>
                    </td>);
            }
        },
    ];

    return (
        <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen w-full h-full">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 h-full flex flex-col">
                <div className="flex items-center justify-between space-x-2 p-8">
                    <div className='flex gap-2 items-center'>
                        <HiOutlineFlag className="text-4xl text-gray-700 dark:text-gray-300" />
                        <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Conditions
                        </h1>
                    </div>
                    <GenericButton icon={<HiPlus />} label={"Create Condition"} handleClick={handleCreate} />
                </div>

                {/* Searchbar */}
                <Searchbar
                    data={conditions} // Pass the original assignments array
                    searchLabel={"condition name"}
                    onFilter={setFilteredConditions} // Update the filtered data
                    filterKeys={["name"]}
                />

                {/* Data table */}
                <div className="flex-grow">
                    <DataTable
                        rows={filteredConditions}
                        columns={columns}
                        isLoading={loading}
                        onDeleteClick={(condition) => {
                            deleteCondition(condition.id);
                        }}
                        onEditClick={(conditionId) => {
                            const condition = getConditionById(conditionId);
                            showPopUp(<EditPopup label="Condition" baseUrl={`conditions`} updateData={updateCondition} data={condition} />);
                        }}
                        onViewClick={(conditionId) => {
                            navigate(`${conditionId}`);
                        }} />
                </div>
            </div>
        </div>
    )
}

const CreateConditionPopUp = () => {
    const [name, setName] = useState('');
    const { addCondition } = useScripting();
    const { showPopUp } = usePopUp();

    const handleSubmit = () => {
        addCondition(name);
        onClose();
    }

    const onClose = () => {
        showPopUp(null);
    }

    return (
        <div className={`fixed inset-0 z-50 flex items-center justify-center`}>
            <div className="relative w-full max-w-2xl bg-white rounded-lg shadow dark:bg-gray-800 overflow-hidden max-h-screen p-4">
                <div className="overflow-auto max-h-[80vh]">
                    <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
                        <h3 className="text-lg font-medium text-gray-900 dark:text-gray-300 mb-2">Create Condition</h3>
                        <button
                            onClick={onClose}
                            type="button"
                            className="text-red-600 bg-transparent hover:bg-red-100 hover:text-red-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center justify-center dark:hover:bg-red-800 dark:hover:text-red-400"
                            style={{ marginTop: '8px', marginRight: '8px' }}  // Extra marge toegevoegd
                        >
                            <span className="sr-only">Close modal</span>
                            <HiX />
                        </button>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Condition name</label>
                            <input
                                type="text"
                                id="conditionName"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                                placeholder="Enter condition name"
                                required
                            />
                        </div>

                        <button type="submit" className="w-full justify-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300">
                            Create Condition
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Conditions;