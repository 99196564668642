import React, { createContext, useState, useEffect } from 'react';
import { subscribeCollection } from '../firebase/firestore';
import useSchool from '../hooks/useSchool';
import useAuth from '../hooks/useAuth';
import Roles from '../utils/roles';
import useCourses from '../hooks/useCourses';

const GradesContext = createContext();

/**
 * The context for all authentication related data such as the signed in user data, authentication loading or authentication error messages
 * @param {*} children The provider's children
 * @returns 
 */
const GradesProvider = ({ children }) => {
    const { school } = useSchool();
    const { user, role } = useAuth();
    const { courses } = useCourses();
    const [grades, setGrades] = useState([]);
    const [gradesMap, setGradesMap] = useState(new Map());
    const [loading, setLoading] = useState(true);

    //The school context listens to changes to school ID and loads the school data for the newly set school ID on changes
    useEffect(() => {
        if (school && user) {
            const unsubscribe = subscribeCollection(`institutions/${school.id}/grades`, (data) => {
                console.log("Refreshed school grades: ", data)
                setLoading(false);
                if (data) {
                    const filteredGrades = data.filter(g => g.userId === user.id || (role !== Roles.STUDENT && courses.some(c => g.courseId === c.id)) || role === Roles.ADMIN || role === Roles.SCHOOLADMIN);
                    // const filteredGrades = data;
                    setGrades(filteredGrades);
                    setGradesMap(new Map(filteredGrades.map((item) => [item.id, item])));
                }
                else {
                    setGradesMap(new Map());
                    setGrades([]);
                }
            }, { orderBy: [["createdAt", "desc"]] });

            return () => unsubscribe(); // Cleanup on unmount
        }
        else {
            console.log("Cleared grades");
            setGrades([]);
            setGradesMap(new Map());
            setLoading(true);
        }
    }, [school, courses, user])

    return (
        <GradesContext.Provider value={{ grades, loading, gradesMap }}>
            {children}
        </GradesContext.Provider>
    );
}

export { GradesProvider, GradesContext };