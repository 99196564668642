import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Checkbox } from 'flowbite-react'; // Import Flowbite components
import { HiArrowRight, HiFilter } from 'react-icons/hi';


// Register the components in Chart.js
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function CourseAnalyticsGraph({ label, navigateTo = "/main/analytics" }) {
    // Mock Data with 3 additional courses
    const mockCourses = [
        { className: "Math 101" },
        { className: "Science 202" },
        { className: "History 303" },
        { className: "English 404" },
        { className: "Biology 505" },
        { className: "Chemistry 606" },
        { className: "Physics 707" },
    ];

    const mockGrades = {
        student1: [
            { courseName: "Math 101", accuracy: 85, safety: 80, communication: 90, answers: 75 },
            { courseName: "Science 202", accuracy: 78, safety: 80, communication: 70, answers: 85 },
        ],
        student2: [
            { courseName: "History 303", accuracy: 88, safety: 90, communication: 85, answers: 92 },
            { courseName: "English 404", accuracy: 65, safety: 75, communication: 60, answers: 70 },
        ],
        student3: [
            { courseName: "Biology 505", accuracy: 92, safety: 88, communication: 80, answers: 90 },
            { courseName: "Chemistry 606", accuracy: 75, safety: 82, communication: 78, answers: 85 },
        ],
        student4: [
            { courseName: "Physics 707", accuracy: 88, safety: 92, communication: 85, answers: 91 },
        ],
    };

    const mockCurrentUser = {
        id: 'student1',
        roleName: 'Admin'
    };

    const [courseData, setCourseData] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({
        accuracy: true,
        communication: true,
        safety: true,
        answers: true,
    });
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [isCourseDropdownOpen, setIsCourseDropdownOpen] = useState(false); // Course dropdown visibility
    const [isMetricDropdownOpen, setIsMetricDropdownOpen] = useState(false); // Metric dropdown visibility
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(navigateTo);
    };

    const handleCourseChange = (event) => {
        const selected = Array.from(event.target.selectedOptions, (option) => option.value);
        setSelectedCourses(selected);
    };

    const handleFilterChange = (event) => {
        const { name, checked } = event.target;
        setSelectedFilters((prevState) => ({
            ...prevState,
            [name]: checked,
        }));
    };

    useEffect(() => {
        const aggregatedData = mockCourses.map((course) => {
            let totalAccuracy = 0;
            let totalSafety = 0;
            let totalCommunication = 0;
            let totalAnswers = 0;
            let countAccuracy = 0;
            let countSafety = 0;
            let countCommunication = 0;
            let countAnswers = 0;

            const relevantGrades = mockCurrentUser.roleName === 'Student'
                ? { [mockCurrentUser.id]: mockGrades[mockCurrentUser.id] || [] }
                : mockGrades;

            Object.keys(relevantGrades).forEach((userId) => {
                const userGrades = relevantGrades[userId];
                userGrades.forEach((grade) => {
                    if (grade.courseName === course.className) {
                        if (grade.accuracy !== undefined) {
                            totalAccuracy += grade.accuracy;
                            countAccuracy++;
                        }
                        if (grade.safety !== undefined) {
                            totalSafety += grade.safety;
                            countSafety++;
                        }
                        if (grade.communication !== undefined) {
                            totalCommunication += grade.communication;
                            countCommunication++;
                        }
                        if (grade.answers !== undefined) {
                            totalAnswers += grade.answers;
                            countAnswers++;
                        }
                    }
                });
            });

            if (countAccuracy > 0 || countSafety > 0 || countCommunication > 0 || countAnswers > 0) {
                return {
                    courseName: course.className,
                    accuracy: countAccuracy > 0 ? totalAccuracy / countAccuracy : 0,
                    safety: countSafety > 0 ? totalSafety / countSafety : 0,
                    communication: countCommunication > 0 ? totalCommunication / countCommunication : 0,
                    answers: countAnswers > 0 ? totalAnswers / countAnswers : 0,
                };
            }
            return null;
        }).filter((course) => course !== null);

        setCourseData(aggregatedData);
    }, []);

    // If no course is selected, show all courses
    const filteredData = courseData.filter((course) =>
        selectedCourses.length === 0 || selectedCourses.includes(course.courseName)
    ).map((course) => {
        const filteredCourse = {};
        if (selectedFilters.accuracy) filteredCourse.accuracy = course.accuracy;
        if (selectedFilters.communication) filteredCourse.communication = course.communication;
        if (selectedFilters.safety) filteredCourse.safety = course.safety;
        if (selectedFilters.answers) filteredCourse.answers = course.answers;
        return {
            ...course,
            ...filteredCourse,
        };
    });

    const chartData = {
        labels: filteredData.map((course) => course.courseName),
        datasets: [
            selectedFilters.accuracy && {
                label: 'Accuracy',
                data: filteredData.map((course) => course.accuracy),
                backgroundColor: '#00bcd4',
                borderRadius: 10, // Add rounded corners to the bars
            },
            selectedFilters.communication && {
                label: 'Communication',
                data: filteredData.map((course) => course.communication),
                backgroundColor: '#1e88e5',
                borderRadius: 10, // Add rounded corners to the bars
            },
            selectedFilters.safety && {
                label: 'Safety',
                data: filteredData.map((course) => course.safety),
                backgroundColor: '#29b6f6',
                borderRadius: 10, // Add rounded corners to the bars
            },
            selectedFilters.answers && {
                label: 'Answers',
                data: filteredData.map((course) => course.answers),
                backgroundColor: '#4db6ac',
                borderRadius: 10, // Add rounded corners to the bars
            },
        ].filter(Boolean),
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Courses'
                },
                grid: {
                    display: false // Removes grid lines from x-axis
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Percentage'
                },
                beginAtZero: true,
                max: 100,
                ticks: {
                    stepSize: 10
                },
                grid: {
                    borderDash: [5, 5]
                }
            }
        },
        plugins: {
            legend: {
                position: 'top'
            },
            tooltip: {
                callbacks: {
                    label: (context) => `${context.dataset.label}: ${context.parsed.y.toFixed(1)}%`
                }
            }
        }
    };

    return (
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white"></h3>
                <h4 className="text-lg font-semibold text-gray-900 dark:text-white">Average Course Performance</h4>
                <button
                    className="text-blue-500 dark:text-blue-400 hover:underline flex flex-wrap gap-2"
                    onClick={handleNavigate}
                >
                    {label}
                    <HiArrowRight className='mt-1' />
                </button>
            </div>

            {/* Dropdowns Container */}
            <div className="flex space-x-4 mb-4">
                {/* Course Filter Dropdown */}
                <Dropdown inline label={
                    <div className="flex items-center space-x-2">
                        <HiFilter className="text-3xl text-gray-600 dark:text-blue-400" />
                        <span className="text-base font-semibold text-gray-900 dark:text-white">
                            Filter by Courses
                        </span>
                    </div>
                }>
                    {mockCourses.map((course) => (
                        <Dropdown.Item key={course.className} className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700">
                            <Checkbox
                                id={course.className}
                                checked={selectedCourses.includes(course.className)}
                                onChange={() => {
                                    setSelectedCourses((prevSelected) =>
                                        prevSelected.includes(course.className)
                                            ? prevSelected.filter((course) => course !== course.className)
                                            : [...prevSelected, course.className]
                                    );
                                }}
                            />
                            {course.className}
                        </Dropdown.Item>
                    ))}
                </Dropdown>

                {/* Metric Filter Dropdown */}
                <Dropdown inline label={
                    <div className="flex items-center space-x-2">
                        <HiFilter className="text-3xl text-gray-600 dark:text-blue-400" />
                        <span className="text-base font-semibold text-gray-900 dark:text-white">
                            Filter by Metrics
                        </span>
                    </div>
                }>
                    {['accuracy', 'communication', 'safety', 'answers'].map((metric) => (
                        <Dropdown.Item key={metric} className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700">
                            <Checkbox
                                id={metric}
                                name={metric}
                                checked={selectedFilters[metric]}
                                onChange={handleFilterChange}
                            />
                            {metric.charAt(0).toUpperCase() + metric.slice(1)}
                        </Dropdown.Item>
                    ))}
                </Dropdown>
            </div>

            {/* Chart Display */}
            {filteredData.length > 0 ? (
                <div style={{ height: '400px' }}>
                    <Bar data={chartData} options={chartOptions} />
                </div>
            ) : (
                <p className="text-gray-600 dark:text-gray-400">No grades available for the selected courses.</p>
            )}
        </div>

    );
}
