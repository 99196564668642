import React, { useState, useEffect } from "react";
import { usePopUp } from "../hooks/usePopUp";
import { TabsUnderlined } from "../components/shared/TabNavigation";
import Searchbar from "../components/shared/Searchbar";
import { HiBookOpen, HiX } from "react-icons/hi";
import { Dropdown, DropdownItem } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import useScenarios from "../hooks/useScenarios";
import useAuth from "../hooks/useAuth";
import Thumbnail from "../components/shared/Thumbnail";
import useSchool from "../hooks/useSchool";
import { Navigate, useNavigate } from "react-router-dom";
import CreateAssignment from "../components/assignments/CreateAssignment";
import ConfirmPopup from "../components/shared/ConfirmPopup";
import { HiExclamationTriangle } from "react-icons/hi2";
import { SkeletonLoaderCard } from "../components/shared/SkeletonLoader";
import { NotificationTypes } from "../utils/notifications";
import { useNotification } from "../hooks/useNotification";
import Empty from "../components/shared/Empty";
import useUsers from "../hooks/useUsers";
import { useInworld } from "../hooks/useInworld";
import defaultImage from '../assets/images/AI Character Placeholder (1).png'


const Tabs = {
   COURSETA: "Courseta Scenarios",
   CUSTOM: "Custom Scenarios",

   AI: "Courseta AI",
   USERAI: "My AI",
   SHARED: "Shared With You"
}
const tabs = [Tabs.COURSETA, Tabs.CUSTOM, Tabs.AI, Tabs.USERAI, Tabs.SHARED];

const Library = () => {
   const [tab, setTab] = useState(0);
   const [filteredScenarios, setFilteredScenarios] = useState([]);
   const [filteredSharedScenarios, setFilteredSharedScenarios] = useState([]);
   const [filteredUserScenarios, setFilteredUserScenarios] = useState([]);
   const [filteredCharacters, setFilteredCharacters] = useState([]);
   const [filteredUserCharacters, setFilteredUserCharacters] = useState([]);
   const { scenarios, allScenarios, userMadeScenarios, sharedScenarios, loading } = useScenarios();
   const { isAdmin } = useAuth();
   const { characters, userMadeCharacters, loadings, error } = useInworld();
   const navigate = useNavigate();

   const defaultCharacterImage = 'https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png';

   // const extractCharacterData = (character) => ({
   //    name: character.defaultCharacterDescription?.givenName || 'Unnamed',
   //    avatarImg: character.defaultCharacterAssets?.avatarImg || defaultCharacterImage,
   //    description: character.defaultCharacterDescription?.description || 'No description provided',
   //    characterId: character.name.split('/').pop(),
   // });

   const getSearchData = () => {
      switch (tabs[tab]) {
         case Tabs.COURSETA:
            return isAdmin ? allScenarios : scenarios;
         case Tabs.CUSTOM:
            return userMadeScenarios;
         case Tabs.AI:
            return characters;
         case Tabs.USERAI:
            return userMadeCharacters;
         case Tabs.SHARED:
            return sharedScenarios;
         default:
            return [];
      }
   }

   const setSearchData = (data) => {
      switch (tabs[tab]) {
         case Tabs.COURSETA:
            setFilteredScenarios(data);
            break;
         case Tabs.CUSTOM:
            setFilteredUserScenarios(data);
            break;
         case Tabs.AI:
            setFilteredCharacters(data);
            break;
         case Tabs.USERAI:
            setFilteredUserCharacters(data);
            break;
         case Tabs.SHARED:
            setFilteredSharedScenarios(data);
            break;
      }
   }

   return (
      <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen w-full h-full">
         <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 w-full h-full flex flex-col">
            <div className="flex items-center space-x-2 p-8">
               <HiBookOpen className="text-4xl text-gray-700 dark:text-gray-300" />
               <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Library
               </h1>
            </div>
            <Searchbar
               data={getSearchData()}
               searchLabel={"Characters and Scenarios"}
               filterKeys={["name"]}
               onFilter={setSearchData}
            />
            <TabsUnderlined tabs={tabs} activeTab={tab} setActiveTab={setTab} />
            {loading ? <SkeletonLoaderCard /> : <div>
               {tabs[tab] === Tabs.COURSETA && (
                  <div>
                     <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {isAdmin && <AddNewCourseCard label1={'Create New'} label2={'Click here to create a new Courseta Scenario'} fromCourseta={true} />}
                        {filteredScenarios.map((scenario) => (
                           <LibraryCard
                              key={scenario.id}
                              scenario={scenario}
                              fromCourseta={true}
                           />
                        ))}
                     </div>
                  </div>
               )}
               {tabs[tab] === Tabs.CUSTOM && (
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 overflow-visible">
                     <AddNewCourseCard label1={'Create New'} label2={'Click Here to Create Your Own Scenario!'} />
                     {filteredUserScenarios.map((scenario) => (
                        <LibraryCard
                           key={scenario.id}
                           scenario={scenario}
                        />
                     ))}
                  </div>
               )}
               {tabs[tab] === Tabs.SHARED && (
                  filteredSharedScenarios.length > 0 ?
                     <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {filteredSharedScenarios.map((scenario) => (
                           <LibraryCard
                              key={scenario.id}
                              scenario={scenario}
                              shared={true}
                           />
                        ))}
                     </div> : <Empty className="w-full mt-20" message="No scenarios have been shared with you yet." subMessage="Other teachers can share their scenarios with you to view and create assignments with." />
               )}
               {tabs[tab] === Tabs.AI &&
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
                     {isAdmin && <AddNewCharacterCard fromCourseta={true} />}
                     {filteredCharacters.map((character) => (
                        <CharacterCard character={character} fromCourseta={true} />
                     ))}
                  </div>
               }
               {tabs[tab] === Tabs.USERAI &&
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
                     <AddNewCharacterCard />
                     {filteredUserCharacters.map((character) => (
                        <CharacterCard character={character} />
                     ))}
                  </div>
               }
            </div>}
         </div>
      </div>
   );
}

const CharacterCard = ({ character, fromCourseta = false }) => {
   const { name, thumbnail, description, brainName } = character;

   const { school } = useSchool();
   const { isAdmin } = useAuth();
   const navigate = useNavigate();

   const characterId = brainName.split("/").pop();

   const path = (fromCourseta ? `characters/${character.id}` : `institutions/${school.id}/characters/${character.id}`);

   return <div
      key={characterId}
      className="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 flex flex-col justify-between"
   >
      <div className="w-full h-full bg-gray-300 rounded-lg mb-4">
         <Thumbnail thumbnailURL={`${path}/${character.thumbnail}`} altName={character.name} defaultImg={defaultImage} />
      </div>
      <h3 className="text-lg font-bold mb-2 text-gray-900 dark:text-white">{name}</h3>
      {/* <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">{description}</p> */}
      <div className="relative group">
         <button
            className="text-sm text-gray-600 dark:text-gray-400 underline hover:text-gray-800 dark:hover:text-gray-200"
         >
            (i)
         </button>
         {/* Hover Info Bubble */}
         <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 hidden group-hover:block bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-200 text-sm p-3 rounded-lg shadow-lg z-10 max-w-xs w-max">
            {description}
         </div>
      </div>
      {(!fromCourseta || isAdmin) && <button
         onClick={(e) => {
            e.preventDefault();
            if (fromCourseta) {
               navigate(`character/${characterId}?courseta`);
            }
            else {
               navigate(`character/${characterId}`);
            }
         }}
         className="w-full flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium"
      >
         Edit Character
      </button>}
   </div>
}

const LibraryCard = ({ scenario, fromCourseta = false, shared = false }) => {
   const { isAdmin } = useAuth();
   const { showPopUp } = usePopUp();
   const { school } = useSchool();
   const { deleteScenario } = useScenarios();
   const { showNotification } = useNotification();
   const navigate = useNavigate();

   const handleScenarioClick = () => {
      // if (fromCourseta && !school.availableScenarios.includes(scenario.id)) {
      //    showNotification(NotificationTypes.WARNING, "This scenario is not in your schools available scenarios.");
      //    return;
      // }
      navigate(`scenario/${scenario.id}${fromCourseta ? "?courseta" : ""}`);
   };

   const handleCreateTemplate = () => {
      showPopUp(<CreateScenario fromCourseta={false} scenario={scenario} />);
   };

   const handleScenarioInfo = () => {
      console.log("View info for scenario:", scenario);
   };

   const handleCreateAssignment = () => {
      showPopUp(<CreateAssignment scenarioId={scenario.id} />);
   };

   const handleDeleteConfirm = async () => {
      await deleteScenario(scenario.id, fromCourseta);
   }

   const handleDelete = () => {
      showPopUp(<ConfirmPopup color={'red'} description={"Are you sure you want to delete this scenario. This action is irreversible."} icon={<HiExclamationTriangle />} label={"Delete"} onConfirm={handleDeleteConfirm} />);
   }

   const handleShare = () => {
      showPopUp(<ShareScenario scenario={scenario} />);
   };

   return (
      <div className="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 flex flex-col relative">
         <div className="w-full h-64 aspect-square flex">
            <Thumbnail
               altName={scenario.name}
               thumbnailURL={`${fromCourseta ? `scenarios` : `institutions/${school.id}/scenarios`}/${scenario.id}/${scenario.thumbnail}`}
            />
         </div>

         {/* Card content */}
         <div className="p-4 flex flex-grow flex-col justify-between">
            <div>
               <h4 className="text-md font-semibold text-gray-800 dark:text-white truncate">
                  {scenario.name}
               </h4>
               <p className="text-gray-600 dark:text-gray-400 mb-4">{scenario.summary}</p>
            </div>

            <div className="relative">
               <Dropdown
                  label={
                     <button className="flex items-center justify-center w-full border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium mt-4">
                        See Options
                        <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
                     </button>
                  }
                  inline={true}
                  arrowIcon={false}
                  className="absolute top-full mt-2 w-max z-50"
               >
                  <DropdownItem onClick={() => handleScenarioInfo()}>
                     <FontAwesomeIcon icon={faEllipsisV} className="mr-2" /> See Info
                  </DropdownItem>

                  {(isAdmin || !fromCourseta) && !shared && (
                     <DropdownItem onClick={() => handleScenarioClick()}>
                        <FontAwesomeIcon icon={faEllipsisV} className="mr-2" /> Edit
                     </DropdownItem>
                  )}

                  {!shared && <DropdownItem onClick={() => handleCreateTemplate()}>
                     <FontAwesomeIcon icon={faEllipsisV} className="mr-2" /> Duplicate
                  </DropdownItem>}

                  <DropdownItem onClick={() => handleCreateAssignment()}>
                     <FontAwesomeIcon icon={faEllipsisV} className="mr-2" /> Create Assignment
                  </DropdownItem>

                  {!fromCourseta && !shared && <DropdownItem onClick={() => handleShare()}>
                     <FontAwesomeIcon icon={faEllipsisV} className="mr-2" /> Share
                  </DropdownItem>}

                  {(!fromCourseta || isAdmin) && !shared && <DropdownItem onClick={() => handleDelete()}>
                     <FontAwesomeIcon icon={faEllipsisV} className="mr-2 text-red-500" />{" "}
                     <div className="text-red-500">Delete</div>
                  </DropdownItem>}
               </Dropdown>
            </div>
         </div>
      </div>
   );

};

const AddNewCharacterCard = ({ fromCourseta = false }) => {
   const { showPopUp } = usePopUp();

   const handleCreate = () => {
      showPopUp(<CreateCharacter />);
   }

   return (
      <div
         onClick={handleCreate}
         className="cursor-pointer p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 flex flex-col justify-between h-full hover:bg-gray-100 dark:hover:bg-gray-700 transition duration-200 ease-in-out"
      >
         {/* Placeholder Image/Graphic */}
         <div className="flex-grow flex items-center justify-center">
            <span className="text-blue-500 text-6xl">+</span>
         </div>

         {/* Card content */}
         <div className="flex-grow p-4 flex flex-col justify-between text-center">
            <div>
               <h4 className="text-md font-semibold text-gray-800 dark:text-white">
                  Create New
               </h4>
               <p className="text-gray-600 dark:text-gray-400 mt-2">
                  Click Here to Create a New AI Character
               </p>
            </div>
         </div>
      </div>
   );
}

const AddNewCourseCard = ({ label1, label2, fromCourseta = false }) => {
   const { showPopUp } = usePopUp();

   const handleCreate = () => {
      showPopUp(<CreateScenario fromCourseta={fromCourseta} />);
   }

   return (
      <div
         onClick={handleCreate}
         className="cursor-pointer p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 flex flex-col justify-between h-full hover:bg-gray-100 dark:hover:bg-gray-700 transition duration-200 ease-in-out"
      >
         {/* Placeholder Image/Graphic */}
         <div className="flex-grow flex items-center justify-center">
            <span className="text-blue-500 text-6xl">+</span>
         </div>

         {/* Card content */}
         <div className="flex-grow p-4 flex flex-col justify-between text-center">
            <div>
               <h4 className="text-md font-semibold text-gray-800 dark:text-white">
                  {label1}
               </h4>
               <p className="text-gray-600 dark:text-gray-400 mt-2">
                  {label2}
               </p>
            </div>
         </div>
      </div>
   );
};

const CreateCharacter = ({ fromCourseta = false }) => {
   const { showPopUp } = usePopUp();
   const [name, setName] = useState("");
   const [description, setDescription] = useState("");
   const [motivation, setMotivation] = useState("");
   const { createCharacter } = useInworld();

   const onClose = () => {
      showPopUp(null);
   }

   const handleSubmit = async () => {
      onClose();
      await createCharacter(name, description, motivation, fromCourseta);
   }

   return (
      <div className={`fixed inset-0 z-50 flex items-center justify-center`}>
         <div className="relative w-full max-w-2xl bg-white rounded-lg shadow dark:bg-gray-800 overflow-hidden max-h-screen p-4">
            <div className="overflow-auto max-h-[80vh]">
               <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-gray-300 mb-2">Create Character</h3>
                  <button
                     onClick={onClose}
                     type="button"
                     className="text-red-600 bg-transparent hover:bg-red-100 hover:text-red-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center justify-center dark:hover:bg-red-800 dark:hover:text-red-400"
                     style={{ marginTop: '8px', marginRight: '8px' }}  // Extra marge toegevoegd
                  >
                     <span className="sr-only">Close modal</span>
                     <HiX />
                  </button>
               </div>

               <form onSubmit={handleSubmit}>
                  <div className="mb-6">
                     <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Character name</label>
                     <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="shadow-sm mb-4 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                        placeholder="John Doe"
                        required
                     />
                     <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Character description</label>
                     <input
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="shadow-sm mb-4 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                        placeholder="55 year old patient"
                        required
                     />
                     <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Character motivation</label>
                     <input
                        type="text"
                        value={motivation}
                        onChange={(e) => setMotivation(e.target.value)}
                        className="shadow-sm mb-4 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                        placeholder="Wants to go home and answer the nurse's questions"
                        required
                     />
                  </div>
                  <button type="submit" className="w-full justify-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300">
                     Create Character
                  </button>
               </form>
            </div>
         </div>
      </div>
   );
}

const CreateScenario = ({ fromCourseta = false, scenario = null }) => {
   const { showPopUp } = usePopUp();
   const [name, setName] = useState("");
   const { createScenario } = useScenarios();

   const onClose = () => {
      showPopUp(null);
   }

   const handleSubmit = async () => {
      onClose();
      await createScenario(name, fromCourseta, scenario);
   }

   return (
      <div className={`fixed inset-0 z-50 flex items-center justify-center`}>
         <div className="relative w-full max-w-2xl bg-white rounded-lg shadow dark:bg-gray-800 overflow-hidden max-h-screen p-4">
            <div className="overflow-auto max-h-[80vh]">
               <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-gray-300 mb-2">{scenario ? 'Duplicate' : 'Create'} {scenario ? scenario.name : "Scenario"}</h3>
                  <button
                     onClick={onClose}
                     type="button"
                     className="text-red-600 bg-transparent hover:bg-red-100 hover:text-red-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center justify-center dark:hover:bg-red-800 dark:hover:text-red-400"
                     style={{ marginTop: '8px', marginRight: '8px' }}  // Extra marge toegevoegd
                  >
                     <span className="sr-only">Close modal</span>
                     <HiX />
                  </button>
               </div>

               <form onSubmit={handleSubmit}>
                  <div className="mb-6">
                     <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Scenario name</label>
                     <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                        placeholder="Enter scenario name"
                        required
                     />
                  </div>
                  <button type="submit" className="w-full justify-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300">
                     {scenario ? 'Duplicate' : 'Create'} Scenario
                  </button>
               </form>
            </div>
         </div>
      </div>
   );
}

const ShareScenario = ({ scenario }) => {
   const { user } = useAuth();
   const { users } = useUsers();
   const { updateShared } = useScenarios();
   const [selectedTeachers, setSelectedTeachers] = useState(scenario.shared ?? []);

   //Filter on teachers
   const teachers = users.filter(u => u.role === "Teacher" && u.id !== user.id);

   const { showPopUp } = usePopUp();

   const handleTeacherSelect = (teacherId) => {
      setSelectedTeachers(prevSelected =>
         prevSelected.includes(teacherId)
            ? prevSelected.filter(id => id !== teacherId)
            : [...prevSelected, teacherId]
      );
   };

   const handleShare = async () => {
      updateShared(scenario.id, selectedTeachers);
      onClose();
   };

   const onClose = () => {
      showPopUp(null);
   }

   return (
      <div className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center">
         <div className="relative p-4 w-full max-w-2xl h-full md:h-auto bg-white rounded-lg shadow dark:bg-gray-800">
            <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
               <h3 className="text-lg font-medium text-gray-900 dark:text-gray-300 mb-2">Share Scenario</h3>
               <button onClick={onClose} className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                  Close
               </button>
            </div>
            <div className="mb-4">
               <h4 className="text-md font-semibold text-gray-900 dark:text-gray-300">Select Teachers to Share</h4>
               {teachers.length > 0 ? (
                  <div className="max-h-64 overflow-y-auto">
                     {teachers.map(teacher => (
                        <div key={teacher.id} className="flex items-center mb-2">
                           <input
                              type="checkbox"
                              id={`teacher-${teacher.id}`}
                              checked={selectedTeachers.includes(teacher.id)}
                              onChange={() => handleTeacherSelect(teacher.id)}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                           />
                           <label htmlFor={`teacher-${teacher.id}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                              {teacher.firstName + ' ' + teacher.lastName} ({teacher.email})
                           </label>
                        </div>
                     ))}
                  </div>
               ) : (
                  <p className="text-sm text-gray-500 dark:text-gray-400">No teachers found in your school.</p>
               )}
            </div>
            <button
               onClick={handleShare}
               className="w-full flex items-center justify-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300 space-x-2"
            >
               Share Template
            </button>
         </div>
      </div>
   );
}

export default Library;