import React, { useState } from 'react';
import { TabsUnderlined } from '../components/shared/TabNavigation';
import { HiArrowRight, HiCollection, HiPlus } from 'react-icons/hi';
import Searchbar from '../components/shared/Searchbar';
import { useNavigate } from 'react-router-dom';
import ScenarioDetails from '../components/simstudio/ScenarioDetails';
import CharacterDetails from '../components/simstudio/CharacterDetails';
import { ScenarioCustomizationProvider } from '../contexts/ScenarioCustomizationContext';
import Roles from '../utils/roles';
import useAuth from '../hooks/useAuth';
import useScenarios from '../hooks/useScenarios';
import Thumbnail from '../components/shared/Thumbnail';
import { useInworld } from '../hooks/useInworld';
import GenericButton from '../components/shared/GenericButton';

const Tabs = {
    SCENARIOS: "Scenario Creator",
    AI: "AI Characters",
    COURSETASCENARIOS: "Courseta Scenarios",
};
const normalTabs = [Tabs.SCENARIOS, Tabs.AI];
const adminTabs = [Tabs.SCENARIOS, Tabs.AI, Tabs.COURSETASCENARIOS];

const SimStudio = () => {
    const [tab, setTab] = useState(0);
    const [filteredResult, setFilteredResults] = useState("");
    const navigate = useNavigate();
    const { role } = useAuth();
    const { allScenarios, userMadeScenarios } = useScenarios();
    const { characters, loading, error } = useInworld();
    const tabs = role === Roles.ADMIN ? adminTabs : normalTabs;

    const defaultCharacterImage = 'https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png';

    const renderCharacterGrid = () => {
        if (loading) {
            return (
                <div className="col-span-4 text-center text-gray-600 dark:text-white">
                    Loading....
                </div>
            );
        }

        if (error) {
            return (
                <div className="col-span-4 text-center text-red-600 dark:text-red-400">
                    {error}
                </div>
            );
        }

        if (characters.length === 0) {
            return (
                <div className="col-span-4 text-center text-gray-600 dark:text-white">
                    No characters available.
                </div>
            );
        }

        const extractCharacterData = (character) => ({
            givenName: character.defaultCharacterDescription?.givenName || 'Unnamed',
            avatarImg: character.defaultCharacterAssets?.avatarImg || defaultCharacterImage,
            description: character.defaultCharacterDescription?.description || 'No description provided',
            characterId: character.name.split('/').pop(),
        });

        const characterData = characters.map(extractCharacterData);

        return (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
                {characterData.map(({ givenName, avatarImg, description, characterId }) => (
                    <div
                        key={characterId}
                        className="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 flex flex-col justify-between"
                    >
                        <div className="w-full h-full bg-gray-300 rounded-lg mb-4">
                            <img
                                src={avatarImg}
                                alt={givenName}
                                className="object-cover w-full h-full rounded-lg"
                            />
                        </div>
                        <h3 className="text-lg font-bold mb-2 text-gray-900 dark:text-white">{givenName}</h3>
                        <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">{description}</p>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                console.log(characterId);
                                navigate(`character/${characterId}`);
                            }}
                            className="w-full flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium"
                        >
                            Edit Character
                        </button>
                    </div>
                ))}
            </div>
        );
    };

    const handleCreate = () => {
    }

    return (
        <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen w-full h-full">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 h-full flex flex-col">
                <div className="flex items-center justify-between space-x-2 p-8">
                    <div className='flex items-center gap-2'>
                        <HiCollection className="text-4xl text-gray-700 dark:text-gray-300" />
                        <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Sim Studio
                        </h1>
                    </div>
                    <GenericButton icon={<HiPlus />} label="Create" handleClick={handleCreate} />
                </div>
                <Searchbar
                    data={filteredResult}
                    searchLabel={"Characters and Scenarios"}
                    onFilter={setFilteredResults}
                />
                <TabsUnderlined tabs={tabs} activeTab={tab} setActiveTab={setTab} />

                {tabs[tab] === Tabs.SCENARIOS && (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
                        {userMadeScenarios?.map((s) => (
                            <ScenarioCard
                                key={s.id}
                                scenario={s}
                            />
                        ))}
                    </div>
                )}
                {tabs[tab] === Tabs.AI && (
                    <div>
                        {renderCharacterGrid()}
                    </div>
                )}
                {tabs[tab] === Tabs.COURSETASCENARIOS && role === Roles.ADMIN && (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
                        {allScenarios?.map((s) => (
                            <ScenarioCard
                                key={s.id}
                                scenario={s}
                                fromCourseta={true}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

const ScenarioCard = ({ scenario, fromCourseta = false }) => {
    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();
        navigate(`scenario/${scenario.id}${fromCourseta ? "?courseta" : ""}`);
    }

    return (
        <div className="bg-white dark:bg-gray-800 border dark:border-gray-700 rounded-lg shadow-md overflow-hidden">
            <Thumbnail altName={scenario.name} thumbnailURL={scenario.scenarioPicture} />
            <div className="p-5">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {scenario.name}
                </h5>
                <button
                    onClick={handleClick}
                    className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                    See more
                    <HiArrowRight />
                </button>
            </div>
        </div>
    );
};

export default SimStudio;
