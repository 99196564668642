import React, { useEffect, useState } from 'react';
//import { uploadFile, getFileUrl, generatePdfThumbnail, deleteFile } from '../../../../services/FirebaseService';
import useScenarios from '../../../hooks/useScenarios';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/Delete';
import GenericButton from '../../shared/GenericButton';
import { HiDocument, HiPlus } from 'react-icons/hi';
import useScenarioCustomization from '../../../hooks/useScenarioCustomization';
import { deleteFile, getFileUrl, uploadFile } from '../../../firebase/storage';
import SingleFileUpload from '../../shared/SingleFileUpload';
import { usePopUp } from '../../../hooks/usePopUp';
import { useSearchParams } from 'react-router-dom';
import { useNotification } from '../../../hooks/useNotification';
import { NotificationTypes } from '../../../utils/notifications';
import useAuth from '../../../hooks/useAuth';
import Roles from '../../../utils/roles';
import useSchool from '../../../hooks/useSchool';
import DeleteButton from '../../shared/DeleteButton';

const EMRNoteCard = ({ title, author, templateImage, onClick, onDelete }) => (
    <div className="border rounded-lg p-2 shadow hover:shadow-md cursor-pointer transition-transform transform hover:scale-105 relative bg-gray-50 w-48">
        <div
            className="h-full w-full bg-gray-100 rounded flex justify-center items-center overflow-hidden"
            onClick={onClick}
        >
            {templateImage ? (
                typeof templateImage === 'string' ? (
                    <img src={templateImage} alt={title} className="object-contain h-full w-full" />
                ) : React.isValidElement(templateImage) ? (
                    templateImage
                ) : (
                    <img src={URL.createObjectURL(templateImage)} alt={title} className="object-contain h-full w-full" />
                )
            ) : (
                <span className="text-sm text-gray-400">+</span>
            )}
        </div>
        <div className="mt-1">
            <h3 className="font-semibold text-xs truncate">{title}</h3>
            <p className="text-gray-500 text-xs">{author}</p>
        </div>
        <DeleteButton iconOnly={true} onDelete={onDelete} />
        {/* <button
            className="absolute top-1 right-1 bg-red-500 text-white p-1 rounded hover:bg-red-600 focus:outline-none"
            onClick={(e) => {
                e.stopPropagation();
                onDelete();
            }}
        >
            <DeleteIcon fontSize="small" />
        </button> */}
    </div>
);

const EMRNotePopup = ({ notePath, setPatientField, patient }) => {
    const [name, setName] = useState("");

    const { showNotification } = useNotification();
    const { showPopUp } = usePopUp();

    const handleUpload = async (file) => {
        if (!name || name === "") {
            showNotification(NotificationTypes.WARNING, `Please enter a valid name for the EMR note.`);
            return;
        }
        showPopUp(null);
        try {
            await uploadFile(file, notePath);
            const updatedNotes = { ...patient.emrNotes };
            updatedNotes[name] = file.name;
            setPatientField('emrNotes', updatedNotes);
            showNotification(NotificationTypes.SUCCESS, `EMR Note: ${file.name} uploaded successfully.`);
        }
        catch (error) {
            console.error("Error uploading EMR note: ", error);
            showNotification(NotificationTypes.DANGER, `An error occurred.`);
        }
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg flex flex-col shadow-lg p-6 w-96">
                <div className="p-4">
                    <h2 className="mb-3 text-lg font-semibold">Note Name</h2>
                    <input
                        type="text"
                        id="noteName"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                        placeholder="Enter EMR note name"
                        required
                    />
                </div>
                <SingleFileUpload onUpload={handleUpload} autoClose={false} />
            </div>
        </div>);
}

const EMRNotesTab = () => {
    const { getNotePath, patient, setPatientField } = useScenarioCustomization();

    const notePath = getNotePath();

    const { showNotification } = useNotification();
    const { showPopUp } = usePopUp();

    const handleRemoveNote = async (category) => {
        try {
            const fileName = patient.emrNotes[category];
            if (fileName) {
                await deleteFile(`${notePath}/${fileName}`);
            }

            const updatedPatientEMRNotes = { ...patient.emrNotes };
            delete updatedPatientEMRNotes[category];
            setPatientField('emrNotes', updatedPatientEMRNotes);
            showNotification(NotificationTypes.SUCCESS, "Deleted note successfully.");
        } catch (error) {
            console.error(`Error removing file for category ${category}:`, error);
            showNotification(NotificationTypes.DANGER, "An error occurred.");
        }
    };

    return (
        <div className="p-4 bg-white rounded-md border">
            <h2 className="text-xl font-semibold mb-2">EMR Notes</h2>
            <p className="mb-2 text-gray-500">Manage patient EMR notes.</p>

            <div className="mb-4 flex items-center space-x-2">
                <GenericButton icon={<HiDocument />} label={'Create EMR Note'} handleClick={(e) => { e.preventDefault(); showPopUp(<EMRNotePopup notePath={notePath} patient={patient} setPatientField={setPatientField} />) }} />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {Object.keys(patient.emrNotes).map((category) => (
                    <div key={category} className="p-2 border rounded shadow-sm bg-gray-50">
                        <div className="flex items-center justify-between mb-2">
                            <h3 className="text-sm font-medium">{category}</h3>
                        </div>
                        {patient.emrNotes[category] && (
                            <EMRNoteCard
                                title={patient.emrNotes[category]}
                                author="Uploaded PDF"
                                templateImage={patient.emrNotes[category].thumbnail ? <img src={patient.emrNotes[category].thumbnail} alt="PDF Thumbnail" /> : <PictureAsPdfIcon style={{ fontSize: 40 }} />}
                                onClick={async () => {
                                    const url = await getFileUrl(`${notePath}/${patient.emrNotes[category]}`);
                                    window.open(url, '_blank')
                                }}
                                onDelete={() => handleRemoveNote(category)}
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EMRNotesTab;
