import { useContext, useEffect, useState } from "react";
import { AssignmentsContext } from "../contexts/AssignmentsContext";
import { useParams, useSearchParams } from "react-router-dom";
import { getFileUrl } from "../firebase/storage";
import useScenarios from "./useScenarios";
import { updateDocument } from "../firebase/firestore";
import useAuth from "./useAuth";
import useSchool from "./useSchool";

/**
 * Hook for all users functionality
 * @returns All usable variables and functions from this hook
 */
const useAssignments = () => {
    const { assignments, assignmentsMap, loading, publishAssignment, deleteAssignment, createAssignment } = useContext(AssignmentsContext);
    const [assignment, setAssignment] = useState(null);
    const { assignmentId } = useParams();
    const { getScenarioByID, isCoursetaScenario } = useScenarios();
    const { updateUser } = useAuth();
    const { school } = useSchool();

    const [searchParams] = useSearchParams();

    const perform = searchParams.get("perform");

    //Maybe this should go in a separate hook for performing assignments only?
    const [prebriefing, setPrebriefing] = useState(null);

    //Update selected assignment whenever the ID changes
    useEffect(() => {
        if (assignmentId) {
            setAssignment(getAssignmentById(assignmentId));
            if (perform != null) {
                console.log("Started assignment: ", assignmentId);
                setStartedAssignment(assignmentId);
            }
        }
        else {
            setAssignment(null);
        }
    }, [assignmentId, assignments])

    //Load assignment related data
    useEffect(() => {
        const loadPrebriefing = async () => {
            const scenario = getScenarioByID(assignment.scenarioId);
            if (scenario) {
                const path = `${isCoursetaScenario(scenario.id) ? `scenarios/${scenario.id}` : `institutions/${school.id}/scenarios/${scenario.id}`}/${scenario.prebriefing}`
                console.log("Loading prebriefing: ", path);
                const url = await getFileUrl(path);
                setPrebriefing(url);
            }
        }

        if (assignment) {
            loadPrebriefing();
        }
    }, [assignment])

    const getAssignmentById = (assignmentId) => {
        if (!assignmentsMap || assignmentsMap.size <= 0) {
            console.error("Error trying to get assignment by ID: No assignments!");
            return null;
        }

        return assignmentsMap.get(assignmentId);
    }

    const getActiveAssignments = () => {
        return assignments.filter((a) => {
            const now = new Date(); // Current timestamp
            const dueDate = a.dueDate?.toDate(); // Convert Firebase Timestamp to JS Date if `dueDate` exists

            // Check if the dueDate is not in the past
            return !dueDate || dueDate >= now;
        });
    }

    const getAssignmentsForCourse = (courseId, filterExpired = false, filterActive = false) => {
        return assignments.filter((a) => {
            const isForCourse = a.courseId === courseId;

            if (filterExpired) {
                const now = new Date(); // Current timestamp
                const dueDate = a.dueDate?.toDate(); // Convert Firebase Timestamp to JS Date if `dueDate` exists

                // Check if the dueDate is not in the past
                return isForCourse && (!dueDate || dueDate >= now);
            }
            if (filterActive) {
                const now = new Date(); // Current timestamp
                const dueDate = a.dueDate?.toDate(); // Convert Firebase Timestamp to JS Date if `dueDate` exists

                // Check if the dueDate is not in the future
                return isForCourse && (!dueDate || dueDate < now);
            }

            return isForCourse;
        });
    }

    const setStartedAssignment = async (id) => {
        await updateUser({ startedAssignment: id });
    }

    return { assignments, loading, assignment, prebriefing, getAssignmentById, publishAssignment, deleteAssignment, createAssignment, setStartedAssignment, getAssignmentsForCourse, getActiveAssignments }; // Access all context values directly
};

export default useAssignments;