import { Timestamp } from "firebase/firestore";

export const chunkArray = (array, size) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
        chunks.push(array.slice(i, i + size));
    }
    return chunks;
};

/**
 * Converts a firestore docs array to a map where the keys are the doc ID's for quick lookup
 * @param {*} docsArray The array of firestore docs
 * @returns A map holding the documents
 */
export const convertDocumentsToMap = (docsArray) => {
    const docsMap = new Map();
    docsArray.forEach((doc) => {
        docsMap.set(doc.id, doc);
    });
    return docsMap;
}

/** 
* Convert "yyyy-MM-dd" string to Firebase Timestamp
*/
export const toFirebaseTimestamp = (dateString) => {
    const dateParts = dateString.split("-");
    const dateObject = new Date(
        parseInt(dateParts[0]),
        parseInt(dateParts[1]) - 1,
        parseInt(dateParts[2])
    );
    return Timestamp.fromDate(dateObject);
};

/** 
 * Convert Firebase Timestamp to "yyyy-MM-dd" string
*/
export const fromFirebaseTimestamp = (timestamp) => {
    const date = timestamp.toDate();
    return [
        date.getFullYear(),
        String(date.getMonth() + 1).padStart(2, "0"),
        String(date.getDate()).padStart(2, "0"),
    ].join("-");
};

export const convertToTimePickerValue = (timestamp) => {
    if (!timestamp) return "";
    const date = timestamp.toDate();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
};

export const convertTimePickerValueToTimestamp = (timestamp, time) => {
    if (!time) return null;
    const [hours, minutes] = time.split(":").map(Number);
    const now = timestamp.toDate(); // Use today's date for the timestamp
    now.setHours(hours, minutes, 0, 0); // Set hours, minutes, seconds, milliseconds
    return Timestamp.fromDate(now);
};

export const transformYoutubeLink = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const matches = regex.exec(url);
    const youtubeId = matches && matches[1];
    return youtubeId ? `https://www.youtube.com/embed/${youtubeId}?enablejsapi=1` : url;
};
