import { useEffect, useState } from "react";
import { getFileUrl } from "../../firebase/storage";
import logo from "../../assets/images/Courseta_Logo.png";

const Thumbnail = ({ thumbnailURL, altName, defaultImg = logo }) => {
    const [pictureUrl, setPictureUrl] = useState("")

    useEffect(() => {
        const fetchPicture = async () => {
            const url = await getFileUrl(thumbnailURL);
            if (url) {
                setPictureUrl(url);
            }
            else {
                setPictureUrl(defaultImg);
            }
        }
        fetchPicture();
    }, [])

    return (
        <div className="w-full aspect-square">
            {pictureUrl ? <img
                src={pictureUrl}
                alt={altName}
                loading="lazy"
                className="w-full h-full object-cover"
            /> : <p className="flex items-center justify-center h-full">Loading thumbnail...</p>}
        </div>
    );
}

export default Thumbnail;