import React, { useState } from 'react';
import { useInworld } from '../../../hooks/useInworld';
import { COLLOQUIALISM_OPTIONS } from '../../../utils/inworld';

const CustomDialogueStyle = () => {
    const { character, setCharacterField } = useInworld();

    const updateCustomDialogStyle = (fieldName, value) => {
        const updatedCustomDialogStyles = [
            {
                ...character.defaultCharacterDescription.customDialogStyles[0],
                [fieldName]: value,
            },
        ];
        const updatedCharacterDescription = {
            ...character.defaultCharacterDescription,
            customDialogStyles: updatedCustomDialogStyles,
        };
        setCharacterField("defaultCharacterDescription", updatedCharacterDescription);
    };

    const generateExampleSentence = () => {
        const adjectives = character.defaultCharacterDescription?.customDialogStyles[0]?.adjectives || [];
        return `${character.defaultCharacterDescription?.givenName} tends to give ${adjectives.join(', ')} responses.`;
    };

    const handleAddAdjective = (e) => {
        if (e.key === 'Enter' && e.target.value.trim()) {
            const adjectives = character.defaultCharacterDescription.customDialogStyles[0]?.adjectives || [];
            if (adjectives.length < 3) {
                const updatedAdjectives = [...adjectives, e.target.value.trim()];
                updateCustomDialogStyle("adjectives", updatedAdjectives);
                e.target.value = ''; // Clear input
            }
        }
    };

    const handleRemoveAdjective = (adjectiveToRemove) => {
        const adjectives = character.defaultCharacterDescription.customDialogStyles[0]?.adjectives || [];
        const updatedAdjectives = adjectives.filter((adjective) => adjective !== adjectiveToRemove);
        updateCustomDialogStyle("adjectives", updatedAdjectives);
    };

    // const handleColloquialismChange = (e) => {
    //     updateCustomDialogStyle("colloquialism", e.target.value);
    // };

    const handleRemoveColloquialism = () => {
        updateCustomDialogStyle("colloquialism", "");
    };

    const handleExampleDialogChange = (e) => {
        updateCustomDialogStyle("exampleDialog", e.target.value);
    };

    return (
        <div>
            <div className="mb-4">
                <p className="text-gray-900 dark:text-white">{generateExampleSentence()}</p>
            </div>
            <div className="mt-4">
                {/* Adjectives */}
                <div className="flex flex-wrap gap-2 mb-4">
                    {character.defaultCharacterDescription.customDialogStyles[0]?.adjectives?.map((adj, index) => (
                        <div
                            key={index}
                            className="bg-gray-300 dark:bg-gray-700 px-2 py-1 rounded-full text-sm text-gray-900 dark:text-white flex items-center"
                        >
                            {adj}
                            <button
                                onClick={() => handleRemoveAdjective(adj)}
                                className="ml-2 text-red-500"
                            >
                                &times;
                            </button>
                        </div>
                    ))}
                    {(!character.defaultCharacterDescription.customDialogStyles[0]?.adjectives || character.defaultCharacterDescription.customDialogStyles[0]?.adjectives?.length < 3) && (
                        <input
                            type="text"
                            onKeyDown={handleAddAdjective}
                            className="bg-gray-100 dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg p-2"
                            placeholder="Add adjective"
                        />
                    )}
                </div>

                {/* Colloquialism */}
                {/* <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Colloquialism</label>
                <div className="flex items-center mt-2">
                    <select
                        value={character.defaultCharacterDescription?.customDialogStyles[0]?.colloquialism || ""}
                        onChange={handleColloquialismChange}
                        className="w-full p-3 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white"
                    >
                        <option value="">Select colloquialism</option>
                        {COLLOQUIALISM_OPTIONS.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    {character.defaultCharacterDescription?.customDialogStyles[0]?.colloquialism && (
                        <button
                            onClick={handleRemoveColloquialism}
                            className="ml-2 text-red-500"
                        >
                            &times;
                        </button>
                    )}
                </div> */}

                {/* Example Dialog */}
                <label
                    htmlFor="exampleDialogue"
                    className="block mt-4 text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                    Example Dialogue
                </label>
                <textarea
                    id="exampleDialogue"
                    className="w-full h-64 p-3 mt-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white"
                    value={character.defaultCharacterDescription?.customDialogStyles[0]?.exampleDialog || ""}
                    onChange={handleExampleDialogChange}
                    placeholder="Provide examples of the type of unique responses your character would give in conversation..."
                />
            </div>
        </div>
    );
};

export default CustomDialogueStyle;
