import React, { useState } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PDFDocument } from './PdfFormat';
import { HiDownload } from 'react-icons/hi';

const LazyPDFDownloadButton = ({ userData, grade, logo }) => {
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
    const [pdfReady, setPdfReady] = useState(false);

    // Functie om de PDF te genereren
    const handleGeneratePDF = () => {
        if (!isGeneratingPDF) {  // Check om dubbelklikken te voorkomen
            setIsGeneratingPDF(true);
            setPdfReady(false);
        }
    };

    // console.log('PDF data:', {
    //   userData,
    //   grade,
    //   courseName,
    //   assessment,
    //   categoryPercentages,
    //   totalResult,
    //   debriefing2Data,
    //   scenario,
    //   questionnaire,
    //   transcript,
    //   reflection
    // });


    return (
        <div>
            {!isGeneratingPDF ? (
                // Knop om het genereren te starten, gedeactiveerd bij herhaalde klikken
                <button
                    onClick={handleGeneratePDF}
                    className={`bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded-lg ${isGeneratingPDF ? 'cursor-not-allowed opacity-50' : ''}`}
                    disabled={isGeneratingPDF}
                >
                    Download Grade
                </button>
            ) : (
                <PDFDownloadLink
                    document={
                        <PDFDocument
                            userData={userData}
                            grade={grade}
                            logo={logo}
                        />
                    }
                    fileName={`${userData?.firstName}_${userData?.lastName}_${grade?.assignmentName}_Grade_Report.pdf`}
                >
                    {({ loading }) => {
                        if (loading) {
                            return (
                                <div className="flex items-center">
                                    <svg
                                        className="animate-spin h-5 w-5 mr-3 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8v8H4z"
                                        ></path>
                                    </svg>
                                    Generating File...
                                </div>
                            );
                        } else {
                            if (!pdfReady) {
                                setPdfReady(true);
                            }

                            return (
                                <button className="bg-green-500 hover:bg-green-700 text-white px-4 py-2 rounded-lg flex items-center">
                                    <HiDownload className="h-6 w-6 mr-2" />
                                    Show Document
                                </button>
                            );
                        }
                    }}
                </PDFDownloadLink>
            )}
        </div>
    );
};

export default LazyPDFDownloadButton;
