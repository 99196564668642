import React, { useEffect, useState } from 'react';
import { useNotification } from '../../hooks/useNotification';
import { Accordion } from 'flowbite-react';
import useSchool from '../../hooks/useSchool';
import useCourses from '../../hooks/useCourses';
import useUsers from '../../hooks/useUsers';
import { NotificationTypes } from '../../utils/notifications';


const placeholderProfilePicture =
    "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='currentColor' class='bi bi-person-circle' viewBox='0 0 16 16'><path d='M13.468 12.37C12.853 11.19 11.34 10 8 10s-4.853 1.19-5.468 2.37C1.778 13.123 0 14.884 0 16.5V17h16v-.5c0-1.616-1.778-3.377-2.532-4.13z'/><path fill-rule='evenodd' d='M8 9a3 3 0 100-6 3 3 0 000 6z'/><path fill-rule='evenodd' d='M8 1a7 7 0 100 14A7 7 0 008 1zM1 8a7 7 0 1114 0A7 7 0 011 8z'/></svg>";


export default function UserInfo({ onClose, userId }) {
    const { showNotification } = useNotification()
    const { getUserByID } = useUsers();
    const { school } = useSchool();
    const { getCoursesForUser, getGradesForUser } = useCourses();

    const user = getUserByID(userId);
    // const courses = getCoursesForUser(userId);
    // const grades = getGradesForUser(userId);

    const grades = [];
    const courses = [];

    const handleSendPasswordReset = async () => {
        showNotification(NotificationTypes.INFO, 'Is not implemented yet!');
    }

    return (
        <div
            id="readUserModal"
            tabIndex="-1"
            aria-hidden="true"
            className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center bg-gray-900 bg-opacity-50"
        // ref={modalRef}
        >
            <div
                className="relative p-6 w-full max-w-3xl h-full md:h-auto"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
                    {/* Modal header */}
                    <div className="flex justify-between items-start p-5 rounded-t">
                        <div className="flex items-center">
                            <img
                                className="w-24 h-24 rounded-full object-cover mr-4"
                                src={user?.photo_url || placeholderProfilePicture}
                                alt="Profile"
                            />
                            <div>
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    {user.firstName + " " + user.lastName}
                                </h3>
                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                    {user.roleName}
                                </p>
                            </div>
                        </div>
                        <button
                            type="button"
                            onClick={onClose}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </button>
                    </div>

                    <div className="px-6 py-4 space-y-4">
                        {/* Admin-specific fields */}
                        <div className="space-y-2">
                            <p className="text-sm font-medium text-gray-900 dark:text-white">Email: {user.email}</p>
                            <p className="text-sm font-medium text-gray-900 dark:text-white">First Name: {user.firstName}</p>
                            <p className="text-sm font-medium text-gray-900 dark:text-white">Last Name: {user.lastName}</p>
                            <p className="text-sm font-medium text-gray-900 dark:text-white">Role: {user.role}</p>
                            <p className="text-sm font-medium text-gray-900 dark:text-white">School: {school.name}</p>
                        </div>

                        {/* Courses Accordion */}
                        <Accordion
                            title="Attending Courses"
                            isOpenByDefault={false}
                            content={
                                courses?.length > 0 ? (
                                    <ul className="space-y-2">
                                        {courses?.map((course) => (
                                            <li key={course?.id} className="text-gray-900 dark:text-white">
                                                {course?.className}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className="text-gray-500 dark:text-gray-400">No classes enrolled</p>
                                )
                            }
                        />
                        <Accordion
                            title="Recent Grades"
                            isOpenByDefault={false}
                            content={
                                grades.length > 0 ? (
                                    <ul className="space-y-2">
                                        {grades?.map((grade) => (
                                            <li key={grade?.id} className="text-gray-900 dark:text-white">
                                                Assignment: {grade?.assessmentName}, Grade: {grade?.averageResult ?? 'N/A'}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className="text-gray-500 dark:text-gray-400">No grades available</p>
                                )
                            }
                        />
                    </div>

                    {/* Footer */}
                    <div className="px-6 py-4 flex justify-between items-center border-t border-gray-200 dark:border-gray-700">
                        <div className="flex space-x-3">

                            <button
                                onClick={handleSendPasswordReset}
                                type="button"
                                className="inline-flex items-center px-5 py-2.5 text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 rounded-lg"
                            >
                                Send Password Reset
                            </button>


                            {/* <div className="p-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800">
                                {inviteUrl}
                            </div> */}

                        </div>
                        <a
                            href={`mailto:${user.email}`}
                            className="inline-flex items-center px-5 py-2.5 text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 rounded-lg"
                        >
                            <svg aria-hidden="true" className="w-5 h-5 mr-1 -ml-1" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd"></path>
                            </svg>
                            Send Email
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}