import React, { useState, useEffect } from "react";
import { HiOutlineAcademicCap, HiOutlineNewspaper, HiPlus, HiX } from "react-icons/hi";
import { TabsUnderlined } from "../../components/shared/TabNavigation";
import useCourses from "../../hooks/useCourses";
import CourseDetails from "../CourseDetails";
import { usePopUp } from "../../hooks/usePopUp";
import { useNavigate } from "react-router-dom";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { useNotification } from "../../hooks/useNotification";
import { Dropdown, DropdownItem } from 'flowbite-react';
import { NotificationTypes } from "../../utils/notifications";
import useUsers from "../../hooks/useUsers";
import { SkeletonLoaderCard } from "../../components/shared/SkeletonLoader";
import GenericButton from "../../components/shared/GenericButton";
import { getFileUrl, uploadFile } from "../../firebase/storage";
import CourseCard from "../../components/courses/CourseCard";


const StudentCourses = () => {
    const { course } = useCourses();

    if (course) {
        return <CourseDetails />
    }
    else {
        return <StudentCoursesOverview />
    }
}

const StudentCoursesOverview = () => {
    const [tab, setTab] = useState(0);
    const { courses, loading } = useCourses();

    const coursesToShow = courses.filter(c => tab == 0 && c.published || tab == 1 && !c.published);

    return (
        <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen w-full h-full">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 h-full flex flex-col">
                <div className="flex justify-between items-center">
                    <div className='flex flex-wrap p-6'>
                        <HiOutlineNewspaper className="text-3xl text-gray-700 dark:text-gray-300" />
                        <h1 className="pl-5 text-2xl font-semibold text-gray-900 dark:text-white">Courses</h1>
                    </div>
                </div>
                {/* <TabsUnderlined tabs={["Active Courses", "Drafts"]} setActiveTab={setTab} activeTab={tab} /> */}
                <section className="mb-8">
                    {/* <div className="flex justify-between items-center bg-green-200 px-4 py-3 rounded-lg">
                        <h2 className="text-lg font-semibold text-green-700">Courses</h2>
                        <span className="text-sm text-green-700">{`(${courses.length})`}</span>
                    </div> */}
                    {loading ? <SkeletonLoaderCard /> : coursesToShow.length > 0 ? (
                        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-4">
                            {coursesToShow.map((course) => (
                                <CourseCard key={course.id} courseId={course.id} />
                            ))}
                        </div>
                    ) : (
                        <p className="mt-4 text-gray-500">{tab == 0 ? "No active courses." : "No course drafts"}</p>
                    )}
                </section>
            </div>

        </div>
    );
}

export default StudentCourses;