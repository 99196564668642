import React, { useEffect, useRef, useState } from 'react';
import useHeader from '../../hooks/navigation/useHeader';
import { usePopUp } from '../../hooks/usePopUp';
import { useNotification } from '../../hooks/useNotification';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase/firebase';
import { NotificationTypes } from '../../utils/notifications';

const SignInCodePopup = () => {
  const [code, setCode] = useState(null);
  const [loading, setLoading] = useState(true);
  const tokenGenerated = useRef(false);

  const { showPopUp } = usePopUp();
  const { showNotification } = useNotification();

  const generateToken = async () => {
    setLoading(true);
    const generateUniqueToken = httpsCallable(functions, "generateUniqueToken");

    try {
      const result = await generateUniqueToken();
      setCode(result.data.code);
      console.log("Generated token:", result.data.code);
    } catch (error) {
      console.error("Error generating token:", error.message);
      showNotification(NotificationTypes.DANGER, `Error generating token: ${error.message}`);
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!tokenGenerated.current) {
      tokenGenerated.current = true; // Mark as called
      generateToken();
    }
  }, [])

  const onClose = (e) => {
    e.preventDefault();
    showPopUp(null);
  }

  return (
    <div id="deleteAlertModal" tabIndex="-1" aria-hidden="true" className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center w-full h-modal md:h-full">
      <div className="relative p-4 w-full max-w-md h-full md:h-auto">
        <div className="bg-white dark:bg-gray-900 p-6 rounded-lg shadow-xl text-center transform transition-all duration-500 ease-in-out">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white mb-6">
            VR Sign-in Code
          </h2>
          <div className="bg-blue-50 dark:bg-blue-900 p-4 rounded-lg shadow-inner mb-8">
            <p className="text-3xl font-bold text-blue-600 dark:text-blue-400 tracking-wider">
              {loading ? "Loading..." : code}
            </p>
            <p className="text-gray-500 dark:text-gray-300 mt-2">{loading ? "Please sit tight while we generate a code for you..." : "This code expires in 5 minutes."}</p>
          </div>
          <button
            onClick={() => { generateToken() }}
            disabled={loading}
            className={`w-full py-3 mb-5 px-6 font-semibold text-white rounded-full shadow-lg transition-all duration-300 ease-in-out transform ${loading
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 focus:ring-4 focus:ring-indigo-500 dark:focus:ring-indigo-800 hover:scale-105"
              }`}
          >
            Regenerate
          </button>
          <button
            onClick={onClose}
            className="w-full py-3 px-6 font-semibold text-white bg-gradient-to-r from-blue-500 to-indigo-600 rounded-full shadow-lg hover:from-blue-600 hover:to-indigo-700 focus:ring-4 focus:ring-indigo-500 dark:focus:ring-indigo-800 transition-all duration-300 ease-in-out transform hover:scale-105"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};


export default SignInCodePopup;
