import React, { useState, useEffect } from 'react';
import { useInworld } from "../../../hooks/useInworld";
import SideSteps from "../../shared/SideSteps";
import Knowledge from './Knowledge';
import SafetyConfig from './SafetyConfig';

const steps = ['Safety Config', 
    //'Miscellaneous',
     'Knowledge'];

const AdvancedInfo = () => {
    const [step, setStep] = useState(0);
    const { character, knowledge } = useInworld();

    if (!character) return <div>Loading...</div>;

    const handleSafetyChange = () => {

    }

    return (
        <div className="flex space-x-8 min-h-screen">
            <div className="w-1/5">
                <SideSteps currentStep={step} steps={steps} setCurrentStep={setStep} />
            </div>

            <div className="flex flex-col flex-grow p-8 rounded-lg border">
                {step === 0 &&
                    <section>
                        <SafetyConfig />
                    </section>
                }
                {/* {step === 1 &&

                    <section>
                        <h2>Miscellaneous</h2>
                        <p><strong>Social Rank:</st</strong> {character.cognitiveControl}</p>
                        <p><strong>Long Term Coherence Enabled:</strong> {character.longTermCoherence?.enabled ? 'Yes' : 'No'}</p>
                    </section>rong> {character.socialRank}</p>
                        <p><strong>Fourth Wall:</strong> {character.fourthWall}</p>
                        <p><strong>Cognitive Control:
                } */}
                {step === 1 &&
                    <section>
                        {/* {character.commonKnowledge.length > 0 ? (
                            <ul>
                                {character.commonKnowledge.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>No common knowledge available</p>
                        )} */}

                        <Knowledge knowledge={knowledge} />
                    </section>
                }



            </div>
        </div>
    );
}

export default AdvancedInfo;