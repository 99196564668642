import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Dropdown } from "flowbite-react";

// Register the Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const mockGrades = {
    student1: [
        { courseName: "Math 101", accuracy: 82, safety: 94, communication: 64, answers: 64, hours: 12, sessions: 6, completed: true, teacher: "Teacher A" },
        { courseName: "Science 202", accuracy: 95, safety: 84, communication: 61, answers: 78, hours: 8, sessions: 4, completed: true, teacher: "Teacher A" },
        { courseName: "History 303", accuracy: 92, safety: 74, communication: 88, answers: 88, hours: 10, sessions: 5, completed: true, teacher: "Teacher B" },
    ],
    student2: [
        { courseName: "Math 101", accuracy: 71, safety: 71, communication: 65, answers: 82, hours: 14, sessions: 7, completed: true, teacher: "Teacher A" },
        { courseName: "Science 202", accuracy: 88, safety: 65, communication: 63, answers: 90, hours: 10, sessions: 5, completed: false, teacher: "Teacher A" },
        { courseName: "History 303", accuracy: 98, safety: 60, communication: 73, answers: 74, hours: 12, sessions: 6, completed: true, teacher: "Teacher B" },
    ],
    student3: [
        { courseName: "Math 101", accuracy: 73, safety: 67, communication: 92, answers: 75, hours: 9, sessions: 4, completed: true, teacher: "Teacher A" },
        { courseName: "Science 202", accuracy: 72, safety: 77, communication: 98, answers: 67, hours: 11, sessions: 6, completed: true, teacher: "Teacher A" },
        { courseName: "History 303", accuracy: 63, safety: 92, communication: 71, answers: 76, hours: 15, sessions: 8, completed: false, teacher: "Teacher B" },
    ],
};

const InstitutionAnalytics = () => {
    const [analyticsData, setAnalyticsData] = useState({});
    const [selectedCourses, setSelectedCourses] = useState([]);

    useEffect(() => {
        const students = Object.keys(mockGrades || {});
        const allGrades = students.flatMap((student) => mockGrades[student] || []);

        const courseNames = [...new Set(allGrades.map((grade) => grade.courseName))];
        if (selectedCourses.length === 0) setSelectedCourses(courseNames);

        const filteredGrades = allGrades.filter((grade) =>
            selectedCourses.includes(grade.courseName)
        );

        // Analytics calculations
        const hoursSpentPerCourse = filteredGrades.reduce((acc, grade) => {
            acc[grade.courseName] = (acc[grade.courseName] || 0) + (grade.hours || 0);
            return acc;
        }, {});

        const sessionsPerCourse = filteredGrades.reduce((acc, grade) => {
            acc[grade.courseName] = (acc[grade.courseName] || 0) + (grade.sessions || 0);
            return acc;
        }, {});

        const avgGradesPerCourse = filteredGrades.reduce((acc, grade) => {
            const avgGrade =
                (grade.accuracy + grade.safety + grade.communication + grade.answers) / 4;
            if (!acc[grade.courseName]) acc[grade.courseName] = [];
            acc[grade.courseName].push(avgGrade);
            return acc;
        }, {});

        const avgGradesComputed = Object.entries(avgGradesPerCourse).reduce(
            (acc, [course, grades]) => {
                acc[course] = grades.reduce((sum, grade) => sum + grade, 0) / grades.length;
                return acc;
            },
            {}
        );

        const analytics = {
            hoursSpentPerCourse,
            sessionsPerCourse,
            avgGradesPerCourse: avgGradesComputed,
        };

        setAnalyticsData(analytics);
    }, [selectedCourses]);

    const handleCourseSelection = (course) => {
        setSelectedCourses((prev) =>
            prev.includes(course)
                ? prev.filter((c) => c !== course)
                : [...prev, course]
        );
    };

    const renderChart = (label, data, color) => {
        if (!data) return null;

        const chartData = {
            labels: Object.keys(data),
            datasets: [
                {
                    label,
                    data: Object.values(data),
                    backgroundColor: Object.keys(data).map(() => color),
                    borderRadius: 10,
                },
            ],
        };

        return (
            <div className="bg-white dark:bg-gray-800 p-4 rounded-lg border">
                <h3 className="text-lg font-semibold mb-4">{label}</h3>
                <div style={{ height: "300px" }}>
                    <Bar
                        data={chartData}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                y: { beginAtZero: true },
                            },
                        }}
                    />
                </div>
            </div>
        );
    };

    return (
        <div className="bg-white dark:bg-gray-800 p-6">
            {/* Course Filter */}
            <div className="mb-6">
                <Dropdown label="Select Courses" inline={true}>
                    {Object.keys(
                        mockGrades.student1.reduce((acc, grade) => {
                            acc[grade.courseName] = true;
                            return acc;
                        }, {})
                    ).map((course) => (
                        <div key={course} className="p-2">
                            <label className="flex items-center space-x-2">
                                <input
                                    type="checkbox"
                                    checked={selectedCourses.includes(course)}
                                    onChange={() => handleCourseSelection(course)}
                                    className="form-checkbox h-4 w-4"
                                />
                                <span>{course}</span>
                            </label>
                        </div>
                    ))}
                </Dropdown>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {renderChart(
                    "Hours Spent Per Course",
                    analyticsData.hoursSpentPerCourse,
                    "#00bcd4"
                )}
                {renderChart("Sessions Per Course", analyticsData.sessionsPerCourse, "#29b6f6")}
                {renderChart("Average Grade Per Course", analyticsData.avgGradesPerCourse, "#1e88e5")}
            </div>
        </div>
    );
};

export default InstitutionAnalytics;
