import React, { useState } from "react";
import { useNotification } from "../../hooks/useNotification";
import { TabsUnderlined } from "../../components/shared/TabNavigation";
import { HiArrowRight, HiCheckCircle, HiBookOpen, HiCalendar } from "react-icons/hi";
import CalendarAssignments from "../../components/assignments/CalendarAssignment";
import useAssignments from "../../hooks/useAssignments";
import useCourses from "../../hooks/useCourses";
import ConfirmPopup from "../../components/shared/ConfirmPopup";
import { HiOutlineExclamationTriangle } from "react-icons/hi2";
import { useNavigate, useSearchParams } from "react-router-dom";
import { usePopUp } from "../../hooks/usePopUp";
import PerformAssignment from "../../components/assignments/PerformAssignment";
import useGrades from "../../hooks/useGrades";
import useScenarios from "../../hooks/useScenarios";
import Thumbnail from "../../components/shared/Thumbnail";
import useSchool from "../../hooks/useSchool";

const StudentAssignments = () => {
    // const { dueAssignments, overdueAssignments } = assignmentsData;
    const { assignment, assignments, getActiveAssignments } = useAssignments();
    const { getGradeForAssignment } = useGrades();
    const [tab, setTab] = useState(0);
    const [searchParams] = useSearchParams();

    const { showPopUp } = usePopUp();
    const navigate = useNavigate();

    const activeAssignments = getActiveAssignments();
    const inProgressAssignments = activeAssignments.filter(a => getGradeForAssignment(a.id) && !getGradeForAssignment(a.id).submissionDate);
    const dueAssignments = activeAssignments.filter(a => !getGradeForAssignment(a.id));
    const overdueAssignments = assignments.filter(a => !activeAssignments.includes(a) && !getGradeForAssignment(a.id));

    const perform = searchParams.get("perform");

    if (assignment && perform != null) {
        return <PerformAssignment />;
    }

    const handleStartAssignment = (assignmentId) => {
        navigate(`${assignmentId}?perform`);
    }

    const handleStartSimulationClick = (assignmentId) => {
        if (!getGradeForAssignment(assignmentId)) {
            showPopUp(<ConfirmPopup label="Start" icon={<HiOutlineExclamationTriangle />} color={"blue"} description={"You are about to perform an assignment. Once an assignment is submitted, there is no ability to redo the assignment. Your performance will be monitored."} onConfirm={() => { handleStartAssignment(assignmentId) }} />);
        }
        else {
            handleStartAssignment(assignmentId);
        }
    };

    return (
        <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen w-full h-full">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 h-full flex flex-col">
                <div className="p-6 min-h-screen">
                    {/* Header */}
                    <div className="flex justify-between items-center mb-6">
                        <h1 className="text-2xl font-semibold text-gray-900 flex items-center">
                            <h1 className="text-2xl font-semibold text-gray-900 flex items-center gap-4">
                                <HiCalendar />
                                Assignment Overview
                            </h1>
                        </h1>
                        <TabsUnderlined activeTab={tab} setActiveTab={setTab} tabs={["All Assignments", "Calendar"]} />
                    </div>
                    {tab == 0 ? (
                        <div>

                            {inProgressAssignments.length > 0 && <section className="mb-8">
                                <div className="flex justify-between items-center bg-yellow-100 px-4 py-3 rounded-lg">
                                    <h2 className="text-lg font-semibold text-yellow-800">In Progress Assignments</h2>
                                    <span className="text-sm text-gray-600">{`(${inProgressAssignments.length})`}</span>
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-4">
                                    {inProgressAssignments.map((assignment) => (
                                        <AssignmentCardStudent key={assignment.id} assignment={assignment} onAssignmentClicked={handleStartSimulationClick} inProgress={true} />
                                    ))}
                                </div>
                            </section>}

                            <section className="mb-8">
                                <div className="flex justify-between items-center bg-gray-100 px-4 py-3 rounded-lg">
                                    <h2 className="text-lg font-semibold text-gray-800">Due Assignments</h2>
                                    <span className="text-sm text-gray-600">{`(${dueAssignments.length})`}</span>
                                </div>
                                {dueAssignments.length > 0 ? (
                                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-4">
                                        {dueAssignments.map((assignment) => (
                                            <AssignmentCardStudent key={assignment.id} assignment={assignment} onAssignmentClicked={handleStartSimulationClick} />
                                        ))}
                                    </div>
                                ) : (
                                    <p className="mt-4 text-gray-500">No assignments due.</p>
                                )}
                            </section>

                            <section>
                                <div className="flex justify-between items-center bg-red-100 px-4 py-3 rounded-lg">
                                    <h2 className="text-lg font-semibold text-red-700">Overdue Assignments</h2>
                                    <span className="text-sm text-red-700">{`(${overdueAssignments.length})`}</span>
                                </div>
                                {overdueAssignments.length > 0 ? (
                                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-4">
                                        {overdueAssignments.map((assignment) => (
                                            <AssignmentCardStudent key={assignment.id} assignment={assignment} isOverdue={true} onAssignmentClicked={handleStartSimulationClick} />
                                        ))}
                                    </div>
                                ) : (
                                    <p className="mt-4 text-gray-500">No overdue assignments.</p>
                                )}
                            </section>
                        </div>
                    ) : (
                        <div>
                            <CalendarAssignments assignments={dueAssignments} onAssignmentClicked={handleStartSimulationClick} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const AssignmentCardStudent = ({ assignment, isOverdue = false, onAssignmentClicked, inProgress = false }) => {
    const { showNotification } = useNotification();
    const { getCourseById } = useCourses();
    const { getScenarioByID, isCoursetaScenario } = useScenarios();

    const { school } = useSchool();

    const scenario = getScenarioByID(assignment.scenarioId);

    const formattedDate = assignment.dueDate.toDate().toLocaleString();
    const course = getCourseById(assignment.courseId);

    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg w-full">
            {/* Square image */}
            <div className="w-full aspect-square">
                {scenario ? <Thumbnail thumbnailURL={isCoursetaScenario(scenario.id) ? `scenarios/${scenario.id}/${scenario.thumbnail}` : `institutions/${school.id}/scenarios/${scenario.id}/${scenario.thumbnail}`} altName={assignment.name} /> : <div className="w-full aspect-square">
                    {<p className="flex items-center justify-center h-full text-red-500">This scenario is no longer available.</p>}
                </div>}
            </div>
            <div className="p-4">
                <h3 className="text-lg font-bold text-gray-900 mb-2 truncate">{assignment.name}</h3>
                <p className="text-sm text-gray-500 mb-4 truncate">{course.name}</p>
                <div className="flex justify-between items-center text-sm">
                    <span className="text-gray-600">📅 {formattedDate}</span>
                    {isOverdue && <span className="text-red-500 font-bold">Overdue</span>}
                </div>
                <button
                    onClick={(e) => { e.preventDefault(); onAssignmentClicked(assignment.id) }}
                    type="button"
                    disabled={isOverdue} // Disable the button if overdue
                    className={`w-full flex items-center justify-center border rounded-lg shadow-sm font-medium mt-4 px-4 py-2
                        ${isOverdue
                            ? "bg-gray-300 text-gray-500 cursor-not-allowed" // Disabled button styles
                            : "bg-blue-500 text-white hover:bg-blue-600" // Active button styles
                        }`}
                >
                    {isOverdue ? "Unavailable" : inProgress ? "Finish" : "Start Now"}
                    {!isOverdue && <HiArrowRight className="ml-2 text-lg" />}
                </button>
            </div>
        </div>
    );
};

export default StudentAssignments;
