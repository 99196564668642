
import React, { useState, useEffect } from 'react';
import { Handle } from 'reactflow';
import NodeParent from './NodeParent';
import { HiOutlineQuestionMarkCircle, HiOutlineCheckCircle, HiOutlineXCircle } from 'react-icons/hi';
import { AssessmentType, BehaviourTypes, CheckTypes, LabTests, MedicationRoute, MedicationTypes, MedicationUnit, NodeTypes, OxygenType, PatientPositionTypes, prettyPrintEnum, TitrateOperation, TitrationUnit, VitalTypes } from '../../../utils/ScriptingEnumLibrary';
import { ScriptingEnumLabel, ScriptingEnumLabelMultiple, ScriptingIntegerInput, ScriptingStringInput } from '../library/ScriptingComponentLibrary';
import useScripting from '../../../hooks/useScripting';


const CheckNode = ({ id, data, selected }) => {
  const { updateNodeData } = useScripting();

  const createMetadata = () => {
    switch (data?.check_type) {
      case CheckTypes.IfVitalIs:
        return (
          <div className="mb-4" >
            <label className="block text-sm mb-1">If</label>
            <ScriptingEnumLabel id={id} data={data} label="vital_id" enumType={VitalTypes} />
            <label className="block text-sm mb-4">is between</label>
            <ScriptingIntegerInput id={id} data={data} label="lower_bound" />
            <label className="block text-sm mb-4">and</label>
            <ScriptingIntegerInput id={id} data={data} label="upper_bound" />
          </div >
        );
      case CheckTypes.IfMedicationWasGiven:
        return (
          <div className="mb-4" >
            <label className="block text-sm mb-1">Medication</label>
            <ScriptingEnumLabel id={id} data={data} label="medication" enumType={MedicationTypes} />
            <label className="block text-sm mt-4 mb-1">Route</label>
            <ScriptingEnumLabel id={id} data={data} label="route" enumType={MedicationRoute} />
            <label className="block text-sm mt-4 mb-1">With dosage between</label>
            <ScriptingIntegerInput id={id} data={data} label="lower_bound" />
            <label className="block text-sm mt-4 mb-1">and</label>
            <ScriptingIntegerInput id={id} data={data} label="upper_bound" />
            <div className="mb-4"></div>
            <ScriptingEnumLabel id={id} data={data} label="unit_type" enumType={MedicationUnit} />
          </div >
        );
      case CheckTypes.IfCustomFieldIs:
        return (
          <div className="mb-4" >
            <label className="block text-sm mb-1">If</label>
            <ScriptingStringInput id={id} data={data} label="custom_field" />
            <label className="block text-sm mt-4 mb-1">is</label>
            <ScriptingStringInput id={id} data={data} label="custom_field_value" />
          </div >
        );
      case CheckTypes.IfIvBeingGiven:
        return (
          <div className="mb-4" >
            <label className="block text-sm mb-1">Medication</label>
            <ScriptingEnumLabel id={id} data={data} label="medication" enumType={MedicationTypes} />
            <label className="block text-sm mt-4 mb-1">With dosage between</label>
            <ScriptingIntegerInput id={id} data={data} label="lower_bound" />
            <label className="block text-sm mt-4 mb-1">and</label>
            <ScriptingIntegerInput id={id} data={data} label="upper_bound" />
            <div className="mb-4"></div>
            <ScriptingEnumLabel id={id} data={data} label="titration_type" enumType={TitrationUnit} />
          </div >
        );
      case CheckTypes.IfPatientIsPositioned:
        return (
          <div className="mb-4" >
            <label className="block text-sm mb-1">Allowed positions</label>
            <ScriptingEnumLabelMultiple id={id} data={data} label="medication" enumType={PatientPositionTypes} />
            <label className="block text-sm mt-4">If the patient is position in one of the allowed positions, this check will be true.</label>
          </div >
        );
      case CheckTypes.IfIvWasChangedBy:
        return (
          <div className="mb-4" >
            <label className="block text-sm mb-1">Medication</label>
            <ScriptingEnumLabel id={id} data={data} label="medication" enumType={MedicationTypes} />
            <label className="block text-sm mt-4 mb-1">was changed by</label>
            <ScriptingIntegerInput id={id} data={data} label="amount" />
            <label className="block text-sm mt-1 mb-4">or more</label>
            <ScriptingEnumLabel id={id} data={data} label="titration_type" enumType={TitrationUnit} />
            <div className="mb-4"></div>
          </div >
        );
      case CheckTypes.IfIvPushedInTime:
        return (
          <div className="mb-4" >
            <label className="block text-sm mb-1">Medication</label>
            <ScriptingEnumLabel id={id} data={data} label="medication" enumType={MedicationTypes} />
            <label className="block text-sm mt-4 mb-1">Between</label>
            <ScriptingIntegerInput id={id} data={data} label="lower_bound" />
            <label className="block text-sm mt-4 mb-1">and</label>
            <ScriptingIntegerInput id={id} data={data} label="upper_bound" />
            <label className="block text-sm mt-4 mb-1">seconds</label>
          </div >
        );
      case CheckTypes.IfUserSaid:
        return (
          <div className="mb-4" >
            <label className="block text-sm mb-1">Key words (e.g. 'hello;greetings;howdy')</label>
            <ScriptingStringInput id={id} data={data} label="keywords" />
            <div className="mt-4 flex items-center">
              <label className="relative inline-flex items-center cursor-pointer pr-3">
                <input
                  type="checkbox" className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded-lg"
                  checked={data?.matchAll || false}
                  onChange={(e) => {
                    updateNodeData(id, { matchAll: e.target.checked })
                  }}
                />
              </label>
              <label className="mr-2 text-sm">Match all</label>
            </div>
            <label className="block text-sm mt-4">This will check if the user has said any of the above words. Use semicolons (;) to check for synonyms. (e.g. "hello;hi;greetings" will be true if any of those 3 words have been said)</label>
          </div >
        );
      case CheckTypes.IfOxygenBeingGiven:
        return (
          <div className="mb-4" >
            <label className="block text-sm mb-1">Mask</label>
            <ScriptingEnumLabel id={id} data={data} label="oxygen_type" enumType={OxygenType} />
            <label className="block text-sm mt-4 mb-1">Flow between</label>
            <ScriptingIntegerInput id={id} data={data} label="lower_bound" />
            <label className="block text-sm mt-4 mb-1">and</label>
            <ScriptingIntegerInput id={id} data={data} label="upper_bound" />
            <label className="block text-sm mt-1 mb-1">LPM</label>
          </div >
        );
      case CheckTypes.IfLabWasCollected:
        return (
          <div className="mb-4" >
            <label className="block text-sm mb-1">Lab</label>
            <ScriptingEnumLabelMultiple id={id} data={data} label="lab" enumType={LabTests} />
            <label className="block text-sm mt-4">This check will only be true if ALL of the selected labs have been collected.</label>
          </div >
        );
      case CheckTypes.IfDoctorCalled:
        return (
          <div className="mb-4" >
            <label className="block text-sm mb-1">This check will be true if the doctor has been called in the simulation</label>
          </div >
        );
      case CheckTypes.IfBehaviorIs:
        return (
          <div className="mb-4" >
            <label className="block text-sm mb-1">Behavior</label>
            <ScriptingEnumLabel id={id} data={data} label="behavior" enumType={BehaviourTypes} />
          </div >
        );
      case CheckTypes.IfAssessmentPerformed:
        return (
          <div className="mb-4">
            <ScriptingEnumLabelMultiple id={id} data={data} label="assessments" enumType={AssessmentType} />
            <label className="block text-sm mt-4 mb-1">This check will be true if ALL of the selected assessments have been performed.</label>
          </div>
        );
      default:
        return (<div className="mb-4" >
          <label className="block text-sm mb-1">Invalid check type</label>
        </div >);
    }
  };

  return (
    <NodeParent title="Check" icon={<HiOutlineQuestionMarkCircle />} selected={selected} description="Checks a condition">
      <Handle type="target" position="left" style={{ width: '12px', height: '12px' }} className="bg-white border border-gray-400" />
      <div className="mb-4">
        <label className="block text-sm mb-1">Check</label>
        <ScriptingEnumLabel id={id} data={data} label="check_type" enumType={CheckTypes} />
      </div>
      {createMetadata()}
      <div className="flex justify-end mt-4 relative" style={{ height: '100px' }}>
        <div className="absolute right-0 top-1/3 transform -translate-y-1/2 flex flex-col space-y-2">
          <div className="relative">
            <HiOutlineCheckCircle className="text-green-500 w-6 h-6" />
            <Handle type="source" position="right" id="yes" style={{ width: '12px', height: '12px', top: '50%', right: '-12px', transform: 'translateY(-50%)' }} className="absolute w-full h-full" />
          </div>
          <div className="relative">
            <HiOutlineXCircle className="text-red-500 w-6 h-6" />
            <Handle type="source" position="right" id="no" style={{ width: '12px', height: '12px', top: '50%', right: '-12px', transform: 'translateY(-50%)' }} className="absolute w-full h-full" />
          </div>
        </div>
      </div>
    </NodeParent>
  );
};

export default CheckNode;
