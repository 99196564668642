import { useNotification } from "../hooks/useNotification";
import useCourses from "../hooks/useCourses";
import useAuth from "../hooks/useAuth";
import { TabsBubble } from "../components/shared/TabNavigation";
import ReturnButton from "../components/shared/ReturnButton";
import React, { useState } from "react";
import { NotificationTypes } from "../utils/notifications";
import Roles from "../utils/roles";
import Overview from "../components/courses/Overview";
import Modules, { ModulePage } from "../components/courses/Modules";
import Materials from "../components/courses/Materials.js";
import CourseAssignments from "../components/courses/CourseAssignments.js";
import logo from '../assets/images/Courseta_Logo.png';
import CoursePeople from "../components/courses/CoursePeople.js";
import CourseGrades from "../components/courses/CourseGrades.js";
import useModules from "../hooks/useModules.js";
import { SkeletonLoaderCard } from "../components/shared/SkeletonLoader.js";
import ConfirmPopup from "../components/shared/ConfirmPopup.js";
import { usePopUp } from "../hooks/usePopUp.js";
import GenericButton from "../components/shared/GenericButton.js";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { HiOutlineExclamationTriangle } from "react-icons/hi2";
import OpenLab from "../components/courses/OpenLab.js";

const CourseDetails = () => {
    const { course, publishCourse } = useCourses();
    const { submodule } = useModules();
    const [activeTab, setActiveTab] = useState(0);
    const { isStudent, user, isAdmin } = useAuth();
    const { showNotification } = useNotification();
    const { showPopUp } = usePopUp();

    if (!course) {
        return <div>Loading...</div>
    }

    if (submodule) {
        return <ModulePage />
    }

    const Tabs = {
        OVERVIEW: "Overview",
        MODULES: "Modules",
        MATERIALS: "Materials",
        ASSIGNMENTS: "Assignments",
        GRADES: "Grades",
        PEOPLE: "People",
        OPENLAB: "Open Lab"
    };
    const tabs = isStudent ? Object.values(Tabs).filter(t => t !== Tabs.PEOPLE && t !== Tabs.OPENLAB) : Object.values(Tabs);

    const canEdit = (course.admin === user.id || isAdmin);

    const handlePublishConfirm = async () => {
        publishCourse(course.id, !course.published);
    }

    const handlePublish = (e) => {
        e.preventDefault();
        showPopUp(<ConfirmPopup label={course.published ? "Unpublish" : "Publish"} icon={<HiOutlineExclamationTriangle />} onConfirm={handlePublishConfirm} color={"blue"} description={`Are you sure you want to ${course.published ? "unpublish" : "publish"}: ${course.name}`} />);
    }

    return (
        <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen w-full h-full">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 h-full flex flex-col">
                <div className="flex justify-start mb-4 pb-5">
                    <ReturnButton className="w-300 " />

                </div>
                {/* <div className="flex items-center space-x-2 p-8">
                    <HiBookOpen className="text-4xl text-gray-700 dark:text-gray-300" />
                    <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                        {course.name}
                    </h1>
                </div> */}
                <div className="flex justify-between items-center mb-4 border-b pb-4 border-gray-200 dark:border-gray-700">
                    <div className="flex items-center space-x-2">
                        <img src={logo} className="h-20" alt="Courseta Logo" />
                        <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                            {course.name}
                        </h1>
                    </div>
                    {canEdit &&
                        <GenericButton className="mt-10 m-5" label={course.published ? "Unpublish" : "Publish"} handleClick={handlePublish} icon={course.published ? <HiEyeOff /> : <HiEye />} />
                    }
                </div>
                <TabsBubble activeTab={activeTab} tabs={tabs} setActiveTab={setActiveTab} />
                {tabs[activeTab] === Tabs.OVERVIEW && (
                    <Overview setTab={setActiveTab} />
                )}
                {tabs[activeTab] === Tabs.MODULES && (
                    <Modules />
                )}
                {tabs[activeTab] === Tabs.MATERIALS && (
                    <Materials />
                )}
                {tabs[activeTab] === Tabs.ASSIGNMENTS && (
                    <CourseAssignments />
                )}
                {tabs[activeTab] === Tabs.PEOPLE && (
                    <CoursePeople />
                )}
                {tabs[activeTab] === Tabs.GRADES && (
                    <CourseGrades />
                )}
                {tabs[activeTab] === Tabs.OPENLAB && (
                    <OpenLab />
                )}
            </div>
        </div>);
}

export default CourseDetails;