import React, { useState } from "react";
import { HiPlus, HiPencil, HiTrash, HiDocumentText, HiOutlineDownload, HiOutlineDocument, HiX, HiUpload, HiEyeOff, HiEye } from "react-icons/hi";
import { useNavigate, useSearchParams } from "react-router-dom";
import useModules from "../../hooks/useModules";
import useCourses from "../../hooks/useCourses";
import ReturnButton from "../shared/ReturnButton";
import DeleteButton from "../shared/DeleteButton";
import { usePopUp } from "../../hooks/usePopUp";
import { SkeletonLoaderRow } from "../shared/SkeletonLoader";
import RichTextEditor from "../shared/RichTextEditor";
import FileUpload from "../shared/FileUpload";
import GenericButton from "../shared/GenericButton";
import FileList from "../shared/FileList";
import { getFileUrl } from "../../firebase/storage";
import { useNotification } from "../../hooks/useNotification";
import { HiFolder, HiOutlineExclamationTriangle } from "react-icons/hi2";
import ConfirmPopup from "../shared/ConfirmPopup";
import { NotificationTypes } from "../../utils/notifications";
import useSchool from "../../hooks/useSchool";
import useAuth from "../../hooks/useAuth";
import RichTextField from "../shared/RichTextField";

// Component to display the list of modules
const ModulesOverview = () => {
    const { course } = useCourses();
    const { isStudent, user, isAdmin } = useAuth();
    const { loading, modules, addModule, updateModule, removeModule, addSubmodule } = useModules();
    const [editingModule, setEditingModule] = useState(null); // Track editing module
    const navigate = useNavigate();
    const { showPopUp } = usePopUp();

    // Add a new module
    const handleAddModule = () => {
        showPopUp(<CreateModule course={course} addModule={addModule} />);
    };

    // Delete a module
    const handleDeleteModule = (moduleId) => {
        removeModule(moduleId);
    };

    // Edit a module name
    const handleEditModuleName = (moduleId, newName) => {
        updateModule(moduleId, { name: newName });
        setEditingModule(null);
    };

    // Add a new page to a module
    const handleAddPage = (moduleId) => {
        showPopUp(<CreateSubmodule course={course} moduleId={moduleId} addSubmodule={addSubmodule} />);
    };

    if (!course) {
        return <div>Loading...</div>
    }

    const canEdit = (course.admin === user.id || isAdmin);

    return (
        <div className="p-4 bg-white border dark:bg-gray-800 min-h-screen w-full h-full">
            <div className="flex justify-between items-center space-x-2 p-8">
                <div className='flex gap-2 items-center'>
                    <HiFolder className="text-4xl text-gray-700 dark:text-gray-300" />
                    <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                        Modules
                    </h1>
                </div>
            </div>
            <div className="space-y-4">
                {loading ? (
                    <SkeletonLoaderRow />
                ) : (
                    modules.map((module, index) => (
                        <div
                            className="border rounded-lg p-4 shadow bg-white dark:bg-gray-800"
                        >
                            <div className="flex justify-between items-center">
                                <div className="flex items-center space-x-2">
                                    <span className="text-lg font-semibold">{`${index + 1}.`}</span>
                                    {editingModule === module.id ? (
                                        <input
                                            type="text"
                                            defaultValue={module.name}
                                            onBlur={(e) => handleEditModuleName(module.id, e.target.value)}
                                            autoFocus
                                            className="border rounded p-2 w-2/3"
                                        />
                                    ) : (
                                        <h2
                                            className="text-lg font-semibold"
                                            onDoubleClick={() => setEditingModule(module.id)}
                                        >
                                            {module.name}
                                        </h2>
                                    )}
                                    {canEdit && <HiPencil
                                        className="text-gray-600 hover:text-blue-500 cursor-pointer ml-2"
                                        onClick={() => setEditingModule(module.id)}
                                    />}
                                </div>
                                {canEdit && <DeleteButton iconOnly={true} onDelete={() => handleDeleteModule(module.id)} />}
                            </div>
                            <ul className="mt-4 space-y-2">
                                {module.submodules?.map((item, itemIndex) => {
                                    if (!item.published && isStudent) { return <div></div> }
                                    return (<button
                                        key={item.id}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate(`?module=${module.id}&submodule=${itemIndex}`);
                                        }}
                                        className="flex justify-between w-full items-center p-2 border rounded-lg bg-gray-100 hover:bg-gray-200"
                                    >
                                        <div>
                                            <h3 className="text-sm font-semibold">{`${index + 1}.${itemIndex + 1} ${item.name}`}</h3>
                                        </div>
                                        {!isStudent ? <div className="flex">
                                            <span
                                                className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${item.published
                                                    ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                                                    : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300'
                                                    }`}
                                            >
                                                {item.published
                                                    ? 'Published'
                                                    : 'Not published'}
                                            </span>
                                            {/* <HiDocumentText className="text-gray-600" /> */}
                                        </div> : <div>
                                            <span
                                                className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${item.completed?.includes(user.id)
                                                    ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                                                    : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300'
                                                    }`}
                                            >
                                                {item.completed?.includes(user.id)
                                                    ? 'Completed'
                                                    : 'Not completed'}
                                            </span>
                                        </div>}
                                    </button>
                                    )
                                })}
                                {canEdit &&
                                    <button
                                        onClick={() => handleAddPage(module.id)}
                                        className="flex items-center text-blue-600 hover:underline mt-6"
                                    >
                                        <HiPlus className="mr-2" /> New Submodule
                                    </button>}
                            </ul>
                        </div>
                    )
                    )
                )
                }
            </div>
            {canEdit && <button
                onClick={handleAddModule}
                className="mt-4 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
            >
                + Add New Module
            </button>}
        </div>
    );
};

export const ModulePage = () => {
    const { course } = useCourses(); // Get the course data if necessary
    const { isStudent, user, isAdmin } = useAuth();
    const { school } = useSchool();
    const { loading, module, submodule, updateSubmodule, deleteSubmodule, addFilesToSubmodule, removeFileFromSubmodule } = useModules();

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const submoduleId = searchParams.get("submodule");

    const { showPopUp } = usePopUp();
    const { showNotification } = useNotification();

    const uploadFiles = (files) => {
        addFilesToSubmodule(module.id, files);
    }

    const handleFileUpload = (e) => {
        e.preventDefault();
        showPopUp(<FileUpload onUpload={uploadFiles} />);
    }

    const handleDeleteFile = async (file) => {
        console.log("Deleting file: ", file);
        // await removeLearningMaterial(course.id, file);
        await removeFileFromSubmodule(module.id, file);
    }

    const handleOpen = async (file) => {
        console.log("Opening file: ", file);
        const url = await getFileUrl(`institutions/${school.id}/courses/${course.id}/learningMaterials/modules/${module.id}/${submoduleId}/${file}`);
        if (url) {
            window.open(url, '_blank');
        } else {
            showNotification(NotificationTypes.DANGER, 'Error: Unable to open file');
        }
    }

    const handleUpdateDescription = async (rawContent) => {
        await updateSubmodule(module.id, { description: rawContent });
    }

    const handlePublishConfirm = async () => {
        await updateSubmodule(module.id, { published: !submodule.published });
    }

    const handlePublish = (e) => {
        e.preventDefault();
        showPopUp(<ConfirmPopup label={submodule.published ? "Unpublish" : "Publish"} icon={<HiOutlineExclamationTriangle />} onConfirm={handlePublishConfirm} color={"blue"} description={`Are you sure you want to ${submodule.published ? "unpublish" : "publish"}: ${submodule.name}`} />);
    }

    const handleDelete = async () => {
        await deleteSubmodule(module.id);
        navigate(-1);
    }

    if (!course || !module || !submodule) {
        return <div>Loading...</div>
    }

    const canEdit = (course.admin === user.id || isAdmin);

    const isCompleted = submodule.completed?.includes(user.id);
    const setCompleted = async (markComplete) => {
        try {
            await updateSubmodule(module.id, { completed: markComplete ? [...submodule.completed ?? [], user.id] : submodule.completed.filter(c => c !== user.id) });
            showNotification(markComplete ? NotificationTypes.SUCCESS : NotificationTypes.INFO, `Marked submodule: ${markComplete ? "complete" : "incomplete"}`);
        }
        catch (error) {
            console.error(error);
            showNotification(NotificationTypes.DANGER, `An error occured.`);
        }
    }

    return (
        <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen w-full h-full">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 h-full flex flex-col">
                <div className="p-6 flex items-center justify-between">
                    <ReturnButton />
                    <h2 className="text-2xl font-semibold">
                        {course.name}
                    </h2>
                    <div>
                        {canEdit ? (<GenericButton className="mt-10" label={submodule.published ? "Unpublish" : "Publish"} handleClick={handlePublish} icon={submodule.published ? <HiEyeOff /> : <HiEye />} />) : (<div className="flex items-center space-x-2">
                            <span
                                className={`w-28 text-sm ${isCompleted ? "text-green-500" : "text-gray-700"}`}
                            >
                                {isCompleted ? "Completed!" : "Not Completed"}
                            </span>
                            <div
                                onClick={() => setCompleted(!isCompleted)}
                                className={`relative inline-flex items-center cursor-pointer w-11 h-6 rounded-full ${isCompleted ? "bg-green-500" : "bg-gray-300"
                                    }`}
                            >
                                <span
                                    className={`${isCompleted ? "translate-x-5" : "translate-x-1"
                                        } inline-block w-4 h-4 transform bg-white rounded-full transition`}
                                ></span>
                            </div>
                        </div>)}
                    </div>
                </div>
                <h2 className="text-xl text-semibold flex justify-center py-5">
                    {module.name} - {submodule.name}
                </h2>
                <div className="flex space-x-6 mt-6">
                    {/* Left Container for Module Description */}
                    <div className="w-3/4 bg-white border rounded-lg">
                        {!canEdit ? <RichTextField initialContent={submodule.description} /> : <RichTextEditor initialContent={submodule.description} onSave={handleUpdateDescription} />}
                    </div>

                    {/* Right Container for Selected Item Files */}
                    <div className="w-1/4 p-6 bg-white border rounded-lg shadow-lg flex flex-col justify-between">
                        <div className="flex flex-col justify-start">
                            <h3 className="text-lg font-semibold mb-4">Files</h3>
                            {loading ? <SkeletonLoaderRow /> :
                                submodule.files.length > 0 ? <FileList files={submodule.files} handleDelete={handleDeleteFile} handleOpen={handleOpen} simple={true} /> : <p>No files.</p>}
                        </div>
                        {canEdit && <div className="w-full flex items-center justify-center">
                            <GenericButton icon={<HiUpload />} label="Upload" handleClick={handleFileUpload} />
                        </div>}
                    </div>
                </div>

                {canEdit && <div className="mt-10 flex flex-col justify-center items-center">
                    {/* <button onClick={handlePublish} className="mt-10 m-5 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300">
                                <div className="flex items-center gap-4 px-4 py-2">
                                    {assignment.published ? <HiEyeOff /> : <HiEye />}
                                    <p>{assignment.published ? "Unpublish Assignment" : "Publish Assignment"}</p>
                                </div>
                            </button> */}
                    <DeleteButton label={"Delete"} onDelete={handleDelete} />
                </div>}
            </div>
        </div>
    );
};

const CreateSubmodule = ({ moduleId, addSubmodule }) => {
    const { showPopUp } = usePopUp();
    const [name, setName] = useState("");

    const onClose = () => {
        showPopUp(null);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        addSubmodule(moduleId, name);
        onClose();
    }

    return (
        <div className={`fixed inset-0 z-50 flex items-center justify-center`}>
            <div className="relative w-full max-w-2xl bg-white rounded-lg shadow dark:bg-gray-800 overflow-hidden max-h-screen p-4">
                <div className="overflow-auto max-h-[80vh]">
                    <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
                        <h3 className="text-lg font-medium text-gray-900 dark:text-gray-300 mb-2">Create Submodule</h3>
                        <button
                            onClick={onClose}
                            type="button"
                            className="text-red-600 bg-transparent hover:bg-red-100 hover:text-red-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center justify-center dark:hover:bg-red-800 dark:hover:text-red-400"
                            style={{ marginTop: '8px', marginRight: '8px' }}  // Extra marge toegevoegd
                        >
                            <span className="sr-only">Close modal</span>
                            <HiX />
                        </button>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Submodule name</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                                placeholder="Enter submodule name"
                                required
                            />
                        </div>

                        <button type="submit" className="w-full justify-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300">
                            Create Module
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

const CreateModule = ({ course, addModule }) => {
    const { showPopUp } = usePopUp();
    const [name, setName] = useState("");

    const onClose = () => {
        showPopUp(null);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        addModule(name);
        onClose();
    }

    return (
        <div className={`fixed inset-0 z-50 flex items-center justify-center`}>
            <div className="relative w-full max-w-2xl bg-white rounded-lg shadow dark:bg-gray-800 overflow-hidden max-h-screen p-4">
                <div className="overflow-auto max-h-[80vh]">
                    <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
                        <h3 className="text-lg font-medium text-gray-900 dark:text-gray-300 mb-2">Create Module</h3>
                        <button
                            onClick={onClose}
                            type="button"
                            className="text-red-600 bg-transparent hover:bg-red-100 hover:text-red-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center justify-center dark:hover:bg-red-800 dark:hover:text-red-400"
                            style={{ marginTop: '8px', marginRight: '8px' }}  // Extra marge toegevoegd
                        >
                            <span className="sr-only">Close modal</span>
                            <HiX />
                        </button>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Module name</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                                placeholder="Enter module name"
                                required
                            />
                        </div>

                        <button type="submit" className="w-full justify-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300">
                            Create Module
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ModulesOverview;