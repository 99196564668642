import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import Roles from "../utils/roles";

/**
 * Hook for authentication functionality
 * @returns 
 */
const useAuth = () => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }

    return context; // Access all context values directly
};

// TODO
const handleProfilePictureUpload = async () => { }

// TODO
const handleNameChange = async () => { }

// TODO
const handlePasswordChange = async () => { }

export default useAuth;