import React from 'react';
import logo from '../../assets/images/Courseta_Logo.png';
import { HiArrowRight } from 'react-icons/hi';
import Thumbnail from './Thumbnail';
import useSchool from '../../hooks/useSchool';
import useCourses from '../../hooks/useCourses';
import useUsers from '../../hooks/useUsers';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import CourseCard from '../courses/CourseCard';

const CoursesScroller = () => {
    const { courses } = useCourses();
    const { school } = useSchool();
    const { getUserByID } = useUsers();
    const { role, isStudent } = useAuth();

    const navigate = useNavigate();
    // const courses = [
    //     {
    //         title: "Figma for Beginners: Fundamentals of Figma App",
    //         author: "Richard Guava",
    //         progress: 25,
    //         modules: 420,
    //         image: logo
    //     },
    //     {
    //         title: "Complete Web Design: from Figma to Webflow",
    //         author: "Richard Guava",
    //         progress: 50,
    //         modules: 1020,
    //         image: logo
    //     },
    //     {
    //         title: "Figma 2023: The Absolute Beginner to Pro Class",
    //         author: "Richard Guava",
    //         progress: 89,
    //         modules: 2810,
    //         image: logo
    //     },
    //     {
    //         title: "Mastering Managing Project with Notion",
    //         author: "Richard Guava",
    //         progress: 75,
    //         modules: 1320,
    //         image: logo
    //     },
    // ];

    return (
        <div className="relative p-4 bg-white rounded-md border dark:bg-gray-800 dark:text-gray-200">
            {/* Header with title and View All button */}
            <div className="flex justify-between items-center mb-3">
                <h2 className="text-lg font-semibold">Recent Courses</h2>
                <button
                    className="text-xs font-medium text-blue-500 hover:underline flex items-center"
                    onClick={() => navigate(`/${role}/courses`)}
                >
                    View All <HiArrowRight className="ml-1" />
                </button>
            </div>

            {/* Course Cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                {courses.map((course, index) => {
                    return <CourseCard courseId={course.id} />
                    // const admin = getUserByID(course.admin);
                    // return (
                    //     <div
                    //         key={index}
                    //         className="bg-white dark:bg-gray-700 rounded-md border p-4 flex flex-col justify-between"
                    //     >
                    //         <div>
                    //             <Thumbnail thumbnailURL={`institutions/${school.id}/courses/${course.id}/${course.thumbnail}`} />
                    //             <h3 className="text-sm font-semibold text-gray-800 dark:text-gray-200">{course.title}</h3>
                    //             <p className="text-xs text-gray-600 dark:text-gray-400 mb-2">By {admin?.firstName} {admin?.lastName}</p>
                    //             {isStudent ? <div> <div className="w-full bg-gray-200 rounded-full h-1.5 mb-2">
                    //                 <div
                    //                     className="bg-blue-500 h-1.5 rounded-full"
                    //                     style={{ width: `${course.progress}%` }}
                    //                 ></div>
                    //             </div>
                    //                 <p className="text-xs text-gray-600 dark:text-gray-400">
                    //                     {course.progress}% Complete - {course.modules} Modules
                    //                 </p>
                    //             </div>
                    //                 : <p className="text-xs text-gray-600 dark:text-gray-400">{course.attendees?.length} Attendees</p>}</div>
                    //         <button
                    //             onClick={(e) => { e.preventDefault(); navigate(`/${role}/courses/${course.id}`) }}
                    //             type="button"
                    //             className="w-full flex items-center justify-center border rounded-md font-medium mt-4 px-3 py-1.5 bg-blue-500 text-white text-sm hover:bg-blue-600"
                    //         >
                    //             View Course
                    //             <HiArrowRight className="ml-2 text-base" />
                    //         </button>
                    //     </div>
                    // )
                })}
            </div>
        </div>
    );
};

export default CoursesScroller;
