import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "./firebase"

/**
 * Gets the download URL for a local storage path
 * @param {String} path The relative path (e.g. "scenarios/test.png")
 * @returns The full download URL that can be used to download the file
 */
export const getFileUrl = async (path) => {
    const storageRef = ref(storage, path);

    try {
        const url = await getDownloadURL(storageRef);
        return url;
    } catch (error) {
        // console.error(error);
        return null;
    }
}

/**
 * Uploads a file to firebase storage
 * @param {*} file The file you wish to upload
 * @param {String} path The local path you want to store it to (e.g. "scenarios/test.png")
 */
export const uploadFile = async (file, path) => {
    const storageRef = ref(storage, `${path}/${file.name}`);
    try {
        // Upload file to the reference location
        await uploadBytes(storageRef, file);
    } catch (e) {
        console.error("Error uploading file to firebase: " + e);
    }
}

/**
 * Deletes a file from firebase storage
 * @param {String} path The local path of the file you wish to delete (e.g. "scenarios/test.png")
 * @returns 
 */
export const deleteFile = async (path) => {
    // Create a reference to the storage object
    const fileRef = ref(storage, path);

    try {
        // Delete the file from storage
        await deleteObject(fileRef);
        console.log(`Successfully deleted file: ${path}`);
        return true;

    } catch (error) {
        console.error('Error deleting file:', error);
        return false;
    }
};