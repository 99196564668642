import React, { createContext, useState, useEffect } from 'react';
import { subscribeCollection } from '../firebase/firestore';
import useSchool from '../hooks/useSchool';
import { getFunctions, httpsCallable } from "firebase/functions";
import { NotificationTypes } from "../utils/notifications";
import { useNotification } from '../hooks/useNotification';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/firebase';
import { useNavigate } from 'react-router-dom';

const RegistrationsContext = createContext();

const RegistrationErrorCodes = {
    400: "Bad Request: Missing fields",
    401: "Unauthorized: No token provided",
    403: "Forbidden: Insufficient permissions",
    409: "Conflict: User already registered",
}

/**
 * The context for all authentication related data such as the signed in registration data, authentication loading or authentication error messages
 * @param {*} children The provider's children
 * @returns 
 */
const RegistrationsProvider = ({ children }) => {
    const { school } = useSchool();
    const [registrations, setRegistrations] = useState([]);
    const [registrationsMap, setRegistrationsMap] = useState(new Map());
    const [loading, setLoading] = useState(true);

    const { showNotification } = useNotification();
    const navigate = useNavigate();

    const addRegistration = async (registration) => {
        setLoading(true);
        try {
            // Initialize Firebase Functions
            const functions = getFunctions();
            const addRegistrationFn = httpsCallable(functions, "addRegistration"); // Replace with your Cloud Function name

            // Call the Cloud Function
            const result = await addRegistrationFn(registration);

            // Process the result
            console.log('Added registration successfully. Token:', result.data.token);
            showNotification(NotificationTypes.SUCCESS, `Added registration successfully.`);
            return result.data.token; // Return the token if needed
        } catch (error) {
            console.error('Error:', error);
            showNotification(NotificationTypes.DANGER, `Error: ${error.message}`);
        }
    };

    const deleteRegistration = async (registrationId) => {
        setLoading(true);
        try {
            // Initialize Firebase Functions
            const functions = getFunctions();
            const deleteRegistrationFn = httpsCallable(functions, "deleteRegistration"); // Replace with your Cloud Function name

            // Call the Cloud Function
            const result = await deleteRegistrationFn({ registrationId: registrationId, schoolId: school.id });

            // Process the result
            console.log('Deleted registration successfully.');
            showNotification(NotificationTypes.SUCCESS, `Deleted registration successfully.`);
            return result.data.token; // Return the token if needed
        } catch (error) {
            console.error('Error:', error);
            showNotification(NotificationTypes.DANGER, `Error: ${error.message}`);
        }
    }

    const completeRegistration = async (registration, email) => {
        setLoading(true);
        try {
            // Initialize Firebase Functions
            const functions = getFunctions();
            const completeRegistrationFn = httpsCallable(functions, "completeRegistration"); // Replace with your Cloud Function name

            // Call the Cloud Function
            await completeRegistrationFn(registration);

            // Process the result
            console.log('Completed registration successfully.');
            showNotification(NotificationTypes.SUCCESS, `Completed registration successfully.`);
            await signInWithEmailAndPassword(auth, email, registration.password);
        } catch (error) {
            console.error('Error:', error);
            showNotification(NotificationTypes.DANGER, `Error: ${error.message}`);
        }
    }

    //The school context listens to changes to school ID and loads the school data for the newly set school ID on changes
    useEffect(() => {
        if (school) {
            const unsubscribe = subscribeCollection(`institutions/${school.id}/registrations`, (data) => {
                console.log("Refreshed school registrations: ", data)
                setRegistrations(data);
                setLoading(false);
                if (data)
                    setRegistrationsMap(new Map(data.map((item) => [item.id, item])));
                else
                    setRegistrationsMap(new Map());
            });

            return () => unsubscribe(); // Cleanup on unmount
        }
        else {
            console.log("Cleared registrations");
            setRegistrations([]);
            setRegistrationsMap(new Map());
            setLoading(true);
        }
    }, [school])

    return (
        <RegistrationsContext.Provider value={{ registrations, registrationsMap, loading, addRegistration, completeRegistration, deleteRegistration }}>
            {children}
        </RegistrationsContext.Provider>
    );
}

export { RegistrationsProvider, RegistrationsContext };