import React, { useEffect } from 'react';
import useScenarios from '../../../hooks/useScenarios';
import { prettyPrintEnum } from '../../../utils/ScriptingEnumLibrary';
import useScenarioCustomization from '../../../hooks/useScenarioCustomization';

export default function PathoField({ options, pathoSystem, fieldName, customDisplay = null }) {
    const { patient, setPatientField } = useScenarioCustomization();

    const updatePathoField = (mapIndex, finding, value) => {
        const patho = { ...patient.patho };

        if (!patho[mapIndex]) {
            patho[mapIndex] = {};
        }

        patho[mapIndex][finding] = value;
        setPatientField('patho', patho);
    };

    const getPathoField = (system, finding) => {
        return patient.patho[system]?.[finding];
    }

    return <select
        value={getPathoField(pathoSystem, fieldName) ?? Object.keys(options)[0]}
        onChange={(e) => {
            const value = Number(e.target.value);
            updatePathoField(pathoSystem, fieldName, value);
        }
        }
        className="w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:text-gray-200"
    >
        {
            Object.entries(options).map(([key, value]) => (
                <option key={value} value={value}>
                    {customDisplay ? customDisplay(value) : prettyPrintEnum(key)}
                </option>
            ))
        }
    </select >;
}