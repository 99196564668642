import { HiBookOpen } from "react-icons/hi2";
import Searchbar from "../../components/shared/Searchbar";
import DataTable from "../../components/shared/Datatable";
import { useState } from "react";
import useAssignments from "../../hooks/useAssignments";
import useUsers from "../../hooks/useUsers";
import defaultImage from '../../assets/images/22.png';
import { useNavigate } from "react-router-dom";
import ReturnButton from "../../components/shared/ReturnButton";
import useCourses from "../../hooks/useCourses";
import useGrades from "../../hooks/useGrades";
import GenericButton from "../../components/shared/GenericButton";
import { HiPlus } from "react-icons/hi";
import CreateAssignment from "../../components/assignments/CreateAssignment";
import { usePopUp } from "../../hooks/usePopUp";

const AdminAssignments = () => {
    //Has a assignment been selected?
    const { assignment } = useAssignments();

    if (assignment) {
        return <AssignmentDetails assignment={assignment} />
    }
    else {
        return <AssignmentsOverview />
    }
}

const AssignmentsOverview = () => {
    const { assignments, loading, deleteAssignment } = useAssignments();
    const [filteredAssignments, setFilteredAssignments] = useState(assignments);

    const { getGradesForAssignment } = useGrades();

    const navigate = useNavigate();
    const { showPopUp } = usePopUp();
    const { getCourseById } = useCourses();

    const columns = [
        {
            key: "name",
            label: "ASSIGNMENT NAME",
            render: (name, assignment) => {
                return (
                    <div className="text-sm font-medium text-gray-900 dark:text-white">
                        {name}
                    </div>);
            }
        },
        {
            key: "courseId",
            label: "COURSE NAME",
            render: (courseId, assignment) => {
                //Get course by courseId
                const course = getCourseById(courseId);

                if (!course) {
                    return <div>Deleted course</div>
                }
                return (
                    <div className="flex items-center space-x-4">
                        <div>
                            <div className="text-sm font-medium text-gray-900 dark:text-white">
                                {`${course.name}`}
                            </div>
                        </div>
                    </div>);
            }
        },
        {
            key: "submissions",
            label: "SUBMISSIONS",
            render: (_, assignment) => {
                const submissions = getGradesForAssignment(assignment.id).length;
                const attendees = getCourseById(assignment.courseId)?.attendees.length;
                return (<div>{`${submissions} out of ${attendees}`}</div>)
            }
        },
        {
            key: "createdAt",
            label: "DATE CREATED",
            render: (date, assignment) => {
                const formattedDate = date?.toDate().toISOString().split('T')[0];
                return (<div>{formattedDate}</div>)
            }
        },
        {
            key: "published",
            label: "STATUS",
            render: (published, assignment) => {
                return (
                    <td className="px-6 py-4">
                        <span
                            className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${published
                                ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                                : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300'
                                }`}
                        >
                            {published
                                ? 'Published'
                                : 'Not published'}
                        </span>
                    </td>);
            }
        }
    ];

    const handleCreate = () => {
        showPopUp(<CreateAssignment />);
    }

    return (
        <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen w-full h-full">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 h-full flex flex-col">
                <div className="flex justify-between items-center space-x-2 p-8">
                    <div className="flex items-center ">
                        <HiBookOpen className="text-4xl text-gray-700 dark:text-gray-300" />
                        <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Assignments
                        </h1>
                    </div>
                    <GenericButton icon={<HiPlus />} label={"Create Assignment"} handleClick={handleCreate} />
                </div>

                {/* Searchbar */}
                <Searchbar
                    data={assignments} // Pass the original assignments array
                    searchLabel={"assignment name"}
                    onFilter={setFilteredAssignments} // Update the filtered data
                    filterKeys={["name"]}
                />

                {/* Data table */}
                <div className="flex-grow">
                    <DataTable
                        rows={filteredAssignments}
                        columns={columns}
                        isLoading={loading}
                        onDeleteClick={(assignment) => {
                            deleteAssignment(assignment.id);
                        }}
                        onEditClick={(assignmentId) => {
                            navigate(`${assignmentId}?edit`);
                        }}
                        onViewClick={(assignmentId) => {
                            navigate(`${assignmentId}`);
                        }} />
                </div>
            </div>
        </div>
    )
}

const AssignmentDetails = ({ assignment }) => {
    return (
        <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen w-full h-full">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 h-full flex flex-col">
                <div className="flex justify-start mb-4">
                    <ReturnButton className="w-300" />
                </div>
                <div className="flex items-center space-x-2 p-8">
                    <HiBookOpen className="text-4xl text-gray-700 dark:text-gray-300" />
                    <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                        {assignment.name}
                    </h1>
                </div>
            </div>
        </div>);
}

export default AdminAssignments;