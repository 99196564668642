import React from "react";
import useAuth from "../../hooks/useAuth";

import {
    HiDocumentText,
    HiChartPie,
    HiCollection,
    HiTicket,
    HiBeaker,
    HiPencilAlt,
    HiCalendar,
    HiLibrary,
    HiNewspaper,
    HiAcademicCap,
    HiUser,
    HiChartBar,
    HiHeart,
    HiFlag
} from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom/dist";
import Roles from "../../utils/roles";

/**
 * Defines all sidebar items and the roles that should see them
 */
const items = [
    { label: 'Dashboard', path: 'dashboard', roles: [Roles.STUDENT, Roles.TEACHER, Roles.ADMIN], icon: HiChartPie },
    // { label: 'Analytics', path: 'analytics', roles: [Roles.ADMIN, Roles.SCHOOLADMIN, Roles.STUDENT, Roles.TEACHER], icon: HiChartBar },
    { label: 'Assignments', path: 'assignments', roles: [Roles.STUDENT, Roles.TEACHER, Roles.ADMIN], icon: HiCalendar },
    { label: 'Library', path: 'library', roles: [Roles.TEACHER, Roles.ADMIN], icon: HiLibrary },
    { label: 'Courses', path: 'courses', roles: [Roles.TEACHER, Roles.ADMIN, Roles.STUDENT], icon: HiNewspaper },
    // { label: 'Schools', path: 'schools', roles: [Roles.ADMIN], icon: HiBeaker },
    { label: 'Grades', path: 'grades', roles: [Roles.STUDENT, Roles.TEACHER, Roles.ADMIN], icon: HiAcademicCap },
    // { label: 'Sim Studio', path: 'simstudio', roles: [Roles.ADMIN, Roles.TEACHER, Roles.SCHOOLADMIN], icon: HiCollection },
    { label: 'Medications', path: 'medications', roles: [Roles.ADMIN], icon: HiHeart },
    { label: 'Conditions', path: 'conditions', roles: [Roles.ADMIN], icon: HiFlag },
    { label: 'Grade Scripting', path: 'gradesscript', roles: [Roles.ADMIN], icon: HiPencilAlt },
    // { label: 'Tokens', path: 'tokens', roles: [Roles.ADMIN], icon: HiTicket },
    { label: 'Users', path: 'users', roles: [Roles.ADMIN, Roles.TEACHER], icon: HiUser },
];

/**
 * Hook for all sidebar functionality such as filtering items based on user role
 * @returns A object with sidebar specific data
 */
const useSidebar = () => {
    const { logout, role } = useAuth();
    const navigate = useNavigate();

    //TODO: This probably should go in a sidebar hook!
    const location = useLocation();

    const authenticatedItems = items.filter((item) => item.roles.includes(role));

    const isActive = (item) => {
        return location.pathname.split("/").some(s => s === item.path);
    };

    const onItemPressed = (item) => {
        if (item.path) {
            navigate(`/${role}/${item.path}`);
        }
    }

    return { authenticatedItems, isActive, onItemPressed, logout };
}

export default useSidebar;