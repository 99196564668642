import { useState } from "react";
import { TabsUnderlined } from "../../shared/TabNavigation";
import Results from "./Results";
import EMRNotesTab from "./EMR_Notes";
import MARTab from "./MAR";
import OrdersTab from "./Orders";
import DocumentationTab from "./Documentation";
import useScenarioCustomization from "../../../hooks/useScenarioCustomization";
import { serverTimestamp, Timestamp } from "firebase/firestore";
import { useInworld } from "../../../hooks/useInworld";
import { MetaHumanImages } from "../../shared/MetaHumanImages";

const tabs = ['Chart Summary', 'Results', 'EMR Notes', 'MAR', 'Orders', 'Documentation'];

const EMRTab = () => {
    const [tab, setTab] = useState(0);


    return (
        <div className="flex flex-col flex-grow">
            <div className="container p-6 bg-white dark:bg-gray-800 dark:text-gray-200">
                <TabsUnderlined
                    activeTab={tab}
                    setActiveTab={setTab}
                    tabs={tabs}
                />
                {tabs[tab] === tabs[0] && (
                    <div>
                        <ChartSummary />
                    </div>
                )}
                {tabs[tab] === tabs[1] && (
                    <div>
                        <Results />
                    </div>
                )}
                {tabs[tab] === tabs[2] && (
                    <div>
                        <EMRNotesTab />
                    </div>
                )}
                {tabs[tab] === tabs[3] && (
                    <div>
                        <MARTab />
                    </div>
                )}
                {tabs[tab] === tabs[4] && (
                    <div>
                        <OrdersTab />
                    </div>
                )}
                {tabs[tab] === tabs[5] && (
                    <div>
                        <DocumentationTab />
                    </div>
                )}
            </div>
        </div>
    );
}

function isValidDate(date) {
    return date instanceof Date && !isNaN(date.getTime());
}

const ChartSummary = () => {
    const { patient, setPatientField } = useScenarioCustomization();
    const { characters } = useInworld();

    const chartData = patient.chartSummary

    const images = MetaHumanImages(patient.metahuman);

    const calculateAge = (birthday) => {
        const today = new Date();
        let age = today.getFullYear() - birthday.getFullYear();
        const monthDifference = today.getMonth() - birthday.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthday.getDate())) {
            age--;
        }
        return age;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedChartSummary = { ...patient.chartSummary };
        updatedChartSummary[name] = value;
        setPatientField('chartSummary', updatedChartSummary);
    };

    return (
        <div className="p-6 bg-white rounded-md border">
            <h2 className="text-2xl font-semibold mb-4">Chart Summary</h2>
            <p className="mb-4 text-gray-600">Enter in the information you want inside the patient EMR</p>

            {/* Top section with image and basic info */}
            <div className="flex items-start mb-6 space-x-6">
                <img
                    src={images.faceImage}
                    alt="Character"
                    className="w-32 h-32 object-cover rounded-md"
                />
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 flex-grow">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Name</label>
                        <input
                            type="text"
                            name="name"
                            placeholder="Enter patient name"
                            value={chartData.name}
                            onChange={handleChange}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Height</label>
                        <input
                            type="text"
                            name="height"
                            placeholder="Enter patient height"
                            value={chartData.height}
                            onChange={handleChange}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Age</label>
                        <input
                            type="text"
                            name="age"
                            value={calculateAge(chartData.birthday?.toDate() ?? new Date())}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            readOnly // Make age read-only as it is auto-calculated
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Gender</label>
                        <select
                            name="gender"
                            value={chartData.gender}
                            onChange={handleChange}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        >
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Weight</label>
                        <input
                            type="text"
                            name="weight"
                            placeholder="Enter patient weight"
                            value={chartData.weight}
                            onChange={handleChange}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Birthday</label>
                        <input
                            type="date"
                            name="birthday"
                            value={isValidDate(chartData.birthday?.toDate()) ? (chartData.birthday?.toDate()).toISOString().split('T')[0] : ""}
                            onChange={(e) => {
                                const name = "birthday";
                                const value = Timestamp.fromDate(new Date(e.target.value));
                                const updatedChartSummary = { ...patient.chartSummary };
                                updatedChartSummary[name] = value;
                                setPatientField('chartSummary', updatedChartSummary);
                            }}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                </div>
            </div>

            {/* Chart Summary Fields */}
            <div className="mb-6">
                <h3 className="text-lg font-semibold mb-2">Chart Summary</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {['roomNumber', 'admittingDiagnosis', 'allergies', 'intake', 'codeStatus', 'activeProblemList', 'output', 'lengthOfStay', 'isolation', 'provider', 'bmi'].map((field) => (
                        <div key={field}>
                            <label className="block text-sm font-medium text-gray-700">{field === 'bmi'
                                ? 'BMI'
                                : field.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}</label>
                            <input
                                type="text"
                                name={field}
                                placeholder={`Enter ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}`}
                                value={chartData[field]}
                                onChange={handleChange}
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

}


export default EMRTab;