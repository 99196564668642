import React, { useEffect, useState } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import useScenarios from '../../hooks/useScenarios';
import { MetaHumanImages } from './MetaHumanImages';
import useScenarioCustomization from '../../hooks/useScenarioCustomization';

export default function MetahumanViewToggle() {
    const [isFront, setIsFront] = useState(true);

    const { patient } = useScenarioCustomization();

    useEffect(() => { }, [patient])
    const images = MetaHumanImages(patient.metahuman || 0);

    return (
        <div className="flex flex-col items-center space-y-4 mt-6">
            <div className="flex space-x-4">
                <button
                    className={`px-4 py-2 ${isFront ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'} rounded-md`}
                    onClick={() => setIsFront(true)}
                >
                    Front Side
                </button>
                <button
                    className={`px-4 py-2 ${!isFront ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'} rounded-md`}
                    onClick={() => setIsFront(false)}
                >
                    Back Side
                </button>
            </div>
            <img
                src={isFront ? images.avatarImage1 : images.avatarImage2}
                alt={isFront ? 'Front View' : 'Back View'}
                className="w-full h-full object-cover dark:border-gray-600"
            />
            <div className="mb-6 flex items-center space-x-2">
                <p className="bg-yellow-100 dark:bg-yellow-900 text-yellow-700 dark:text-red-300 p-4 rounded">
                    This is a placeholder and does not show the choices the user makes
                </p>
                {/* Add a question mark icon */}
                <HelpOutlineIcon className="text-yellow-700 dark:text-red-300 cursor-pointer" />
            </div>
        </div>
    );
}
