import React, { useState } from "react";
import { usePopUp } from "../../hooks/usePopUp";

const FileUpload = ({ onUpload }) => {
    const [files, setFiles] = useState([]);
    const [dragActive, setDragActive] = useState(false);
    const {showPopUp} = usePopUp();

    const onClose = () => {
        showPopUp(null);
    }

    const handleFiles = (newFiles) => {
        const fileList = Array.from(newFiles);
        setFiles([...files, ...fileList]);
    };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFiles(e.dataTransfer.files);
            e.dataTransfer.clearData();
        }
    };

    const handleFileInputChange = (e) => {
        handleFiles(e.target.files);
    };

    const handleUpload = () => {
        onUpload(files);
        setFiles([]);
        onClose();
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-96">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-semibold">Upload Files</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        ✕
                    </button>
                </div>

                <div
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                    className={`border-2 border-dashed p-6 rounded-lg text-center ${
                        dragActive ? "border-blue-500 bg-blue-50" : "border-gray-300"
                    }`}
                >
                    <p className="text-gray-500">
                        Drag and drop your files here, or{" "}
                        <label
                            htmlFor="file-upload"
                            className="text-blue-500 cursor-pointer underline"
                        >
                            browse
                        </label>
                    </p>
                    <input
                        type="file"
                        id="file-upload"
                        multiple
                        className="hidden"
                        onChange={handleFileInputChange}
                    />
                </div>

                <ul className="mt-4 space-y-2">
                    {files.map((file, index) => (
                        <li
                            key={index}
                            className="text-sm text-gray-700 flex justify-between items-center"
                        >
                            <span>{file.name}</span>
                            <button
                                onClick={() =>
                                    setFiles(files.filter((_, i) => i !== index))
                                }
                                className="text-red-500 hover:text-red-700"
                            >
                                Remove
                            </button>
                        </li>
                    ))}
                </ul>

                <div className="flex justify-end mt-4">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 text-gray-700 bg-gray-200 rounded hover:bg-gray-300 mr-2"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleUpload}
                        className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                        disabled={!files.length}
                    >
                        Upload
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FileUpload;
