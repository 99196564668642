import React, { createContext, useState, useEffect } from 'react';
import { addDocument, deleteDocument, subscribeCollection, updateDocument } from '../firebase/firestore';
import useSchool from '../hooks/useSchool';
import useCourses from '../hooks/useCourses';
import { useSearchParams } from 'react-router-dom';
import { sortByDate } from '../utils/analytics';
import { useNotification } from '../hooks/useNotification';
import { NotificationTypes } from '../utils/notifications';
import { deleteFile, uploadFile } from '../firebase/storage';

const ModulesContext = createContext();

/**
 * The context for all authentication related data such as the signed in user data, authentication loading or authentication error messages
 * @param {*} children The provider's children
 * @returns 
 */
const ModulesProvider = ({ children }) => {
    const { school } = useSchool();
    const { course } = useCourses();
    const [modules, setModules] = useState([]);
    const [modulesMap, setModulesMap] = useState(new Map());
    const [loading, setLoading] = useState(true);
    const [module, setModule] = useState(null);
    const [submodule, setSubmodule] = useState(null);
    const [searchParams] = useSearchParams();
    const { showNotification } = useNotification();

    useEffect(() => {
        const moduleId = searchParams.get("module");
        const edit = searchParams.get("edit");
        if (moduleId) {
            setModule(modulesMap.get(moduleId));
        }
    }, [searchParams, modules])

    useEffect(() => {
        const submoduleId = searchParams.get("submodule");
        if (module && submoduleId) {
            console.log("Set submodule: ", module.submodules?.[submoduleId]);
            setSubmodule(module.submodules?.[submoduleId]);
        }
        else {
            setSubmodule(null);
        }
    }, [module, searchParams])

    //The school context listens to changes to school ID and loads the school data for the newly set school ID on changes
    useEffect(() => {
        if (school && course) {
            const unsubscribe = subscribeCollection(`institutions/${school.id}/courses/${course.id}/modules`, (data) => {
                console.log("Refreshed course modules: ", data)
                const sorted = data.sort((a, b) => a.order - b.order);
                setModules(sorted);
                if (data)
                    setModulesMap(new Map(data.map((item) => [item.id, item])));
                else
                    setModulesMap(new Map());
                setLoading(false);
            });

            return () => unsubscribe(); // Cleanup on unmount
        }
        else {
            console.log("Cleared modules");
            setModules([]);
            setModulesMap(new Map());
            setLoading(true);
        }
    }, [course])

    const addModule = async (moduleName) => {
        try {
            if (!course) {
                throw new Error("Error adding module: no course!");
            }
            setLoading(true);
            await addDocument({ name: moduleName, order: modules.length }, `institutions/${school.id}/courses/${course.id}/modules`);
            showNotification(NotificationTypes.SUCCESS, `Module: ${moduleName} added successfully.`);
        }
        catch (error) {
            showNotification(NotificationTypes.DANGER, "An error occured.");
        }
    }

    const removeModule = async (moduleId) => {
        if (!course) {
            throw new Error("Error removing module: no course!");
        }
        setLoading(true);

        try {
            const moduleToDelete = modulesMap.get(moduleId);
            if (!moduleToDelete) {
                throw new Error("Error removing module: no module with that ID!");
            }
            const deletedOrder = moduleToDelete.order;

            const modulesToUpdate = modules.filter(m => m.order > deletedOrder);

            let updatePromises = modulesToUpdate.map((mod) => updateDocument(mod.id, `institutions/${school.id}/courses/${course.id}/modules`, { order: mod.order - 1 }));
            updatePromises.push(deleteDocument(moduleId, `institutions/${school.id}/courses/${course.id}/modules`));
            await Promise.all(updatePromises);
            showNotification(NotificationTypes.SUCCESS, "Module removed successfully.");
        }
        catch (error) {
            console.error("Error removing module:", error);
            showNotification(NotificationTypes.DANGER, "An error occured.");
        }
    }

    const updateModule = async (moduleId, data) => {
        if (!course) {
            throw new Error("Error updating module: no course!");
        }

        const currentModule = modulesMap.get(moduleId);
        if (!currentModule) {
            throw new Error("Error updating module: no module with that ID!");
        }

        const isDataIdentical = Object.keys(data).every(
            (key) => currentModule[key] === data[key]
        );

        if (isDataIdentical) {
            console.log("No changes detected, skipping update.");
            return;
        }

        setLoading(true);
        try {
            await updateDocument(moduleId, `institutions/${school.id}/courses/${course.id}/modules`, data);
            showNotification(NotificationTypes.SUCCESS, "Module updated successfully.");
        } catch (error) {
            console.error("Error updating modules:", error);
            showNotification(NotificationTypes.DANGER, "An error occured.");
        }
    };

    const addSubmodule = async (moduleId, submoduleName) => {
        if (!course) {
            throw new Error("Error updating module: no course!");
        }

        const currentModule = modulesMap.get(moduleId);
        if (!currentModule) {
            throw new Error("Error updating module: no module with that ID!");
        }

        setLoading(true);

        try {
            const updatedSubmodules = [...currentModule.submodules ?? [], { name: submoduleName, files: [], published: false }];
            await updateDocument(moduleId, `institutions/${school.id}/courses/${course.id}/modules`, {
                submodules: updatedSubmodules
            });
            showNotification(NotificationTypes.SUCCESS, "Added submodule successfully.");
        }
        catch (error) {
            console.error("Error updating modules:", error);
            showNotification(NotificationTypes.DANGER, "An error occured.");
        }
    }

    const updateSubmodule = async (moduleId, data) => {
        if (!course) {
            throw new Error("Error updating submodule: no course!");
        }

        const currentModule = modulesMap.get(moduleId);
        if (!currentModule) {
            throw new Error("Error updating submodule: no module with that ID!");
        }
        const submoduleId = Number(searchParams.get("submodule"));

        const updatedSubmodules = [...currentModule.submodules];
        updatedSubmodules[submoduleId] = { ...currentModule.submodules[submoduleId], ...data };

        console.log(updatedSubmodules);
        setLoading(true);
        try {
            await updateDocument(moduleId, `institutions/${school.id}/courses/${course.id}/modules`, { submodules: updatedSubmodules });
        } catch (error) {
            console.error("Error updating submodules:", error);
            showNotification(NotificationTypes.DANGER, "An error occured.");
        }
    };

    const deleteSubmodule = async (moduleId) => {
        try {
            if (!course) {
                throw new Error("Error updating submodule: no course!");
            }

            const currentModule = modulesMap.get(moduleId);
            if (!currentModule) {
                throw new Error("Error updating submodule: no module with that ID!");
            }
            const submoduleId = Number(searchParams.get("submodule"));

            const updatedSubmodules = currentModule.submodules.filter((s, index) => index !== submoduleId);

            console.log(updatedSubmodules);
            setLoading(true);
            await updateDocument(moduleId, `institutions/${school.id}/courses/${course.id}/modules`, { submodules: updatedSubmodules });
            showNotification(NotificationTypes.SUCCESS, "Submodule deleted successfully.");
        } catch (error) {
            console.error("Error deleting submodule:", error);
            showNotification(NotificationTypes.DANGER, "An error occured.");
        }
    };

    const addFilesToSubmodule = async (moduleId, files) => {
        if (!course) {
            throw new Error("Error updating submodule: no course!");
        }

        const currentModule = modulesMap.get(moduleId);
        if (!currentModule) {
            throw new Error("Error updating submodule: no module with that ID!");
        }
        if (!submodule) {
            throw new Error("Error updating submodule: no submodule!");
        }

        const submoduleId = Number(searchParams.get("submodule"));

        setLoading(true);
        try {
            for (const file of files) {
                if (submodule.files?.includes(file.name)) {
                    showNotification(NotificationTypes.WARNING, `Material ${file.name} already exists.`);
                    continue;
                }
                console.log(`Uploading file: ${file.name} to ${course.id}(${course.name})`);
                await uploadFile(file, `institutions/${school.id}/courses/${course.id}/learningMaterials/modules/${module.id}/${submoduleId}`);
                console.log("Succesfully uploaded material:", file);
                showNotification(NotificationTypes.SUCCESS, `Uploaded file: ${file.name}.`);
            }
            const filteredFiles = files.map(f => f.name).filter(f => !submodule.files?.includes(f));

            if (JSON.stringify(submodule.files) !== JSON.stringify([...submodule.files, ...filteredFiles])) {
                await updateSubmodule(moduleId, { files: [...submodule.files, ...filteredFiles] });
            }
            else {
                setLoading(false);
            }
        } catch (e) {
            console.log("Error uploading submodule material", e);
            showNotification(NotificationTypes.DANGER, "An error occured.");
        }
    };

    const removeFileFromSubmodule = async (moduleId, file) => {
        if (!course) {
            throw new Error("Tried to remove learning material from non-existent course!");
        }
        const currentModule = modulesMap.get(moduleId);
        if (!currentModule) {
            throw new Error("Error updating submodule: no module with that ID!");
        }
        if (!submodule) {
            throw new Error("Error updating submodule: no submodule!");
        }

        const submoduleId = Number(searchParams.get("submodule"));

        setLoading(true);
        try {
            await deleteFile(`institutions/${school.id}/courses/${course.id}/learningMaterials/modules/${module.id}/${submoduleId}/${file}`);
            await updateSubmodule(moduleId, {
                files: submodule.files.filter(f => f !== file)
            });
            console.log("Succesfully removed file from submodule.");
            showNotification(NotificationTypes.SUCCESS, `Removed file: ${file}.`);
        }
        catch (e) {
            console.log("Error removing file", e);
            showNotification(NotificationTypes.DANGER, "An error occured.");
        }
    }

    return (
        <ModulesContext.Provider value={{ modules, loading, module, submodule, addModule, removeModule, updateModule, addSubmodule, updateSubmodule, addFilesToSubmodule, removeFileFromSubmodule, deleteSubmodule }}>
            {children}
        </ModulesContext.Provider>
    );
}

export { ModulesProvider, ModulesContext };