import { useEffect, useState } from "react";
import { getFileUrl } from "../../firebase/storage";
import useSchool from "../../hooks/useSchool";
import { usePopUp } from "../../hooks/usePopUp";

const EditPopup = ({ label, data, updateData, baseUrl, editName = true }) => {
    const { school } = useSchool();
    const [name, setName] = useState(data.name || "");
    const [thumbnail, setThumbnail] = useState(null);
    const [preview, setPreview] = useState(null);

    const { showPopUp } = usePopUp();

    useEffect(() => {
        const fetchPicture = async () => {
            if (data.thumbnail) {
                const url = await getFileUrl(`${baseUrl}/${data.thumbnail}`);
                setPreview(url);
            }
        }
        fetchPicture();
    }, [])

    const onClose = () => {
        showPopUp(null);
    }

    const handleThumbnailChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setThumbnail(file);
            setPreview(URL.createObjectURL(file));
        }
    };

    const handleSave = async () => {
        // onSave({ name, thumbnail });
        onClose();
        updateData(data.id, name, thumbnail);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-96 p-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                    Edit {label}
                </h2>

                {/* Course Name */}
                {editName && <div className="mb-4">
                    <label
                        htmlFor="data-name"
                        className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                    >
                        {label} Name
                    </label>
                    <input
                        id="data-name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:ring-blue-500 focus:border-blue-500 bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        placeholder="Enter name"
                    />
                </div>}

                {/* Thumbnail */}
                <div className="mb-4">
                    <label
                        htmlFor="thumbnail"
                        className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                    >
                        Thumbnail Image
                    </label>
                    <input
                        id="thumbnail"
                        type="file"
                        accept="image/*"
                        onChange={handleThumbnailChange}
                        className="w-full text-gray-900 bg-gray-50 border rounded-lg file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-600 hover:file:bg-blue-100 dark:bg-gray-700 dark:text-white"
                    />
                    {preview && (
                        <div className="mt-4">
                            <img
                                src={preview}
                                alt="Thumbnail Preview"
                                className="w-full h-64 object-cover rounded-lg border dark:border-gray-600"
                            />
                        </div>
                    )}
                </div>

                {/* Actions */}
                <div className="flex justify-end space-x-3">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSave}
                        className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EditPopup;