import React from "react";
import { FaCheckCircle, FaCircle } from "react-icons/fa";

const Steps = ({ steps, currentStep }) => {
    const getStatus = (index) => {
        if (index < currentStep) return "Completed";
        if (index === currentStep) return "In Progress";
        return "Pending";
    };

    return (
        <div className="p-6">
            <div className="flex justify-between items-center">
                {steps.map((step, index) => {
                    const isActive = index === currentStep;
                    const isCompleted = index < currentStep;

                    return (
                        <React.Fragment key={index}>
                            {/* Step Item */}
                            <div className="flex flex-col items-center text-center">
                                <div
                                    className={`w-10 h-10 rounded-full flex items-center justify-center 
                                    ${isActive ? "bg-blue-500 text-white" : isCompleted ? "bg-green-500 text-white" : "bg-gray-300 text-gray-500"}`}
                                >
                                    {isCompleted ? (
                                        <FaCheckCircle className="text-white" />
                                    ) : (
                                        isActive ? <FaCircle /> : <div />
                                    )}
                                </div>
                                <span
                                    className={`mt-2 text-sm ${isActive
                                        ? "text-blue-500 font-semibold"
                                        : isCompleted
                                            ? "text-green-500"
                                            : "text-gray-600"
                                        }`}
                                >
                                    {step}
                                </span>
                                <span
                                    className={`mt-1 text-xs ${isActive
                                        ? "text-blue-500"
                                        : isCompleted
                                            ? "text-green-500"
                                            : "text-gray-500"
                                        }`}
                                >
                                    {getStatus(index)}
                                </span>
                            </div>

                            {/* Connecting Line */}
                            {index < steps.length - 1 && (
                                <div
                                    className={`flex-grow h-1 mb-12 mx-2 ${isCompleted
                                        ? "bg-green-500"
                                        : isCompleted
                                            ? "bg-blue-500"
                                            : "bg-gray-300"
                                        }`}
                                ></div>
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export default Steps;
