import React, { useState, useEffect } from 'react';
import useScenarios from '../../../hooks/useScenarios';
import { OrderTypes, prettyPrintEnum } from '../../../utils/ScriptingEnumLibrary';
import GenericButton from '../../shared/GenericButton';
import { HiPlus } from 'react-icons/hi';
import Accordion from '../../shared/Accordion.js';
import { useNotification } from '../../../hooks/useNotification.js';
import { NotificationTypes } from '../../../utils/notifications.js';
import useScenarioCustomization from '../../../hooks/useScenarioCustomization.js';

const OrderCard = ({ order, index, type }) => {
    const { patient, setPatientField } = useScenarioCustomization();

    const handleRemoveOrder = () => {
        const updatedOrders = { ...patient.orders };
        updatedOrders[type.toLowerCase()] = updatedOrders[type.toLowerCase()].filter((o, i) => i !== index);
        setPatientField('orders', updatedOrders);
    };

    const handleUpdateOrder = (updatedOrder) => {
        const updatedOrders = { ...patient.orders };
        updatedOrders[type.toLowerCase()][index] = updatedOrder;
        setPatientField('orders', updatedOrders);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedOrder = { ...order, [name]: value };
        handleUpdateOrder(updatedOrder);
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        const updatedOrder = { ...order, [name]: checked };
        handleUpdateOrder(updatedOrder);
    };

    return (
        <div className="border p-4 rounded-lg shadow-sm mb-4">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold">{order.name}</h3>
                <button
                    onClick={() => handleRemoveOrder()}
                    className="text-red-500 hover:text-red-700"
                >
                    &#10005;
                </button>
            </div>
            <input
                type="text"
                name="name"
                value={order.name}
                onChange={handleInputChange}
                placeholder={`Enter ${type} Order`}
                className="w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 mb-4"
            />
            <div className="flex space-x-4 items-center">
                <label className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        name="present"
                        checked={order.present}
                        onChange={handleCheckboxChange}
                        className="text-blue-500 border-gray-300 rounded focus:ring-blue-500"
                    />
                    <span>Present</span>
                </label>
                <label className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        name="physicianRequired"
                        checked={order.physicianRequired}
                        onChange={handleCheckboxChange}
                        className="text-blue-500 border-gray-300 rounded focus:ring-blue-500"
                    />
                    <span>Physician Required</span>
                </label>
            </div>
        </div>
    );
};

const OrdersSection = ({ type }) => {
    const { patient, setPatientField } = useScenarioCustomization();

    const orders = patient.orders[type.toLowerCase()] ?? [];

    const handleAddOrder = (e) => {
        e.preventDefault();

        const updatedOrders = { ...patient.orders };
        if (!updatedOrders[type.toLowerCase()]) {
            updatedOrders[type.toLowerCase()] = []
        }
        updatedOrders[type.toLowerCase()].push({ name: "", present: false, physicianRequired: false });
        setPatientField('orders', updatedOrders);
    };

    return (
        <Accordion
            key={type}
            title={prettyPrintEnum(type)}
        // onRemove={() => {
        //     removeResultCategory(categoryName);
        // }}
        // isRemovable={!defaultCategories[categoryName]}
        >
            {orders.map((order, index) => (
                <OrderCard
                    key={index}
                    order={order}
                    index={index}
                    type={type}
                />
            ))}
            {/* {!defaultCategories[categoryName] && <div className="mt-4 flex items-center">
                <input
                    type="text"
                    value={newResultName}
                    onChange={(e) => setNewResultName(e.target.value)}
                    placeholder="Enter new result name"
                    className="border rounded px-4 py-2 mr-2 focus:outline-none focus:ring focus:border-blue-300"
                />
                <button
                    onClick={() => addResult(categoryName)}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    Add New Result
                </button>
            </div>} */}
            <GenericButton
                handleClick={handleAddOrder}
                label={'Add Order'}
                icon={<HiPlus />}
            />
        </Accordion>
    );
};

const OrdersTab = () => {
    const { scenario, updatePatientArrayMap } = useScenarios();
    const [ordersData, setOrdersData] = useState(() => {
        const existingOrders = scenario?.patients?.[0]?.orders || {};
        return Object.keys(OrderTypes).reduce((acc, type) => {
            if (type.toLowerCase() !== 'medication') {
                acc[type.toLowerCase()] = existingOrders[type.toLowerCase()] || {};
            }
            return acc;
        }, {});
    });

    useEffect(() => {
        const existingOrders = scenario?.patients?.[0]?.orders || {};
        setOrdersData(
            Object.keys(OrderTypes).reduce((acc, type) => {
                if (type.toLowerCase() !== 'medication') {
                    acc[type.toLowerCase()] = existingOrders[type.toLowerCase()] || {};
                }
                return acc;
            }, {})
        );
    }, [scenario]);

    const handleSetOrders = (type, newOrders) => {
        // Remove orderName from all orders before saving
        const sanitizedOrders = Object.fromEntries(
            Object.entries(newOrders).map(([key, value]) => {
                const { orderName, ...sanitizedValue } = value;
                return [key, sanitizedValue];
            })
        );

        const newOrdersData = {
            ...ordersData,
            [type.toLowerCase()]: sanitizedOrders,
        };
        setOrdersData(newOrdersData);

        // Save to Firebase
        updatePatientArrayMap('orders', newOrdersData);
    };

    return (
        <div className="p-6 bg-white rounded-md border">
            <h2 className="text-2xl font-semibold mb-4">Orders</h2>
            <p className="mb-4 text-gray-600">Enter what orders you want your student to see in the EMR</p>
            {Object.keys(OrderTypes).filter(type => type.toLowerCase() !== 'medications').map(type => (
                <OrdersSection
                    key={OrderTypes[type]}
                    type={type}
                    orders={ordersData[type.toLowerCase()] || {}}
                    setOrders={(newOrders) => handleSetOrders(type, newOrders)}
                />
            ))}
        </div>
    );
};

export default OrdersTab;
