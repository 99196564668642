import React, { useEffect, useState } from 'react';
import { TabsUnderlined, } from "../../shared/TabNavigation";
import SideSteps from '../../shared/SideSteps';
import useScenarios from '../../../hooks/useScenarios';
import { HiPlus, HiTrash, HiUpload } from 'react-icons/hi';
import useScenarioCustomization from '../../../hooks/useScenarioCustomization';
import { getFileUrl } from '../../../firebase/storage';
import { useNotification } from '../../../hooks/useNotification';
import { usePopUp } from '../../../hooks/usePopUp';
import { capitalize } from 'lodash';
import GenericButton from '../../shared/GenericButton';
import SingleFileUpload from '../../shared/SingleFileUpload';
import { NotificationTypes } from '../../../utils/notifications';


const ScenarioFile = ({ fieldName }) => {
    const { scenario, setFile, getFile } = useScenarioCustomization();

    const { showNotification } = useNotification();
    const { showPopUp } = usePopUp();

    const handleOpen = async () => {
        const url = await getFileUrl(getFile(fieldName));
        if (url) {
            window.open(url, '_blank');
        } else {
            showNotification(NotificationTypes.DANGER, 'Error: Unable to open file');
        }
    }

    const handleUpload = async (file) => {
        setFile(file, fieldName);
    }

    return (
        <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Scenario {capitalize(fieldName)}</label>
            <div className="flex items-center gap-4 m-4">
                {scenario[fieldName] ?
                    <button
                        onClick={() => handleOpen()}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-xs font-medium text-blue-600 hover:underline"
                    >
                        {scenario[fieldName]}
                    </button> : <p>No {fieldName} file selected.</p>}
                <GenericButton icon={<HiUpload />} label={"Upload"} handleClick={() => { showPopUp(<SingleFileUpload onUpload={handleUpload} />) }} />
            </div>
        </div>
    );
};

export default ScenarioFile;